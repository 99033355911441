/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { organizationConfig } from "@/app/ams/config/organization/organizationConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import Form from "@/app/ams/components/Form";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useFormScroll, formLogic, organizationFormConfig } from "@/app/ams/config/organization/organizationFormConfig";
import ToolBar from "@/app/ams/components/ToolBar";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
import Bread from "@/app/ams/components/Bread";
import { fetchServicesAPI, fetchOrganizationServiceOrdersAPI, fetchOrganizationProductOrdersAPI, fetchIdentityListAPI } from '@/app/ams/services/api';
import { serviceConfig } from '@/app/ams/config/service/serviceConfig';
import { productConfig } from '@/app/ams/config/product/productConfig';
export default function Organization() {
  const { showForm, setShowForm, selectedOrganization, setSelectedOrganization, organizations, loading, fetchOrganizations, debugMode } = useAmsContext();
  const [formData, setFormData] = useState({});
  const [currentView, setCurrentView] = useState('default');
  const views = [ 'services', 'serviceOrders', 'products', 'productOrders', 'beauticians', 'tickets', 'ticketRecords', 'branches' ];

  // 可以定義一個常量來管理所有可能的視圖類型
  const VIEW_TYPES = {
    DEFAULT: 'default',
    SERVICES: 'services',
    SERVICE_ORDERS: 'serviceOrders',
    PRODUCTS: 'products',
    PRODUCT_ORDERS: 'productOrders',
    BEAUTICIANS: 'beauticians',
    TICKETS: 'tickets',
    TICKET_RECORDS: 'ticketRecords',
    BRANCHES: 'branches'
  };

  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    console.log('organizationsConfig.detail', organizationConfig);
    console.log('organizations', organizations);
  }, []);


  useEffect(() => {
    fetchOrganizations();
  }, [debugMode]);

  useEffect(() => {
    if (organizations?.length > 0 && !selectedOrganization) {
      const firstOrganization = organizations[0];
      const organizationWithImage = {
        ...firstOrganization,
        imageUrl: firstOrganization?.blobs?.[0]?.url || '/images/default-organization.jpg',
        specs: firstOrganization.specs || [],
        description: firstOrganization.description || '',
        notification: firstOrganization.notification || '',
        status: firstOrganization.status || 'On Sale',
        provider: firstOrganization.provider || '',
        category: firstOrganization.category || {},
        tags: firstOrganization.tags || [],
        recommendation: firstOrganization.recommendation || null,
        publishDate: firstOrganization.publishDate || null
      };
      setSelectedOrganization(organizationWithImage);
    }
  }, [organizations, selectedOrganization]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleRowClick = (record) => {
    if ( currentView === 'default' ) {
      if (selectedOrganization?.key !== record.key) {
        setSelectedOrganization(record);
      }
    }
  };

  const handleFormChange = (changedValues) => {
    setFormData(changedValues);
  };

  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">{organizationFormConfig.organization.title}</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/organization'}
        >
          {organizationFormConfig.organization.title}
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">{organizationFormConfig.organization.add}</span>
      </div>
    </div>
  );

  const renderTabs = () => {
    return (
      <div className="flex gap-4 my-2">
        {organizationConfig.tabs.map((tab) => (
          <Button
            key={tab.key}
            className={`${activeTab === tab.key
              ? "bg-[#90C290] hover:bg-[#7AB27A] text-white"
              : "bg-white hover:bg-gray-100 border border-gray-200"
              } px-3 py-2 rounded-full`}
            onClick={() => {
              setActiveTab(tab.key);
              switch (tab.key) {
                case '1':
                  setCurrentView(VIEW_TYPES.SERVICES);
                  break;
                case '2':
                  setCurrentView(VIEW_TYPES.SERVICE_ORDERS);
                  break;
                case '3':
                  setCurrentView(VIEW_TYPES.PRODUCTS);
                  break;
                case '4':
                  setCurrentView(VIEW_TYPES.PRODUCT_ORDERS);
                  break;
                case '5':
                  setCurrentView(VIEW_TYPES.BEAUTICIANS);
                  break;
                case '6':
                  setCurrentView(VIEW_TYPES.TICKETS);
                  break;
                case '7':
                  setCurrentView(VIEW_TYPES.TICKET_RECORDS);
                  break;
                case '8':
                  setCurrentView(VIEW_TYPES.BRANCHES);
                  break;
                case '9':
                  setCurrentView(VIEW_TYPES.DEFAULT);
                  break;
                default:
                  break;
              }
            }}
          >
            {tab.label}
          </Button>
        ))}
      </div>
    );
  };

  const ShowOrganizationServices = () => {
    const [services, setServices] = useState([]);
    const [servicesLoading, setServicesLoading] = useState(false);
    const productType = serviceConfig.apis.product_type;
    useEffect(() => {
      const res = async () => {
        setServicesLoading(true);
        const res = await fetchServicesAPI(productType, selectedOrganization?.id);
        // 確保每個服務項目都有唯一的 key
        const servicesWithKeys = res?.map(service => ({
          ...service,
          key: service.id || `service-${Math.random()}`
        }));
        setServices(servicesWithKeys);
        setServicesLoading(false);
      }
      res();
    }, [selectedOrganization]);

    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.services} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                currentView,
              })}
              dataSource={services}
              loading={servicesLoading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowOrganizationServicesOrders = () => {
    const [serviceOrders, setServiceOrders] = useState([]);
    const [serviceOrdersLoading, setServiceOrdersLoading] = useState(false);
    const productType = serviceConfig.apis.product_type;
    useEffect(() => {
      const res = async () => {
        setServiceOrdersLoading(true);
        const res = await fetchOrganizationServiceOrdersAPI(productType, selectedOrganization?.id);
        if (res.length > 0) {
          setServiceOrders(res);
        }
        setServiceOrdersLoading(false);
      }
      res();
    }, [selectedOrganization]);
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name || 'unset store name'}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.serviceOrders} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                currentView,
              })}
              dataSource={serviceOrders}
              loading={serviceOrdersLoading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowOrganizationProducts = () => {
    // const [products, setProducts] = useState([]);
    // const productType = productConfig?.apis?.product_type;
    // const [productsLoading, setProductsLoading] = useState(false);
    // useEffect(() => {
    // const res = async () => {
    //   setProductsLoading(true);
    //   const res = await fetchServicesAPI(productType, selectedOrganization?.id);
    //   // 確保每個產品項目都有唯一的 key
    //   const productsWithKeys = res.map(product => ({
    //       ...product,
    //       key: product.id || `product-${Math.random()}`
    //     }));
    //     setProducts(productsWithKeys);
    //     setProductsLoading(false);
    //   }
    //   res();
    // }, [selectedOrganization]);
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name || 'unset store name'}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.products} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                setSelectedOrganization,
                organizations,
              })}
              dataSource={''}
              // loading={''}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowOrganizationProductsOrders = () => {
    const [productOrders, setProductOrders] = useState([]);
    const [productOrdersLoading, setProductOrdersLoading] = useState(false);
    const productType = productConfig.apis.product_type;
    useEffect(() => {
      const res = async () => {
        setProductOrdersLoading(true);
        const res = await fetchOrganizationProductOrdersAPI(productType, selectedOrganization?.id);
        if (res.length > 0) {
          setProductOrders(res);
        }
        setProductOrdersLoading(false);
      }
      res();
    }, [selectedOrganization]);
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name || 'unset store name'}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.productOrders} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                currentView,
              })}
              dataSource={productOrders}
              loading={productOrdersLoading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowOrganizationBeauticians = () => {
    const [beauticians, setBeauticians] = useState([]);
    const [beauticiansLoading, setBeauticiansLoading] = useState(false);
    
    useEffect(() => {
      const res = async () => {
        setBeauticiansLoading(true);
        const res = await fetchIdentityListAPI(selectedOrganization?.id);
        
        // 從每個對象中提取 providers 數組並合併
        const allBeauticians = res?.reduce((acc, item) => {
          if (item.providers && Array.isArray(item.providers)) {
            // 為每個美容師添加唯一的 key
            const providersWithKeys = item.providers.map(provider => ({
              ...provider,
              key: provider.id || `beautician-${Math.random()}`
            }));
            return [...acc, ...providersWithKeys];
          }
          return acc;
        }, []);
        
        setBeauticians(allBeauticians);
        console.log('beauticians', allBeauticians);
        
        setBeauticiansLoading(false);
      }
      res();
    }, [selectedOrganization]);

    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name || 'unset store name'}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.beauticians} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                setSelectedOrganization,
                organizations,
              })}
              dataSource={beauticians}
              loading={beauticiansLoading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowOrganizationTickets = () => {
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name || 'unset store name'}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.tickets} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                setSelectedOrganization,
                organizations,
              })}
              dataSource={''}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowOrganizationBranches = () => {
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name || 'unset store name'}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.branches} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                setSelectedOrganization,
                organizations,
              })}
              dataSource={''}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowOrganizationTicketRecords = () => {
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-bold">{selectedOrganization?.name || 'unset store name'}</div>
            </div>
            <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.ticketRecords} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.organization} type="organization" /> */}
            <Table
              columns={organizationConfig.columns({
                setCurrentView,
                setSelectedOrganization,
                organizations,
              })}
              dataSource={''}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedOrganization}
              type="organization"
              items={organizationConfig?.detail?.(selectedOrganization, true)}
            />
          </div>
        </div>
      </>
    );
  };


  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">

        <div className="flex-1 flex overflow-auto">
          {showForm ? (
            <>
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-hidden">
                  <BreadcrumbNav />
                  <Form
                    formConfig={organizationFormConfig}
                    formLogic={formLogic}
                    useFormScroll={useFormScroll}
                    title={organizationFormConfig.organization.title}
                    draftText={organizationFormConfig.organization.draft}
                    publishText={organizationFormConfig.organization.publish}
                    onChange={handleFormChange}
                  />
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail data={formData} items={organizationConfig.detail(formData, false)} />
                </div>
              </div>
            </>
          )
            :
            currentView === VIEW_TYPES.SERVICES ?
              <ShowOrganizationServices />
              :
              currentView === VIEW_TYPES.SERVICE_ORDERS ?
                <ShowOrganizationServicesOrders />
                :
                currentView === VIEW_TYPES.PRODUCTS ?
                  <ShowOrganizationProducts />
                  :
                  currentView === VIEW_TYPES.PRODUCT_ORDERS ?
                    <ShowOrganizationProductsOrders />
                    :
                    currentView === VIEW_TYPES.BEAUTICIANS ?
                      <ShowOrganizationBeauticians />
                      :
                      currentView === VIEW_TYPES.TICKETS ?
                        <ShowOrganizationTickets />
                        :
                        currentView === VIEW_TYPES.TICKET_RECORDS ?
                          <ShowOrganizationTicketRecords />
                          :
                          currentView === VIEW_TYPES.BRANCHES ?
                            <ShowOrganizationBranches />
                            :
                              (
                                <>
                                  <div className="flex w-full overflow-auto bg-gray-50">
                                    <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                                      <div className="flex justify-between items-center">
                                        <div className="text-lg font-bold">{organizationFormConfig.organization.title}</div>
                                        <Button
                                          type="primary"
                                          icon={<PlusOutlined />}
                                          onClick={handleShowForm}
                                          className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                                        >
                                          {organizationFormConfig.organization.add}
                                        </Button>
                                      </div>
                                      <Bread title={organizationConfig.page.title} path="organization" subTitle={organizationConfig.page.add} />
                                      <Statistics data={statisticsConfig?.statistics?.organization} type="organization" />
                                      <Table
                                        columns={organizationConfig.columns({
                                          setCurrentView,
                                          currentView,
                                        })}
                                        dataSource={organizations.map(org => ({
                                          ...org,
                                          key: org.id || org.key
                                        }))}
                                        loading={loading}
                                        pagination={{
                                          pageSize: 10,
                                          className: "sticky bottom-0 pb-2",
                                        }}
                                        onRow={(record) => ({
                                          onClick: () => handleRowClick(record),
                                          className: record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : "",
                                        })}
                                        rowClassName={(record) => `cursor-pointer ${record.key === (selectedOrganization?.key ?? '') ? "bg-gray-100" : ""}`}
                                        scroll={{ x: 2000 }}
                                        size="small"
                                        className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                                      />
                                    </div>
                                    <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                                      <Detail
                                        data={selectedOrganization}
                                        type="organization"
                                        items={Array.isArray(organizationConfig?.detail?.(selectedOrganization, 'organization')) ? organizationConfig?.detail?.(selectedOrganization, 'organization') : []}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
        </div>
      </div>
    </div>
  );
};
