/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useAuth } from '@/contexts/AuthProvider'
import moment from 'moment';

import VoiceList from './VoiceList'
import VoicePanel from './VoicePanel'
import VoiceConfig from './VoiceConfig'

import { fetchProfile, fetchSamples } from './hooks/voice.api'
import VoiceInit from './VoiceInit'

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const tabList = [
  {
    key: 0,
    label: '通話',
  },
  // {
  //   key: 1,
  //   label: '會議',
  // },
];

const VoiceInterface = ({ room, switchTo, lineBound }) => {
  const { userId, token } = useAuth()
  const [items, setItems] = useState([])
  const [activeId, setActiveId] = useState('')
  const [loading, setLoading] = useState(true)
  const [tabs, setTabs] = useState(0)
  const [profile, setProfile] = useState(false)
  const [timeInterval, setTimeInterval] = useState([moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().add(1, 'days').format('YYYY-MM-DD')])

  useEffect(() => {
    setActiveId('')
    setProfile(false)
    fetchProfileData()
    fetchItems()
    console.log('userId', userId);
    console.log('tabs', tabs);
    
    
  }, [room])

  useEffect(() => {
    refresh()
  }, [timeInterval])

  const refresh = async (unselect = false) => {
    await fetchProfileData()
    fetchItems()
    if (unselect) {
      setActiveId('')
    }
  }

  const fetchProfileData = async () => {
    setLoading(true)
    try {
      const data = await fetchProfile(room.id, token)
      setProfile(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchItems = async () => {
    setLoading(true)
    try {
      const data = await fetchSamples(room.id, token, timeInterval[0], timeInterval[1])
      if (data.length > 0) {
        setItems(data)
      } else {
        setItems([])
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onChangeTab = (key) => {
    setTabs(key)
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  if (profile === false) {
    return (
      <div className="flex flex-col items-center justify-center w-2/3 self-center mx-auto gap-4">
        <div className="text-2xl font-bold text-textLight">立即建立語音模組</div>
        <VoiceInit roomId={room.id} token={token} refresh={refresh} />
      </div>
    )
  }

  return (
    <div className="w-full flex flex-row">
      <VoiceList
        items={items}
        activeId={activeId}
        setActiveId={setActiveId}
        loading={loading}
        tabs={tabList}
        onChangeTab={onChangeTab}
        switchTo={switchTo}
        lineBound={lineBound}
        roomId={room.id}
        token={token}
        refresh={refresh}
        timeInterval={timeInterval}
        setTimeInterval={setTimeInterval}
      />
      {
        activeId.length > 0 ?
          <VoicePanel room={room} token={token} itemId={activeId} tagGroupId={profile.body.tag_group_id} passedItem={items.filter(x => x.id === activeId)} parentRefresh={refresh} />
          :
          <div className="flex flex-row items-center justify-center w-full h-full text-2xl font-bold text-textLight">
            請選擇語音記錄
          </div>
      }
      <VoiceConfig room={room} token={token} profile={profile} />
    </div>
  )
}

export default VoiceInterface