/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Modal, Input, Button, Skeleton } from 'antd'
import { AddASRTagToProfile, fetchHotel, updateHotelInfo, updateHotelKeywords, createHotel, createCustomTag } from './hooks/voice.api'
import TSTags from './TSTags'

export const HotelModal = ({ roomId, token, open, close, hotel, create, refresh }) => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [abbrev, setAbbrev] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [originalHotelInfo, setOriginalHotelInfo] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const checkValid = name.length > 0 && abbrev.length > 0 && address.length > 0 && phoneNumber.length > 0 && website.length > 0 && keywords.length > 0;

  useEffect(() => {
    setLoading(true);
    if (create) {
      // clear states
      setName('');
      setAbbrev('');
      setAddress('');
      setPhoneNumber('');
      setWebsite('');
      setKeywords([]);
      setLoading(false);
    }
    hotel && !create && fetchHotel(roomId, token, hotel.id).then((data) => {
      setName(data.body.name);
      setAbbrev(data.body.abbrev);
      setAddress(data.body.address);
      setPhoneNumber(data.body.phone_number);
      setWebsite(data.body.website);
      setKeywords(data.body.keywords);
      setOriginalHotelInfo(data.body);
      setLoading(false);
    });
    setLoading(false);
  }, [hotel, create])

  const handleCreate = async () => {
    setSubmitting(true);
    const newHotel = {
      name: name,
      abbrev: abbrev,
      address: address,
      phone_number: phoneNumber,
      website: website,
      keywords: keywords,
    }

    await createHotel(roomId, token, newHotel);
    close(false);
    refresh();
    setSubmitting(false);
  }

  if (create) {
    return (
      <Modal
        title={"建立新目標飯店"}
        open={open}
        onCancel={() => close(false)}
        footer={null}
      >
        <div className="flex flex-col gap-4 mt-4">
          <div className="flex flex-row gap-2 items-center">
            <div className="w-24 text-sm font-medium">飯店名稱</div>
            <Input className="text-sm" value={name} onChange={(e) => setName(e.target.value)} disabled={submitting} />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="w-24 text-sm font-medium">飯店簡稱</div>
            <Input className="text-sm" value={abbrev} onChange={(e) => setAbbrev(e.target.value)} disabled={submitting} />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="w-24 text-sm font-medium">飯店地址</div>
            <Input className="text-sm" value={address} onChange={(e) => setAddress(e.target.value)} disabled={submitting} />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="w-24 text-sm font-medium">飯店電話</div>
            <Input className="text-sm" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} disabled={submitting} />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="w-24 text-sm font-medium">飯店網站</div>
            <Input className="text-sm" value={website} onChange={(e) => setWebsite(e.target.value)} disabled={submitting} />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="w-[77.65px] text-sm font-medium h-[30px]">關鍵字</div>
            <TSTags tags={keywords} setTags={setKeywords} disabled={submitting} maxLength={20} addNewWord="新增關鍵字" />
          </div>
          <div className="flex flex-row justify-end mt-2 items-center gap-2">
            <div className="text-xs text-textLight">
              建立後即無法刪除，僅可編輯
            </div>
            <Button type="primary" onClick={handleCreate} loading={submitting} disabled={!checkValid}>儲存</Button>
          </div>
        </div>
      </Modal>
    )
  }

  if (!hotel) return null;

  const handleSubmit = async () => {
    setSubmitting(true);
    const newHotelInfo = {
      name: name,
      abbrev: abbrev,
      address: address,
      phone_number: phoneNumber,
      website: website,
    }

    // compare difference, and update each field with single api call
    for (const key in newHotelInfo) {
      if (newHotelInfo[key] !== originalHotelInfo[key]) {
        await updateHotelInfo(roomId, token, hotel.id, key, newHotelInfo[key]);
      }
    }

    // handle keywords
    if (keywords !== originalHotelInfo.keywords) {
      await updateHotelKeywords(roomId, token, hotel.id, keywords);
    }

    close(false);
    refresh();
    setSubmitting(false);
  }

  return (
    <Modal
      title={hotel.name}
      open={open}
      onCancel={() => close(false)}
      footer={null}
    >
      <div className="flex flex-col gap-4 mt-4">
        {
          loading ?
            <Skeleton />
            :
            <>
              <div className="flex flex-row gap-2 items-center">
                <div className="w-24 text-sm font-medium">飯店名稱</div>
                <Input className="text-sm" value={name} onChange={(e) => setName(e.target.value)} disabled={submitting} />
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="w-24 text-sm font-medium">飯店簡稱</div>
                <Input className="text-sm" value={abbrev} onChange={(e) => setAbbrev(e.target.value)} disabled={submitting} />
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="w-24 text-sm font-medium">飯店地址</div>
                <Input className="text-sm" value={address} onChange={(e) => setAddress(e.target.value)} disabled={submitting} />
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="w-24 text-sm font-medium">飯店電話</div>
                <Input className="text-sm" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} disabled={submitting} />
              </div>
              <div className="flex flex-row gap-2 items-center">
                <div className="w-24 text-sm font-medium">飯店網站</div>
                <Input className="text-sm" value={website} onChange={(e) => setWebsite(e.target.value)} disabled={submitting} />
              </div>
              <div className="flex flex-row gap-2 items-start">
                <div className="min-w-[77.65px] text-sm font-medium h-[30px]">關鍵字</div>
                <TSTags tags={keywords} setTags={setKeywords} disabled={submitting} maxLength={20} addNewWord="新增關鍵字" />
              </div>
              <div className="flex flex-row justify-end mt-2">
                <Button type="primary" onClick={handleSubmit} loading={submitting} disabled={!checkValid}>儲存</Button>
              </div>
            </>
        }
      </div>
    </Modal>
  )
}

export const TagModal = ({ roomId, token, open, close, refresh, tagGroupId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [newName, setNewName] = useState('');

  const handleCreate = async () => {
    setSubmitting(true);
    await createCustomTag(roomId, token, tagGroupId, newName);
    close(false);
    refresh();
    setSubmitting(false);

    // clear
    setNewName('');
  }

  return (
    <Modal
      title={"建立新標籤"}
      open={open}
      onCancel={() => close(false)}
      footer={null}
    >
      <div className="flex flex-col gap-4 mt-4">
        <Input placeholder="建立後即無法刪除" className="text-sm" value={newName} onChange={(e) => setNewName(e.target.value)} disabled={submitting} />
        <div className="flex flex-row justify-end mt-2">
          <Button type="primary" loading={submitting} disabled={newName.length === 0} onClick={handleCreate}>儲存</Button>
        </div>
      </div>
    </Modal>
  )
}

export const KeywordModal = ({ roomId, token, open, close, refresh }) => {
  const [keyword, setKeyword] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleCreate = async () => {
    setSubmitting(true);
    await AddASRTagToProfile(roomId, token, keyword)
    setKeyword('');
    close(false);
    setSubmitting(false);
    refresh();
  }

  return (
    <Modal
      title={"建立新常用詞彙"}
      open={open}
      onCancel={() => close(false)}
      footer={null}
    >
      <div className="flex flex-col items-end gap-2 pt-2">
        <Input
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          maxLength={20}
          placeholder="常用詞彙可以幫助 AI 分析文本"
          allowClear
          disabled={submitting}
        />
        <Button
          type="primary"
          onClick={handleCreate}
          loading={submitting}
        >
          儲存
        </Button>
      </div>
    </Modal>
  )
}