/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuth } from '@/contexts/AuthProvider';
import axios from 'axios';
import { message } from 'antd';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const MemberList = ({ id }) => {
  const { token } = useAuth()
  const [members, setMembers] = useState([]);

  useEffect(() => {
    setMembers([])

    const fetchMembers = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/private/chatrooms/${id}/members`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        // console.log("Members: ", response.data)
        setMembers(response.data);
      } catch (error) {
        message.error(JSON.stringify(error.response?.data?.detail));
      }
    };

    fetchMembers();
  }, [id]);

  return (
    <div className="flex flex-col min-w-60 h-full items-start justify-start p-4 bg-bgMedium overflow-scroll">
      <h3 className="mb-2 self-start">成員 ({members.length > 0 ? members.length : "..."})</h3>
      {
        members.length > 0 &&
        <ul>
          {members.map(member => (
            <div className="flex flex-row gap-2 min-w-full text-sm my-1 items-center h-8" key={member.id}>
              {/* <img className="h-9 w-9 rounded-full" src={member.photo_url ? member.photo_url : "./logo192.png"} alt={member.username} /> */}
              <div className="truncate">{member.nickname}</div>
            </div>
          ))}
        </ul>
      }
    </div>
  );
}

export default MemberList;