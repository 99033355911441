import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Skeleton, Modal, Tooltip, notification } from "antd";
import { FileOutlined, HighlightOutlined } from "@ant-design/icons";
import axios from "axios";
import { useAuth } from "@/contexts/AuthProvider";

import aiAvatar from "../assets/aiAvatar.png";
import userAvatar from "../assets/userAvatar.png";
import { ReactComponent as Play } from "../assets/play.svg";
import { ReactComponent as Pause } from "../assets/pause.svg";
// import 'katex/dist/katex.min.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const OFFSET_HOUR = 8;

const openNotification = (title, description, type = 'info') => {
  notification[type]({
    message: title,
    description: description,
    placement: "bottomRight",
  });
};

const ImageModal = ({ token, open, url, onCancel }) => {
  return (
    <Modal open={open} onCancel={onCancel} footer={null} width={600} centered>
      <a href={url} target="_blank" rel="noreferrer">
        <div className="text-xs text-textLight underline font-normal cursor-pointer mb-4">
          下載
        </div>
      </a>
      <img src={url} alt={url} style={{ width: "100%", height: "auto" }} />
    </Modal>
  );
};

const Message = ({ type, message, roomId }) => {
  const { token } = useAuth();
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [uploading, setUploading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");
  const audioRef = useRef(null);

  if (message) {
    if (message.text) {
      if (message.text === "#.aFaKeMeSsAgE") {
        return null;
      }
    }
  }

    

  const handleOpenImageModal = (url) => {
    setCurrentImageUrl(url);
    setShowImageModal(true);
  };

  const handlePostText = async (text) => {
    setUploading(true);
    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/text/" + roomId,
        {
          text: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      openNotification("文字檔案記憶成功送出！", "稍後即可在記憶模式中查看。", "success");
    } catch (error) {
      openNotification(
        "發生錯誤",
        JSON.stringify(error.response?.data?.detail)
      , "error");
    } finally {
      setUploading(false);
    }
  };

  const handlePostFiles = async (blobs) => {
    setUploading(true);
    try {
      const formData = new FormData();
      blobs.forEach((blob) => {
        formData.append("files", blob);
      });

      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/file/" + roomId,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      openNotification(
        "檔案記憶成功送出！",
        "若檔案類型支援，稍後即可在記憶模式中查看。"
      , "success");
    } catch (error) {
      openNotification(
        "發生錯誤",
        JSON.stringify(error.response?.data?.detail)
      , "error");
    } finally {
      setUploading(false);
    }
  };

  const handleQuickMemorize = async (message) => {
    openNotification("記憶中", "請保持網路連線。", "info");
    if (message.text) {
      await handlePostText(message.text);
      return;
    }
    if (message.blob) {
      const response = await axios.get(message.blob, {
        responseType: "blob",
      });
      const fetchedBlob = new File(
        [response.data],
        message.blob.split("/")[message.blob.split("/").length - 1],
        { type: response.headers["content-type"] }
      );
      await handlePostFiles([fetchedBlob]);
      return;
    }
  };

  const baseOuterStyle = "flex flex-row items-start my-3 gap-1 w-full group";
  const selfOuterStyle = "self-end justify-end";
  const otherOuterStyle = "self-start justify-start";
  const messageOuterStyle = `${baseOuterStyle} ${
    type === "self" ? selfOuterStyle : otherOuterStyle
  }`;

  const baseInnerStyle =
    "px-3 py-2 text-sm font-medium text-black rounded-lg w-fit max-w-[320px] overflow-hidden h-fit";
  const selfInnerStyle = "bg-primaryMedium";
  const otherInnerStyle = "bg-white";
  const messageInnerStyle = `${baseInnerStyle} ${
    type === "self" ? selfInnerStyle : otherInnerStyle
  }`;

  if (type === "skeleton") {
    return (
      <div className={messageOuterStyle}>
        <div className={messageInnerStyle}>
          <Skeleton active paragraph={false} className="w-36" />
        </div>
      </div>
    );
  }

  const formatTime = (time) => {
    if (!time) {
      return;
    }
    if (time.length < 19) {
      return time;
    }
    let timeObj = new Date(time);

    timeObj.setHours(timeObj.getHours() + OFFSET_HOUR * 2); // TODO: why *2?
    return JSON.stringify(timeObj).split("T")[1].split(".")[0].substring(0, 5);
  };

  // 語音訊息
  const handleAudioPlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  // 更新語音播放時間
  const handleTimeUpdate = () => {
    const current = audioRef.current.currentTime;
    const total = audioRef.current.duration;
    setCurrentTime(formatAudioTime(current));
    setDuration(formatAudioTime(total));
  };
  // 語音播放結束
  const handleAudioEnded = () => {
    setIsPlaying(false);
    setCurrentTime("0:00");
  };
  // 格式化語音時間
  const formatAudioTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  // 載入語音資料更新時間
  const handleLoadedMetadata = () => {
    setDuration(formatAudioTime(audioRef.current.duration));
  };

  return (
    <div className={messageOuterStyle}>
      <ImageModal
        open={showImageModal}
        url={currentImageUrl}
        onCancel={() => setShowImageModal(false)}
      />
      {type === "other" && (
        <img
          src={
            message.avatar
              ? message.avatar
              : message.question
              ? aiAvatar
              : userAvatar
          }
          alt="avatar"
          className="w-8 h-8 rounded-full mr-1"
        />
      )}
      {type === "self" && (
        <div className="self-end min-w-8">
          <p className="text-xs text-textLight font-thin group-hover:hidden">
            {message.time === "傳送中" ? "傳送中" : formatTime(message.time)}
          </p>
          <div className="text-xs text-textLight font-thin hidden group-hover:block">
            {message.time === "傳送中" ? (
              <p>傳送中</p>
            ) : (
              <div className="text-xs text-textLight font-thin hidden group-hover:block">
                {message.blob && message.blobType.includes("image") ? (
                  <>{formatTime(message.time)}</>
                ) : (
                  <Tooltip title="快速記憶">
                    <HighlightOutlined
                      className="text-md ml-4 hover:text-primary cursor-pointer"
                      onClick={() => handleQuickMemorize(message)}
                    />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-1 text-textDark text-xs font-bold">
        {type === "other" && message.name}
        <div className="flex flex-row gap-1 items-center">
          <div className={messageInnerStyle}>
            {message.question && (
              <div className="text-textDark line-clamp-3 border-b border-b-borderLight mb-3">
                {"回覆: "}
                {message.question}
              </div>
            )}
            <ReactMarkdown
              components={{
                a: ({ node, ...props }) => (
                  <a
                    {...props}
                    className="text-blue-500 hover:text-blue-700 hover:underline break-words"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.children}
                  </a>
                )
              }}
            >
              {message.text}
            </ReactMarkdown>
            {message.url && (
              <p className="break-all">{message.url}</p>
            )}
            {message.blob &&
              (message.blobType.includes("image") ? (
                <div className="mt-2">
                  <img
                    src={message.blob}
                    alt={message.blob}
                    className="w-100 cursor-pointer"
                    onClick={() => handleOpenImageModal(message.blob)}
                  />
                </div>
              ) : message.blobType.includes("audio") ? (
                <div className=" flex gap-2 items-center">
                  {/* Play / Pause Button */}
                  <div
                    className=" cursor-pointer "
                    onClick={handleAudioPlayPause}
                  >
                    {isPlaying ? (
                      <Pause className="w-4 h-4" />
                    ) : (
                      <Play className="w-4 h-4" />
                    )}
                  </div>
                  {/* Time Display */}
                  <div className="text-xs text-gray-600">
                    {currentTime} / {duration}
                  </div>

                  <audio
                    ref={audioRef}
                    src={message.blob}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    onEnded={handleAudioEnded}
                  />
                </div>
              ) : (
                <div className="flex flex-row min-w-48 min-h-10 items-center border border-borderLight px-4 py-2 justify-between rounded-lg cursor-pointer">
                  <a
                    href={message.blob}
                    alt={message.blob}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="w-full text-xs flex flex-row gap-2 line-clamp-1">
                      <FileOutlined />
                      {
                        message.blob.split("/")[
                          message.blob.split("/").length - 1
                        ]
                      }
                    </div>
                  </a>
                </div>
              ))}
          </div>
          {type === "other" && (
            <div className="self-end min-w-8">
              <div className="text-xs text-textLight font-thin group-hover:hidden">
                {formatTime(message.time)}
              </div>
              <div className="text-xs text-textLight font-thin hidden group-hover:block">
                {message.blob && message.blobType.includes("image") ? (
                  <>{formatTime(message.time)}</>
                ) : (
                  <Tooltip title="快速記憶">
                    <HighlightOutlined
                      className="text-md mr-4 hover:text-primary cursor-pointer"
                      onClick={() => handleQuickMemorize(message)}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
