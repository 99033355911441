import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import detailConfig from '../config/detail/detailConfig';

export default function Detail({ data, items, showButton }) {

  // useEffect(() => {
  //   if (data) {
  //     console.log('data:', data);
  //   }
  // }, [data]);

  useEffect(() => {
    console.log('showButton:', showButton);
  }, [showButton]);

  if (!data) {
    return (
      <div className="w-full flex items-center justify-center h-[calc(100vh-64px-32px)] bg-gray-50">
        <div className="flex flex-col items-center justify-center">
          <p className="text-gray-500 text-center">
            {detailConfig.labels.selectService}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col h-full bg-white overflow-auto">
      <Tabs defaultActiveKey="1" items={items} className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4"  />
    </div>
  );
};