/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Radio, Input, message } from 'antd';

import { useAuth } from '@/contexts/AuthProvider';

import RmMemList from './RmMemList';
import MgntAvatar from './MgntAvatar';

const RmMgnt = ({ selectedRoomId }) => {
  const { token } = useAuth();
  const [isPublic, setIsPublic] = useState();
  const [roomInfo, setRoomInfo] = useState();
  const [roomName, setRoomName] = useState();
  const [roomId, setRoomId] = useState();
  const [botName, setBotName] = useState();
  const [creatorNickname, setCreatorNickname] = useState();
  const [creatorId, setCreatorId] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [password, setPassword] = useState();
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [roomAvatar, setRoomAvatar] = useState();

  useEffect(() => {
    console.log('creatorId', creatorId);
  }, [creatorId]);
    

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchRoData = async () => {
    if (!token) return;
    setRoomAvatar(null)
    try {
      const response = await axios.get(`${SERVER_URL}/private/chatrooms/${selectedRoomId}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      // if (!response.ok) throw new Error('Department Data was not ok!');
      const data = response.data;
      setRoomInfo(data);
      setRoomName(data.name);
      setRoomId(data.id);
      setBotName(data.bot_name);
      setCreatorNickname(data.creator_nickname);
      setCreatorId(data.creator_id);
      setRoomAvatar(data.photo_url);
      if (data.is_public === true) {
        setIsPublic(true)
      } else if (data.is_public === false) {
        setIsPublic(false)
      }
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };
  useEffect(() => {
    fetchRoData();
  }, []);

  const handleNameChange = (e) => {
    setRoomName(e.target.value);
  };
  const handleBotNameChange = (e) => {
    setBotName(e.target.value);
  };
  const onChange = (e) => {
    setIsPublic(e.target.value);
  };

  const handleSubmit = async () => {
    // 如果聊天室從公開變為私人但沒有設置密碼，則彈出警告
    if (roomInfo.is_public && isPublic === false && !isPasswordSet) {
      message.error('私人聊天室必須設定密碼');
      return;
    }

    try {
      //update chat room name
      const roomNameResponse = await axios.patch(`${SERVER_URL}/private/chatrooms/setting/name/${roomId}`,
        { text: roomName, }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      //update chatbot name
      const botNameResponse = await axios.patch(`${SERVER_URL}/private/chatrooms/setting/botname/${roomId}`,
        { text: botName }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      //update chatroom visibility
      const visibilityResponse = await axios.patch(`${SERVER_URL}/private/chatrooms/setting/visibility/${roomId}?is_public=${isPublic}`,
        null, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      //alert message if all update sucecessfully
      if (roomNameResponse.status === 200 && botNameResponse.status === 200 && visibilityResponse.status === 200) {
        message.success('房間已更新');
      } else {
        message.error('Update failed');
      }
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
    fetchRoData();
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = async () => {
    if (password.length < 4) {
      message.error('密碼長度需大於 4 位');
      return;
    }

    try {
      await axios.patch(`${SERVER_URL}/private/chatrooms/setting/visibility/${roomId}?is_public=${isPublic}`,
        null, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      await axios.patch(`${SERVER_URL}/private/chatrooms/setting/passphrase/${roomId}`,
        { text: password }, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      setIsPasswordSet(true); // 設置密碼成功後，更新狀態
      message.success('密碼設定成功');

    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  const changeOwner = (nickname, id) => {
    setCreatorNickname(nickname);
    setCreatorId(id);
    onClose();
  };
  const popup = () => {
    setIsPopup(!isPopup);
  };
  const onClose = () => {
    setIsPopup(false);
  };

  const redirectSetting = () => {
    window.location.reload();
  };


  return (
    <div className="flex flex-col w-full bg-white z-10">
      <div className="px-4 font-bold text-xl mb-1 mt-6">
        編輯房間
      </div>
      <div className='w-[540px]'>
        <MgntAvatar
          photoUrl={roomAvatar}
          uploadUrl={`${SERVER_URL}/private/chatrooms/setting/image/${roomId}`}
          title=""
          name={roomName}
          refresh={fetchRoData}
        />
      </div>
      <div className='flex flex-col p-3'>
        <div className='flex p-1'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            名稱</p>
          <Input className='border-[#D9D9D9] border-[1px] w-[240px] rounded-[5px]'
            value={roomName}
            onChange={handleNameChange}
          />
        </div>
        <div className='flex p-1'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            房主</p>
          <Input className='border-[#D9D9D9] border-[1px] w-[240px] rounded-[5px] bg-[#EAEAEA] mr-2'
            value={creatorNickname} />
          <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center mr-[10px]'
            onClick={popup}>
            變更</button>
          {isPopup && <RmMemList roomId={roomInfo.id} onClose={onClose} changeOwner={changeOwner} fetchRoData={fetchRoData} />}
        </div>
        <div className='flex p-1'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            AI 機器人名稱</p>
          <Input className='border-[#D9D9D9] border-[1px] w-[240px] rounded-[5px]'
            value={botName}
            onChange={handleBotNameChange} />
        </div>
        <div className='flex p-1 items-center'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            目前狀態</p>
          <Radio.Group onChange={onChange} value={isPublic}>
            <Radio value={true}>公開</Radio>
            <Radio value={false}>私人</Radio>
          </Radio.Group>
        </div>
        <div className='flex p-1'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            密碼</p>
          {isPublic ?
            <Input className='border-[#D9D9D9] border-[1px] w-[240px] rounded-[5px]  '
              disabled />
            :
            <>
              <Input className='border-[#D9D9D9] border-[1px] w-[240px] rounded-[5px] mr-2'
                onChange={handlePasswordChange}
                value={password}
              />
              <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center mr-[10px]'
                onClick={handlePasswordSubmit}>
                設置</button>
            </>
          }
        </div>
      </div>
      <div className='flex p-5'>
        <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center mr-[10px]'
          onClick={handleSubmit}>
          確認</button>
        <button className='text-[14px] bg-[#DEACAC] p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center'
          onClick={redirectSetting}>
          返回</button>
      </div>
    </div>
  )
}

export default RmMgnt;