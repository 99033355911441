import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider, App as AntApp } from 'antd';
import './App.css';

import { useAuth } from '@/contexts/AuthProvider';

import Redirection from '@/app/landing/Redirection';
import Login from '@/app/landing/Login';
import Register from '@/app/landing/Register';
import Forget from '@/app/landing/Forget';
import Reset from '@/app/landing/Reset';
import Explore from '@/app/explore/Explore';
import Chat from '@/app/chat/Chat';
import Settings from '@/app/systemSettings/Settings';
import NavBar from '@/components/navBar/NavBar';
import NotFound from '@/components/NotFound';
import { Provider } from 'react-redux';
import store from '@/store/index';


// e-commerce
import Service from '@/app/ams/Service';
import Product from '@/app/ams/Product';
import Organization from '@/app/ams/Organization';
import User from '@/app/ams/User';

import Ticket from '@/app/ams/Ticket';
import Venue from '@/app/ams/Venue';
import Campaign from '@/app/ams/Campaign';
// official module
import Demand from '@/app/ams/Demand';
import Management from '@/app/ams/Management';

import { AmsProvider } from '@/app/ams/context/AmsContext';
import { ENV } from '@/constants/env';



const teamsyncTheme = {
  token: {
    colorPrimary: '#67be5f',
    colorSecondary: '#e0e0e0',
    colorBgContainer: '#f7f7f7',
  },
};

function App() {
  const { token, loading } = useAuth();
  if (loading) {
    return null;
  }

  const render_official_site = ENV.FEATURES.E_OFFICIAL_SITE_MODULE;
  const render_panco = ENV.E_COMMERCE.PANCO;
  const render_stag = ENV.E_COMMERCE.STAG5;
  const render_77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

  return (
    <ConfigProvider theme={teamsyncTheme}>
      <AntApp>
        <Router>
          <div className="flex flex-row overflow-x-hidden">
            {token && <NavBar />}
            <Provider store={store}>
              <AmsProvider>
                <Routes>
                  <Route path="/login" element={token ? <Redirection target={"/explore"} /> : <Login />} />
                  <Route path="/register" element={token ? <Redirection target={"/explore"} /> : <Register />} />
                  <Route path="/forget" element={token ? <Redirection target={"/explore"} /> : <Forget />} />
                  <Route path="/reset/:ticketId" element={token ? <Redirection target={"/explore"} /> : <Reset />} />

                  <Route path="/" element={token ? <Redirection target={"/explore"} /> : <Login />} />
                  <Route path="/explore" element={token ? <Explore /> : <Login />} />
                  <Route path="/chat/:roomId?" element={token ? <Chat /> : <Login />} />

                  <Route path="/service" element={token ? <Service /> : <Login />} />

                  {render_official_site && (
                    <>
                      <Route path="/management" element={<Management />} />
                      <Route path="/demand" element={<Demand />} />
                    </>
                  )}
                  <Route path="/settings" element={token ? <Settings /> : <Login />} />
                  {render_panco && (
                    <>
                      <Route path="/user" element={token ? <User /> : <Login />} />
                      <Route path="/organization" element={token ? <Organization /> : <Login />} />
                      <Route path="/service" element={token ? <Service /> : <Login />} />
                      <Route path="/product" element={token ? <Product /> : <Login />} />
                    </>
                  )}
                  {render_stag && (
                    <>
                      <Route path="/user" element={token ? <User /> : <Login />} />
                      <Route path="/organization" element={token ? <Organization /> : <Login />} />
                      <Route path="/service" element={token ? <Service /> : <Login />} />
                      <Route path="/product" element={token ? <Product /> : <Login />} />
                    </>
                  )}
                  {render_77 && (
                    <>
                      <Route path="/user" element={token ? <User /> : <Login />} />
                      <Route path="/organization" element={token ? <Organization /> : <Login />} />
                      <Route path="/ticket" element={token ? <Ticket /> : <Login />} />
                      <Route path="/venue" element={token ? <Venue /> : <Login />} />
                      <Route path="/campaign" element={token ? <Campaign /> : <Login />} />
                    </>
                  )}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </AmsProvider>
            </Provider>
          </div>
        </Router>
      </AntApp>
    </ConfigProvider>
  );
}

export default App;
