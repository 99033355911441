import { ENV } from '@/constants/env';

const pancoConfig = {
    tagSelector: {
        addTag: '新增標籤',
        inputPlaceholder: '請輸入標籤名稱',
        okText: '確認',
        cancelText: '取消',
    }
}

const stag5Config = {
    tagSelector: {
        addTag: 'Add Tag',
        inputPlaceholder: 'Please enter the tag name',
        okText: 'ok',
        cancelText: 'cancel',
    }
}

const _77Config = {
    tagSelector: {
        addTag: '新增標籤',
        inputPlaceholder: '請輸入標籤名稱',
        okText: '確認',
        cancelText: '取消',
    }
}

const isPanco = ENV.E_COMMERCE.PANCO;
const isStag5 = ENV.E_COMMERCE.STAG5;
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export default isPanco ? pancoConfig : isStag5 ? stag5Config : is77 ? _77Config : {};