/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import axios from 'axios';

const UserContext = createContext();

export function useUser() {
	return useContext(UserContext);
};
export const UserProvider = ({ children }) => {
	const { userId, token } = useAuth();
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;
	
	useEffect(() => {
		const fetchData = async () => {
			if (!token) return;

			try {
				const response = await axios.get(`${SERVER_URL}/private/user/me`, {
					headers: {
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`
					}
				});
				console.log('response', response);
				
				const data = response.data;
				setUser(data);

			} catch (error) {
				setError(error.response.data.detail);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [userId]);

	return (
		<UserContext.Provider value={{ user, isLoading, error }}>
			{children}
		</UserContext.Provider>
	);
};





