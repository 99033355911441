import { Tag, Switch, Button } from 'antd';
import moment from 'moment';
import { detailConfig as config } from '../detail/detailConfig';
import { ENV } from '@/constants/env';

const pancoConfig = {
  page: 
    {
      title: "會員管理",
      serviceOrders: "服務訂單列表",
      productOrders: "商品訂單列表",
      export: "匯出會員資料"
    },
  columns: [
    {
      title: "會員序號",
      dataIndex: "id",
      width: "150px",
      fixed: "left",
      render: (id) => id.slice(0, 8)
    },
    {
      title: "會員名稱",
      dataIndex: "nickname",
      width: "180px",
      fixed: "left",
      render: (text, record) => (
        <div className="flex items-center">
          <img
            src={record.picture || "/images/user.png"}
            alt="會員頭像"
            className="w-6 h-6 object-cover rounded-full mr-2"
          />
          <span className={!text && !record.first_name && !record.last_name ? "text-gray-400" : ""}>{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset'}</span>          </div>
      ),
    },
    {
      title: "綁定美業老師",
      dataIndex: "beautician",
      width: "120px",
      render: (beautician) => <span className={!beautician ? "text-gray-400" : ""}>{beautician || 'Unset'}</span>
    },
    {
      title: "美業老師數量",
      dataIndex: "beautician_count",
      width: "120px",
      render: (beautician_count) => <span className={!beautician_count ? "text-gray-400" : ""}>{beautician_count || 'Unset'}</span>
    },
    {
      title: "會員等級",
      dataIndex: "level",
      width: "100px",
      render: (level) => (
        <span>Level {level}</span>
      ),
    },
    {
      title: "性別",
      dataIndex: "gender",
      width: "130px",
      render: (gender) => gender === 'male' ? '先生' : gender === 'female' ? '小姐' : '先生/小姐',
    },
    {
      title: "社群帳號",
      dataIndex: "sso_info",
      width: "200px",
      render: () => {

        return (
          <>
            <div className="flex items-center gap-4">
              <img src={`/images/facebook.png`} alt="facebook" className="w-4 h-4 object-contain" />
              <img src={`/images/instagram.png`} alt="instagram" className="w-4 h-4 object-contain" />
              <img src={`/images/threads.png`} alt="threads" className="w-4 h-4 object-contain" />
              <img src={`/images/tiktok.png`} alt="tiktok" className="w-4 h-4 object-contain" />
            </div>
          </>
        );
      }
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "200px",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <Tag color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">Normal</Tag>;
        }

        return (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag, index) => (
              <Tag key={index} color="blue">{tag}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "會員生日",
      dataIndex: "birthday",
      width: "200px",
      render: (birthday) => (
        <span className={!birthday ? "text-gray-400" : ""}>
          {birthday ? moment(birthday).format("YYYY-MM-DD") : "Unset"}
        </span>
      ),
    },
    {
      title: "會員電話",
      dataIndex: "phone",
      width: "200px",
      render: (phone) => (
        <span className={!phone ? "text-gray-400" : ""}>
          {phone || "Unset"}
        </span>
      ),
    },
    {
      title: "會員Email",
      dataIndex: "email",
      width: "200px",
      render: (email) => (
        <span className={!email ? "text-gray-400" : ""}>
          {email || "Unset"}
        </span>
      ),
    },
    {
      title: "會員來源",
      dataIndex: "source",
      width: "200px",
      render: (source) => (
        <span className={!source ? "text-gray-400" : ""}>
          {source || "Unset"}
        </span>
      ),
    },
    {
      title: "累積消費金額",
      dataIndex: "total_spending",
      width: "200px",
      render: (amount) => (
        <span className={!amount ? "text-gray-400" : ""}>
          {amount ? `$${amount.toLocaleString()}` : "Unset"}
        </span>
      ),
    },
    {
      title: "累積消費次數",
      dataIndex: "order_count",
      width: "200px",
      render: (count) => (
        <span className={!count ? "text-gray-400" : ""}>
          {count || "0"}
        </span>
      ),
    },
    {
      title: "累積紅利點數",
      dataIndex: "points",
      width: "200px",
      render: (points) => (
        <span className={!points ? "text-gray-400" : ""}>
          {points || "0"}
        </span>
      ),
    },
    {
      title: "最後一次消費",
      dataIndex: "last_order_date",
      width: "200px",
      render: (date) => (
        <span className={!date ? "text-gray-400" : ""}>
          {date ? moment(date).format("YYYY-MM-DD") : "Unset"}
        </span>
      ),
    },
    {
      title: "加入日期",
      dataIndex: "created_at",
      width: "200px",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "最近一次上線日期",
      dataIndex: "last_login",
      width: "200px",
      render: (date) => (
        <span className={!date ? "text-gray-400" : ""}>
          {date ? moment(date).format("YYYY-MM-DD HH:mm") : "Unset"}
        </span>
      ),
    },
    {
      title: "歷史服務訂單",
      dataIndex: "service_orders",
      width: "200px",
      render: (orders) => (
        <span className={!orders?.length ? "text-gray-400" : ""}>
          {orders?.length || "0"}
        </span>
      ),
    },
    {
      title: "歷史商品訂單",
      dataIndex: "product_orders",
      width: "200px",
      render: (orders) => (
        <span className={!orders?.length ? "text-gray-400" : ""}>
          {orders?.length || "0"}
        </span>
      ),
    },
    {
      title: "開放狀態",
      dataIndex: "enabled",
      width: "90px",
      align: "center",
      fixed: "right",
      render: (enabled) => (
        <Switch
          checked={enabled}
          disabled
          checkedChildren="開放"
          unCheckedChildren="關閉"
          size="large"
          className={enabled ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"}
        />
      ),
    },
  ],
  formFields: [
    { name: 'name', label: '服務名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  detail: (data, showButton, setCurrentView) => {
    if (!data) return [];    
    return [
      {
        key: "1",
        label: '會員資訊',
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片區域 */}
              <div className="relative flex justify-center">
                <img
                  src={data.picture || "/images/user.png"}
                  alt={data.name}
                  className="w-[138px] h-[138px] object-cover rounded-full"
                />
              </div>
              {/* 標籤 */}
              {data.tags && (
                <div className="flex justify-center gap-2">
                  {data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  ))}
                </div>
              )}
              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-center gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.nickname ||
                      `${data.first_name || ''} ${data.last_name || ''}`.trim() ||
                      "貴賓"}
                  </h1>
                  <span className="text-gray-400 ml-2 text-[18px]">
                    {data.gender === 'male' ? '先生' : data.gender === 'female' ? '小姐' : '先生/小姐'}
                  </span>
                </div>

                {/* 社交平台 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">社交平台</div>
                  {['facebook', 'instagram', 'threads', 'tiktok'].map((platform) => (
                    <div key={platform} className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <img src={`/images/${platform}.png`} alt={platform} className="w-4 h-4 object-contain" />
                        <span>{platform}</span>
                      </div>
                      <Button type="primary" className={`bg-[#ADDEAC] text-black ${!data[`${platform}_url`] ? "bg-gray-300 opacity-50 cursor-not-allowed" : ""}`}>{data[`${platform}_url`] || '未設定'}</Button>
                    </div>
                  ))}
                </div>

                {/* 基本資料 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold">基本資料</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/date.png" alt="birthday" className="w-4 h-4" />
                    <span>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/email.png" alt="email" className="w-4 h-4" />
                    <span>{data.email || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/location.png" alt="location" className="w-4 h-4" />
                    <span>{data.address || 'Unset'}</span>
                  </div>
                </div>

                {/* 興趣 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">興趣</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg">
                    {data.interests?.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {data.interests.map((interest, index) => (
                          <Tag
                            key={index}
                            className="px-3 py-1 rounded-full bg-white border-[#ADDEAC] text-[#67BE5F]"
                          >
                            {interest}
                          </Tag>
                        ))}
                      </div>
                    ) : (
                      <span className="text-gray-400">Unset</span>
                    )}
                  </div>
                </div>

                {/* 備註 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">備註</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg min-h-[80px]">
                    <span className={!data.note ? "text-gray-400" : ""}>
                      {data.note || 'Unset'}
                    </span>
                  </div>
                </div>

              </div>

              {/* 編輯按鈕 */}
              {
                showButton && (
                  <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                  <Button
                    type="primary"
                    className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    onClick={() => setCurrentView(userConfig.tabs[0].key)}
                  >
                    {config.labels.check}
                  </Button>
                </div>
              )
              }
            </div>
          </div>
        ),
      }
    ];
  },
  tabs: [
    { key: 'service', label: '服務訂單列表' },
    { key: 'product', label: '商品訂單列表' }
  ],
};

const stag5Config = {
  page: 
    {
      title: "Member Management",
      productOrders: "Product Order Records",
      serviceOrders: "Service Order Records",
      export: "Export Member Data"
    },
  columns: [
    {
      title: "Member ID",
      dataIndex: "id",
      width: "150px",
      fixed: "left",
      render: (id) => id.slice(0, 8)
    },
    {
      title: "Member Name",
      dataIndex: "nickname",
      width: "180px",
      fixed: "left",
      render: (text, record) => (
        <div className="flex items-center">
          <img
            src={record.picture || "/images/user.png"}
            alt="員頭像"
            className="w-6 h-6 object-cover rounded-full mr-2"
          />
          <span className={!text && !record.first_name && !record.last_name ? "text-gray-400" : ""}>{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset'}</span>          </div>
      ),
    },
    {
      title: "LEVEL",
      dataIndex: "level",
      width: "100px",
      render: (level) => (
        <span>Level {level}</span>
      ),
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      width: "100px",
      render: (gender) => <span className={!gender ? "text-gray-400" : ""}>{gender || 'Unset'}</span>
    },
    {
      title: "SOCIAL ACCOUNT",
      dataIndex: "sso_info",
      width: "200px",
      render: () => {

        return (
          <>
            <div className="flex items-center gap-4">
              <img src={`/images/facebook.png`} alt="facebook" className="w-4 h-4 object-contain" />
              <img src={`/images/instagram.png`} alt="instagram" className="w-4 h-4 object-contain" />
              <img src={`/images/threads.png`} alt="threads" className="w-4 h-4 object-contain" />
              <img src={`/images/tiktok.png`} alt="tiktok" className="w-4 h-4 object-contain" />
            </div>
          </>
        );
      }
    },
    {
      title: "TAG",
      dataIndex: "tags",
      width: "200px",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <Tag color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">Normal</Tag>;
        }

        return (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag, index) => (
              <Tag key={index} color="blue">{tag}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "BIRTH DATE",
      dataIndex: "birthday",
      width: "200px",
      render: (birthday) => (
        <span className={!birthday ? "text-gray-400" : ""}>
          {birthday ? moment(birthday).format("YYYY-MM-DD") : "Unset"}
        </span>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      width: "150px",
      render: (phone) => <span className={!phone ? "text-gray-400" : ""}>{phone || 'Unset'}</span>
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      width: "120px",
      render: (email) => <span className={!email ? "text-gray-400" : ""}>{email || 'Unset'}</span>
    },
    {
      title: "SOURCE",
      dataIndex: "source",
      width: "120px",
      render: (source) => <span className={!source ? "text-gray-400" : ""}>{source || 'Unset'}</span>
    },
    {
      title: "TOTAL SPENDING AMOUNT",
      dataIndex: "total_spending",
      width: "230px",
      render: (amount) => <span className={!amount ? "text-gray-400" : ""}>{amount ? `$${amount.toLocaleString()}` : "Unset"}</span>
    },
    {
      title: "TOTAL SPENDING COUNT",
      dataIndex: "order_count",
      width: "210px",
      render: (amount) => <span className={!amount ? "text-gray-400" : ""}>{amount ? `$${amount.toLocaleString()}` : "Unset"}</span>
    },
    {
      title: "LAST PURCHASE",
      dataIndex: "last_order_date",
      width: "180px",
      render: (date) => <span className={!date ? "text-gray-400" : ""}>{date ? moment(date).format("YYYY-MM-DD") : "Unset"}</span>
    },
    {
      title: "JOIN DATE",
      dataIndex: "created_at",
      width: "130px",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "LAST ONLINE DATE",
      dataIndex: "last_login",
      width: "210px",
      render: (date) => <span className={!date ? "text-gray-400" : ""}>{date ? moment(date).format("YYYY-MM-DD HH:mm") : "Unset"}</span>
    },
    {
      title: "SERVICE ORDERS",
      dataIndex: "service_orders",
      width: "180px",
      align: "center",
      render: () => (
        <Button
          type="link"
          className="w-[60px] h-[32px] px-[15px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
        >
          View
        </Button>
      ),
    },
    {
      title: "PRODUCT ORDERS",
      dataIndex: "product_orders",
      width: "180px",
      align: "center",
      render: () => (
        <Button
          type="link"
          className="w-[60px] h-[32px] px-[15px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
        >
          View
        </Button>
      ),
    },
    {
      title: "COUPON RECORDS",
      dataIndex: "coupon_records",
      width: "180px",
      align: "center",
      render: () => (
        <Button
          type="link"
          className="w-[60px] h-[32px] px-[15px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
        >
          View
        </Button>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "enabled",
      width: "140px",
      align: "center",
      fixed: "right",
      render: (enabled) => (
        <Switch
          checked={enabled}
          checkedChildren="OPEN"
          unCheckedChildren="CLOSED"
          className={enabled ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"}
        />
      ),
    },

  ],
  formFields: [
    { name: 'name', label: '服務名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  detail: (data, showButton, setCurrentView) => {
    if (!data) return [];

    return [
      {
        key: "1",
        label: 'User Information',
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片區域 */}
              <div className="relative flex justify-center">
                <img
                  src={data.picture || "/images/user.png"}
                  alt={data.name}
                  className="w-[138px] h-[138px] object-cover rounded-full"
                />
              </div>
              {/* 標籤 */}
              {data.tags && (
                <div className="flex justify-center gap-2">
                  {data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  ))}
                </div>
              )}
              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-center gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.nickname ||
                      `${data.first_name || ''} ${data.last_name || ''}`.trim() ||
                      "User"}
                  </h1>
                  <span className="text-gray-400 ml-2 text-[18px]">
                    {data.gender === 'male' ? 'Mr.' : data.gender === 'female' ? 'Lady' : 'Mr./Miss'}
                  </span>
                </div>

                {/* 社交平台 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">Store Platform</div>
                  {['facebook', 'instagram', 'threads', 'tiktok'].map((platform) => (
                    <div key={platform} className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <img src={`/images/${platform}.png`} alt={platform} className="w-4 h-4 object-contain" />
                        <span>{platform}</span>
                      </div>
                      <Button type="primary" className={`bg-[#ADDEAC] text-black ${!data[`${platform}_url`] ? "bg-gray-300 opacity-50 cursor-not-allowed" : ""}`}>{data[`${platform}_url`] || 'Unset'}</Button>
                    </div>
                  ))}
                </div>

                {/* 基本資料 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold">Basic Information</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/date.png" alt="birthday" className="w-4 h-4" />
                    <span>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/email.png" alt="email" className="w-4 h-4" />
                    <span>{data.email || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/location.png" alt="location" className="w-4 h-4" />
                    <span>{data.address || 'Unset'}</span>
                  </div>
                </div>

                {/* 興趣 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">Interests</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg">
                    {data.interests?.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {data.interests.map((interest, index) => (
                          <Tag
                            key={index}
                            className="px-3 py-1 rounded-full bg-white border-[#ADDEAC] text-[#67BE5F]"
                          >
                            {interest}
                          </Tag>
                        ))}
                      </div>
                    ) : (
                      <span className="text-gray-400">Unset</span>
                    )}
                  </div>
                </div>

                {/* 備註 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">Note</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg min-h-[80px]">
                    <span className={!data.note ? "text-gray-400" : ""}>
                      {data.note || 'Unset'}
                    </span>
                  </div>
                </div>

              </div>

              {/* 編輯按鈕 */}
              {showButton && (
                <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                  <Button
                    type="primary"
                  className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                  onClick={() => setCurrentView(userConfig.tabs[0].key)}
                >
                  {config.labels.check}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        key: "2",
        label: config.labels.filter,
        children: (
          <>
          </>
        ),
      }
    ];
  },
  tabs: [
    { key: 'service', label: 'Order Records' },
    { key: 'product', label: 'Product Records' }
  ],
};

const _77Config = {
  page: {
    title: "會員管理",
    productOrders: "服務訂單列表",
    serviceOrders: "商品訂單列表",
    ticketRecords: "票券紀錄",
    compaign: "活動紀錄",
    export: "匯出會員資料"
  },
  columns: [
    {
      title: "會員序號",
      dataIndex: "id",
      width: "150px",
      fixed: "left",
      render: (id) => id.slice(0, 8)
    },
    {
      title: "會員名稱",
      dataIndex: "nickname",
      width: "180px",
      fixed: "left",
      render: (text, record) => (
        <div className="flex items-center">
          <img
            src={record.picture || "/images/user.png"}
            alt="會員頭像"
            className="w-6 h-6 object-cover rounded-full mr-2"
          />
          <span className={!text && !record.first_name && !record.last_name ? "text-gray-400" : ""}>{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset'}</span>          </div>
      ),
    },
    {
      title: "會員等級",
      dataIndex: "level",
      width: "100px",
      render: (level) => (
        <span>Level {level}</span>
      ),
    },
    {
      title: "性別",
      dataIndex: "gender",
      width: "130px",
      render: (gender) => <span className={!gender ? "text-gray-400" : ""}>{gender || 'Unset'}</span>
    },
    {
      title: "社群帳號",
      dataIndex: "sso_info",
      width: "200px",
      render: () => {

        return (
          <>
            <div className="flex items-center gap-4">
              <img src={`/images/facebook.png`} alt="facebook" className="w-4 h-4 object-contain" />
              <img src={`/images/instagram.png`} alt="instagram" className="w-4 h-4 object-contain" />
              <img src={`/images/threads.png`} alt="threads" className="w-4 h-4 object-contain" />
              <img src={`/images/tiktok.png`} alt="tiktok" className="w-4 h-4 object-contain" />
            </div>
          </>
        );
      }
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "200px",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <Tag color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">Normal</Tag>;
        }

        return (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag, index) => (
              <Tag key={index} color="blue">{tag}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "會員電話",
      dataIndex: "phone",
      width: "200px",
      render: (phone) => (
        <span className={!phone ? "text-gray-400" : ""}>
          {phone || "Unset"}
        </span>
      ),
    },
    {
      title: "會員Email",
      dataIndex: "email",
      width: "200px",
      render: (email) => (
        <span className={!email ? "text-gray-400" : ""}>
          {email || "Unset"}
        </span>
      ),
    },
    {
      title: "會員來源",
      dataIndex: "source",
      width: "200px",
      render: (source) => (
        <span className={!source ? "text-gray-400" : ""}>
          {source || "Unset"}
        </span>
      ),
    },
    {
      title: "加入日期",
      dataIndex: "created_at",
      width: "200px",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "最近一次上線日期",
      dataIndex: "last_login",
      width: "200px",
      render: (date) => (
        <span className={!date ? "text-gray-400" : ""}>
          {date ? moment(date).format("YYYY-MM-DD HH:mm") : "Unset"}
        </span>
      ),
    },
    {
      title: "票券紀錄",
      dataIndex: "ticket_records",
      width: "200px",
      render: (records) => <span className={!records?.length ? "text-gray-400" : ""}>{records?.length || "0"}</span>
    },
    {
      title: "活動紀錄",
      dataIndex: "event_records",
      width: "200px",
      render: (records) => <span className={!records?.length ? "text-gray-400" : ""}>{records?.length || "0"}</span>
    },
    {
      title: "開放狀態",
      dataIndex: "enabled",
      width: "140px",
      align: "center",
      fixed: "right",
      render: (enabled) => (
        <Switch
          checked={enabled}
          disabled
          checkedChildren="開放"
          unCheckedChildren="關閉"
          size="large"
          className={enabled ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"}
        />
      ),
    },
  ],
  formFields: [
    { name: 'name', label: '服務名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  detail: (data, showButton, setCurrentView) => {
    if (!data) return [];

    return [
      {
        key: "1",
        label: '會員資訊',
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片區域 */}
              <div className="relative flex justify-center">
                <img
                  src={data.picture || "/images/user.png"}
                  alt={data.name}
                  className="w-[138px] h-[138px] object-cover rounded-full"
                />
              </div>
              {/* 標籤 */}
              {data.tags && (
                <div className="flex justify-center gap-2">
                  {data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  ))}
                </div>
              )}
              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-center gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.nickname ||
                      `${data.first_name || ''} ${data.last_name || ''}`.trim() ||
                      "貴賓"}
                  </h1>
                  <span className="text-gray-400 ml-2 text-[18px]">
                    {data.gender === 'male' ? '先生' : data.gender === 'female' ? '小姐' : '先生/小姐'}
                  </span>
                </div>

                {/* 社交平台 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">社交平台</div>
                  {['facebook', 'instagram', 'threads', 'tiktok'].map((platform) => (
                    <div key={platform} className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <img src={`/images/${platform}.png`} alt={platform} className="w-4 h-4 object-contain" />
                        <span>{platform}</span>
                      </div>
                      <Button type="primary" className={`bg-[#ADDEAC] text-black ${!data[`${platform}_url`] ? "bg-gray-300 opacity-50 cursor-not-allowed" : ""}`}>{data[`${platform}_url`] || '未設定'}</Button>
                    </div>
                  ))}
                </div>

                {/* 基本資料 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold">基本資料</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/date.png" alt="birthday" className="w-4 h-4" />
                    <span>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/email.png" alt="email" className="w-4 h-4" />
                    <span>{data.email || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/location.png" alt="location" className="w-4 h-4" />
                    <span>{data.address || 'Unset'}</span>
                  </div>
                </div>

                {/* 興趣 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">興趣</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg">
                    {data.interests?.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {data.interests.map((interest, index) => (
                          <Tag
                            key={index}
                            className="px-3 py-1 rounded-full bg-white border-[#ADDEAC] text-[#67BE5F]"
                          >
                            {interest}
                          </Tag>
                        ))}
                      </div>
                    ) : (
                      <span className="text-gray-400">Unset</span>
                    )}
                  </div>
                </div>

                {/* 備註 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">備註</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg min-h-[80px]">
                    <span className={!data.note ? "text-gray-400" : ""}>
                      {data.note || 'Unset'}
                    </span>
                  </div>
                </div>

              </div>

              {/* 編輯按鈕 */}
              {showButton && (
                <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                  <Button
                    type="primary"
                  className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                  onClick={() => setCurrentView(userConfig.tabs[0].key)}
                >
                  {config.labels.check}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ),
      },
      {
        key: "2",
        label: config.labels.filter,
        children: (
          <>
          </>
        ),
      }
    ];
  },
  tabs: [
    { key: 'ticket', label: '票券紀錄' },
    { key: 'event', label: '活動紀錄' }
  ],
};

// 根據環境變數選擇配置
const isStag5 = ENV.E_COMMERCE.STAG5;
const isPanco = ENV.E_COMMERCE.PANCO;
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const userConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : is77 ? _77Config : null;
