import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Card, Avatar, Spin, message } from "antd";
import { CloseOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';

import { useUser } from '@/contexts/UserProvider';
import { useAuth } from '@/contexts/AuthProvider';

const UploadPhoto = ({ handleUploadWindow }) => {
  const { user, isLoading } = useUser();
  const { token } = useAuth();
  const [photo, setPhoto] = useState();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (user && user.photo_url) {
      setPhoto(user.photo_url);
    }
  }, [user]);

  if (isLoading) {
    return <div className='flex items-center justify-center w-full'>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 48,
            }}
            spin
          />
        }
      />
    </div>
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    setLoading(true);
    setPhoto(null);

    try {
      const response = await axios.patch(`${SERVER_URL}/private/user/image`, formData, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.data;
      setPhoto(null);
      setTimeout(() =>
        setPhoto(data.preview_url)// Set the new photoUrl after a brief delay
        , 1000)
      setLoading(false);
      window.location.reload();

    } catch (error) {
      message.error('上傳失敗，請重試');
      setLoading(false);
    }
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleUpload(file);
  };

  const handleUploadPhoto = () => {
    fileInputRef.current.click();
  };

  const handleRemovePhoto = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${SERVER_URL}/private/user/image`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        setPhoto(null);
        // window.location.reload();
      } else {
        message.error('照片刪除失敗，請重試');
      }
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
      // alert('刪除照片失敗');
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };


  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-30 '>
      <Card title="變更大頭照" extra={<CloseOutlined onClick={handleUploadWindow} />}
        className='w-[380px] h-[393px] flex flex-col justfiy-between'
      >
        <div className='w-full flex justify-center'>
          {loading ?
            <div className='w-full h-[202px] flex justify-center items-center'>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 48,
                    }}
                    spin
                  />
                }
              />
            </div>
            :
            <Avatar size={202} icon={<UserOutlined />} src={photo ? photo : undefined} />
          }
        </div>
        <div className='flex w-full justify-around h-[32px] mt-[32px]'>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <button className='bg-primaryMedium p-2 rounded-[5px] flex items-center justify-center'
            onClick={handleUploadPhoto}>
            上傳照片</button>
          <button className='bg-[#DEACAC] p-2 rounded-[5px] flex items-center justify-center'
            onClick={handleRemovePhoto}>
            移除照片</button>
        </div>
      </Card>
    </div>
  )
}

export default UploadPhoto;