import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import classNames from 'classnames';
import { ReactComponent as ExploreIcon } from './assets/explore_tab_icon.svg';
import { ReactComponent as ChatIcon } from './assets/chat_tab_icon.svg';
import { ReactComponent as ManagementIcon } from './assets/management_tab_icon.svg';
import { ReactComponent as DemandIcon } from './assets/ams_demand_icon.svg';
import { ReactComponent as ServiceIcon } from './assets/ams_service_icon.svg';
import { ReactComponent as ProductIcon } from './assets/ams_product_icon.svg';
import { ReactComponent as UserIcon } from './assets/ams_user_icon.svg';
import { ReactComponent as OrganizationIcon } from './assets/ams_organization_icon.svg';
import { ReactComponent as TicketIcon } from './assets/ams_ticket_icon.svg';
import { ReactComponent as VenueIcon } from './assets/ams_venue_icon.svg';
import { ReactComponent as CampaignIcon } from './assets/ams_campaign_icon.svg';
import Logo from '../logo/Logo';
import { FloatingPortal, useFloating, useHover, useInteractions, offset, FloatingArrow, arrow } from '@floating-ui/react';
import { DevModeSelector } from '../DevModeSelector';
import { ENV } from '@/constants/env';
import { useAuth } from '@/contexts/AuthProvider';

const useTooltip = (label) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const arrowRef = React.useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [
      offset(10),
      arrow({ element: arrowRef })
    ],
  });

  const hover = useHover(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  const TooltipContent = React.useMemo(() => {
    return isOpen ? (
      <FloatingPortal>
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            zIndex: 1000
          }}
          {...getFloatingProps()}
          className="bg-gray-800 text-white px-2 py-1 rounded text-sm"
        >
          {label}
          <FloatingArrow
            ref={arrowRef}
            context={context}
            className="fill-gray-800"
          />
        </div>
      </FloatingPortal>
    ) : null;
  }, [isOpen, refs.setFloating, floatingStyles, getFloatingProps, context, label, arrowRef]);

  return {
    refs,
    getReferenceProps,
    TooltipContent
  };
};

const NavBar = () => {
  const location = useLocation();
  const { token } = useAuth();
  const [isDev, setIsDev] = useState(false);
  const render_official_site = ENV.FEATURES.E_OFFICIAL_SITE_MODULE;
  const isPanco = ENV.E_COMMERCE.PANCO;
  const isStag = ENV.E_COMMERCE.STAG5;
  const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const exploreTooltip = useTooltip('探索');
  const chatTooltip = useTooltip('聊天');
  const managementTooltip = useTooltip('後台管理');
  const demandTooltip = useTooltip('需求管理');
  const serviceTooltip = useTooltip('服務管理');
  const productTooltip = useTooltip('商品管理');
  const userTooltip = useTooltip('用戶管理');
  const organizationTooltip = useTooltip('店家管理');
  const ticketTooltip = useTooltip('票券管理');
  const venueTooltip = useTooltip('場館管理');
  const campaignTooltip = useTooltip('活動管理');

  useEffect(() => {
    console.log('isDev', isDev);
    
  }, [isDev]);

  useEffect(() => {
    // 初始化 DEV_ECOMMERCE_MODE
    if (!localStorage.getItem('DEV_ECOMMERCE_MODE')) {
      localStorage.setItem('DEV_ECOMMERCE_MODE', 'NONE');
    }
  }, []);

  useEffect(() => {
		const fetchData = async () => {
			if (!token) return;

			try {
				const response = await axios.get(`${SERVER_URL}/private/user/me`, {
					headers: {
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`
					}
				});		
        setIsDev(response.data.company_id === '858c6f14-54fd-47bb-a169-1ec686f4a02e');		

			} catch (error) {
			} finally {
			}
		};

		fetchData();
	}, []);

  return (
    <div className="flex flex-col items-center justify-start h-screen min-w-24 w-24 pt-4 gap-4 bg-bgDark">
      <a href="/" className='w-[45px] h-[45px] flex justify-center items-center overflow-hidden' >
        <Logo type="NAVBAR" />
      </a>
      <a href="/explore"
        ref={exploreTooltip.refs.setReference}
        {...exploreTooltip.getReferenceProps()}
        className={classNames({
          'border-primary': location.pathname.includes("/explore"),
          'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
        })}>
        <ExploreIcon />
        {exploreTooltip.TooltipContent}
      </a>

      <a href="/chat"
        ref={chatTooltip.refs.setReference}
        {...chatTooltip.getReferenceProps()}
        className={classNames({
          'border-primary': location.pathname.includes("/chat"),
          'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
        })}>
        <ChatIcon />
        {chatTooltip.TooltipContent}
      </a>
      {render_official_site && (
        <>
          <a href="/management"
            ref={managementTooltip.refs.setReference}
            {...managementTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/management"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ManagementIcon />
            {managementTooltip.TooltipContent}
          </a>
          <a href="/demand"
            ref={demandTooltip.refs.setReference}
            {...demandTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/demand"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <DemandIcon />
            {demandTooltip.TooltipContent}
          </a>
        </>
      )}
      {isPanco && (
        <div className='flex flex-col items-center justify-start gap-4'>
          <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/user"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/organization"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/service"
            ref={serviceTooltip.refs.setReference}
            {...serviceTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/service"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ServiceIcon />
            {serviceTooltip.TooltipContent}
          </a>
          <a href="/product"
            ref={productTooltip.refs.setReference}
            {...productTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/product"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ProductIcon />
            {productTooltip.TooltipContent}
          </a>
        </div>
      )}

      {isStag && (
        <div className='flex flex-col items-center justify-start gap-4'>
          <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/user"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/organization"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/service"
            ref={serviceTooltip.refs.setReference}
            {...serviceTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/service"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ServiceIcon />
            {serviceTooltip.TooltipContent}
          </a>
          <a href="/product"
            ref={productTooltip.refs.setReference}
            {...productTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/product"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <ProductIcon />
            {productTooltip.TooltipContent}
          </a>
        </div>
      )}

      {is77 && (
        <div className='flex flex-col items-center justify-start gap-4'>
          <a href="/user"
            ref={userTooltip.refs.setReference}
            {...userTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/user"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <UserIcon />
            {userTooltip.TooltipContent}
          </a>
          <a href="/organization"
            ref={organizationTooltip.refs.setReference}
            {...organizationTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/organization"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <OrganizationIcon />
            {organizationTooltip.TooltipContent}
          </a>
          <a href="/ticket"
            ref={ticketTooltip.refs.setReference}
            {...ticketTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/ticket"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <TicketIcon />
            {ticketTooltip.TooltipContent}
          </a>
          <a href="/venue"
            ref={venueTooltip.refs.setReference}
            {...venueTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/venue"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <VenueIcon />
            {venueTooltip.TooltipContent}
          </a>
          <a href="/campaign"
            ref={campaignTooltip.refs.setReference}
            {...campaignTooltip.getReferenceProps()}
            className={classNames({
              'border-primary': location.pathname.includes("/campaign"),
              'flex w-11 h-11 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
            <CampaignIcon />
            {campaignTooltip.TooltipContent}
          </a>
        </div>
      )}

      <div
        className="text-xs text-bgMedium"
      >
        v-1212-01

        {
          isDev && (
            <DevModeSelector />
          )
        }
      </div>

    </div>
  )
}

export default NavBar
