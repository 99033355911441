/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { serviceConfig } from "@/app/ams/config/service/serviceConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import Form from "@/app/ams/components/Form";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useFormScroll, formLogic, serviceFormConfig } from "@/app/ams/config/service/serviceFormConfig";
import ToolBar from "@/app/ams/components/ToolBar";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
import Bread from "@/app/ams/components/Bread";
import { fetchServiceOrdersAPI } from '@/app/ams/services/api';

export default function Service() {
  const { showForm, setShowForm, selectedService, setSelectedService, services, loading, fetchServices, debugMode } = useAmsContext();
  const columns = serviceConfig.columns;
  const [showServiceOrders, setShowServiceOrders] = useState(false);
  const [formData, setFormData] = useState({});
  const productType = serviceConfig.apis.product_type;


  useEffect(() => {
    console.log('showServiceOrders', showServiceOrders);
  }, [showServiceOrders]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchServices(productType);
  }, [debugMode]);

  useEffect(() => {
    if (services?.length > 0 && !selectedService) {
      const firstService = services[0];
      const serviceWithImage = {
        ...firstService,
        imageUrl: firstService?.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: firstService.specs || [],
        description: firstService.description || '',
        notification: firstService.notification || '',
        status: firstService.status || 'On Sale',
        provider: firstService.provider || '',
        category: firstService.category || {},
        tags: firstService.tags || [],
        recommendation: firstService.recommendation || null,
        publishDate: firstService.publishDate || null
      };
      setSelectedService(serviceWithImage);
    }
  }, [services, selectedService]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const renderTabs = () => {
    return (
      <div className="flex gap-4 my-2">
        {serviceConfig.tabs.map((tab) => (
          <Button
            key={tab.key}
            className={`${showServiceOrders === true
                ? "bg-[#90C290] hover:bg-[#7AB27A]"
                : "bg-white hover:bg-gray-100 border border-gray-200"
              } px-3 py-2 rounded-full`}
            onClick={() => setShowServiceOrders(true)}
          >
            {tab.label}
          </Button>
        ))}
      </div>
    );
  };

  const handleRowClick = (record) => {
    if ( !showServiceOrders ) {
      if (selectedService?.key !== record.key) {
        const serviceWithImage = {
          ...record,
          imageUrl: record?.blobs?.[0]?.url || '/images/default-service.jpg',
          specs: record.specs || [],
          description: record.description || '',
          notification: record.notification || '',
          status: record.status || 'On Sale',
          provider: record.provider || '',
          category: record.category || {},
          tags: record.tags || [],
          recommendation: record.recommendation || null,
          publishDate: record.publishDate || null
        };
        setSelectedService(serviceWithImage);
      }
    }
  };

  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">{serviceFormConfig.service.title}</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          {serviceFormConfig.service.title}
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">{serviceFormConfig.service.add}</span>
      </div>
    </div>
  );

  const handleFormChange = (changedValues) => {
    setFormData(changedValues);
  };

  const ShowServiceOrders = () => {
    const [serviceOrders, setServiceOrders] = useState([]);
    useEffect(() => {
      const res = async () => {
        const res = await fetchServiceOrdersAPI(selectedService?.id);
        if (res.length > 0) {
          setServiceOrders(res);
        }
      }
      res();
    }, [selectedService]);
    useEffect(() => {
      console.log(serviceOrders);
    }, [serviceOrders]);
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold">{selectedService?.name || selectedService?.title}</div>
            </div>
            <Bread title={serviceConfig?.page?.title || ""} path="service" subTitle={serviceConfig?.page?.serviceOrders || ""} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.service} type="service" /> */}
            <Table
              columns={columns({
                setShowServiceOrders,
                setSelectedService,
                services,
              })}
              dataSource={serviceOrders}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedService?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedService?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedService}
              type="service"
              items={serviceConfig?.detail?.(selectedService, true)}
            />
          </div>
        </div>
      </>
    );
  };


  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">

        <div className="flex-1 flex overflow-auto">
          {showForm ? (
            <>
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-hidden">
                  <BreadcrumbNav />
                  <Form onChange={handleFormChange} formConfig={serviceFormConfig} formLogic={formLogic} useFormScroll={useFormScroll} title={serviceFormConfig.service.title} draftText={serviceFormConfig.service.draft} publishText={serviceFormConfig.service.publish} />
                </div>
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <Detail data={formData} items={serviceConfig.detail(formData, false)} />
                </div>
              </div>
            </>
          ) :
            showServiceOrders ?
              (
                <ShowServiceOrders />
              )
              :
              (
                <>
                  <div className="flex w-full overflow-auto bg-gray-50">
                    <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                      <div className="flex justify-between items-center">
                        <div className="text-lg font-bold">{serviceFormConfig?.service?.title}</div>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={handleShowForm}
                          className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                        >
                          {serviceFormConfig?.service?.add}
                        </Button>
                      </div>
                      <Bread title={serviceFormConfig?.service?.title} path="service" page={serviceFormConfig?.service?.add} />
                      <Statistics data={statisticsConfig?.statistics?.service} type="service" />
                      <Table
                        columns={columns({
                          setShowServiceOrders,
                          setSelectedService,
                          services,
                        })}
                        dataSource={services}
                        loading={loading}
                        pagination={{
                          pageSize: 10,
                          className: "sticky bottom-0 pb-2",
                        }}
                        onRow={(record) => ({
                          onClick: () => handleRowClick(record),
                          className: record.key === (selectedService?.key ?? '') ? "bg-gray-100" : "",
                        })}
                        rowClassName={(record) => `cursor-pointer ${record.key === (selectedService?.key ?? '') ? "bg-gray-100" : ""}`}
                        scroll={{ x: 2000 }}
                        size="small"
                        className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                      />
                    </div>
                    <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                      <Detail
                        data={selectedService}
                        type="service"
                        items={serviceConfig?.detail?.(selectedService, true)}
                      />
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};
