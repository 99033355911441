/* eslint-disable react-hooks/exhaustive-deps */
import { ENV } from '@/constants/env';
import { Input, Select, DatePicker, InputNumber, Upload, Radio, Button, Table, message, Switch, Form, Row, Col, Divider, Space, Checkbox, TimePicker } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { fetchCategoriesAPI, fetchOrganizationsAPI, fetchIdentityListAPI, createServiceAPI, createSpecAPI, createDiscountAPI, createProviderAPI, updateProductImagesAPI } from '@/app/ams/services/api';
import { useAmsContext } from '@/app/ams/context/AmsContext';
import { serviceConfig } from '@/app/ams/config/service/serviceConfig';
import TagSelector from '@/app/ams/components/TagSelector';
import BannerUpload from '@/app/ams/components/BannerUpload';
import detailConfig from '../detail/detailConfig';
// useAmsContext 
const BeauticianSelect = ({ value, onChange }) => {
    const { identityList } = useAmsContext();

    // 將 identityList 轉換為包含 providers 的選項
    const options = identityList.reduce((acc, identity) => {
        if (identity.providers && identity.providers.length > 0) {
            // 為每個 provider 創建一個選項
            const providerOptions = identity.providers.map(provider => ({
                label: `${identity.name}-${provider.last_name || provider.first_name || provider.nickname || provider.email}`,
                value: JSON.stringify({
                    provider_id: provider.id,
                    identity_id: identity.id
                })
            }));
            return [...acc, ...providerOptions];
        }
        return acc;
    }, []);

    // 處理值的轉��
    const handleChange = (selectedValues) => {
        const formattedValues = selectedValues.map(value => JSON.parse(value));
        onChange(formattedValues);
    };

    // 將 value 轉換回 Select 組件需要的格式
    const formattedValue = value ? value.map(item =>
        JSON.stringify({
            provider_id: item.provider_id,
            identity_id: item.identity_id
        })
    ) : [];

    return (
        <Select
            mode="multiple"
            value={formattedValue}
            onChange={handleChange}
            options={options}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            placeholder="請選擇美容師"
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

// fetch categories
const CategorySelect = ({ value, onChange }) => {
    const { categories, setCategories } = useAmsContext();
    const stag5 = ENV.E_COMMERCE.STAG5;
    const panco = ENV.E_COMMERCE.PANCO;


    useEffect(() => {
        let cats = [];
        const fetchCategories = async () => {
            if (stag5 === true) {
                const type = 'service_product_ip';
                cats = await fetchCategoriesAPI(type);
            } else if (panco === true) {
                const type = 'service_product_ii';
                cats = await fetchCategoriesAPI(type);
            }
            setCategories(cats);
        };
        fetchCategories();
    }, []);

    return (
        <Select
            value={value?.name} // 只顯示名稱
            onChange={(_, option) => {
                onChange({
                    id: option.data.id,
                    name: option.data.name,
                    product_type: option.data.product_type
                });
            }}
            options={categories.map(category => ({
                value: category.id,
                label: category.name,
                data: category // 保存完整的類別數據
            }))}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            placeholder="請選擇服務類別"
        />
    );
};

// fetch organizations
const OrganizationSelect = (props) => {
    const { value, ...restProps } = props;
    const form = Form.useFormInstance();
    const {
        organizations,
        setOrganizations,
        setIdentityList
    } = useAmsContext();


    useEffect(() => {
        const initOrganizations = async () => {
            try {
                const orgs = await fetchOrganizationsAPI();
                console.log('orgs', orgs);
                setOrganizations(orgs);
            } catch (error) {
                console.error('獲取店家列表失敗:', error);
                message.error({
                    content: '獲取店家列表失敗',
                    className: 'custom-message'
                });
            }
        };
        initOrganizations();
    }, [setOrganizations]);

    const handleChange = async (selectedValue) => {
        try {
            // 直接使用 form.setFieldValue 來更新表單值
            form.setFieldValue(['basic', 'organization'], selectedValue);

            if (selectedValue) {
                const res = await fetchIdentityListAPI(selectedValue);
                if (res) {
                    setIdentityList(res);
                }
            } else {
                setIdentityList([]);
            }
        } catch (error) {
            console.error('獲取美容師失敗:', error);
            message.error({
                content: '獲取美容師列表失敗',
                className: 'custom-message'
            });
        }
    };

    return (
        <Select
            {...restProps}
            value={value}
            onChange={handleChange}
            options={(Array.isArray(organizations) ? organizations : []).map(organization => ({
                label: organization.name,
                value: organization.id
            }))}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

// SpecTableComponent
const SpecTableComponent = () => {
    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const form = Form.useFormInstance();
    const specSettings = Form.useWatch('specSettings', form);

    useEffect(() => {
        if (specSettings && specSettings.length > 0) {
            // 動態生成規格列
            const dynamicColumns = [];

            // 第一個規格列
            if (specSettings[0]) {
                dynamicColumns.push({
                    title: specSettings[0].specName || '',
                    dataIndex: 'spec0',
                    key: 'spec0',
                });
            }

            // 第二個規格列（如果存在）
            if (specSettings[1]) {
                dynamicColumns.push({
                    title: specSettings[1].specName || '',
                    dataIndex: 'spec1',
                    key: 'spec1',
                    render: (text, record) => {
                        // 如果有多個選項，以上下格方顯示
                        return (
                            <div className="flex flex-col">
                                {Array.isArray(record.spec1) ? record.spec1.map((option, index) => (
                                    <div key={index} className={`p-2 ${index !== record.spec1.length - 1 ? 'border-b' : ''}`}>
                                        {option}
                                    </div>
                                )) : text}
                            </div>
                        );
                    }
                });
            }

            // 固定的列（價格、折扣等）
            const fixedColumns = [
                {
                    title: serviceConfig.specTable.price,
                    dataIndex: 'price',
                    key: 'price',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => (
                                <Form.Item
                                    key={optionIndex}
                                    name={['specData', rowIndex, 'options', optionIndex, 'price']}
                                    rules={[{ required: true, message: serviceConfig.specTable.enterPrice }]}
                                    className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'price']}
                                    rules={[{ required: true, message: '請輸入價格' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                {
                    title: serviceConfig.specTable.discount,
                    dataIndex: 'discount',
                    key: 'discount',
                    render: (_, record, rowIndex) => (
                        <Form.Item
                            name={['specData', rowIndex, 'discount']}
                            valuePropName="checked"  // 添加這行
                            noStyle
                            initialValue={false}     // 設置預設值為 false
                        >
                            <Switch
                                checkedChildren="開"
                                unCheckedChildren="關"
                            />
                        </Form.Item>
                    ),
                },
                // 其他列（折扣價、晚鳥優惠等）使用相同的模式
                {
                    title: serviceConfig.specTable.discountPrice,
                    dataIndex: 'discountPrice',
                    key: 'discountPrice',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => {
                                const discount = form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'discount']);
                                return (
                                    <Form.Item
                                        key={optionIndex}
                                        name={['specData', rowIndex, 'options', optionIndex, 'discountPrice']}
                                        className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                        
                                    >
                                        <InputNumber
                                            min={0}
                                            formatter={(value) => `$ ${value}`}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            style={{ width: '100%' }}
                                            disabled
                                        />
                                    </Form.Item>
                                );
                            }) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'discountPrice']}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                        disabled
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                {
                    title: serviceConfig.specTable.lateDiscount,
                    dataIndex: 'lateDiscount',
                    key: 'lateDiscount',
                    render: (_, record, rowIndex) => (
                        <>
                            <Form.Item
                                name={['fixedTable', 'lateDiscount']}
                                noStyle
                                initialValue={record.lateDiscount} // 設置初始值
                            >
                                <div className="flex items-center justify-center">
                                <Switch
                                        checkedChildren="開"
                                        unCheckedChildren="關"
                                        defaultChecked={record.lateDiscount}
                                    />
                                </div>
                            </Form.Item>
                        </>
                    ),
                },
                {
                    title: serviceConfig.specTable.lateDiscountPrice,
                    dataIndex: 'lateDiscountPrice',
                    key: 'lateDiscountPrice',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => {
                                const lateDiscount = form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'lateDiscount']);
                                return (
                                    <Form.Item
                                        key={optionIndex}
                                        name={['specData', rowIndex, 'options', optionIndex, 'lateDiscountPrice']}
                                        rules={[{ required: lateDiscount, message: '請輸入晚鳥優惠價' }]}
                                        className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                    >
                                        <InputNumber
                                            min={0}
                                            formatter={(value) => `$ ${value}`}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            style={{ width: '100%' }}
                                            disabled
                                        />
                                    </Form.Item>
                                );
                            }) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'lateDiscountPrice']}
                                    rules={[{ required: form.getFieldValue(['specData', rowIndex, 'lateDiscount']), message: '請輸入晚鳥優惠價' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                        disabled
                                    />
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
                {
                    title: serviceConfig.specTable.stock,
                    dataIndex: 'stock',
                    key: 'stock',
                    render: (value, record, rowIndex) => (
                        <div className="flex flex-col">
                            {Array.isArray(record.spec1) ? record.spec1.map((_, optionIndex) => (
                                <Form.Item
                                    key={optionIndex}
                                    className={`mb-0 ${optionIndex !== record.spec1.length - 1 ? 'border-b pb-2' : ''} ${optionIndex !== 0 ? 'pt-2' : ''}`}
                                >
                                    <Form.Item
                                        name={['specData', rowIndex, 'options', optionIndex, 'stock']}
                                        initialValue="unlimited"
                                        noStyle
                                    >
                                        <Radio.Group>
                                            <Radio value="unlimited">Unlimited</Radio>
                                            <Radio value="limited">
                                                <Form.Item
                                                    name={['specData', rowIndex, 'options', optionIndex, 'stockAmount']}
                                                    noStyle
                                                    rules={[{
                                                        required: form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'stock']) === 'limited',
                                                        message: '請輸入庫存數量'
                                                    }]}
                                                >
                                                    <InputNumber
                                                        min={0}
                                                        disabled={form.getFieldValue(['specData', rowIndex, 'options', optionIndex, 'stock']) !== 'limited'}
                                                        style={{ width: '80px', marginLeft: 8 }}
                                                    />
                                                </Form.Item>
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Form.Item>
                            )) : (
                                <Form.Item
                                    name={['specData', rowIndex, 'stock']}
                                    initialValue="unlimited"
                                >
                                    <Radio.Group>
                                        <Radio value="unlimited">Unlimited</Radio>
                                        <Radio value="limited">
                                            <Form.Item
                                                name={['specData', rowIndex, 'stockAmount']}
                                                noStyle
                                                rules={[{
                                                    required: form.getFieldValue(['specData', rowIndex, 'stock']) === 'limited',
                                                    message: '請輸入庫存數量'
                                                }]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    disabled={form.getFieldValue(['specData', rowIndex, 'stock']) !== 'limited'}
                                                    style={{ width: '80px', marginLeft: 8 }}
                                                />
                                            </Form.Item>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                        </div>
                    ),
                },
            ];

            // 修改生成規格組合的邏輯
            const combinations = generateCombinations(specSettings);
            const data = combinations.map((combination, index) => ({
                key: index,
                ...combination,
            }));

            setColumns([...dynamicColumns, ...fixedColumns]);
            setDataSource(data);
        }
    }, [specSettings, form]);

    // 修改生成規格組合的方法
    const generateCombinations = (specs) => {
        if (!specs || specs.length === 0) return [];

        const spec1Options = specs[0]?.options.filter(opt => opt.trim() !== '') || [];
        const spec2Options = specs[1]?.options.filter(opt => opt.trim() !== '') || [];

        return spec1Options.map(spec1Option => ({
            spec0: spec1Option,
            spec1: spec2Options.length > 0 ? spec2Options : undefined,
        }));
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
        />
    );
};

// DiscountInput
const DiscountInput = ({ name, onChange, disabled }) => {
    const form = Form.useFormInstance();
    
    return (
        <Space.Compact>
            <InputNumber
                style={{ width: '120px' }}
                min={1}
                max={9}
                disabled={disabled}
                onChange={(value) => {
                    const percentOff = value ? (10 - value) * 10 : 0;
                    form.setFieldValue([...name, 'percentOff'], percentOff);
                    onChange?.(value);
                }}
            />
            <span 
                style={{ 
                    display: 'inline-block', 
                    lineHeight: '32px', 
                    padding: '0 8px', 
                    color: '#595959' 
                }}
            >
                 =
            </span>
            <Form.Item
                name={[...name, 'percentOff']}
                noStyle
            >
                <InputNumber
                    style={{ width: '120px' }}
                    disabled={true}
                    formatter={value => `${value}`}
                />
            </Form.Item>
            <span 
                style={{ 
                    display: 'inline-block', 
                    lineHeight: '32px', 
                    padding: '0 8px',
                    color: '#595959'
                }}
            >
                % off
            </span>
        </Space.Compact>
    );
};

const pancoConfig = {
    tabs: [
        { key: 'basic', label: '基本資訊' },
        { key: 'spec', label: '服務規格' },
        { key: 'discount', label: '優惠折扣' },
        { key: 'publish', label: '服務上架' }
    ],
    service: {
        title: '服務管理',
        add: '新增服務',
        draft: '儲存至草稿夾',
        publish: '儲存並發布'
    },
    formItems: {
        basic: [
            {
                name: ['basic', 'organization'],
                label: '選擇店家',
                type: 'select',
                required: true,
                component: OrganizationSelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: '請選擇店家' }],
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇店家'
                }
            },
            {
                name: 'category',
                label: '選擇類別',
                type: 'select',
                required: true,
                component: CategorySelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: '請選擇類別' }],
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇類別',
                    options: ({ categories }) =>
                        categories?.map((category) => ({
                            label: category.name,
                            value: category.id
                        })) || []
                }
            },
            {
                name: 'name',
                label: '服務名稱',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入服務名稱'
                },
                rules: [
                    { required: true, message: '請輸入服務名稱' },
                    { max: 128, message: '最多128個字元' }
                ]
            },
            {
                name: 'serviceCode',
                label: '服務代碼',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: '系統自動填入',
                options: {
                    disabled: true,
                    placeholder: '系統自動填入',
                    style: {
                        background: '#d9d9d9'
                    }
                }
            },
            {
                name: 'serviceImage',
                label: '服務主圖',
                type: 'custom',
                required: true,
                rules: [{ required: true, message: '請上傳服務主圖' }],
                component: BannerUpload,
                col: 24,
            },
            // {
            //     name: 'serviceOtherImage',
            //     label: '其他照片',
            //     type: 'upload',
            //     required: false,
            //     component: Upload,
            //     col: 12,
            //     valuePropName: 'fileList',
            //     getValueFromEvent: e => e?.fileList,
            //     options: {
            //         listType: "picture-card",
            //         maxCount: 5,
            //         children: (
            //             <div>
            //                 <PlusOutlined />
            //                 <div style={{ marginTop: 8 }}>上傳</div>
            //             </div>
            //         )
            //     }
            // },
            {
                name: 'serviceDescription',
                label: '服務介紹',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                rules: [
                    { required: true, message: '請輸入服務介紹' }
                ],
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入服務介紹',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceNotice',
                label: '注意事項',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                rules: [
                    { required: true, message: '請輸入注意事項' }
                ],
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入注意事項',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'reservation',
                label: '預約類型',
                type: 'select',
                col: 6,
                required: true,
                component: Select,
                labelCol: { span: 24 },
                style: {
                    display: 'block',
                    width: '100%'
                },
                rules: [
                    { required: true, message: '請選擇預約類型' }
                ],
                options: {
                    placeholder: '請選擇預約類型',
                    options: [
                        { label: '無預約', value: 'no_reservation' },
                        { label: '僅日期', value: 'date_only' },
                        { label: '日期與時間', value: 'date_time' }
                    ]
                },
                initialValue: 'no_reservation'
            },
            {
                name: 'servicePeriod',
                label: '使用期限',
                col: 24,
                type: 'custom',
                component: () => (
                    <Form.Item className="flex items-center min-h-[30px] mb-0">
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item 
                                    name={['servicePeriod', 'dateRange']}
                                    rules={[{ required: true, message: '請選擇使用期限' }]}
                                    noStyle
                                >
                                    <DatePicker.RangePicker
                                        style={{ width: '100%' }}
                                        disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                        allowEmpty={[true, true]}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'serviceTags',
                label: '標籤',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
                labelCol: { span: 24 },
                // rules: [
                //     { 
                //         required: true, 
                //         message: 'Please Add At Least One Tag',
                //         validator: (_, value) => {
                //             if (value && value.length > 0) {
                //                 return Promise.resolve();
                //             }
                //             return Promise.reject('Please Add At Least One Tag');
                //         }
                //     }
                // ]
            },
            {
                name: 'serviceNote',
                label: '服務備註（小標呈現）',
                type: 'text',
                required: true,
                component: Input,
                col: 24,
                options: {
                    maxLength: 128,
                    placeholder: '請輸入備註（25字內）'
                }
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        spec: [
            {
                name: 'priceType',
                type: 'radio',
                required: true,
                component: Radio.Group,
                col: 24,
                options: {
                    options: [
                        { label: '固定金額', value: 'fixed' },
                        { label: '規格', value: 'spec' }
                    ],
                    initialValues: 'fixed'
                },
            },
            {
                name: 'specSettings',
                type: 'list',
                rules: [
                    {
                        validator: async (_, value) => {
                            if (!value || value.length === 0) {
                                return Promise.reject('請至少新增一個規格');
                            }
            
                            // 檢查每個規格是否都有名稱
                            for (const spec of value) {
                                if (!spec.specName) {
                                    return Promise.reject('請填寫規格名稱');
                                }
            
                                // 檢查是否有至少一個非空選項
                                const validOptions = spec.options.filter(opt => opt.trim() !== '');
                                if (validOptions.length === 0) {
                                    return Promise.reject('請至少填寫一個規格選項');
                                }
                            }
            
                            return Promise.resolve();
                        }
                    }
                ],
                component: ({ value = [], onChange }) => {
                    const calculateTotalCombinations = (specs) => {
                        return specs.reduce((total, spec) => {
                            const validOptions = spec.options.filter(opt => opt.trim() !== '').length;
                            return total === 0 ? validOptions : total * validOptions;
                        }, 0);
                    };

                    const addSpec = () => {
                        if (value.length >= 2) {
                            message.warning('最多只能新增2個規格');
                            return;
                        }
                        const newSpec = {
                            key: Date.now(),
                            specName: '',
                            options: ['']
                        };
                        onChange([...(value || []), newSpec]);
                    };

                    const removeSpec = (specIndex) => {
                        const newSpecs = [...value];
                        newSpecs.splice(specIndex, 1);
                        onChange(newSpecs);
                    };

                    const handleOptionChange = (specIndex, optionIndex, newValue) => {
                        const newSpecs = [...value];
                        const oldValue = newSpecs[specIndex].options[optionIndex];
                        newSpecs[specIndex].options[optionIndex] = newValue;

                        // Calculate the total number of combinations
                        const totalCombinations = calculateTotalCombinations(newSpecs);

                        // If the total number of combinations exceeds 30, revert to the original value and display a warning
                        if (totalCombinations > 30) {
                            newSpecs[specIndex].options[optionIndex] = oldValue;
                            message.warning('規格組合數量不能超過30');
                            onChange(newSpecs);
                            return;
                        }

                        // If the current input box has a value and is the last input box, add an empty input box
                        if (newValue && optionIndex === newSpecs[specIndex].options.length - 1) {
                            // Pre-check if adding a new option will exceed the limit
                            const potentialCombinations = calculateTotalCombinations([
                                ...newSpecs.slice(0, specIndex),
                                { ...newSpecs[specIndex], options: [...newSpecs[specIndex].options, ''] },
                                ...newSpecs.slice(specIndex + 1)
                            ]);

                            if (potentialCombinations <= 30) {
                                newSpecs[specIndex].options.push('');
                            }
                        }

                        onChange(newSpecs);
                    };

                    const removeOption = (specIndex, optionIndex) => {
                        const newSpecs = [...value];
                        // Ensure at least one option is retained
                        if (newSpecs[specIndex].options.length > 1) {
                            newSpecs[specIndex].options.splice(optionIndex, 1);
                            onChange(newSpecs);
                        }
                    };

                    return (
                        <div className="space-y-4">
                            {value.map((spec, specIndex) => (
                                <div key={spec.key} className="space-y-4 bg-gray-100 p-4 rounded-md">
                                    <div>
                                        <div className="text-sm text-gray-600 mb-1">Spec {specIndex + 1}</div>
                                        <Input
                                            placeholder="規格名稱（例如：時間）"
                                            value={spec.specName}
                                            onChange={(e) => {
                                                const newSpecs = [...value];
                                                newSpecs[specIndex].specName = e.target.value;
                                                onChange(newSpecs);
                                            }}
                                            style={{ width: '340px' }}
                                        />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="text-sm text-gray-600">選項</div>
                                        <div className="flex flex-wrap gap-4">
                                            {spec.options.map((option, optionIndex) => (
                                                <div key={optionIndex} className="flex gap-4">
                                                    <div className="w-[340px]">
                                                        <Input
                                                            placeholder="選項（例如：60分鐘）"
                                                            value={option}
                                                            onChange={(e) => handleOptionChange(specIndex, optionIndex, e.target.value)}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>
                                                    <Button
                                                        type="text"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => removeOption(specIndex, optionIndex)}
                                                        style={{ padding: '4px 8px' }}
                                                        disabled={spec.options.length === 1}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex justify-end">
                                        <Button
                                            type="text"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => removeSpec(specIndex)}
                                        >
                                            刪除規格
                                        </Button>
                                    </div>
                                </div>
                            ))}
                            {value.length < 2 && (
                                <Button
                                    type="dashed"
                                    onClick={addSpec}
                                    icon={<PlusOutlined />}
                                >
                                    新增規格
                                </Button>
                            )}
                        </div>
                    );
                },
                col: 24,
                dependencies: ['priceType'],
                hidden: ({ getFieldValue }) => getFieldValue('priceType') !== 'spec'
            },
            {
                name: 'fixedTable',
                label: '規格表',
                type: 'table',
                component: Table,
                dependencies: ['priceType'],
                hidden: ({ getFieldValue }) => getFieldValue('priceType') !== 'fixed',
                col: 24,
                options: {
                    columns: [
                        {
                            title: 'Price',
                            dataIndex: 'price',
                            key: 'price',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    name={['fixedTable', index, 'price']}
                                    rules={[{ required: true, message: '請輸入金額' }]}
                                    noStyle
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={value => `$ ${value}`}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )
                        },
                        {
                            title: '折扣',
                            dataIndex: 'discount',
                            key: 'discount',
                            width: 100,
                            render: (_, __, index) => (
                                <Form.Item
                                    name={['fixedTable', index, 'discount']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Switch
                                        checkedChildren="On"
                                        unCheckedChildren="Off"
                                    />
                                </Form.Item>
                            )
                        },
                        {
                            title: '折扣價',
                            dataIndex: 'discountPrice',
                            key: 'discountPrice',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.fixedTable?.[index]?.discount !== currentValues?.fixedTable?.[index]?.discount;
                                    }}
                                >
                                    {({ getFieldValue }) => (
                                        <Form.Item
                                            name={['fixedTable', index, 'discountPrice']}
                                            noStyle
                                        >
                                            <InputNumber
                                                min={0}
                                                disabled
                                                formatter={value => `$ ${value}`}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            )
                        },
                        {
                            title: '晚鳥折扣',
                            dataIndex: 'lateDiscount',
                            key: 'lateDiscount',
                            width: 100,
                            render: (_, __, index) => (
                                <Form.Item
                                    name={['fixedTable', index, 'lateDiscount']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Switch
                                        checkedChildren="On"
                                        unCheckedChildren="Off"
                                    />
                                </Form.Item>
                            )
                        },
                        {
                            title: '晚鳥折扣價',
                            dataIndex: 'lateDiscountPrice',
                            key: 'lateDiscountPrice',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.fixedTable?.[index]?.lateDiscount !== currentValues?.fixedTable?.[index]?.lateDiscount;
                                    }}
                                >
                                    {({ getFieldValue }) => (
                                        <Form.Item
                                            name={['fixedTable', index, 'lateDiscountPrice']}
                                            noStyle
                                        >
                                            <InputNumber
                                                min={0}
                                                disabled
                                                formatter={value => `$ ${value}`}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            )
                        },
                        {
                            title: '庫存',
                            dataIndex: 'stock',
                            key: 'stock',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    noStyle
                                    shouldUpdate
                                >
                                    {({ getFieldValue, setFieldsValue }) => (
                                        <Form.Item
                                            name={['fixedTable', index, 'stock']}
                                            noStyle
                                            initialValue="unlimited"
                                        >
                                            <Radio.Group
                                                onChange={(e) => {
                                                    if (e.target.value === 'unlimited') {
                                                        const newFixedTable = [...(getFieldValue('fixedTable') || [])];
                                                        newFixedTable[index] = {
                                                            ...newFixedTable[index],
                                                            stockAmount: null
                                                        };
                                                        setFieldsValue({ fixedTable: newFixedTable });
                                                    }
                                                }}
                                            >
                                                <Radio value="unlimited">Unlimited</Radio>
                                                <Radio value="limited">
                                                    <Form.Item
                                                        name={['fixedTable', index, 'stockAmount']}
                                                        noStyle
                                                        rules={[
                                                            {
                                                                required: getFieldValue(['fixedTable', index, 'stock']) === 'limited',
                                                                message: 'Please Enter Stock Amount'
                                                            }
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            disabled={getFieldValue(['fixedTable', index, 'stock']) !== 'limited'}
                                                            style={{ width: '80px' }}
                                                        />
                                                    </Form.Item>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            )
                        }
                    ],
                    dataSource: [{ key: '1' }],
                    pagination: false,
                    bordered: true
                }
            },
            {
                name: 'specTable',
                label: '規格表',
                type: 'custom',
                component: SpecTableComponent,
                dependencies: ['priceType'],  // 移除 'specSettings' 依賴
                hidden: ({ getFieldValue }) => getFieldValue('priceType') !== 'spec',  // 只依賴 priceType
                col: 24,
            },
            {
                name: 'specDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        discount: [
            {
                name: 'discountContainer',
                label: '',
                type: 'custom',
                component: () => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['discount', 'type']}
                                    rules={[{ required: true, message: '請選擇折扣' }]}
                                    label="選擇折扣"
                                >
                                    <Select
                                        placeholder="選擇折扣"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: '無折扣', value: 'none' },
                                            { label: '會員折扣', value: 'member' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.discount?.type !== currentValues?.discount?.type;
                                    }}
                                >
                                    {({ getFieldValue }) => {
                                        const discountType = getFieldValue(['discount', 'type']);
                                        return (
                                            <Form.Item
                                                name={['discount', 'value']}
                                                label="折扣類型"
                                                rules={[
                                                    {
                                                        required: discountType === 'member',
                                                        message: '請輸入折扣值'
                                                    },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value < 1 || value > 9)) {
                                                                return Promise.reject('折扣必須在1到9之間');
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <DiscountInput 
                                                    name={['discount']} 
                                                    disabled={discountType === 'none'}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.discount?.type !== currentValues?.discount?.type;
                                    }}
                                >
                                    {({ getFieldValue }) => {
                                        const discountType = getFieldValue(['discount', 'type']);
                                        return (
                                            <Form.Item
                                                name={['discount', 'period']}
                                                className="custom-label-flex"
                                                rules={[
                                                    {
                                                        required: discountType === 'member',
                                                        message: '請選擇折扣期限'
                                                    }
                                                ]}
                                                label="折扣期限"
                                            >
                                                <DatePicker.RangePicker
                                                    style={{ width: '100%' }}
                                                    disabled={discountType === 'none'}
                                                    allowEmpty={[true, true]}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )
            },
            {
                name: 'lateDiscountContainer',
                label: '',
                type: 'custom',
                component: () => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['lateDiscount', 'type']}
                                    rules={[{ required: true, message: '請選擇晚鳥折扣' }]}
                                    label="選擇晚鳥折扣"
                                >
                                    <Select
                                        placeholder="選擇晚鳥折扣"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: '無折扣', value: 'none' },
                                            { label: '晚鳥折扣', value: 'late' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.lateDiscount?.type !== currentValues?.lateDiscount?.type;
                                    }}
                                >
                                    {({ getFieldValue }) => {
                                        const lateDiscountType = getFieldValue(['lateDiscount', 'type']);
                                        return (
                                            <Form.Item
                                                name={['lateDiscount', 'value']}
                                                label="折扣類型"
                                                rules={[
                                                    {
                                                        required: lateDiscountType === 'late',
                                                        message: '請輸入晚鳥折扣值'
                                                    },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value < 1 || value > 9)) {
                                                                return Promise.reject('折扣必須在1到9之間');
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <DiscountInput 
                                                    name={['lateDiscount']} 
                                                    disabled={lateDiscountType === 'none'}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.lateDiscount?.type !== currentValues?.lateDiscount?.type;
                                    }}
                                >
                                    {({ getFieldValue }) => {
                                        const lateDiscountType = getFieldValue(['lateDiscount', 'type']);
                                        return (
                                            <Form.Item
                                                name={['lateDiscount', 'period']}
                                                className="custom-label-flex"
                                                rules={[
                                                    {
                                                        required: lateDiscountType === 'late',
                                                        message: '請選擇晚鳥折扣期限'
                                                    }
                                                ]}
                                                label="晚鳥折扣期限"
                                            >
                                                <DatePicker.RangePicker
                                                    style={{ width: '100%' }}
                                                    disabled={lateDiscountType === 'none'}
                                                    allowEmpty={[true, true]}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )
            },
            {
                name: 'profitShare',
                label: '利潤分潤（單位：%）',
                type: 'number',
                required: true,
                component: InputNumber,
                style: {
                    width: '100%'
                },
                col: 12,
                options: {
                    min: 0,
                    max: 100,
                    formatter: value => `${value}%`,
                    parser: value => value.replace('%', '')
                }
            },
            {
                name: 'bonusPoints',
                label: '紅利點數（單位：點）',
                type: 'number',
                required: true,
                component: InputNumber,
                style: {
                    width: '100%'
                },
                col: 12,
                options: {
                    min: 0,
                    formatter: value => `${value} Point`,
                    parser: value => value.replace('Point', '')
                }
            },
            {
                name: 'discountDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        publish: [
            {
                name: 'recommendContainer',
                label: '',
                type: 'custom',
                component: ({ children }) => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['recommend', 'type']}
                                    label="推薦服務"
                                >
                                    <Select
                                        placeholder="選擇類別"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: '活動', value: 'event' },
                                            { label: '熱門', value: 'popular' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['recommend', 'text']}
                                    label="顯示文字"
                                >
                                    <Input placeholder="熱門" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={['recommend', 'period']}
                                    className="custom-label-flex"
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                            <span>期限</span>
                                            <Form.Item
                                                name={['recommend', 'noLimit']}
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                {/* <Checkbox>無期限</Checkbox> */}
                                            </Form.Item>
                                        </div>
                                    }
                                    labelCol={{
                                        style: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues?.recommend?.noLimit !== currentValues?.recommend?.noLimit;
                                        }}
                                    >
                                        {({ getFieldValue }) => (
                                            <DatePicker.RangePicker
                                                style={{ width: '100%' }}
                                                allowEmpty={[true, true]}
                                                disabled={getFieldValue(['recommend', 'noLimit'])}
                                            />
                                        )}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ),
                col: 24
            },
            {
                name: 'beauticians',
                label: '分配可進行美業老師',
                type: 'select',
                required: true,
                component: BeauticianSelect,
                col: 8,
                rules: [
                    { required: true, message: '請選擇至少一位美容師' }
                ],
                options: {
                    mode: 'multiple',
                    placeholder: '選擇美業老師',
                }
            },
            {
                name: 'scheduleContainer',
                label: 'Schedule',
                type: 'custom',
                component: () => (
                    <Row>
                        <Col span={8}>
                            <div className="flex items-center bg-white gap-4">
                                <DatePicker
                                    placeholder="Select Date"
                                />
                                <TimePicker
                                    placeholder="Select Time"
                                    format="HH:mm"
                                />
                            </div>
                        </Col>
                    </Row>
                ),
                col: 24
            },
            {
                name: 'spacer',
                label: ' ',
                type: 'custom',
                component: () => <div className="h-[500px]" />,
            }
        ]
    }
};

const stag5Config = {
    tabs: [
        { key: 'basic', label: 'Basic Information' },
        { key: 'spec', label: 'Service Specifications' },
        { key: 'discount', label: 'Discounts' },
        { key: 'publish', label: 'Service Publishing' }
    ],
    service: {
        title: 'Service Management',
        add: 'Add Service',
        draft: 'Save to Draft',
        publish: 'Save and Publish'
    },
    formItems: {
        basic: [
            {
                name: ['basic', 'organization'],
                label: 'Select Store',
                type: 'select',
                required: true,
                component: OrganizationSelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: 'Please Select Store' }],
                style: {
                    display: 'block',
                    width: '100%'
                }
            },
            {
                name: 'category',
                label: 'Select Category',
                type: 'select',
                required: true,
                component: CategorySelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: 'Please Select Category' }],
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇類別',
                    options: ({ categories }) =>
                        categories?.map((category) => ({
                            label: category.name,
                            value: category.id
                        })) || []
                }
            },
            {
                name: 'name',
                label: 'Service Name',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: 'Please Enter Service Name'
                },
                rules: [
                    { required: true, message: 'Please Enter Service Name' },
                    { max: 128, message: 'Max 128 Characters' }
                ]
            },
            {
                name: 'serviceCode',
                label: 'Service Code',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: 'System Auto Fill',
                options: {
                    disabled: true,
                    placeholder: 'System Auto Fill',
                    style: {
                        background: '#d9d9d9'
                    }
                }
            },
            {
                name: 'serviceImage',
                label: 'Service Main Image',
                type: 'custom',
                required: true,
                rules: [{ required: true, message: 'Please Upload Service Main Image' }],
                component: BannerUpload,
                col: 24,
            },
            // {
            //     name: 'serviceOtherImage',
            //     label: '其他照片',
            //     type: 'upload',
            //     required: false,
            //     component: Upload,
            //     col: 12,
            //     valuePropName: 'fileList',
            //     getValueFromEvent: e => e?.fileList,
            //     options: {
            //         listType: "picture-card",
            //         maxCount: 5,
            //         children: (
            //             <div>
            //                 <PlusOutlined />
            //                 <div style={{ marginTop: 8 }}>上傳</div>
            //             </div>
            //         )
            //     }
            // },
            {
                name: 'serviceDescription',
                label: 'Service Introduction',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                rules: [
                    { required: true, message: 'Please Enter Service Description' }
                ],
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: 'Please Enter Service Introduction',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceNotice',
                label: 'Notice',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                rules: [
                    { required: true, message: 'Please Enter Notice' }
                ],
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: 'Please Enter Notice',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'reservation',
                label: 'Reservation',
                type: 'select',
                col: 6,
                required: true,
                component: Select,
                labelCol: { span: 24 },
                style: {
                    display: 'block',
                    width: '100%'
                },
                rules: [
                    { required: true, message: 'Please Select Reservation Type' }
                ],
                options: {
                    placeholder: 'Please Select Reservation Type',
                    options: [
                        { label: 'No Reservation', value: 'no_reservation' },
                        { label: 'Date Only', value: 'date_only' },
                        { label: 'Date and Time', value: 'date_time' }
                    ]
                },
                initialValue: 'no_reservation'
            },
            {
                name: 'servicePeriod',
                col: 24,
                type: 'custom',
                required: true,
                component: () => (
                    <Form.Item
                        className="custom-label-flex"
                        label={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <span>Service Period</span>
                                <Form.Item
                                    name={['servicePeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    {/* <Checkbox>無期限</Checkbox> */}
                                </Form.Item>
                            </div>
                        }
                        labelCol={{
                            style: {
                                width: '100%'
                            }
                        }}
                        wrapperCol={{
                            span: 6
                        }}
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item 
                                    name={['servicePeriod', 'dateRange']}
                                    rules={[
                                        {
                                            required: !getFieldValue(['servicePeriod', 'noLimit']),
                                            message: 'Please Select Service Period'
                                        }
                                    ]}
                                    noStyle
                                >
                                    <DatePicker.RangePicker
                                        style={{ width: '100%' }}
                                        disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                        allowEmpty={[true, true]}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'serviceTags',
                label: 'Tags',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
                labelCol: { span: 24 },
                // rules: [
                //     { 
                //         required: true, 
                //         message: 'Please Add At Least One Tag',
                //         validator: (_, value) => {
                //             if (value && value.length > 0) {
                //                 return Promise.resolve();
                //             }
                //             return Promise.reject('Please Add At Least One Tag');
                //         }
                //     }
                // ]
            },
            {
                name: 'serviceNote',
                label: 'Service Note (Subtitle)',
                type: 'text',
                required: true,
                component: Input,
                col: 24,
                options: {
                    maxLength: 128,
                    placeholder: 'Please Enter Note (25 Characters)'
                }
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        spec: [
            {
                name: 'priceType',
                type: 'radio',
                required: true,
                component: Radio.Group,
                col: 24,
                options: {
                    options: [
                        { label: 'Fixed Amount', value: 'fixed' },
                        { label: 'Spec Amount', value: 'spec' }
                    ],
                    initialValues: 'fixed'
                },
            },
            {
                name: 'specSettings',
                type: 'list',
                component: ({ value = [], onChange }) => {
                    const calculateTotalCombinations = (specs) => {
                        return specs.reduce((total, spec) => {
                            const validOptions = spec.options.filter(opt => opt.trim() !== '').length;
                            return total === 0 ? validOptions : total * validOptions;
                        }, 0);
                    };

                    const addSpec = () => {
                        if (value.length >= 2) {
                            message.warning('Can Only Add 2 Specs');
                            return;
                        }
                        const newSpec = {
                            key: Date.now(),
                            specName: '',
                            options: ['']
                        };
                        onChange([...(value || []), newSpec]);
                    };

                    const removeSpec = (specIndex) => {
                        const newSpecs = [...value];
                        newSpecs.splice(specIndex, 1);
                        onChange(newSpecs);
                    };

                    const handleOptionChange = (specIndex, optionIndex, newValue) => {
                        const newSpecs = [...value];
                        const oldValue = newSpecs[specIndex].options[optionIndex];
                        newSpecs[specIndex].options[optionIndex] = newValue;

                        // Calculate the total number of combinations
                        const totalCombinations = calculateTotalCombinations(newSpecs);

                        // If the total number of combinations exceeds 30, revert to the original value and display a warning
                        if (totalCombinations > 30) {
                            newSpecs[specIndex].options[optionIndex] = oldValue;
                            message.warning('Spec Combinations Cannot Exceed 30');
                            onChange(newSpecs);
                            return;
                        }

                        // If the current input box has a value and is the last input box, add an empty input box
                        if (newValue && optionIndex === newSpecs[specIndex].options.length - 1) {
                            // Pre-check if adding a new option will exceed the limit
                            const potentialCombinations = calculateTotalCombinations([
                                ...newSpecs.slice(0, specIndex),
                                { ...newSpecs[specIndex], options: [...newSpecs[specIndex].options, ''] },
                                ...newSpecs.slice(specIndex + 1)
                            ]);

                            if (potentialCombinations <= 30) {
                                newSpecs[specIndex].options.push('');
                            }
                        }

                        onChange(newSpecs);
                    };

                    const removeOption = (specIndex, optionIndex) => {
                        const newSpecs = [...value];
                        // Ensure at least one option is retained
                        if (newSpecs[specIndex].options.length > 1) {
                            newSpecs[specIndex].options.splice(optionIndex, 1);
                            onChange(newSpecs);
                        }
                    };

                    return (
                        <div className="space-y-4">
                            {value.map((spec, specIndex) => (
                                <div key={spec.key} className="space-y-4 bg-gray-100 p-4 rounded-md">
                                    <div>
                                        <div className="text-sm text-gray-600 mb-1">Spec {specIndex + 1}</div>
                                        <Input
                                            placeholder="Spec Name (e.g., Duration)"
                                            value={spec.specName}
                                            onChange={(e) => {
                                                const newSpecs = [...value];
                                                newSpecs[specIndex].specName = e.target.value;
                                                onChange(newSpecs);
                                            }}
                                            style={{ width: '340px' }}
                                        />
                                    </div>
                                    <div className="space-y-2">
                                        <div className="text-sm text-gray-600">Options</div>
                                        <div className="flex flex-wrap gap-4">
                                            {spec.options.map((option, optionIndex) => (
                                                <div key={optionIndex} className="flex gap-4">
                                                    <div className="w-[340px]">
                                                        <Input
                                                            placeholder="Option (e.g., 60 Minutes)"
                                                            value={option}
                                                            onChange={(e) => handleOptionChange(specIndex, optionIndex, e.target.value)}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>
                                                    <Button
                                                        type="text"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => removeOption(specIndex, optionIndex)}
                                                        style={{ padding: '4px 8px' }}
                                                        disabled={spec.options.length === 1}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex justify-end">
                                        <Button
                                            type="text"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={() => removeSpec(specIndex)}
                                        >
                                            Delete Spec
                                        </Button>
                                    </div>
                                </div>
                            ))}
                            {value.length < 2 && (
                                <Button
                                    type="dashed"
                                    onClick={addSpec}
                                    icon={<PlusOutlined />}
                                >
                                    Add Spec
                                </Button>
                            )}
                        </div>
                    );
                },
                col: 24,
                dependencies: ['priceType'],
                hidden: ({ getFieldValue }) => getFieldValue('priceType') !== 'spec'
            },
            {
                name: 'fixedTable',
                label: 'Spec Table',
                type: 'table',
                component: Table,
                dependencies: ['priceType'],
                hidden: ({ getFieldValue }) => getFieldValue('priceType') !== 'fixed',
                col: 24,
                options: {
                    columns: [
                        {
                            title: 'Price',
                            dataIndex: 'price',
                            key: 'price',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    name={['fixedTable', index, 'price']}
                                    rules={[{ required: true, message: 'Please Enter Price' }]}
                                    noStyle
                                >
                                    <InputNumber
                                        min={0}
                                        formatter={value => `$ ${value}`}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            )
                        },
                        {
                            title: 'Discount',
                            dataIndex: 'discount',
                            key: 'discount',
                            width: 100,
                            render: (_, __, index) => (
                                <Form.Item
                                    name={['fixedTable', index, 'discount']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Switch
                                        checkedChildren="On"
                                        unCheckedChildren="Off"
                                    />
                                </Form.Item>
                            )
                        },
                        {
                            title: 'Discount Price',
                            dataIndex: 'discountPrice',
                            key: 'discountPrice',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.fixedTable?.[index]?.discount !== currentValues?.fixedTable?.[index]?.discount;
                                    }}
                                >
                                    {({ getFieldValue }) => (
                                        <Form.Item
                                            name={['fixedTable', index, 'discountPrice']}
                                            noStyle
                                        >
                                            <InputNumber
                                                min={0}
                                                disabled
                                                formatter={value => `$ ${value}`}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            )
                        },
                        {
                            title: 'Late Discount',
                            dataIndex: 'lateDiscount',
                            key: 'lateDiscount',
                            width: 100,
                            render: (_, __, index) => (
                                <Form.Item
                                    name={['fixedTable', index, 'lateDiscount']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Switch
                                        checkedChildren="On"
                                        unCheckedChildren="Off"
                                    />
                                </Form.Item>
                            )
                        },
                        {
                            title: 'Late Discount Price',
                            dataIndex: 'lateDiscountPrice',
                            key: 'lateDiscountPrice',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.fixedTable?.[index]?.lateDiscount !== currentValues?.fixedTable?.[index]?.lateDiscount;
                                    }}
                                >
                                    {({ getFieldValue }) => (
                                        <Form.Item
                                            name={['fixedTable', index, 'lateDiscountPrice']}
                                            rules={[
                                                {
                                                    required: getFieldValue(['fixedTable', index, 'lateDiscount']),
                                                    message: 'Please Enter Late Discount Price'
                                                }
                                            ]}
                                            noStyle
                                        >
                                            <InputNumber
                                                min={0}
                                                disabled
                                                formatter={value => `$ ${value}`}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            )
                        },
                        {
                            title: 'Stock',
                            dataIndex: 'stock',
                            key: 'stock',
                            width: 150,
                            render: (_, __, index) => (
                                <Form.Item
                                    noStyle
                                    shouldUpdate
                                >
                                    {({ getFieldValue, setFieldsValue }) => (
                                        <Form.Item
                                            name={['fixedTable', index, 'stock']}
                                            noStyle
                                            initialValue="unlimited"
                                        >
                                            <Radio.Group
                                                onChange={(e) => {
                                                    if (e.target.value === 'unlimited') {
                                                        const newFixedTable = [...(getFieldValue('fixedTable') || [])];
                                                        newFixedTable[index] = {
                                                            ...newFixedTable[index],
                                                            stockAmount: null
                                                        };
                                                        setFieldsValue({ fixedTable: newFixedTable });
                                                    }
                                                }}
                                            >
                                                <Radio value="unlimited">Unlimited</Radio>
                                                <Radio value="limited">
                                                    <Form.Item
                                                        name={['fixedTable', index, 'stockAmount']}
                                                        noStyle
                                                        rules={[
                                                            {
                                                                required: getFieldValue(['fixedTable', index, 'stock']) === 'limited',
                                                                message: 'Please Enter Stock Amount'
                                                            }
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            min={0}
                                                            disabled={getFieldValue(['fixedTable', index, 'stock']) !== 'limited'}
                                                            style={{ width: '80px' }}
                                                        />
                                                    </Form.Item>
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    )}
                                </Form.Item>
                            )
                        }
                    ],
                    dataSource: [{ key: '1' }],
                    pagination: false,
                    bordered: true
                }
            },
            {
                name: 'specTable',
                label: 'Spec Table',
                type: 'custom',
                component: SpecTableComponent,
                dependencies: ['priceType'],  // 移除 'specSettings' 依賴
                hidden: ({ getFieldValue }) => getFieldValue('priceType') !== 'spec',  // 只依賴 priceType
                col: 24,
            },
            {
                name: 'specDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        discount: [
            {
                name: 'discountContainer',
                label: '',
                type: 'custom',
                component: () => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['discount', 'type']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Discount'
                                        }
                                    ]}
                                    label="Select Discount"
                                >
                                    <Select
                                        placeholder="Tier Discount"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: 'None', value: 'none' },
                                            { label: 'Member Discount', value: 'member' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['discount', 'value']}
                                    label="Discount Type"
                                    rules={[
                                        {
                                            required: ({ getFieldValue }) => getFieldValue(['discount', 'type']) === 'member',
                                            message: 'Please Enter Discount Value'
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (value && (value < 1 || value > 9)) {
                                                    return Promise.reject('Discount must be between 1 and 9');
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <DiscountInput name={['discount']} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={['discount', 'period']}
                                    className="custom-label-flex"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Discount Period'
                                        }
                                    ]}
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                            <span>Discount Period</span>
                                            <Form.Item
                                                name={['discount', 'noLimit']}
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                {/* <Checkbox>無期限</Checkbox> */}
                                            </Form.Item>
                                        </div>
                                    }
                                    labelCol={{
                                        style: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues?.discount?.noLimit !== currentValues?.discount?.noLimit;
                                        }}
                                    >
                                        {({ getFieldValue, setFieldsValue }) => (
                                            <DatePicker.RangePicker
                                                style={{ width: '100%' }}
                                                disabled={getFieldValue(['discount', 'noLimit'])}
                                                allowEmpty={[true, true]}
                                                value={getFieldValue(['discount', 'period'])}
                                                onChange={(dates) => {
                                                    setFieldsValue({
                                                        discount: {
                                                            ...getFieldValue('discount'),
                                                            period: dates
                                                        }
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )
            },
            {
                name: 'lateDiscountContainer',
                label: '',
                type: 'custom',
                component: () => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['lateDiscount', 'type']}
                                    rules={[{ required: true, message: '請選擇晚鳥折扣' }]}
                                    label="選擇晚鳥折扣"
                                >
                                    <Select
                                        placeholder="選擇晚鳥折扣"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: '無折扣', value: 'none' },
                                            { label: '晚鳥折扣', value: 'late' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.lateDiscount?.type !== currentValues?.lateDiscount?.type;
                                    }}
                                >
                                    {({ getFieldValue }) => {
                                        const lateDiscountType = getFieldValue(['lateDiscount', 'type']);
                                        return (
                                            <Form.Item
                                                name={['lateDiscount', 'value']}
                                                label="折扣類型"
                                                rules={[
                                                    {
                                                        required: lateDiscountType === 'late',
                                                        message: '請輸入晚鳥折扣值'
                                                    },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && (value < 1 || value > 9)) {
                                                                return Promise.reject('折扣必須在1到9之間');
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <DiscountInput 
                                                    name={['lateDiscount']} 
                                                    disabled={lateDiscountType === 'none'}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) => {
                                        return prevValues?.lateDiscount?.type !== currentValues?.lateDiscount?.type;
                                    }}
                                >
                                    {({ getFieldValue }) => {
                                        const lateDiscountType = getFieldValue(['lateDiscount', 'type']);
                                        return (
                                            <Form.Item
                                                name={['lateDiscount', 'period']}
                                                className="custom-label-flex"
                                                rules={[
                                                    {
                                                        required: lateDiscountType === 'late',
                                                        message: '請選擇晚鳥折扣期限'
                                                    }
                                                ]}
                                                label="晚鳥折扣期限"
                                            >
                                                <DatePicker.RangePicker
                                                    style={{ width: '100%' }}
                                                    disabled={lateDiscountType === 'none'}
                                                    allowEmpty={[true, true]}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )
            },
            {
                name: 'profitShare',
                label: 'Profit Share (Unit: %)',
                type: 'number',
                required: true,
                component: InputNumber,
                style: {
                    width: '100%'
                },
                col: 12,
                options: {
                    min: 0,
                    max: 100,
                    formatter: value => `${value}%`,
                    parser: value => value.replace('%', '')
                }
            },
            {
                name: 'bonusPoints',
                label: 'Bonus Points (Unit: Point)',
                type: 'number',
                required: true,
                component: InputNumber,
                style: {
                    width: '100%'
                },
                col: 12,
                options: {
                    min: 0,
                    formatter: value => `${value} Point`,
                    parser: value => value.replace('Point', '')
                }
            },
            {
                name: 'discountDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        publish: [
            {
                name: 'recommendContainer',
                label: '',
                type: 'custom',
                component: ({ children }) => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['recommend', 'type']}
                                    label="Recommend Service"
                                >
                                    <Select
                                        placeholder="Select Category"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: 'Event', value: 'event' },
                                            { label: 'Popular', value: 'popular' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['recommend', 'text']}
                                    label="Display Text"
                                >
                                    <Input placeholder="Popular" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={['recommend', 'period']}
                                    className="custom-label-flex"
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                            <span>Period</span>
                                            <Form.Item
                                                name={['recommend', 'noLimit']}
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                {/* <Checkbox>無期限</Checkbox> */}
                                            </Form.Item>
                                        </div>
                                    }
                                    labelCol={{
                                        style: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues?.recommend?.noLimit !== currentValues?.recommend?.noLimit;
                                        }}
                                    >
                                        {({ getFieldValue }) => (
                                            <DatePicker.RangePicker
                                                style={{ width: '100%' }}
                                                allowEmpty={[true, true]}
                                                disabled={getFieldValue(['recommend', 'noLimit'])}
                                            />
                                        )}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ),
                col: 24
            },
            {
                name: 'scheduleContainer',
                label: 'Schedule',
                type: 'custom',
                component: () => (
                    <Row>
                        <Col span={8}>
                            <div className="flex items-center bg-white gap-4">
                                <DatePicker
                                    placeholder="Select Date"
                                />
                                <TimePicker
                                    placeholder="Select Time"
                                    format="HH:mm"
                                />
                            </div>
                        </Col>
                    </Row>
                ),
                col: 24
            },
            {
                name: 'spacer',
                label: ' ',
                type: 'custom',
                component: () => <div className="h-[500px]" />,
            }
        ]
    }
};

// form scroll active tab
const useFormScroll = (formRef) => {
    const [activeTab, setActiveTab] = useState('basic');

    useEffect(() => {
        const handleScroll = () => {
            if (!formRef.current) return;

            const container = formRef.current.closest('.overflow-auto');
            if (!container) return;

            const containerTop = container.getBoundingClientRect().top;
            const offset = 200; // 整這個值來改變切換的觸發位置

            const tabSections = pancoConfig.tabs.map(tab => {
                const element = document.getElementById(tab.key);
                if (!element) return { key: tab.key, top: Infinity };
                const rect = element.getBoundingClientRect();
                // 計算相���於容器的位置
                const relativeTop = rect.top - containerTop;
                return {
                    key: tab.key,
                    top: relativeTop
                };
            }).filter(section => section.top !== Infinity);

            // 找到最��近但還未超過觸發位置的section
            const currentSection = tabSections.reduce((prev, curr) => {
                if (curr.top <= offset && curr.top > prev.top) {
                    return curr;
                }
                return prev;
            }, { key: tabSections[0].key, top: -Infinity });

            if (currentSection && currentSection.key !== activeTab) {
                setActiveTab(currentSection.key);
            }
        };

        const container = formRef.current?.closest('.overflow-auto');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [formRef, activeTab]);

    return activeTab;
};

// 新增表單邏輯處理
const formLogic = {
    handleSubmit: async (values, setLoading) => {
        setLoading(true);
        try {
            const specs = [];
            const discounts = [];

            console.log('表單原始資料', values);

             // 處理折扣數據
             const discountData = [
                {
                    name: values.discount?.type === 'member' ? '會員折扣' : '不使用會員折扣',
                    description: values.discount?.type === 'member' ? '會員折扣' : '不使用會員折扣',
                    discount: values.discount?.type === 'member' ? 0 : 0,
                    discount_percentage: values.discount?.type === 'member' ? values.discount?.percentOff : 0,
                    level: values.discount?.type === 'member' ? 0 : '',
                    valid_from: values.discount?.noLimit ? null : values.discount?.period?.[0]?.format('YYYY-MM-DD') || null,
                    valid_until: values.discount?.noLimit ? null : values.discount?.period?.[1]?.format('YYYY-MM-DD') || null,
                },
                {
                    name: values.lateDiscount?.type === 'late' ? '晚鳥折扣' : '不使���晚鳥折扣',
                    description: values.lateDiscount?.type === 'late' ? '晚鳥折扣' : '不使用晚鳥折扣',
                    discount: values.lateDiscount?.type === 'late' ? 0 : 0,
                    discount_percentage: values.lateDiscount?.type === 'late' ? values.lateDiscount?.percentOff : 0,
                    level: values.lateDiscount?.type === 'late' ? 0 : 0,
                    valid_from: values.lateDiscount?.noLimit ? null : values.lateDiscount?.period?.[0]?.format('YYYY-MM-DD') || null,
                    valid_until: values.lateDiscount?.noLimit ? null : values.lateDiscount?.period?.[1]?.format('YYYY-MM-DD') || null,
                },
            ];            
            
            // 構建 blobData
            const blobData = values.serviceImage ? values.serviceImage.blobs.map((blob, index) => ({
                preview: values.serviceImage.preview[index],
                blob: blob
            })) : [];

            // 構建 formattedData
            const formattedData = {
                name: values.name || '',
                product_type: values.category?.product_type || '',
                description: values.serviceDescription || '',
                notification: values.serviceNotice || '',
                category_id: values.category?.id || '',
                identity_ids: values.beauticians?.map(item => item.identity_id) || [],
                tags: values.serviceTags?.map(tag => ({
                    id: tag.id,
                    name: tag.name
                })) || [],
                valid_from: values.servicePeriod?.noLimit ? null : values.servicePeriod?.dateRange?.[0]?.format('YYYY-MM-DD') || null,
                valid_until: values.servicePeriod?.noLimit ? null : values.servicePeriod?.dateRange?.[1]?.format('YYYY-MM-DD') || null,
                specs: specs,
                discounts: discounts,
            };
           

            // 處理規格金額資料
            if (values.priceType === 'fixed' && values.fixedTable && values.fixedTable.length > 0) {
                // 將固定價格規格改為陣列格式
                values.fixedTable.forEach((item) => {
                    const spec = {
                        information: {
                            tier_variations: []
                        },
                        name: detailConfig.labels.fixedAmount,
                        description: "",
                        price: item.price || 0,
                        cost: 0,
                        discount_enabled: item.discount || false,
                        discount_price: item.discountPrice || 0,
                        late_discount_enabled: item.lateDiscount || false,
                        late_discount_price: item.lateDiscountPrice || 0,
                        stock: item.stock === 'limited' ? (item.stockAmount || 0) : 0,
                        unlimited_stock: item.stock === 'unlimited'
                    };
                    specs.push(spec);
                });
            } else if (values.priceType === "spec" && values.specData) {
                // 取得規格1和規格2的所有選項（過濾空值）
                const spec1Options = values.specSettings[0]?.options.filter(opt => opt.trim() !== '') || [];
                const spec2Options = values.specSettings[1]?.options.filter(opt => opt.trim() !== '') || [];
                
                console.log('values.specData:', values.specData);
                
                values.specData.forEach((row, rowIndex) => {
                    const spec1Name = values.specSettings[0]?.specName || '';
                    const spec2Name = values.specSettings[1]?.specName || '';
                    
                    console.log('specData:', values.specData);
                    
                    if (row.options) {
                        // 多規格情況
                        row.options.forEach((option, optionIndex) => {
                            const information = {
                                tier_variations: [
                                    {
                                        name: spec1Name,
                                        value: spec1Options[rowIndex]
                                    }
                                ]
                            };

                            if (spec2Name && spec2Options[optionIndex]) {
                                information.tier_variations.push({
                                    name: spec2Name,
                                    value: spec2Options[optionIndex]
                                });
                            }

                            const formattedSpec = {
                                information,
                                name: `${spec1Options[rowIndex]} ${spec2Options[optionIndex] || ''}`.trim(),
                                description: "",
                                price: option.price || 0,
                                cost: 0,
                                discount_enabled: values.specData[rowIndex].discount || false,
                                discount_price: option.discountPrice || 0,
                                late_discount_enabled: option.lateDiscount || false,
                                late_discount_price: option.lateDiscountPrice || 0,
                                stock: option.stock === 'limited' ? (option.stockAmount || 0) : 0,
                                unlimited_stock: option.stock === 'unlimited'
                            };
                            specs.push(formattedSpec);
                        });
                    } else {
                        // 單規格情況
                        const information = {
                            tier_variations: [
                                {
                                    name: spec1Name,
                                    value: spec1Options[rowIndex]
                                }
                            ]
                        };

                        const formattedSpec = {
                            information,
                            name: spec1Options[rowIndex],
                            description: "",
                            price: row.price || 0,
                            cost: 0,
                            discount_enabled: row.discount || false,
                            discount_price: row.discountPrice || 0,
                            late_discount_enabled: row.lateDiscount || false,
                            late_discount_price: row.lateDiscountPrice || 0,
                            stock: row.stock === 'limited' ? (row.stockAmount || 0) : 0,
                            unlimited_stock: row.stock === 'unlimited'
                        };
                        specs.push(formattedSpec);
                    }
                });
            }

            console.log('有specs:', specs);
            
            // 將規格資料加入 formattedData
            formattedData.specs = specs;

            // 處理折扣資料...
            formattedData.discounts = discountData;

            console.log('表單提交數據:', formattedData);
            // API 調用邏輯...

            const res = await createServiceAPI(formattedData, values.basic.organization);
        
            if (res.status === 200) {
                const { data } = res;
                const id = data.id;

            if (blobData.length > 0) {
                await updateProductImagesAPI(id, {
                    imagesToAdd: blobData.map(item => item.blob),
                    imagesToRemove: [] 
                });
            }

            // 修改這裡：循環處理所有的 specs
            try {
                for (const spec of specs) {
                    await createSpecAPI(id, spec);
                }
            } catch (error) {
                console.error('規格創建失敗:', error);
                message.error('部分規格創建失敗');
            }

            try {                                
                for (const discount of discountData) {
                    await createDiscountAPI(id, discount);
                }

                console.log('beauticians:', values.beauticians);
                
                // 增加防護檢查
                if (values.beauticians && Array.isArray(values.beauticians)) {
                    for (const beautician of values.beauticians) {
                        if (beautician && beautician.identity_id) {
                            await createProviderAPI(id, beautician.identity_id, beautician.provider_id);
                        }
                    }
                }
                
                
                setLoading(false);
                message.success(serviceConfig.message.success);
                
                // 使用 setTimeout 延遲 1.5 秒後跳轉
                setTimeout(() => {
                    window.location.href = '/service';
                }, 1500);
            } catch (error) {
                console.error('折扣或美容師創建失敗:', error);
                message.error(serviceConfig.message.error);
                window.location.href = '/service';
            }
        } else {
            message.error(serviceConfig.message.error);
            window.location.href = '/service';
        }

        } catch (error) {
            console.error('表單提交失敗:', error);
            message.error(serviceConfig.message.error);
            window.location.href = '/service';
        }
    },

    scrollToTab: (key, formRef) => {
        const element = document.getElementById(key);
        if (element) {
            const container = formRef.current.closest('.overflow-auto');
            if (container) {
                container.scrollTo({
                    top: element.offsetTop - 300,
                    behavior: 'smooth'
                });
            }
        }
    }
};

export { useFormScroll, formLogic };

// 根據環境變數選擇配置
const isPanco = ENV.E_COMMERCE.PANCO;
const isStag5 = ENV.E_COMMERCE.STAG5;


export const serviceFormConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : null;
