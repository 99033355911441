import { ENV } from '@/constants/env';
import { Tag, Input, Button } from 'antd';
import moment from 'moment';
import { detailConfig as config } from '../detail/detailConfig';
const ellipsisStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%'
};

const _77Config = {
  page: [
    {
      title: "Service Management",
      addButton: "Add Service",
      information: "Basic Information",
      specification: "Service Specifications",
      discount: "Discounts and Offers",
      publish: "Service Deployment",
    }
  ],
  columns: ({ setCurrentView, setSelectedTicket, currentView }) => [
    {
      title: "活動序號",
      dataIndex: "id",
      width: "auto",
      align: "center",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => id.substring(0, 5),
    },
    {
      title: "主辦人1",
      dataIndex: "organizer1",
      width: "auto",
      align: "center",
      sorter: (a, b) => a.organizer1.localeCompare(b.organizer1),
      render: (organizer) => organizer || <span className="text-gray-400">未設定主辦人</span>,
    },
    {
      title: "主辦人2",
      dataIndex: "organizer2",
      width: "auto",
      align: "center",
      sorter: (a, b) => a.organizer2.localeCompare(b.organizer2),
      render: (organizer2) => organizer2 || <span className="text-gray-400">未設定主辦人</span>,
    },
    {
      title: "活動種類",
      dataIndex: "type",
      width: "auto",
      align: "center",
      sorter: (a, b) => a.type.localeCompare(b.type),
      render: (type) => type || <span className="text-gray-400">未設定活動種類</span>,
    },
    {
      title: "活動時間",
      dataIndex: "valid_from",
      width: "auto",
      align: "center",
      render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">未設定上架日期</span>,
    },
    {
      title: "活動場館",
      dataIndex: "location",
      width: "auto",
      align: "center",
      render: (location) => location || <span className="text-gray-400">未設定活動場館</span>,
    },
    {
      title: "活動空間",
      dataIndex: "space",
      width: "auto",
      align: "center",
      render: (space) => space || <span className="text-gray-400">未設定活動空間</span>,
    },
    {
      title: "預定桌數",
      dataIndex: "table_count",
      width: "auto",
      align: "center",
      render: (table_count) => table_count || <span className="text-gray-400">未設定預定桌數</span>,
    },
    {
      title: "預定人數",
      dataIndex: "people_count",
      width: "auto",
      align: "center",
      render: (people_count) => people_count || <span className="text-gray-400">未設定預定人數</span>,
    },
    {
      title: "聯絡電話",
      dataIndex: "phone",
      width: "auto",
      align: "center",
      render: (phone) => phone || <span className="text-gray-400">未設定聯絡電話</span>,
    },
    {
      title: "聯絡Email",
      dataIndex: "email",
      width: "auto",
      align: "center",
      render: (email) => email || <span className="text-gray-400">未設定聯絡Email</span>,
    },
    {
      title: "活動類型",
      dataIndex: "type",
      width: "auto",
      align: "center",
      render: (type) => type || <span className="text-gray-400">未設定活動類型</span>,
    },
  ],
  formFields: [
    { name: 'name', label: '票券名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  detail: (data, type) => {
    if (!data) return [];
    
    return [
      {
        key: "1",
        label: config.info,
        children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white">
            {/* 圖片區域 */}
            <div className="relative">
              <img 
                src={data.imageUrl} 
                alt={data.name} 
                className="w-full h-[174px] object-contain"
              />
              {type === 'service' && data.recommendation && (
                <div className="absolute top-4 left-4 bg-[#FF7171] text-white px-3 py-1 rounded-md">
                  {config.popular}
                </div>
              )}
            </div>

            <div className="p-6 flex flex-col gap-2">
              {/* 標題區域 */}
              <h1 className="text-2xl font-bold text-center">
                {data.name}
                {/* <span className="text-gray-400 ml-2 text-[18px]">
                  {data.gender ? (
                    data.gender === 'male' ? '先生' : data.gender === 'female' ? '女士' : '先生/女士'
                  ) : '先生/女士'}
                </span> */}
              </h1>

              {/* 功能標籤 - 只在服務類型時顯示 */}
              {type === 'service' && (
                <div className="flex gap-2">
                  <div className="bg-[#F3E5D4] text-[#DFB782] px-2 py-1 rounded-md">
                    {config.instantUse}
                  </div>
                  <div className="bg-[#F3E5D4] text-[#DFB782] px-2 py-1 rounded-md">
                    {config.freeCancellation}
                  </div>
                </div>
              )}

              {/* 描述資訊 */}
              <div>
                <h3 className="text-lg font-bold">{type === 'user' ? config.userInfo : config.serviceDesc}</h3>
                <p className="text-gray-700 whitespace-pre-line">{data.description}</p>
              </div>

              {/* 注意事項/其他資訊 */}
              <div>
                <h3 className="text-lg font-bold">{type === 'user' ? config.otherInfo : config.notice}</h3>
                <p className="text-gray-700 whitespace-pre-line">{data.notification}</p>
              </div>

              {/* 訂單資訊/規格選擇 */}
              {data.specs && (
                <div>
                  <h3 className="text-gray-400 mb-2">{type === 'user' ? config.orderInfo : config.specSelection}</h3>
                  <div className="flex gap-4">
                    {data.specs.map((spec, index) => (
                      <button key={index} className="bg-gray-100 px-6 py-3 rounded-lg">
                        {spec.name}: {spec.price}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* 編輯按鈕 */}
            <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
              <Button
                type="primary"
                className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
              >
                {config.edit}
              </Button>
            </div>
          </div>
        </div>
        ),
      }
    ];
  },
  onPublish: (formData, { saveServiceData }) => {
    saveServiceData(formData);
  },
  apis: 
    {
      product_type: "free_ticket",
    },
  tabs: [
    { key: 'ticket', label: '票券紀錄' },
    { key: 'ticket_send', label: '票券發送' },
  ],
  message: {
    success: '票券創建成功',
    error: '票券創建失敗',
  }
};

// 根據環境變數選擇配置
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const campaignConfig = is77 ? _77Config : null;
