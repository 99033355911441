// 環境變數配置
const DEV_MODE = process.env.NODE_ENV === 'development';

export const ENV = {
  // API 相關
  SERVER_URL: process.env.REACT_APP_SERVER_URL || 'https://api.scfg.io',
  MQTT: {
    URL: process.env.REACT_APP_MQTT_URL || 'wss://api-broker.scfg.io',
    PORT: process.env.REACT_APP_MQTT_PORT || '8083',
  },

  // 功能開關
  FEATURES: {
    E_OFFICIAL_SITE_MODULE: process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true',
  },

  // 開發環境配置
  DEV_MODE: DEV_MODE,

  // 開發環境設定
  DEV_CONFIG: {
    IS_DEV: DEV_MODE,
    CURRENT_MODE: localStorage.getItem('DEV_ECOMMERCE_MODE') || 'NONE',
  },

  // 電商模組開關
  E_COMMERCE: {
    STAG5: DEV_MODE 
      ? (localStorage.getItem('DEV_ECOMMERCE_MODE') === 'STAG5' || 
         (localStorage.getItem('DEV_ECOMMERCE_MODE') === 'NONE' && process.env.REACT_APP_STAG5_E_COMMERCE === 'true'))
      : process.env.REACT_APP_STAG5_E_COMMERCE === 'true',
    PANCO: DEV_MODE 
      ? (localStorage.getItem('DEV_ECOMMERCE_MODE') === 'PANCO' || 
         (localStorage.getItem('DEV_ECOMMERCE_MODE') === 'NONE' && process.env.REACT_APP_PANCO_E_COMMERCE === 'true'))
      : process.env.REACT_APP_PANCO_E_COMMERCE === 'true',
    SEVENTY_SEVEN: DEV_MODE 
      ? (localStorage.getItem('DEV_ECOMMERCE_MODE') === 'SEVENTY_SEVEN' || 
         (localStorage.getItem('DEV_ECOMMERCE_MODE') === 'NONE' && process.env.REACT_APP_77_E_COMMERCE === 'true'))
      : process.env.REACT_APP_77_E_COMMERCE === 'true',
  },

  // AI 相關功能
  AI: {
    HEALTECH_FORM: process.env.REACT_APP_HEALTECH_AI_FORM === 'true',
  },
};

// 凍結物件，防止意外修改
Object.freeze(ENV);
