/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { venueConfig } from "@/app/ams/config/venue/venueConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import Form from "@/app/ams/components/Form";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useFormScroll, formLogic, venueFormConfig } from "@/app/ams/config/venue/venueFormConfig";
import ToolBar from "@/app/ams/components/ToolBar";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
import Bread from "@/app/ams/components/Bread";
import { fetchHallsAPI } from '@/app/ams/services/api';

export default function Venue() {
  const { selectedVenue, setSelectedVenue, venues, loading, fetchVenue } = useAmsContext();
  const columns = venueConfig.columns;
  const [formData, setFormData] = useState({});
  const [currentView, setCurrentView] = useState('default');
  const [showForm, setShowForm] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchVenue();
  }, []);
    
  const handleFormChange = (changedValues) => {
    setFormData(changedValues);
  };

  useEffect(() => {
    if (venues?.length > 0 && !selectedVenue) {
      const firstVenue = venues[0];
      const venueWithImage = {
        ...firstVenue,
        imageUrl: firstVenue?.blobs?.[0]?.url || '/images/default-service.jpg',
      };
      setSelectedVenue(venueWithImage);
    }
  }, [venues, selectedVenue]);

  const handleRowClick = (record) => {
    if (selectedVenue?.key !== record.key) {
      const venueWithImage = {
        ...record,
        imageUrl: record?.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: record.specs || [],
        description: record.description || '',
        notification: record.notification || '',
        status: record.status || 'On Sale',
        provider: record.provider || '',
        category: record.category || {},
        tags: record.tags || [],
        recommendation: record.recommendation || null,
        publishDate: record.publishDate || null
      };
      setSelectedVenue(venueWithImage);
    }
  };

  const renderTabs = () => {
    return (
      <div className="flex gap-4 my-2">
        {venueConfig.tabs.map((tab) => (
          <Button
            key={tab.key}
            className={`${currentView === tab.key
              ? "bg-[#90C290] hover:bg-[#7AB27A]"
              : "bg-white hover:bg-gray-100 border border-gray-200"
              } px-3 py-2 rounded-full`}
            onClick={() => setCurrentView(tab.key)}
          >
            {tab.label}
          </Button>
        ))}
      </div>
    );
  };

  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">{venueFormConfig.ticket.title}</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          {venueFormConfig.ticket.title}
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">{venueFormConfig.ticket.add}</span>
      </div>
    </div>
  );

  const ShowHalls = () => {
    const [halls, setHalls] = useState([]);
    useEffect(() => {
      const fetchHalls = async () => {
        try {
          const response = await fetchHallsAPI();
          const halls = Array.isArray(response) ? response : [];
          const hallsWithKeys = halls.map((hall, index) => ({
            ...hall,
            key: hall.id || `hall-${index}`
          }));
          setHalls(hallsWithKeys);
        } catch (error) {
          console.error('獲取活動空間失敗:', error);
          setHalls([]);
        }
      };

      if (selectedVenue?.id && selectedVenue?.specs?.[0]?.id) {
        fetchHalls();
      }
    }, [selectedVenue]);

    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold">{selectedVenue?.name || selectedVenue?.title}</div>
            </div>
            <Bread title={venueFormConfig?.page?.title || ""} path="ticket" subTitle={venueFormConfig?.page?.ticketOrders || ""} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            <Statistics data={statisticsConfig?.statistics?.venue} type="ticket" />
            <Table
              columns={columns({
                setSelectedVenue,
                halls,
              })}
              dataSource={halls}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedVenue?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowKey={record => record.id}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedVenue?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedVenue}
              type="venue"
              items={venueConfig?.detail?.(selectedVenue, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowDefault = () => {
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
              <div className="flex justify-between items-center">
                <div className="text-lg font-bold">{venueFormConfig.ticket.title}</div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setShowForm(true)
                  }}
                  className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                >
                  {venueFormConfig.ticket.add}
                </Button>
              </div>
              <Bread title={venueFormConfig.ticket.title} path="ticket" page={venueFormConfig.ticket.add} />
              <Statistics data={statisticsConfig?.statistics?.venue} type="ticket" />
              <Table
               
                columns={columns({
                  currentView,
                  setCurrentView,
                  setSelectedVenue,
                  venues,
                })}
                dataSource={venues}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  className: "sticky bottom-0 pb-2",
                }}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                  className: record.key === (selectedVenue?.key ?? '') ? "bg-gray-100" : "",
                })}
                rowKey={record => record.id}
                rowClassName={(record) => `cursor-pointer ${record.key === (selectedVenue?.key ?? '') ? "bg-gray-100" : ""}`}
                scroll={{ x: 2000 }}
                size="small"
                className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
              />
            </div>
            <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
              <Detail
                data={selectedVenue}
                type="venue"
                items={venueConfig?.detail?.(selectedVenue, true)}
              />
          </div>
        </div>
      </>
    );
  };

  // render content
  const renderContent = () => {
    const views = {
      default: <ShowDefault />,
      halls: <ShowHalls />,
    };
    return views[currentView] || views.default;
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">
        <div className="flex-1 flex overflow-auto">
        {showForm ? 
        <>
        <div className="flex w-full space-x-4 overflow-auto">
          <div className="flex-1 overflow-hidden">
            <BreadcrumbNav />
            <Form onChange={handleFormChange} formConfig={venueFormConfig} formLogic={formLogic} useFormScroll={useFormScroll} title={venueFormConfig.ticket.title} draftText={venueFormConfig.ticket.draft} publishText={venueFormConfig.ticket.publish} />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail data={formData} items={venueConfig.detail(formData, false)} />
            </div>
          </div>
        </>
        :
        (renderContent())}
        </div>
      </div>
    </div>
  );
};
