/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { Button, Upload, message, Switch, Radio, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "@/contexts/AuthProvider";

const VoiceSetting = (props) => {
  const [audioFile, setAudioFile] = useState(null);
  const [hifi, setHifi] = useState(false);
  const [audioFileName, setAudioFileName] = useState("");
  const [gender, setGender] = useState(0);
  const [aiAudio, setAiAudio] = useState("");
  const [audioURL, setAudioURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);
  const { token } = useAuth();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleUpload = (info) => {
    // console.log('Upload info:', info);
    const file = info.file.originFileObj || info.file;
    if (!file) {
      // console.log('不是音檔', file)
      return;
    }
    console.log(file.type);
    const isAudio =
      file.type === "audio/mpeg" ||
      file.type === "audio/wav" ||
      file.type === "audio/aac" ||
      file.type === "audio/x-m4a";
    if (!isAudio) {
      message.error("僅支援音檔文件");
      return;
    }
    setAudioFile(file);
    setAudioFileName(file.name);
    setAudioURL(URL.createObjectURL(file));
    message.success(`已選擇 ${file.name}`);
  };

  const uploadProps = {
    beforeUpload: (file) => {
      return false; // 阻止自動上傳
    },
    onChange: handleUpload, // 設定 onChange 事件
  };

  //if had uploaded voice file
  useEffect(() => {
    checkExistingVoice();
    console.log('hifi', hifi);
    console.log('audioFileName', audioFileName);
    
    
  }, [props.room.id]);

  const checkExistingVoice = async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/property/chatroom/voices/${props.room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.body;
      setAiAudio(data.test_url);
      setHifi(data.hifi);
      setGender(data.gender);
    } catch (error) {
      console.error("Failed to check existing voice status:", error);
    }
  };

  const fetchVoiceStatus = async (retryCount = 0) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/property/chatroom/voices/${props.room.id}/status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data;

      if (data.status === "done" && data.test_url) {
        await validateAudioURL(data.test_url);
        setHifi(data.hifi);
        setGender(data.gender);
        setIsLoading(false);
      } else {
        if (retryCount < 18) {
          setTimeout(() => fetchVoiceStatus(retryCount + 1), 5000); // 每隔5秒檢查一次
        } else {
          setIsLoading(false);
          message.info("語音生成超時");
          message.info("請稍後返回查看或重新上傳");
        }
      }
    } catch (error) {
      console.error("Failed to fetch voice status:", error);
      setIsLoading(false);
    }
  };

  const validateAudioURL = async (url, retryCount = 0) => {
    try {
      const testResponse = await axios.head(url, { timeout: 5000 }); // 設置5秒超時
      if (testResponse.status === 200) {
        setAiAudio(url);
        message.success("語音生成成功");
      } else {
        throw new Error("Audio URL is not ready");
      }
    } catch (error) {
      if (retryCount < 6) {
        setTimeout(() => validateAudioURL(url, retryCount + 1), 5000); // 每隔5秒重試一次
      } else {
        console.error("Failed to validate audio URL:", error);
        setIsLoading(false);
        message.error("語音生成結果檢查失敗");
      }
    }
  };

  const handleRemoveVoice = async () => {
    try {
      await axios.delete(
        `${SERVER_URL}/private/property/chatroom/voices/${props.room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAiAudio("");
      setAudioFile(null);
      setAudioFileName("");
      setAudioURL(null);
      setGender(0);
      setHifi(false);
      message.success("語音已移除");
    } catch (error) {
      console.error("Failed to remove voice:", error);
      message.error("語音移除失敗");
    }
  };

  const handleGenerateVoice = async () => {
    if (!audioFile) {
      message.error("請先上傳音檔");
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", audioFile);
      // console.log('formData:', formData);
      await axios.post(
        `${SERVER_URL}/private/property/chatroom/voices/${props.room.id}/from_audio?name=sample_audio&gender=${gender}&hifi=false&service=elevenlabs`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            service: "elevenlabs",
          },
        }
      );
      message.success("語音上傳成功，處理中...");
      fetchVoiceStatus();
    } catch (error) {
      console.error("Failed to generate voice:", error);
      message.error("語音上傳失敗");
      setIsLoading(false);
    }
  };

  // 離開時清除音檔
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = "";
      }
    };
  }, []);

  return (
    <div className="flex flex-col justify-start items-start w-full px-4 py-6 gap-4">
      <div className="text-lg text-textDark font-bold">
        <h3>語音輸出</h3>
      </div>
      <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
        <div className="flex flex-col items-start w-full h-22 gap-2">
          {audioURL ? (
            <div className="flex-col w-full h-full flex justify-start items-start p-4">
              <p className="font-bold">上傳語音</p>
              <div className="flex flex-row w-full items-center">
                <audio
                  controls
                  src={audioURL}
                  className="w-full"
                  ref={audioRef}
                />
                <Button
                  danger
                  onClick={() => {
                    setAudioURL(null);
                    setAudioFile(null);
                  }}
                >
                  移除
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full h-full flex justify-between ">
              <div className="flex flex-col justify-start p-4 gap-2">
                <p className="font-bold">上傳語音</p>
                <p>請上傳 1 分鐘以上的語音內容</p>
              </div>
              <div className="h-full flex flex-col justify-center p-3">
                <Tooltip title="僅支援 MP3, WAV & AAC 格式">
                  <Upload showUploadList={false} {...uploadProps}>
                    <Button icon={<UploadOutlined />} type="primary">
                      新增
                    </Button>
                  </Upload>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        <div className="hidden flex flex-row items-center justify-between w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="flex  flex-col gap-2">
            <p className="font-bold">Hi-Fi 高級處理</p>
            <p>開啟 Hi-Fi 處理以還原最真實的聲音</p>
          </div>
          <Switch />
        </div>
        <div className="flex flex-col items-start w-full h-22 gap-2 border-t border-t-borderLight">
          <div className="flex flex-col justify-start p-4 gap-2">
            <p className="font-bold">語音性別選擇</p>
            <Radio.Group
              name="radio_group"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <Radio value={0}>中性</Radio>
              <Radio value={1}>男性</Radio>
              <Radio value={2}>女性</Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between mt-3">
        <Button
          type="primary"
          disabled={!audioFile}
          loading={isLoading}
          onClick={handleGenerateVoice}
        >
          {aiAudio ? "重新生成語音" : isLoading ? "語音處理中" : "生成語音"}
        </Button>
        <Button danger disabled={!aiAudio} onClick={handleRemoveVoice}>
          移除語音
        </Button>
      </div>
      {aiAudio && (
        <div className="w-full justify-between items-start p-4 mt-3 rounded-[4px] border-[1px] bg-bgMedium border-borderLight flex-col flex">
          <p className="font-bold">AI 生成語音試聽</p>
          <audio controls src={aiAudio} className="w-full" />
        </div>
      )}
    </div>
  );
};

export default VoiceSetting;
