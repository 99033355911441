import React, { useState, useRef } from 'react'
import { Tabs, Tooltip, DatePicker } from 'antd'
import VoiceListItem from './VoiceListItem.js'

import { LoadingOutlined, ReloadOutlined, SwapOutlined, PlusOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { uploadSamples } from './hooks/voice.api.js'

const { RangePicker } = DatePicker;

const VoiceList = ({ items, activeId, setActiveId, loading, tabs, onChangeTab, switchTo, lineBound, roomId, token, refresh, timeInterval, setTimeInterval }) => {
  const fileInputRef = useRef(null);
  const [openDatPicker, setOpenDatePicker] = useState(false);

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const onFileUploadChange = async (e) => {
    await uploadSamples(roomId, token, Array.from(e.target.files))
    refresh()
  }

  return (
    <div className="flex flex-col min-w-72 max-w-72 bg-bgLight px-2 pt-6">
      <div className="flex flex-row items-center justify-between text-2xl font-bold mb-2 text-textDark ml-1">
        <div>
          語音模組
          {
            lineBound &&
            <Tooltip title="切換至第三方紀錄">
              <SwapOutlined
                className="hover:text-primary ml-2"
                style={{ width: "12px", height: "12px" }}
                onClick={() => switchTo('boundRoom')}
              />
            </Tooltip>}
        </div>
        <div className="flex flex-row items-center gap-1 hover:text-primary cursor-pointer border rounded p-1 hover:border-primary" onClick={handleClickUpload}>
          <input type="file" multiple onChange={onFileUploadChange} id="file-upload" style={{ display: 'none' }} ref={fileInputRef} />
          <PlusOutlined
            style={{ width: "12px", height: "12px" }}
          />
          <span className="text-xs font-medium">上傳語音</span>
        </div>
        <ReloadOutlined
          className="hover:text-primary mr-2"
          style={{ width: "12px", height: "12px" }}
          onClick={refresh}
        />
      </div>
      <Tabs defaultActiveKey={0} items={tabs} onChange={onChangeTab} className="px-2" />
      <div className="flex flex-col pb-2 w-full items-center gap-1 border-b">
        <p className="text-sm text-textDark">
          {timeInterval[0]} ~ {timeInterval[1]}
          <span className="ml-2 hover:underline cursor-pointer" onClick={() => setOpenDatePicker(!openDatPicker)}>
            {openDatPicker ? "取消" : "變更"}
          </span>
        </p>
        {
          openDatPicker &&
          <RangePicker
            format="YYYY-MM-DD"
            onChange={(value, dateString) => {
              setTimeInterval(dateString)
            }}
          />
        }
      </div>
      {
        loading ?
          <div className="flex justify-center items-center w-full h-screen">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
          :
          (items.length === 0 ?
            <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
              這裡沒有任何語音紀錄。
            </div>
            :
            <div className="flex flex-col overflow-y-scroll pb-12 overflow-x-hidden">
              {items
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((item) => (
                  <VoiceListItem item={item} activeId={activeId} setActiveId={setActiveId} />
                ))
              }
            </div>
          )
      }
    </div>
  )
}

export default VoiceList