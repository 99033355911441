import React, { useState } from 'react';
import { Tag, Button, Modal, Input, Form, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { serviceConfig } from '@/app/ams/config/service/serviceConfig';
import tagConfig from '@/app/ams/config/tag/tagConfig';

const TagSelector = ({ value = [], onChange }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newTagName, setNewTagName] = useState('');

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (newTagName.trim()) {
            const newTag = {
                id: Date.now(),
                name: newTagName.trim()
            };
            onChange([...value, newTag]);
            setNewTagName('');
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        setNewTagName('');
        setIsModalVisible(false);
    };

    const handleRemoveTag = (removedTag) => {
        const newTags = value.filter(tag => tag.id !== removedTag.id);
        onChange(newTags);
    };

    return (
        <Form.Item className="flex items-center">
            <Space size={[4, 8]} wrap className='border border-solid p-2 items-center justify-center'>
                {value.map(tag => (
                    <Tag
                        key={tag.id}
                        closable
                        onClose={() => handleRemoveTag(tag)}
                        style={{ marginRight: 8 }}
                        className="bg-[#ADDEAC] w-[108px] h-[29px] px-[13px] py-[5px] gap-[10px] rounded-[20px] border-t border-l border-[#67BE5F] border-solid flex items-center justify-between text-sm"
                    >
                        <span className="flex-grow text-center">{tag.name}</span>
                    </Tag>
                ))}
                <Button
                    type="dashed"
                    onClick={showModal}
                    icon={<PlusOutlined />}
                    size="small"
                    className="text-[#67BE5F] w-[108px] h-[29px] px-[13px] py-[5px] gap-[10px] rounded-[20px] border-t border-l border-[#67BE5F] border-solid"
                >
                    {tagConfig.tagSelector.addTag}
                </Button>
            </Space>

            <Modal
                title={tagConfig.tagSelector.addTag}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={tagConfig.tagSelector.okText}
                cancelText={tagConfig.tagSelector.cancelText}
            >
                <Input
                    placeholder={tagConfig.tagSelector.inputPlaceholder}
                    value={newTagName}
                    onChange={e => setNewTagName(e.target.value)}
                    maxLength={20}
                    showCount
                />
            </Modal>
        </Form.Item>
    );
};

export default TagSelector;