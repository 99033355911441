import React from 'react';
import { ENV } from '../constants/env';

export const DevModeSelector = () => {
  if (!ENV.DEV_CONFIG.IS_DEV) return null;

  const handleModeChange = (mode) => {
    localStorage.setItem('DEV_ECOMMERCE_MODE', mode);
    window.location.reload();
  };

  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      padding: '10px',
      background: '#333',
      color: 'black',
      borderRadius: '5px',
      zIndex: 9999
    }}>
      <h4>開發模式選擇</h4>
      <select 
        value={ENV.DEV_CONFIG.CURRENT_MODE}
        onChange={(e) => handleModeChange(e.target.value)}
      >
        <option value="NONE">預設模式</option>
        <option value="STAG5">STAG5</option>
        <option value="PANCO">PANCO</option>
        <option value="SEVENTY_SEVEN">77</option>
      </select>
    </div>
  );
};