import { ENV } from '@/constants/env';
import moment from 'moment';
import {Button, Modal, Radio, message as antMessage, Input, Checkbox } from 'antd';
import React, { useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const genderMap = {
  male: "先生",
  female: "女士"
};

const AuditStatusModal = ({ visible, onClose, onConfirm }) => {
  const [value, setValue] = useState('approved');
  const [message, setMessage] = useState('您好，需求單已接受，請等候聯繫！');
  const [rejectReasons, setRejectReasons] = useState({
      photoFormat: false,
      audioFormat: false,
      other: false
  });
  const [otherReason, setOtherReason] = useState('');

  const handleConfirm = () => {
      if (value === 'rejected') {
          // 組合拒絕原因
          let reasons = [];
          if (rejectReasons.photoFormat) {
              reasons.push('照片規格不符合(解析度需達1080P以上、需為單獨照片、需為正面照片)');
          }
          if (rejectReasons.audioFormat) {
              reasons.push('聲音檔案規格不符合(如：太多雜訊、有1人以上的聲音)');
          }
          if (rejectReasons.other && otherReason.trim()) {
              reasons.push(otherReason.trim());
          }
          
          // 更新拒絕訊息
          const rejectMessage = '您好，很抱歉需求單已被拒絕。\n原因：\n' + reasons.join('\n');
          onConfirm(value, rejectMessage);
      } else {
          onConfirm(value, message);
      }
  };

  return (
      <Modal
          title={<div className="text-center w-full">審核確認</div>}
          open={visible}
          onCancel={onClose}
          footer={[
              <Button 
                  key="submit" 
                  className="bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC] text-white"
                  onClick={handleConfirm}
              >
                  確認
              </Button>
          ]}
          className="audit-modal"
          width={600}
      >
          <div className="space-y-6">
              <div className="space-y-2">
                  <Radio.Group 
                      value={value} 
                      onChange={(e) => {
                          setValue(e.target.value);
                          if (e.target.value === 'approved') {
                              setMessage('您好，需求單已接受，請等候聯繫！');
                          }
                      }}
                      className="w-full flex justify-center gap-8"
                  >
                      <Radio value="approved" className="text-lg">接受需求</Radio>
                      <Radio value="rejected" className="text-lg">拒絕需求</Radio>
                  </Radio.Group>
              </div>
              
              {value === 'rejected' && (
                  <div className="space-y-4">
                      <div className="font-bold">信件通知</div>
                      <div className="space-y-2">
                          <Checkbox
                              checked={rejectReasons.photoFormat}
                              onChange={e => setRejectReasons(prev => ({
                                  ...prev,
                                  photoFormat: e.target.checked
                              }))}
                          >
                              照片規格不符合(解析度需達1080P以上、需為單獨照片、需為正面照片)
                          </Checkbox>
                          <Checkbox
                              checked={rejectReasons.audioFormat}
                              onChange={e => setRejectReasons(prev => ({
                                  ...prev,
                                  audioFormat: e.target.checked
                              }))}
                          >
                              聲音檔案規格不符合(如：太多雜訊、有1人以上的聲音)
                          </Checkbox>
                          <div className="space-y-2">
                              <Checkbox
                                  checked={rejectReasons.other}
                                  onChange={e => setRejectReasons(prev => ({
                                      ...prev,
                                      other: e.target.checked
                                  }))}
                              >
                                  其他
                              </Checkbox>
                              {rejectReasons.other && (
                                  <Input.TextArea
                                      value={otherReason}
                                      onChange={e => setOtherReason(e.target.value)}
                                      placeholder="請輸入其他拒絕緣由"
                                      className="mt-2"
                                  />
                              )}
                          </div>
                      </div>
                  </div>
              )}
              
              {value === 'approved' && (
                  <div>
                      <div className="font-bold mb-2">信件通知</div>
                      <div className="bg-white p-4 rounded-lg border">
                          {message}
                      </div>
                  </div>
              )}
          </div>
      </Modal>
  );
};

const StatusCell = ({ status, record, action }) => {
  const [localStatus, setLocalStatus] = useState(status);

  return (
      <div className="flex items-center gap-2">
          <div>
              {localStatus === 'approved' ? '已接單' : 
               localStatus === 'rejected' ? '已拒絕' : 
               localStatus === 'pending' ? '未執行' : '未設定'}
          </div>
          {(localStatus === 'pending' || localStatus === 'approved' || localStatus === 'rejected') && (
              <ViewButton 
                  record={record} 
                  onStatusChange={setLocalStatus}
                  onStatusUpdate={async (id, newStatus) => {
                      try {
                          // 這裡可以加入 API 呼叫
                          // await api.updateAuditStatus(id, newStatus);
                          
                          // 更新表格數據
                          if (action?.reload) {
                              action.reload();
                          }
                      } catch (error) {
                          throw error;
                      }
                  }}
              />
          )}
      </div>
  );
};

const ViewButton = ({ record, onStatusUpdate, onStatusChange }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(record.auditStatus);

  const handleClick = (e) => {
      e.stopPropagation();
      if (currentStatus === 'pending') {
          setModalVisible(true);
      } else {
          console.log('查看詳情');
      }
  };

  const handleConfirm = async (value, messageText) => {
      const newStatus = value === 'approved' ? 'approved' : 'rejected';
      
      // 立更新本地狀態
      setCurrentStatus(newStatus);
      record.auditStatus = newStatus;
      // 通知父組件狀態已更新
      onStatusChange?.(newStatus);
      setModalVisible(false);

      // 非同步更新後端
      if (onStatusUpdate) {
          try {
              await onStatusUpdate(record.id, newStatus);
              antMessage.success(
                  newStatus === 'approved' 
                      ? '已成功接受需求' 
                      : '已拒絕需求'
              );
          } catch (error) {
              // 如果更新失敗，回復原狀態
              setCurrentStatus('pending');
              record.auditStatus = 'pending';
              onStatusChange?.('pending');
              antMessage.error('更新狀態失敗，請稍後再試');
          }
      }
  };

  return (
      <>
          <Button
              type="primary"
              size="small"
              className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
              onClick={handleClick}
          >
              {currentStatus === 'pending' ? '審核' : '查看'}
          </Button>
          <AuditStatusModal 
              visible={modalVisible}
              onClose={() => setModalVisible(false)}
              onConfirm={handleConfirm}
          />
      </>
  );
};

const LinkModal = ({ visible, onClose, onConfirm, initialValue = '' }) => {
  const [link, setLink] = useState(initialValue);

  return (
      <Modal
          title={<div className="text-center w-full">完成紀錄</div>}
          open={visible}
          onCancel={onClose}
          footer={[
              <Button 
                  key="submit" 
                  className="bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC] text-white"
                  onClick={() => onConfirm(link)}
              >
                  保存
              </Button>
          ]}
          className="link-modal"
          width={600}
      >
          <div className="space-y-4">
              <div>
                  <div className="font-bold mb-2">影片雲端連結</div>
                  <Input 
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      placeholder="請輸入連結"
                  />
              </div>
          </div>
      </Modal>
  );
};

const CompletionCell = ({ status, record, action }) => {
  const [localStatus, setLocalStatus] = useState(status || 'incomplete');
  const [modalVisible, setModalVisible] = useState(false);
  const [videoLink, setVideoLink] = useState(record.videoLink || '');

  const handleStatusChange = async (e) => {
      e.stopPropagation();
      
      Modal.confirm({
          title: <div className="text-center w-full">確認完成</div>,
          content: '確定要將此需求標記為已完成嗎？',
          okText: '確認',
          cancelText: '取消',
          className: "confirm-modal",
          icon: null,
          okButtonProps: {
              className: "bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC] text-white hover:text-white"
          },
          cancelButtonProps: {
              className: "border-gray-200 hover:border-gray-200 hover:text-gray-500"
          },
          onOk: async () => {
              try {
                  // 設置為已完成
                  const newStatus = 'complete';
                  setLocalStatus(newStatus);
                  record.completionStatus = newStatus;

                  // 這裡可以加入 API 呼叫
                  // await api.updateCompletionStatus(record.id, newStatus);
                  
                  // 更新表格數據
                  if (action?.reload) {
                      action.reload();
                  }

                  antMessage.success('已標記為完成');
              } catch (error) {
                  setLocalStatus('incomplete');
                  record.completionStatus = 'incomplete';
                  antMessage.error('更新狀態失敗，請稍後再試');
              }
          }
      });
  };

  const handleLinkSubmit = async (link) => {
      try {
          // 這裡可以加入 API 呼叫來保存連結
          // await api.saveVideoLink(record.id, link);
          
          setVideoLink(link);
          setModalVisible(false);
          antMessage.success('連結已保存');
          
          // 更新表格數據
          if (action?.reload) {
              action.reload();
          }
      } catch (error) {
          antMessage.error('保存連結失敗，請稍後再試');
      }
  };

  return (
      <div className="flex items-center gap-2">
          <div>
              {localStatus === 'complete' ? '已完成' : '未完成'}
          </div>
          {localStatus === 'incomplete' ? (
              <Button
                  type="primary"
                  size="small"
                  className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
                  onClick={handleStatusChange}
              >
                  接受
              </Button>
          ) : (
              <Button
                  type="primary"
                  size="small"
                  className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
                  onClick={(e) => {
                      e.stopPropagation();
                      setModalVisible(true);
                  }}
              >
                  連結
              </Button>
          )}
          <LinkModal 
              visible={modalVisible}
              onClose={() => setModalVisible(false)}
              onConfirm={handleLinkSubmit}
              initialValue={videoLink}
          />
      </div>
  );
};

const PreviewButton = ({ text, title }) => {
  const handleClick = (e) => {
      e.stopPropagation();
      Modal.info({
          title: <div className="text-center w-full">{title}</div>,
          content: (
              <div className="whitespace-pre-wrap">
                  {text || '暫無內容'}
              </div>
          ),
          width: 600,
          okText: '關閉',
          className: "preview-modal",
          icon: null,
          okButtonProps: {
              className: "bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC]"
          }
      });
  };

  return (
      <Button
          type="primary"
          size="small"
          className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
          onClick={handleClick}
      >
          查看
      </Button>
  );
};

const ImagePreviewContent = ({ blobs, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = blobs?.filter(blob => blob.content_type.includes('image')) || [];

  return (
      <div className="relative">
          <div className="h-[400px] flex items-center justify-center">
              <img
                  src={images[currentIndex]?.url}
                  alt={`圖片檔案 ${currentIndex + 1}`}
                  className="max-w-full max-h-full object-contain"
              />
          </div>
          {images.length > 1 && (
              <>
                  <Button 
                      icon={<LeftOutlined />} 
                      className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/50 border-none hover:bg-white/80"
                      onClick={(e) => {
                          e.stopPropagation();
                          setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
                      }}
                  />
                  <Button 
                      icon={<RightOutlined />} 
                      className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/50 border-none hover:bg-white/80"
                      onClick={(e) => {
                          e.stopPropagation();
                          setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
                      }}
                  />
                  <div className="text-center mt-4">
                      {currentIndex + 1} / {images.length}
                  </div>
              </>
          )}
      </div>
  );
};

const ImagePreviewButton = ({ blobs }) => {
  const handleClick = (e) => {
      e.stopPropagation();
      Modal.info({
          title: <div className="text-center w-full">照片預覽</div>,
          content: <ImagePreviewContent blobs={blobs} />,
          width: 800,
          okText: '關閉',
          className: "preview-modal image-preview-modal",
          icon: null,
          okButtonProps: {
              className: "bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC]"
          }
      });
  };

  return (
      <Button
          type="primary"
          size="small"
          className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
          onClick={handleClick}
      >
          查看
      </Button>
  );
};

const AudioPreviewButton = ({ blobs }) => {
  const handleClick = (e) => {
      e.stopPropagation();
      Modal.info({
          title: <div className="text-center w-full">聲音預覽</div>,
          content: (
              <div className="space-y-4">
                  {blobs?.filter(blob => blob.content_type.includes('audio'))?.map((audio, index) => (
                      <audio key={audio.id} controls className="w-full">
                          <source src={audio.url} type={audio.content_type} />
                          您的瀏覽器不支援音訊播放
                      </audio>
                  ))}
              </div>
          ),
          width: 600,
          okText: '關閉',
          className: "preview-modal",
          icon: null,
          okButtonProps: {
              className: "bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC]"
          }
      });
  };

  return (
      <Button
          type="primary"
          size="small"
          className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
          onClick={handleClick}
      >
          查看
      </Button>
  );
};


const officialConfig = {
  page: [
      {
          title: "會員管理",
      }
  ],
  columns: [
    {
      title: "審核狀態",
      dataIndex: "auditStatus",
      width: "12%",
      fixed: "left",
      render: (status, record, index, action) => (
          <StatusCell 
              status={status} 
              record={record} 
              action={action}
          />
      )
  },
  {
      title: "完成狀態",
      dataIndex: "completionStatus",
      width: "12%",
      fixed: "left",
      render: (status, record, index, action) => (
          <CompletionCell 
              status={status} 
              record={record} 
              action={action}
          />
      )
  },
  {
      title: "需求單序號",
      dataIndex: "id",
      width: "12%",
      align: "center",
      render: (id) => id.substring(0, 8),
  },
  {
      title: "親友",
      dataIndex: ["information", "basic", "name"],
      width: "12%",
      render: (name) => name || <span className="text-gray-400">未設定</span>
  },
  {
      title: "親友性別",
      dataIndex: ["information", "basic", "gender"],
      width: "8%",
      align: "center",
      render: (gender) => genderMap[gender] || <span className="text-gray-400">未設定</span>
  },
  {
      title: "親友生日",
      dataIndex: ["information", "basic", "birthday"],
      width: "10%",
      render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">未設定</span>
  },
  {
      title: "家屬姓名",
      dataIndex: ["information", "basic", "contact_name"],
      width: "12%",
      render: (name) => name || <span className="text-gray-400">未設定</span>
  },
  {
      title: "家屬聯絡電話",
      dataIndex: ["information", "basic", "phone"],
      width: "10%",
      render: (phone) => phone || <span className="text-gray-400">未設</span>
  },
  {
      title: "禮儀業者",
      dataIndex: ["information", "basic", "funeral"],
      width: "8%",
      render: (funeral) => funeral || <span className="text-gray-400">未設定</span>
  },
  {
      title: "需求日期",
      dataIndex: ["information", "basic", "date"],
      width: "8%",
      align: "center",
      render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">未設定</span>
  },
  {
      title: "公祭台詞",
      dataIndex: ["information", "ceremony", "public_sacrifice"],
      width: "8%",
      align: "center",
      render: (text) => (
          <div className="flex items-center gap-2 justify-center">
              {text ? (
                  <>
                      <PreviewButton text={text} title="公祭台詞預覽" />
                  </>
              ) : (
                  <span className="text-red-500">未設定</span>
              )}
          </div>
      )
  },
  {
      title: "家祭台詞",
      dataIndex: ["information", "ceremony", "family_sacrifice"],
      width: "8%",
      align: "center",
      render: (text) => (
          <div className="flex items-center gap-2 justify-center">
              {text ? (
                  <>
                      <PreviewButton text={text} title="家祭台詞預覽" />
                  </>
              ) : (
                  <span className="text-red-500">未設定</span>
              )}
          </div>
      )
  },
  {
      title: "遠行台詞",
      dataIndex: ["information", "ceremony", "travel"],
      width: "8%",
      align: "center",
      render: (text) => (
          <div className="flex items-center gap-2 justify-center">
              {text ? (
                  <>
                      <PreviewButton text={text} title="遠行台詞預覽" />
                  </>
              ) : (
                  <span className="text-red-500">未設定</span>
              )}
          </div>
      )
  },
  {
      title: "選型影片需求",
      dataIndex: ["information", "ceremony", "action"],
      width: "10%",
      align: "center",
      render: (text) => {
          switch (text) {
              case 'sit': return '坐下';
              case 'stand': return '站著';
              case 'turn': return '轉身';
              case 'bow': return '鞠躬';
              case 'worship': return '膜拜';
              case 'other': return '其他';
              default: return <span className="text-gray-400">未設定</span>;
          }
      }
  },
  {
      title: "親友照片",
      dataIndex: "blobs",
      width: "8%",
      align: "center",
      render: (blobs, record) => {
          const hasImages = blobs?.some(blob => blob.content_type.includes('image'));
          return (
              <div className="flex items-center gap-2 justify-center">
                  {hasImages ? (
                      <>
                          <ImagePreviewButton blobs={blobs} />
                      </>
                  ) : (
                      <span className="text-gray-400">未設定</span>
                  )}
              </div>
          );
      }
  },
  {
      title: "親友聲音",
      dataIndex: "blobs",
      width: "8%",
      align: "center",
      render: (blobs, record) => {
          const hasAudio = blobs?.some(blob => blob.content_type.includes('audio'));
          return (
              <div className="flex items-center gap-2 justify-center">
                  {hasAudio ? (
                      <>
                          <AudioPreviewButton blobs={blobs} />
                      </>
                  ) : (
                      <span className="text-gray-400">未設定</span>
                  )}
              </div>
          );
      }
  }
  ],
  formFields: [
      { name: 'name', label: '服務名稱', type: 'text', required: true },
      { name: 'price', label: '價格', type: 'number', required: true },
      { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
};

// 根據環境變數選擇配置
const isOfficial = ENV.FEATURES.E_OFFICIAL_SITE_MODULE;

export const demandConfig = isOfficial ? officialConfig : null;
