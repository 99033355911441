/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { useAuth } from '@/contexts/AuthProvider'

import ToolBar from './ToolBar'
import Room from './room/Room'
import MemberList from './MemberList'
import Memory from './memory/Memory'
import BoundInterface from './boundRoom/BoundInterface'
import VoiceInterface from './VoiceRoom/VoiceInterface'
import { useBound } from '@/contexts/BoundContext'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ChatInterface = ({ room, refresh }) => {
  const { setRoomId } = useBound()
  const { token } = useAuth()
  const [tab, setTab] = useState("chatroom")
  const [binding, setBinding] = useState(null)


  useEffect(() => {
    setBinding(null)
    setTab("chatroom")
    checkBinding(room.id)
    setRoomId(room.id)
  }, [room])

  const checkBinding = async (id) => {
    try {
      const response = await axios.get(`${SERVER_URL}/private/property/chatroom/line_channel/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
        setBinding(response.data.body)
    } catch (error) {
      // console.log(error)
    }
  }

  return (
    <div className="flex flex-col w-full h-screen">
      <ToolBar room={room} setTab={setTab} tab={tab} refresh={refresh} binding={binding ? true : false} />
      <div className="flex flex-row" style={{ height: 'calc(100dvh - 56px)' }}>
        {
          tab === 'chatroom' &&
          <div className="w-full flex flex-row">
            <div className="border-r border-r-borderLight" style={{ width: 'calc(50vw - 310px)' }}>
              <Room isAI={false} room={room} />
            </div>
            <div className="" style={{ width: 'calc(50vw - 310px)' }}>
              <Room isAI={true} room={room} />
            </div>
          </div>
        }
        {
          tab === 'memory' &&
          <Memory room={room} />
        }
        {
          (tab === 'memory' || tab === 'chatroom') &&
          <MemberList id={room.id} />
        }
        {
          tab === 'boundRoom' &&
          <BoundInterface room={room} switchTo={setTab} />
        }
        {
          tab === 'asr' &&
          <VoiceInterface room={room} switchTo={setTab} lineBound={binding ? true : false} />
        }
      </div>
    </div>
  )
}

export default ChatInterface