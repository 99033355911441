import React, { createContext, useContext, useState } from "react";
import { request } from "@/utils";

const BoundContext = createContext();

export const BoundProvider = ({ children }) => {
  const [roomId, setRoomId] = useState("");
  const [messageId, setMessageId] = useState("");
  const [lineMessage, setLineMessage] = useState([]);
  const [airbnbMessage, setAirbnbMessage] = useState([]);
  const [agodaMessage, setAgodaMessage] = useState([]);

  const [orderLineMessage, setOrderLineMessage] = useState([]);
  const [orderAirbnbMessage, setOrderAirbnbMessage] = useState([]);
  const [orderAgodaMessage, setOrderAgodaMessage] = useState([]);

  const [listLoading, setListLoading] = useState(true);

  const fetchAgodaHistory = async () => {
    const getHistory = (limit = 60) => request.get(
      `/private/module/rpa/agoda/history/${roomId}`, {
      params: {
        limit: limit
      }
    });

    const getMessageIds = () => request.get(
      `/private/module/rpa/agoda/message_ids/${roomId}`
    );
    try {
      // setListLoading(true);
      const messageIdsResponse = await getMessageIds();
      console.log('messageIdsResponse', messageIdsResponse.status);
      if (messageIdsResponse.status === 200) {
        console.time('排序總執行時間');

        const orderedMessageIds = messageIdsResponse.result;
        const messageIdIndexMap = new Map(
          orderedMessageIds.map((id, index) => [id, index])
        );

        const historyResponse = await getHistory();
        const startFlatMap = performance.now();


        const allMessages = historyResponse.flatMap(item => {
          const getMessages = item.get_messages;
          const messageId = item.message_id;
          const lastSubmitTimestamp = item.last_submit_timestamp;

          if (getMessages && typeof getMessages === 'object') {
            return [{
              ...getMessages,
              message_id: messageId,
              last_submit_timestamp: lastSubmitTimestamp
            }];
          }
          return [];
        });
        const endFlatMap = performance.now();
        console.log(`數據轉換時間: ${(endFlatMap - startFlatMap).toFixed(2)}ms`);

        const startSort = performance.now();
        const sortedMessages = [...allMessages].sort((a, b) => {
          const aIndex = messageIdIndexMap.get(a.message_id);
          const bIndex = messageIdIndexMap.get(b.message_id);

          if (aIndex !== undefined && bIndex !== undefined) {
            return aIndex - bIndex;
          }
          if (aIndex !== undefined) return -1;
          if (bIndex !== undefined) return 1;
          return new Date(a.last_submit_timestamp) - new Date(b.last_submit_timestamp);
        });
        const endSort = performance.now();
        console.log(`排序操作的時間: ${(endSort - startSort).toFixed(2)}ms`);

        console.timeEnd('排序總執行時間');



        // 如果 allMessages 長度小於 message ids 長度，則退出
        if (allMessages.length < orderedMessageIds.length) {
          console.warn('消息數量少於預期，可能數據不完整');
          return;
        }


        const startStateUpdate = performance.now();


        try {
          setAgodaMessage(prevMessages => {
            const prevAgodaMessages = Array.isArray(prevMessages) ? prevMessages : [];
            const newMessages = sortedMessages.filter(
              newMsg => !prevAgodaMessages.some(existingMsg => existingMsg.message_id === newMsg.message_id)
            );
            if (newMessages.length === 0) return prevAgodaMessages;
            const updatedMessages = [...prevAgodaMessages, ...newMessages];
            return updatedMessages;
          });
        } catch (error) {
          console.log('8787', error);
        }
        const endStateUpdate = performance.now();
        console.log(`狀態更新耗時: ${(endStateUpdate - startStateUpdate).toFixed(2)}ms`);


        const allOrders = historyResponse.flatMap(item => {
          const getOrders = item.get_order_info;
          const messageId = item.message_id;
          const lastSubmitTimestamp = item.last_submit_timestamp;
          if (getOrders && typeof getOrders === 'object') {
            return [{
              ...getOrders,
              message_id: messageId,
              last_submit_timestamp: lastSubmitTimestamp
            }];
          }
          return [];
        });
        // set order to state
        try {
          setOrderAgodaMessage(prevOrders => {
            const prevOrderMessages = Array.isArray(prevOrders) ? prevOrders : [];
            const updatedOrders = [...prevOrderMessages, ...allOrders];
            return updatedOrders;
          });
        } catch (error) {
          console.log('設定訂單時發生錯誤:', error);
        }
      } else {
        console.warn('獲取messageIds失敗');

      }
    } catch (error) {

    } finally {
      setListLoading(false);
    }
  };

  return (
    <BoundContext.Provider
      value={{
        roomId,
        setRoomId,
        messageId,
        setMessageId,
        lineMessage,
        setLineMessage,
        airbnbMessage,
        setAirbnbMessage,
        agodaMessage,
        setAgodaMessage,
        orderLineMessage,
        setOrderLineMessage,
        orderAirbnbMessage,
        setOrderAirbnbMessage,
        orderAgodaMessage,
        setOrderAgodaMessage,
        listLoading,
        setListLoading,
        fetchAgodaHistory
      }}
    >
      {children}
    </BoundContext.Provider>
  );
};

// hook
export const useBound = () => {
  return useContext(BoundContext);
};
