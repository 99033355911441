import { ENV } from '@/constants/env';

const pancoConfig = {
  labels: {
    selectUser: '請選擇一位用戶',
    selectService: '請選擇一筆資訊',
    info: '會員資訊',
    popular: '熱門',
    instantUse: '即買即用',
    freeCancellation: '15天前免費取消',
    userInfo: '用戶資訊',
    serviceDesc: '服務說明',
    otherInfo: '其他資訊',
    notice: '注意事項',
    orderInfo: '訂單資訊',
    specSelection: '規格選擇',
    edit: '編輯',
    check: '查看會員',
    fixedAmount: '固定金額',
    filter: '篩選會員',
    firm: 'panco'
  },
}

const stag5Config = {
  labels: {
    selectUser: 'Please select a user',
    selectService: 'Please select a product',
    info: 'Information',
    popular: 'Popular',
    instantUse: 'Instant Use',
    freeCancellation: '15-day Free Cancellation',
    userInfo: 'Basic Information',
    serviceDesc: 'product Description',
    otherInfo: 'Other Information',
    notice: 'Notice',
    orderInfo: 'Order Information',
    specSelection: 'Specification',
    edit: 'Edit',
    check: 'Check Member',
    fixedAmount: 'Fixed Amount',
    filter: 'Filter',
    firm: 'stag5'
  },
}

const _77Config = {
  labels: {
    selectUser: '請選擇一位用戶',
    selectService: '請選擇一筆資訊',
    info: '會員資訊',
    popular: '熱門',
    instantUse: '即買即用',
    freeCancellation: '15天前免費取消',
    userInfo: '用戶資訊',
    serviceDesc: '商品說明',
    otherInfo: '其他資訊',
    notice: '注意事項',
    orderInfo: '訂單資訊',
    specSelection: '規格選擇',
    edit: '編輯',
    check: '查看會員',
    fixedAmount: '固定金額',
    filter: '篩選會員',
    firm: '77'
  },
}

const officialConfig = {
  labels: {
    selectUser: '請選擇一位用戶',
    selectService: '請選擇一筆資訊',
  }
}

// 根據環境變數選擇配置
const isPanco = ENV.E_COMMERCE.PANCO;
const isStag5 = ENV.E_COMMERCE.STAG5;
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;
const isOfficial = ENV.FEATURES.E_OFFICIAL_SITE_MODULE;

export const detailConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : is77 ? _77Config : isOfficial ? officialConfig : null;

export default detailConfig;
