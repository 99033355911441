import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  fetchServicesAPI, 
  fetchIdentityListAPI, 
  fetchCategoriesAPI, 
  fetchUsersAPI, 
  fetchOrganizationsAPI, 
  fetchUserServiceOrdersAPI,
  fetchUserProductOrdersAPI,
  fetchUserTicketOrdersAPI,
  fetchUserCompaignsAPI,
  fetchVenueAPI,
  fetchCampaignAPI,
} from '../services/api';

import { setDebugMode as setGlobalDebugMode, getDebugMode } from '../utils/debugConfig';

export const AmsContext = createContext({
  // Users
  users: [],
  selectedUser: null,
  fetchUsers: () => {},
  setUsers: () => {},
  setSelectedUser: () => {},
  userServiceOrders: [],
  userProductOrders: [],
  userTicketOrders: [],
  userCompaigns: [],

  // Organizations
  organizations: [],
  selectedOrganization: null,
  fetchOrganizations: () => {},
  setOrganizations: () => {},
  setSelectedOrganization: () => {},
  

  // Services
  services: [],
  selectedService: null,
  filteredServices: [],
  fetchServices: () => {},
  setSelectedService: () => {},
  filterServices: () => {},
  
  // Products
  products: [],
  selectedProduct: null,
  filteredProducts: [],
  fetchProducts: () => {},
  setSelectedProduct: () => {},
  filterProducts: () => {},

  // Tickets
  tickets: [],
  selectedTicket: null,
  filteredTickets: [],
  fetchTickets: () => {},
  setSelectedTicket: () => {},
  filterTickets: () => {},

  // Venue
  venues: [],
  selectedVenue: null,
  filteredVenues: [],
  fetchVenue: () => {},
  setSelectedVenue: () => {},
  filterVenues: () => {},

  // Campaign
  campaigns: [],
  selectedCampaign: null,
  fetchCampaign: () => {},
  setSelectedCampaigns: () => {},
  
  // Common
  loading: false,
  showForm: false,
  showOrderManagement: false,
  setShowForm: () => {},
  handleShowForm: () => {},
  setShowOrderManagement: () => {},
  debugMode: true,
  setDebugMode: () => {},
});

export const AmsProvider = ({ children }) => {
  // Users state
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userServiceOrders, setUserServiceOrders] = useState([]);
  const [userProductOrders, setUserProductOrders] = useState([]);
  const [userTicketOrders, setUserTicketOrders] = useState([]);
  const [userCompaigns, setUserCompaigns] = useState([]);

  // Organizations state
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  // Services state
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [filteredServices, setFilteredServices] = useState([]);
  
  // Products state
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  // Tickets state
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [filteredTickets, setFilteredTickets] = useState([]);

  // Venue state
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [filteredVenues, setFilteredVenues] = useState([]);

  // Campaign state
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);

  // Common state
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const [identityList, setIdentityList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [debugMode, setDebugModeState] = useState(getDebugMode());

  const setDebugMode = (value) => {
    setDebugModeState(value);
    setGlobalDebugMode(value);
  };

  // 監聽 `debugModeChanged` 事件
  useEffect(() => {
    const handleDebugModeChange = (event) => {
      const newDebugMode = event.detail;
      setDebugModeState(newDebugMode);
    };

    window.addEventListener('debugModeChanged', handleDebugModeChange);

    return () => {
      window.removeEventListener('debugModeChanged', handleDebugModeChange);
    };
  }, []);


  // Users
  const fetchUsers = async () => {
    setLoading(true);
    setUsers([]);
    try {
      const fetchedUsers = await fetchUsersAPI();
      const formattedUsers = Array.isArray(fetchedUsers) ? fetchedUsers.map(user => ({
        ...user,
        key: user.id
      })) : [];
      setUsers(formattedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      setUsers([]);
    } finally {
      setLoading(false);
    }
  };

  // User Service Orders
  const fetchUserServiceOrders = async () => {
    setLoading(true);
    setUserServiceOrders([]);
    try {
      const fetchedUserServiceOrders = await fetchUserServiceOrdersAPI();
      const formattedUserServiceOrders = Array.isArray(fetchedUserServiceOrders) ? fetchedUserServiceOrders.map(userServiceOrder => ({
        ...userServiceOrder,
        key: userServiceOrder.id
      })) : [];
      setUserServiceOrders(formattedUserServiceOrders);
    } catch (error) {
      console.error('Error fetching user service orders:', error);
      setUserServiceOrders([]);
    } finally {
      setLoading(false);
    }
  };

  // User Product Orders
  const fetchUserProductOrders = async () => {
    setLoading(true);
    setUserProductOrders([]);
    try {
      const fetchedUserProductOrders = await fetchUserProductOrdersAPI();
      const formattedUserProductOrders = Array.isArray(fetchedUserProductOrders) ? fetchedUserProductOrders.map(userProductOrder => ({
        ...userProductOrder,
        key: userProductOrder.id
      })) : [];
      setUserProductOrders(formattedUserProductOrders);
    } catch (error) {
      console.error('Error fetching user product orders:', error);
      setUserProductOrders([]);
    } finally {
      setLoading(false);
    }
  };

  // User Ticket Orders
  const fetchUserTicketOrders = async () => {
    setLoading(true);
    setUserTicketOrders([]);
    try {
      const fetchedUserTicketOrders = await fetchUserTicketOrdersAPI();
      const formattedUserTicketOrders = Array.isArray(fetchedUserTicketOrders) ? fetchedUserTicketOrders.map(userTicketOrder => ({
        ...userTicketOrder,
        key: userTicketOrder.id
      })) : [];
      setUserTicketOrders(formattedUserTicketOrders);
    } catch (error) {
      console.error('Error fetching user ticket orders:', error);
      setUserTicketOrders([]);
    } finally {
      setLoading(false);
    }
  };

  // User Compaigns
  const fetchUserCompaigns = async () => {
    setLoading(true);
    setUserCompaigns([]);
    try {
      const fetchedUserCompaigns = await fetchUserCompaignsAPI();
      const formattedUserCompaigns = Array.isArray(fetchedUserCompaigns) ? fetchedUserCompaigns.map(userCompaign => ({
        ...userCompaign,
        key: userCompaign.id
      })) : [];
      setUserCompaigns(formattedUserCompaigns);
    } catch (error) {
      console.error('Error fetching user compaigns:', error);
      setUserCompaigns([]);
    } finally {
      setLoading(false);
    }
  };

  // Organizations
  const fetchOrganizations = async () => {
    setLoading(true);
    setOrganizations([]);
    try {
      const fetchedOrganizations = await fetchOrganizationsAPI();
      const organizationsWithKeys = fetchedOrganizations.map(org => ({
        ...org,
        key: org.id
      }));
      setOrganizations(organizationsWithKeys);      
    } catch (error) {
      console.error('Error fetching organizations:', error);
      setOrganizations([]);
    } finally {
      setLoading(false);
    }
  };

  // Services
  const fetchServices = async (productType, orgId) => {
    setLoading(true);
    setServices([]);
    try {
      const fetchedServices = await fetchServicesAPI(productType, orgId);
      const formattedServices = fetchedServices.map(service => ({
        ...service,
        key: service.id,                    
        status: "On Sale",                  
        order: 0,                           
        memberDiscount: "無折扣",           
        earlyBirdDiscount: "無折扣",        
        imageUrl: service.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: service.specs?.map(spec => ({
          ...spec,
          price: spec.price || 0,
          stock: spec.unlimited_stock ? "0" : (spec.stock?.toString() || "Unset")
        }))
      }));      
      setServices(formattedServices);      
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterServices = (status) => {
    if (!status || status === "all") {
      setFilteredServices(services);
    } else {
      const filtered = services.filter(service => service.status === status);
      setFilteredServices(filtered);
    }
  };

  // Products
  const fetchProducts = async (productType, orgId) => {
    setLoading(true);
    setProducts([]);
    try {
      const fetchedProducts = await fetchServicesAPI(productType, orgId);
      const productsWithKeys = fetchedProducts.map(product => ({
        ...product,
        key: product.id
      }));
      setProducts(productsWithKeys);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterProducts = (status) => {
    if (!status || status === "all") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter(product => product.status === status);
      setFilteredProducts(filtered);
    }
  };

  // Tickets
  const fetchTickets = async (productType, orgId) => {
    setLoading(true);
    setTickets([]);
    try {
      const fetchedTickets = await fetchServicesAPI(productType, orgId);
      const formattedTickets = fetchedTickets.map(ticket => ({
        ...ticket,
        key: ticket.id,                    
        status: "On Sale",                  
        order: 0,                           
        memberDiscount: "無折扣",           
        earlyBirdDiscount: "無折扣",        
        imageUrl: ticket.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: ticket.specs?.map(spec => ({
          ...spec,
          price: spec.price || 0,
          stock: spec.unlimited_stock ? "0" : (spec.stock?.toString() || "Unset")
        }))
      }));      
      setTickets(formattedTickets);      
    } catch (error) {
      console.error('Error fetching tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterTickets = (status) => {
    if (!status || status === "all") {
      setFilteredTickets(tickets);
    } else {
      const filtered = tickets.filter(ticket => ticket.status === status);
      setFilteredTickets(filtered);
    }
  };

  // 過濾出場館
  const getVenues = (data) => {
    const hallIds = new Set();
    
    // 先收集所有廳的 ID
    data.forEach(category => {
      category.resources?.forEach(resource => {
        resource.children?.forEach(child => {
          hallIds.add(child.id);
        });
      });
    });

    // 過濾出場館（不在 hallIds 中的資源）
    const venues = [];
    data.forEach(category => {
      category.resources?.forEach(resource => {
        if (!hallIds.has(resource.id)) {
          venues.push({
            id: resource.id,
            category_id: category.id,
            src_information: resource.src_information,
            category: resource.category_name,
            created_at: resource.created_at,
            src_name: resource.src_name,
            halls: resource.children
          });
        }
      });
    });

    return venues;
  };

  // Venue
  const fetchVenue = async () => {
    setLoading(true);
    setVenues([]);
    try {
      const fetchedVenue = await fetchVenueAPI();
      const venues = getVenues(fetchedVenue);
      setVenues(venues);
         
    } catch (error) {
      console.error('Error fetching venue:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterVenues = (status) => {
    if (!status || status === "all") {
      setFilteredVenues(venues);
    } else {
      const filtered = venues.filter(venue => venue.status === status);
      setFilteredVenues(filtered);
    }
  };

  // Campaign
  const fetchCampaign = async () => {
    setLoading(true);
    setCampaigns([]);
    try {
      const fetchedCampaigns = await fetchCampaignAPI();
      setCampaigns(fetchedCampaigns);
    } catch (error) {
      console.error('Error fetching campaign:', error);
    } finally {
      setLoading(false);
    }
  };

  // Common
  const handleShowForm = () => setShowForm(!showForm);

  // Save service data
  const saveServiceData = async (formData) => {
    try {
      const newService = {
        id: Date.now().toString(),
        key: Date.now().toString(),
        ...formData,
        status: "On Sale",
        order: 0,
        memberDiscount: "無折扣",
        earlyBirdDiscount: "無折扣",
        imageUrl: formData.information?.imageUrl || '/images/default-service.jpg',
        created_at: new Date().toISOString()
      };

      setServices(prevServices => [...prevServices, newService]);
      return true;
    } catch (error) {
      console.error('保存服務資料失敗:', error);
      throw error;
    }
  };

  const fetchIdentityList = async (orgId) => {
    try {
      const fetchedIdentityList = await fetchIdentityListAPI(orgId);
      console.log('fetchedIdentityList', fetchedIdentityList);
      if (Array.isArray(fetchedIdentityList)) {
        setIdentityList(fetchedIdentityList);
      } else {
        setIdentityList([]);
      }
    } catch (error) {
      console.error('獲取美容師失敗:', error);
      setIdentityList([]);
    }
  };

  const fetchCategories = async () => {
    try {
      const fetchedCategories = await fetchCategoriesAPI();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('獲取類別失敗:', error);
      setCategories([]);
    }
  };

  const value = {
    // Users
    users,
    selectedUser,
    fetchUsers,
    setUsers,
    setSelectedUser,
    userServiceOrders,
    setUserServiceOrders,
    userProductOrders,
    setUserProductOrders,
    userTicketOrders,
    setUserTicketOrders,
    userCompaigns,
    setUserCompaigns,

    // Organizations
    organizations,
    selectedOrganization,
    fetchOrganizations,
    setOrganizations,
    setSelectedOrganization,

    // Services
    services,
    selectedService,
    filteredServices,
    fetchServices,
    setSelectedService,
    filterServices,
    saveServiceData,
    
    // Products
    products,
    selectedProduct,
    filteredProducts,
    fetchProducts,
    setSelectedProduct,
    filterProducts,

    // Tickets
    tickets,
    selectedTicket,
    filteredTickets,
    fetchTickets,
    setSelectedTicket,
    filterTickets,

    // Venue
    venues,
    selectedVenue,
    filteredVenues,
    fetchVenue,
    setSelectedVenue,
    filterVenues,

    // Campaign
    campaigns,
    selectedCampaign,
    fetchCampaign,
    setSelectedCampaign,

    // Common
    loading,
    showForm,
    showOrderManagement,
    setShowForm,
    handleShowForm,
    setShowOrderManagement,
    identityList,
    setIdentityList,
    fetchIdentityList,
    categories,
    setCategories,
    fetchCategories,
    debugMode,
    setDebugMode,
  };

  return (
    <AmsContext.Provider value={value}>
      {children}
    </AmsContext.Provider>
  );
};

export const useAmsContext = () => {
  const context = useContext(AmsContext);
  if (!context) {
    throw new Error('useAmsContext must be used within an AmsProvider');
  }
  return context;
};

export const useAms = () => {
  const context = useContext(AmsContext);
  if (!context) {
    throw new Error('useAms must be used within an AmsProvider');
  }
  return context;
};
