import axios from "axios";
import { message } from "antd";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const fetchProfile = async (roomId, token) => {
  try {
    const response = await axios.get(`${SERVER_URL}/private/module/hotel/${roomId}/profile`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return false;
    }
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const createProfile = async (roomId, token, keywords) => {
  let query = ""

  if (keywords.length > 0) {
    query += "?"
    keywords.forEach((keyword) => {
      query += `asr_keywords=${keyword}&`
    })
    query = query.slice(0, -1)
  }

  try {
    const response = await axios.post(`${SERVER_URL}/private/module/hotel/${roomId}/profile/auto${query}`, {}, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const AddASRTagToProfile = async (roomId, token, tag) => {
  try {
    const response = await axios.patch(`${SERVER_URL}/private/module/hotel/${roomId}/profile/asr_keywords`, {}, {
      params: {
        value: tag
      },
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const DeleteASRTagFromProfile = async (roomId, token, tag) => {
  try {
    const response = await axios.delete(`${SERVER_URL}/private/module/hotel/${roomId}/profile/asr_keywords`, {
      params: {
        key: tag
      },
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const fetchSamples = async (roomId, token, from, to) => {
  try {
    const response = await axios.get(`${SERVER_URL}/private/module/hotel/${roomId}/sample`, {
      params: {
        start_date: from,
        end_date: to
      },
      headers: {
        "Accept": "application/octet-stream",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

// post audio sample as request body array
export const uploadSamples = async (roomId, token, samples) => {
  try {
    message.info('上傳中...');

    // Create an instance of FormData
    const formData = new FormData();
    samples.forEach((file, index) => {
      if (file.type !== "audio/mpeg") {
        message.error('僅支援 MP3 檔案');
        return null;
      }
      formData.append(`samples`, file, file.name);
    });

    const response = await axios.post(`${SERVER_URL}/private/module/hotel/${roomId}/sample`, formData, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
        // "Content-Type": "multipart/form-data" is not required to be explicitly set;
        // Axios will set it correctly based on the FormData instance
      }
    });
    message.success('上傳成功！若檔案支援稍後重整就會出現在列表中');
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const rerunASR = async (roomId, token, sampleId) => {
  message.success('重新分析語音原文...');
  try {
    const response = await axios.post(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/asr`, {}, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

// rerun analysis
export const rerunAnalysis = async (roomId, token, sampleId) => {
  message.success('重新分析 AI 大綱...');
  try {
    const response = await axios.post(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/analysis`, {}, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

// rerun labels
export const rerunLabels = async (roomId, token, sampleId) => {
  message.success('重新分析標籤...');
  try {
    const response = await axios.post(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/labels`, {}, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

// rerun hotel
export const rerunHotel = async (roomId, token, sampleId) => {
  message.success('重新分析目標飯店...');
  try {
    const response = await axios.post(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/hotel`, {}, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const fetchSample = async (roomId, token, sampleId) => {
  try {
    const response = await axios.get(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    // message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

// key: customer_question, service_response, optimized_response
export const updateSample = async (roomId, token, sampleId, key, value) => {
  try {
    const response = await axios.patch(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}?key=${key}&value=${value}`, {}, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const addCustomTagToSample = async (roomId, token, sampleId, tagId) => {
  try {
    const response = await axios.patch(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/labels`, {}, {
      params: {
        tag_id: tagId
      },
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

export const deleteCustomTagFromSample = async (roomId, token, sampleId, tagId) => {
  try {
    const response = await axios.delete(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/labels`, {
      params: {
        tag_id: tagId
      },
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

export const updateHotelToSample = async (roomId, token, sampleId, hotelId) => {
  try {
    const response = await axios.patch(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/hotel`, {}, {
      params: {
        hotel_id: hotelId
      },
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

export const deleteSample = async (roomId, token, sampleId) => {
  try {
    message.info('刪除中...');
    const response = await axios.delete(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    message.success('刪除成功');
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

// get transcription (segments)
export const fetchTranscript = async (roomId, token, sampleId) => {
  try {
    const response = await axios.get(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/segments`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const updateTranscript = async (roomId, token, sampleId, segmentId, value) => {
  const body = {
    idx: segmentId,
    text: value,
  };

  try {
    const response = await axios.patch(`${SERVER_URL}/private/module/hotel/${roomId}/sample/${sampleId}/segments`, body, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

export const fetchHotels = async (roomId, token) => {
  try {
    const response = await axios.get(`${SERVER_URL}/private/module/hotel/${roomId}/hotel`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

export const fetchHotel = async (roomId, token, hotelId) => {
  try {
    const response = await axios.get(`${SERVER_URL}/private/module/hotel/${roomId}/hotel/${hotelId}`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

// hotel info
// {
//   "abbrev": "string",
//   "name": "string",
//   "address": "string",
//   "phone_number": "string",
//   "website": "string",
//   "keywords": []
// }
export const createHotel = async (roomId, token, hotel) => {
  try {
    const response = await axios.post(`${SERVER_URL}/private/module/hotel/${roomId}/hotel`, hotel, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

// key: abbrev, name, address, phone_number, website
export const updateHotelInfo = async (roomId, token, hotelId, key, value) => {
  try {
    const response = await axios.patch(`${SERVER_URL}/private/module/hotel/${roomId}/hotel/${hotelId}`, {}, {
      params: {
        key: key,
        value: value,
      },
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

// remember to retain the original keywords when updating them to avoid deleting all keywords
export const updateHotelKeywords = async (roomId, token, hotelId, newKeywords) => {
  let query = ""

  if (newKeywords.length > 0) {
    query += "?"
    newKeywords.forEach((keyword) => {
      query += `keywords=${keyword}&`
    })
    query = query.slice(0, -1)
  } else {
    return
  }

  try {
    const response = await axios.put(`${SERVER_URL}/private/module/hotel/${roomId}/hotel/${hotelId}/keywords${query}`, {}, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
    return response.data
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail))
    // throw error
  }
}

// custom tag
export const fetchCustomTags = async (roomId, token, tagGroupId) => {
  try {
    const response = await axios.get(`${SERVER_URL}/private/module/tags/${roomId}/${tagGroupId}`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    // message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
};

export const createCustomTag = async (roomId, token, tagGroupId, tag) => {
  const body = {
    text: tag
  };

  try {
    const response = await axios.post(`${SERVER_URL}/private/module/tags/${roomId}/${tagGroupId}`, body, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}

export const deleteCustomTag = async (roomId, token, tagGroupId, tagId) => {
  try {
    const response = await axios.delete(`${SERVER_URL}/private/module/tags/${roomId}/${tagGroupId}/${tagId}`, {
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    message.error(JSON.stringify(error.response?.data?.detail));
    // throw error;
  }
}