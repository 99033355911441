import moment from 'moment';
import { ENV } from '@/constants/env';
import { Button, Tag } from 'antd';
import { callApi } from '@/utils';
import { detailConfig as config } from '../detail/detailConfig';

const ellipsisStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%'
};


const stag5Config = {
  page: {
    title: "Store Management",
    addButton: "Add Store",
    information: "Basic Information",
    specification: "Service Specifications",
    discount: "Discounts and Offers",
    publish: "Service Deployment",
    services: "Services",
    serviceOrders: "Service Order Records",
    products: "Products",
    productOrders: "Product Order Records",
  },
  onPublish: (formData, { saveServiceData }) => {
    // 保存表單數據到 context
    saveServiceData(formData);
  },
  columns: ({ setCurrentView, setSelectedOrganization, organizations }) => [
    {
      title: "STATUS",
      dataIndex: "status",
      width: "8%",
      fixed: "left",
      align: "center",
      sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
      render: (status = "On Sale") => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
          style={ellipsisStyle}
        >
          {status}
        </span>
      ),
    },
    {
      title: "REVIEW STATUS",
      dataIndex: "enabled",
      width: "12%",
      align: "center",
      render: (enabled) => (
        <span
          className="badge rounded-full px-2 py-1 text-black"
          style={ellipsisStyle}
        >
          {enabled === true ? 'Approved' : 'Not Approved'}
        </span>
      ),
    },
    {
      title: "STORE SERIAL NUMBER",
      dataIndex: "id",
      width: "18%",
      align: "center",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => (
        <span style={ellipsisStyle}>
          {id ? id.substring(0, 5) : 'Unset Serial Number'}
        </span>
      ),
    },
    {
      title: "STORE NAME",
      dataIndex: "name",
      width: "15%",
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      render: (text, record) => {
        const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
        return (
          <div className="flex items-center"
            style={ellipsisStyle}
          >
            <img
              src={imageUrl}
              alt="store photo"
              className="w-6 h-6 object-cover rounded mr-2"
            />
            <span
              className="truncate text-blue-500 hover:text-blue-700 hover:underline"
              onClick={() => {
                setCurrentView('services');
              }}
              style={{ cursor: 'pointer' }}
            >
              {text || 'unset store name'}
            </span>
          </div>
        );
      }
    },
    {
      title: "PERSON IN CHARGE",
      dataIndex: ["admin", "nickname"] || ["admin", "first_name"],
      width: "15%",
      align: "center",
      render: (text, record) => (
        <div className="flex items-center"
          style={ellipsisStyle}
        >
          <img src={record.picture || "/images/user.png"}
            alt="user avatar"
            className="w-6 h-6 object-cover rounded-full mr-2"
          />
          <span className="truncate">{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || 'Unset Name'}</span>
        </div>
      ),
    },
    {
      title: "NUMBER OF SERVICES",
      dataIndex: "service",
      width: "15%",
      align: "center",
      render: (service) => (
        <>
          <span className="mr-2" style={ellipsisStyle}> {service ? service : '0'}</span>
        </>
      ),
    },
    {
      title: "NUMBER OF PRODUCTS",
      dataIndex: "product",
      width: "18%",
      align: "center",
      render: (product) => (
        <>
          <span className="mr-2" style={ellipsisStyle}> {product ? product : '0'}</span>
        </>
      ),
    },
    {
      title: "TOTAL REVENUE",
      dataIndex: "revenue",
      width: "12%",
      align: "center",
      render: (revenue) => (
        <>
          <span className="mr-2" style={ellipsisStyle}>NT$ {revenue ? revenue : '0'}</span>
        </>
      ),
    },
    {
      title: "TAGS",
      dataIndex: "tags",
      width: "12%",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <Tag color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">Normal</Tag>;
        }

        return (
          <div className="flex flex-wrap gap-1" style={ellipsisStyle}>
            {tags.map((tag, index) => (
              <Tag key={index} color="blue">{tag}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "JOIN DATE",
      dataIndex: "created_at",
      width: "12%",
      align: "center",
      render: (date) => <span style={ellipsisStyle}>{date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">Unset</span>}</span>,
    },
    {
      title: "STORE PHONE",
      dataIndex: "phone",
      width: "12%",
      align: "center",
      render: (phone) => <span style={ellipsisStyle}>{phone || <span className="text-gray-400">Unset Phone</span>}</span>,
    },
    {
      title: "STORE EMAIL",
      dataIndex: "email",
      width: "15%",
      align: "center",
    },
    {
      title: "LOCATION CITY",
      dataIndex: "region",
      width: "12%",
      align: "center",
      render: (region) => <span style={ellipsisStyle}>{region || <span className="text-gray-400">Unset City</span>}</span>,
    },
    {
      title: "LOCATION AREA",
      dataIndex: "service_region",
      width: "12%",
      align: "center",
      render: (service_region) => <span style={ellipsisStyle}>{service_region || <span className="text-gray-400">Unset Area</span>}</span>,
    },
  ],
  apis: {
    fetchCategories: async () => await callApi.get('/public/category/?product_type=service_product_ip'),
  },
  detail: (data, isEdit = false) => {
    if (!data) return [];

    return [
      {
        key: "1",
        label: config.info,
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片區域 */}
              <div className="relative flex justify-center">
                <img
                  src={data.picture || "/images/default-store.jpg"}
                  alt={data.name}
                  className="w-[138px] h-[138px] object-cover rounded-full"
                />
              </div>
              {/* 標籤 */}
              {data.tags && (
                <div className="flex justify-center gap-2">
                  {data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  ))}
                </div>
              )}
              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-center gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.name || "Unset Store Name"}
                  </h1>
                </div>
                {/* 標籤 */}
                <div className="flex justify-center gap-2">
                  {data.tags?.length > 0 ? data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  )) : <Tag className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">Unset Tags</Tag>}
                </div>

                {/* 社交平台 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">Store Platform</div>
                  {['facebook', 'instagram', 'threads', 'tiktok'].map((platform) => (
                    <div key={platform} className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <img src={`/images/${platform}.png`} alt={platform} className="w-4 h-4 object-contain" />
                        <span>{platform}</span>
                      </div>
                      <Button type="primary" className={`bg-[#ADDEAC] text-black ${!data[`${platform}_url`] ? "bg-gray-300 opacity-50 cursor-not-allowed" : ""}`}>{data[`${platform}_url`] || 'Unset'}</Button>
                    </div>
                  ))}
                </div>

                {/* 基本資料 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold">Basic Information</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/date.png" alt="birthday" className="w-4 h-4" />
                    <span>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/email.png" alt="email" className="w-4 h-4" />
                    <span>{data.email || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/location.png" alt="location" className="w-4 h-4" />
                    <span>{data.address || 'Unset'}</span>
                  </div>
                </div>

                {/* 負責人 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">Person in Charge</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/person.png" alt="person" className="w-3 h-4" />
                    <span>{data.nickname || data.first_name || data.last_name || 'Unset'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || 'Unset'}</span>
                  </div>
                </div>

                {/* 備註 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">Store Introduction</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg min-h-[80px]">
                    <span className={!data.note ? "text-gray-400" : ""}>
                      {data.note || 'Unset Introduction'}
                    </span>
                  </div>
                </div>

              </div>

              {
                isEdit && (
                  <>
                    {/* 編輯按鈕 */}
                    <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                      <Button
                        type="primary"
                        className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                      >
                        {config.labels.edit}
                      </Button>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        ),
      }
    ];
  },
  tabs: [
    { key: '1', label: 'Services' },
    { key: '2', label: 'Services Orders' },
    { key: '3', label: 'Products' },
    { key: '4', label: 'Products Orders' }
  ]
};

const pancoConfig = {
  page: {
    title: "店家管理",
    serviceOrders: "服務訂單列表",
    productOrders: "商品訂單列表",
    services: "服務列表",
    products: "商品列表",
    beauticians: "美業老師列表",
    users: "會員管理"
  },
  columns: ({ setCurrentView, currentView }) => [
    {
      title: "前台狀態",
      dataIndex: "status",
      width: "8%",
      fixed: "left",
      align: "center",
      sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
      render: (status = "On Sale") => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
          style={ellipsisStyle}
        >
          {status}
        </span>
      ),
    },
    {
      title: "審核狀態",
      dataIndex: "enabled",
      width: "8%",
      align: "center",
      render: (enabled) => (
        <span
          className="badge rounded-full px-2 py-1 text-black"
          style={ellipsisStyle}
        >
          {enabled === true ? '已通過' : '申請中'}
        </span>
      ),
    },
    {
      title: "店家序號",
      dataIndex: "id",
      width: "12%",
      align: "center",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => (
        <span style={ellipsisStyle}>
          {id ? id.substring(0, 5) : '未設定序號'}
        </span>
      ),
    },
    {
      title: "店家名稱",
      dataIndex: "name",
      width: "12%",
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      render: (text, record) => {
        const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
        console.log('record', record);
        
        return (
          <div className="flex items-center"
            style={ellipsisStyle}
          >
            <img
              src={imageUrl}
              alt="服務縮圖"
              className="w-6 h-6 object-cover rounded mr-2"
            />
            {currentView === 'default' ?
            <span
            className="truncate text-blue-500 hover:text-blue-700 hover:underline"
            onClick={() => {
              
              
              if (currentView === 'default') {
                console.log('444', currentView);
                setCurrentView('services');
              } 
            }}
            style={{ cursor: 'pointer' }}
          >
            {text || '未設定服務名稱'}
          </span>
          :
          <span>
            {text || '未設定服務名稱'}
          </span>

          }
          </div>
        );
      }
    },
    {
      title: "註冊申請人",
      dataIndex: ["admin", "nickname"] || ["admin", "first_name"],
      width: "12%",
      align: "center",
      render: (text, record) => (
        <div className="flex items-center"
          style={ellipsisStyle}
        >
          <img src={record.picture || "/images/user.png"}
            alt="user avatar"
            className="w-6 h-6 object-cover rounded-full mr-2"
          />
          <span className="truncate">{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || '未設定名稱'}</span>
        </div>
      ),
    },
    {
      title: "美業老師數量",
      dataIndex: "teacher",
      width: "12%",
      align: "center",
      render: (teacher) => (
        <>
          <span className="mr-2" style={ellipsisStyle}> {teacher ? teacher : '0'}</span>
          {/* <Button
            type="primary"
            size="small"
            className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
            onClick={() => {
              setCurrentView('beauticians');
            }}
          >
            查看
          </Button> */}
        </>
      ),
    },
    {
      title: "服務數量",
      dataIndex: "service",
      width: "12%",
      align: "center",
      render: (service) => (
        <>
          <span className="mr-2" style={ellipsisStyle}> {service ? service : '0'}</span>
        </>
      ),
    },
    {
      title: "商品數量",
      dataIndex: "product",
      width: "12%",
      align: "center",
      render: (product) => (
        <>
          <span className="mr-2" style={ellipsisStyle}> {product ? product : '0'}</span>
        </>
      ),
    },
    {
      title: "累積營收",
      dataIndex: "revenue",
      width: "12%",
      align: "center",
      render: (revenue) => (
        <>
          <span className="mr-2" style={ellipsisStyle}>NT$ {revenue ? revenue : '0'}</span>
        </>
      ),
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "12%",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <Tag color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">Normal</Tag>;
        }

        return (
          <div className="flex flex-wrap gap-1" style={ellipsisStyle}>
            {tags.map((tag, index) => (
              <Tag key={index} color="blue">{tag}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "加入日期",
      dataIndex: "created_at",
      width: "12%",
      align: "center",
      render: (date) => <span style={ellipsisStyle}>{date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">Unset</span>}</span>,
    },
    {
      title: "店家電話",
      dataIndex: "phone",
      width: "12%",
      align: "center",
      render: (phone) => <span style={ellipsisStyle}>{phone || <span className="text-gray-400">未設定電話</span>}</span>,
    },
    {
      title: "店家Email",
      dataIndex: "email",
      width: "20%",
      align: "center",
    },
    {
      title: "所在縣市",
      dataIndex: "region",
      width: "12%",
      align: "center",
      render: (region) => <span style={ellipsisStyle}>{region || <span className="text-gray-400">未設定縣市</span>}</span>,
    },
    {
      title: "所在區域",
      dataIndex: "service_region",
      width: "12%",
      align: "center",
      render: (service_region) => <span style={ellipsisStyle}>{service_region || <span className="text-gray-400">未設定區域</span>}</span>,
    },
  ],
  formFields: [
    { name: 'name', label: '服務名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  onPublish: (formData, { saveServiceData }) => {
    // 保存表單數據到 context
    saveServiceData(formData);
  },
  apis: {
    fetchCategories: async () => await callApi.get('/public/category/?product_type=service_product_ii'),
  },
  detail: (data, isEdit = false) => {
    if (!data) return [];

    return [
      {
        key: "1",
        label: "店家資訊",
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片區域 */}
              <div className="relative flex justify-center">
                <img
                  src={data.picture || "/images/service1.png"}
                  alt={data.name}
                  className="w-[138px] h-[138px] object-cover rounded-full"
                />
              </div>
              {/* 標籤 */}
              {data.tags && (
                <div className="flex justify-center gap-2">
                  {data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  ))}
                </div>
              )}
              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-center gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.name || "Unset Name"}
                  </h1>
                </div>
                {/* 標籤 */}
                <div className="flex justify-center gap-2">
                  {data.tags?.length > 0 ? data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  )) : <Tag className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">未設定標籤</Tag>}
                </div>

                {/* 社交平台 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">店家平台</div>
                  {['facebook', 'instagram', 'threads', 'tiktok'].map((platform) => (
                    <div key={platform} className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <img src={`/images/${platform}.png`} alt={platform} className="w-4 h-4 object-contain" />
                        <span>{platform}</span>
                      </div>
                      <Button type="primary" className={`bg-[#ADDEAC] text-black ${!data[`${platform}_url`] ? "bg-gray-300 opacity-50 cursor-not-allowed" : ""}`}>{data[`${platform}_url`] || '未設定'}</Button>
                    </div>
                  ))}
                </div>

                {/* 基本資料 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold">基本資料</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/date.png" alt="birthday" className="w-4 h-4" />
                    <span>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : '未設定日期'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || '未設定手機'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/email.png" alt="email" className="w-4 h-4" />
                    <span>{data.email || '未設定Email'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/location.png" alt="location" className="w-4 h-4" />
                    <span>{data.address || '未設定地區'}</span>
                  </div>
                </div>

                {/* 負責人 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">負責人</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/person.png" alt="person" className="w-4 h-4" />
                    <span>{data.nickname || data.first_name || data.last_name || '未設定名稱'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || '未設定手機'}</span>
                  </div>
                </div>

                {/* 備註 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">店家介紹</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg min-h-[80px]">
                    <span className={!data.note ? "text-gray-400" : ""}>
                      {data.note || '未設定介紹'}
                    </span>
                  </div>
                </div>

              </div>

              {
                isEdit && (
                  <>
                    {/* 編輯按鈕 */}
                    <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                      <Button
                        type="primary"
                        className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                      >
                        {config.labels.edit}
                      </Button>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        ),
      }
    ];
  },
  tabs: [
    { key: '1', label: '服務列表' },
    { key: '2', label: '服務訂單列表' },
    { key: '3', label: '產品列表' },
    { key: '4', label: '產品訂單列表' },
    { key: '5', label: '美業老師管理' },
  ],
};

const _77Config = {
  page: {
    title: "店家管理",
    services: "票券列表",
    serviceOrders: "票券核銷紀錄",
    products: "分店管理",
    productOrders: "會員管理",
    beauticians: "美業老師管理",
    users: "會員管理"

  },
  columns: ({ setCurrentView, setSelectedOrganization, organizations }) => [
    {
      title: "前台狀態",
      dataIndex: "status",
      width: "8%",
      fixed: "left",
      align: "center",
      sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
      render: (status = "On Sale") => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
          style={ellipsisStyle}
        >
          {status}
        </span>
      ),
    },
    {
      title: "審核狀態",
      dataIndex: "enabled",
      width: "8%",
      align: "center",
      render: (enabled) => (
        <span
          className="badge rounded-full px-2 py-1 text-black"
          style={ellipsisStyle}
        >
          {enabled === true ? '已通過' : '申請中'}
        </span>
      ),
    },
    {
      title: "店家序號",
      dataIndex: "id",
      width: "12%",
      align: "center",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => (
        <span style={ellipsisStyle}>
          {id ? id.substring(0, 5) : '未設定序號'}
        </span>
      ),
    },
    {
      title: "店家名稱",
      dataIndex: "name",
      width: "12%",
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      render: (text, record) => {
        const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
        return (
          <div className="flex items-center"
            style={ellipsisStyle}
          >
            <img
              src={imageUrl}
              alt="服務縮圖"
              className="w-6 h-6 object-cover rounded mr-2"
            />
            <span
              className="truncate text-blue-500 hover:text-blue-700 hover:underline"
              onClick={() => {
                setCurrentView('tickets');
              }}
              style={{ cursor: 'pointer' }}
            >
              {text || '未設定服務名稱'}
            </span>
          </div>
        );
      }
    },
    {
      title: "註冊申請人",
      dataIndex: ["admin", "nickname"] || ["admin", "first_name"],
      width: "12%",
      align: "center",
      render: (text, record) => (
        <div className="flex items-center"
          style={ellipsisStyle}
        >
          <img src={record.picture || "/images/user.png"}
            alt="user avatar"
            className="w-6 h-6 object-cover rounded-full mr-2"
          />
          <span className="truncate">{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || '未設定名稱'}</span>
        </div>
      ),
    },
    {
      title: "分店數量",
      dataIndex: "service",
      width: "12%",
      align: "center",
      render: (service) => (
        <>
          <span className="mr-2" style={ellipsisStyle}> {service ? service : '0'}</span>
          <Button
            type="primary"
            size="small"
            className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
          >
            查看
          </Button>
        </>
      ),
    },
    {
      title: "票券種類",
      dataIndex: "service",
      width: "12%",
      align: "center",
      render: (service) => (
        <>
          <span className="mr-2" style={ellipsisStyle}> {service ? service : '0'}</span>
          <Button
            type="primary"
            size="small"
            className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
          >
            查看
          </Button>
        </>
      ),
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "12%",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <Tag color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">Normal</Tag>;
        }

        return (
          <div className="flex flex-wrap gap-1" style={ellipsisStyle}>
            {tags.map((tag, index) => (
              <Tag key={index} color="blue">{tag}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "加入日期",
      dataIndex: "created_at",
      width: "12%",
      align: "center",
      render: (date) => <span style={ellipsisStyle}>{date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">Unset</span>}</span>,
    },
    {
      title: "店家電話",
      dataIndex: "phone",
      width: "7%",
      align: "center",
      render: (phone) => <span style={ellipsisStyle}>{phone || <span className="text-gray-400">未設定電話</span>}</span>,
    },
    {
      title: "店家Email",
      dataIndex: "email",
      width: "15%",
      align: "center",
    },
    {
      title: "所在縣市",
      dataIndex: "region",
      width: "12%",
      align: "center",
      render: (region) => <span style={ellipsisStyle}>{region || <span className="text-gray-400">未設定縣市</span>}</span>,
    },
    {
      title: "所在區域",
      dataIndex: "service_region",
      width: "12%",
      align: "center",
      render: (service_region) => <span style={ellipsisStyle}>{service_region || <span className="text-gray-400">未設定區域</span>}</span>,
    },
  ],
  formFields: [
    { name: 'name', label: '服務名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  onPublish: (formData, { saveServiceData }) => {
    // 保存表單數據到 context
    saveServiceData(formData);
  },
  apis: {
    fetchCategories: async () => await callApi.get('/public/category/?product_type=service_product_ii'),
  },
  detail: (data, isEdit = false) => {
    if (!data) return [];

    return [
      {
        key: "1",
        label: config.info,
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片區域 */}
              <div className="relative flex justify-center">
                <img
                  src={data.picture || "/images/default-store2.jpg"}
                  alt={data.name}
                  className="w-[138px] h-[138px] object-cover rounded-full"
                />
              </div>
              {/* 標籤 */}
              {data.tags && (
                <div className="flex justify-center gap-2">
                  {data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  ))}
                </div>
              )}
              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-center gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.nickname ||
                      `${data.first_name || ''} ${data.last_name || ''}`.trim() ||
                      "未設定店名"}
                  </h1>
                </div>
                {/* 標籤 */}
                <div className="flex justify-center gap-2">
                  {data.tags?.length > 0 ? data.tags.map((tag, index) => (
                    <Tag key={index} color="default" className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">{tag}</Tag>
                  )) : <Tag className="badge rounded-full px-2 py-1 text-white bg-[#67BE5F]">未設定標籤</Tag>}
                </div>

                {/* 社交平台 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">店家平台</div>
                  {['facebook', 'instagram', 'threads', 'tiktok'].map((platform) => (
                    <div key={platform} className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <img src={`/images/${platform}.png`} alt={platform} className="w-4 h-4 object-contain" />
                        <span>{platform}</span>
                      </div>
                      <Button type="primary" className={`bg-[#ADDEAC] text-black ${!data[`${platform}_url`] ? "bg-gray-300 opacity-50 cursor-not-allowed" : ""}`}>{data[`${platform}_url`] || '未設定'}</Button>
                    </div>
                  ))}
                </div>

                {/* 基本資料 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold">基本資料</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/date.png" alt="birthday" className="w-4 h-4" />
                    <span>{data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : '未設定日期'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || '未設定手機'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/email.png" alt="email" className="w-4 h-4" />
                    <span>{data.email || '未設定Email'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/location.png" alt="location" className="w-4 h-4" />
                    <span>{data.address || '未設定地區'}</span>
                  </div>
                </div>

                {/* 負責人 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">負責人</div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/person.png" alt="person" className="w-4 h-4" />
                    <span>{data.nickname || data.first_name || data.last_name || '未設定名稱'}</span>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <img src="/images/phone.png" alt="phone" className="w-4 h-4" />
                    <span>{data.phone || '未設定手機'}</span>
                  </div>
                </div>

                {/* 備註 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">店家介紹</div>
                  <div className="bg-[#F5F5F5] p-4 rounded-lg min-h-[80px]">
                    <span className={!data.note ? "text-gray-400" : ""}>
                      {data.note || '未設定介紹'}
                    </span>
                  </div>
                </div>

              </div>

              {
                isEdit && (
                  <>
                    {/* 編輯按鈕 */}
                    <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                      <Button
                        type="primary"
                        className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                      >
                        {config.labels.edit}
                      </Button>
                    </div>
                  </>
                )
              }
            </div>
          </div>
        ),
      }
    ];
  },
  tabs: [
    { key: '1', label: '票券列表' },
    { key: '2', label: '票券核銷紀錄' },
    { key: '3', label: '分店管理' },
  ],
};

// 根據環境變數選擇配置
const isPanco = ENV.E_COMMERCE.PANCO;
const isStag5 = ENV.E_COMMERCE.STAG5;
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const organizationConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : is77 ? _77Config : null;
