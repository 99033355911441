/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { productConfig } from "@/app/ams/config/product/productConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useFormScroll, formLogic, productFormConfig } from "@/app/ams/config/product/productFormConfig";
import ToolBar from "@/app/ams/components/ToolBar";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
import Form from "@/app/ams/components/Form";
import Bread from "@/app/ams/components/Bread";
import { fetchProductOrdersAPI } from '@/app/ams/services/api';

export default function Product() {
  const { showForm, setShowForm, selectedProduct, setSelectedProduct, products, loading, fetchProducts, debugMode } = useAmsContext();
  const columns = productConfig.columns;
  const productType = productConfig.apis.product_type;
  const [formData, setFormData] = useState({});
  const [showProductOrders, setShowProductOrders] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchProducts(productType);
  }, [debugMode]);

  useEffect(() => {
    if (products?.length > 0 && !selectedProduct) {
      const firstProduct = products[0];
      const productWithImage = {
        ...firstProduct,
        imageUrl: firstProduct?.blobs?.[0]?.url || '/images/default-product.jpg',
        specs: firstProduct.specs || [],
        description: firstProduct.description || '',
        notification: firstProduct.notification || '',
        status: firstProduct.status || 'On Sale',
        provider: firstProduct.provider || '',
        category: firstProduct.category || {},
        tags: firstProduct.tags || [],
        recommendation: firstProduct.recommendation || null,
        publishDate: firstProduct.publishDate || null
      };
      setSelectedProduct(productWithImage);
    }
  }, [products, selectedProduct]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('products', products);
  }, [products]);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleRowClick = (record) => {
    if ( !showProductOrders ) {
      if (selectedProduct?.key !== record.key) {
        const productWithImage = {
          ...record,
          imageUrl: record?.blobs?.[0]?.url || '/images/default-product.jpg',
          specs: record.specs || [],
          description: record.description || '',
          notification: record.notification || '',
          status: record.status || 'On Sale',
          provider: record.provider || '',
          category: record.category || {},
          tags: record.tags || [],
          recommendation: record.recommendation || null,
          publishDate: record.publishDate || null
        };
        setSelectedProduct(productWithImage);
      }
    }
  };

  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">{productFormConfig.product.title}</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/product'}
        >
          {productFormConfig.product.title}
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">{productFormConfig.product.add}</span>
      </div>
    </div>
  );

  const handleFormChange = (changedValues) => {
    setFormData(changedValues);
  };

  const renderTabs = () => {
    return (
      <div className="flex gap-4 my-2">
        {productConfig.tabs.map((tab) => (
          <Button
            key={tab.key}
            className={`${showProductOrders === true
                ? "bg-[#90C290] hover:bg-[#7AB27A]"
                : "bg-white hover:bg-gray-100 border border-gray-200"
              } px-3 py-2 rounded-full`}
            onClick={() => setShowProductOrders(true)}
          >
            {tab.label}
          </Button>
        ))}
      </div>
    );
  };

  const ShowProductOrders = () => {
    const [productOrders, setProductOrders] = useState([]);
    useEffect(() => {
      const res = async () => {
        const res = await fetchProductOrdersAPI(selectedProduct?.id);
        if (res.length > 0) {
          setProductOrders(res);
        }
      }
      res();
    }, [selectedProduct]);
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold">{selectedProduct?.name || selectedProduct?.title}</div>
            </div>
            <Bread title={productConfig.page.title} path="product" subTitle={productConfig.page.orderRecords} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            {/* <Statistics data={statisticsConfig?.statistics?.product} type="product" /> */}
            <Table
              columns={columns({
                setShowProductOrders,
                setSelectedProduct,
                products,
              })}
              dataSource={productOrders}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedProduct?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedProduct?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedProduct}
              type="product"
              items={productConfig?.detail?.(selectedProduct, true)}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">

        <div className="flex-1 flex overflow-auto">
          {
            showForm ?
              (
                <>
                  <div className="flex w-full space-x-4 overflow-auto">
                    <div className="flex-1 overflow-hidden">
                      <BreadcrumbNav />
                      <Form onChange={handleFormChange} formConfig={productFormConfig} formLogic={formLogic} useFormScroll={useFormScroll} title={productFormConfig.product.title} draftText={productFormConfig.product.draft} publishText={productFormConfig.product.publish} />
                    </div>
                    <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                      <Detail data={formData} items={productConfig.detail(formData, false)} />
                    </div>
                  </div>
                </>
              )
              :
              showProductOrders ?
                (
                  <ShowProductOrders />
                )
                :
                (
                  <>
                    <div className="flex w-full overflow-auto bg-gray-50">
                      <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                        <div className="flex justify-between items-center">
                          <div className="text-lg font-bold">{productFormConfig.product.title}</div>
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={handleShowForm}
                            className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                          >
                            {productFormConfig.product.add}
                          </Button>
                        </div>
                        <Bread title={productFormConfig.product.title} path="product" page={productFormConfig.product.add} />
                        <Statistics data={statisticsConfig?.statistics?.product} type="product" />
                        <Table
                          columns={columns({
                            setShowProductOrders,
                            setSelectedProduct,
                            products,
                          })}
                          dataSource={products}
                          loading={loading}
                          pagination={{
                            pageSize: 10,
                            className: "sticky bottom-0 pb-2",
                          }}
                          onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                            className: record.key === (selectedProduct?.key ?? '') ? "bg-gray-100" : "",
                          })}
                          rowClassName={(record) => `cursor-pointer ${record.key === (selectedProduct?.key ?? '') ? "bg-gray-100" : ""}`}
                          scroll={{ x: 2000 }}
                          size="small"
                          className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
                        />
                      </div>
                      <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                        <Detail
                          data={selectedProduct}
                          type="product"
                          items={Array.isArray(productConfig?.detail?.(selectedProduct, 'product')) ? productConfig?.detail?.(selectedProduct, 'product') : []}
                        />
                      </div>
                    </div>
                  </>
                )
          }
        </div>
      </div>
    </div>
  );
};
