import React from 'react';
import { Tabs } from 'antd';

const DemandDetail = ({ service, emptyMessage }) => {

  if (!service) {
    return (
      <div className="w-full flex items-center justify-center h-[calc(100vh-64px-32px)] bg-gray-50">
        <div className="flex flex-col items-center justify-center">
          <p className="text-gray-500 text-center">{emptyMessage}</p>
        </div>
      </div>
    );
  }

  const items = [
    {
      key: "1",
      label: "需求單詳細資料",
      children: (
        <div className="p-6">
          <div className="space-y-6">
            {/* 親友基本資料 */}
            <div className="bg-[#F7F7F7] p-4 rounded-lg shadow-sm">
              <h3 className="text-base text-black font-bold border-b border-gray-200 pb-3 text-center mb-3">基本資訊</h3>
              <div className="space-y-3">
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">親友姓名：</div>
                  <div className="text-left">{service.information?.basic?.name || '未設定'}</div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">性別：</div>
                  <div className="text-left">{service.information?.basic?.gender === 'male' ? '男' : service.information?.basic?.gender === 'female' ? '女' : '未設定'}</div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">親友出生年月日：</div>
                  <div className="text-left">{service.information?.basic?.birthday || '未設定'}</div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">家屬姓名（聯絡人）：</div>
                  <div className="text-left">{service.information?.basic?.contact_name || '未設定'}</div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">家屬聯絡電話：</div>
                  <div className="text-left">{service.information?.basic?.phone || '未設定'}</div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">禮儀業者：</div>
                  <div className="text-left">{service.information?.basic?.funeral || '未設定'}</div>
                </div>
              </div>
            </div>

            {/* 祭禮 */}
            <div className="bg-[#F7F7F7] p-4 rounded-lg shadow-sm">
              <h3 className="text-base text-black font-bold border-b border-gray-200 pb-3 text-center mb-3">祭禮</h3>
              <div className="space-y-3">
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">公祭台詞</div>
                  <div>
                    <div className="text-left text-[12px] leading-[18.2px] text-gray-500">台詞</div>
                    <div className="text-left">{service.information?.ceremony?.public_sacrifice || '未設定'}</div>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">家祭台詞</div>
                  <div>
                    <div className="text-left text-[12px] leading-[18.2px] text-gray-500">台詞</div>
                    <div className="text-left">{service.information?.ceremony?.family_sacrifice || '未設定'}</div>
                  </div>
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">遠行台詞</div>
                  <div>
                    <div className="text-left text-[12px] leading-[18.2px] text-gray-500">台詞</div>
                    <div className="text-left">{service.information?.ceremony?.travel || '未設定'}</div>
                    <div className="text-left text-[12px] leading-[18.2px] text-gray-500 mt-2">動作</div>
                    <div className="text-left">{service.information?.ceremony?.action === 'sit' ? '坐下' : service.information?.ceremony?.action === 'stand' ? '站著' : service.information?.ceremony?.action === 'turn' ? '轉身' : service.information?.ceremony?.action === 'bow' ? '鞠躬' : service.information?.ceremony?.action === 'worship' ? '膜拜' : service.information?.ceremony?.action === 'other' ? '其他' : '未設定'}</div>
                  </div>
                </div>
              </div>
            </div>


            {/* 檔案 */}
            <div className="bg-[#F7F7F7] p-4 rounded-lg shadow-sm">
              <h3 className="text-base text-black font-bold border-b border-gray-200 pb-3 text-center mb-3">檔案</h3>
              {console.log('service:', service)}
              {console.log('service.blobs:', service.blobs)}
              <div className="space-y-3">
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">圖片檔案</div>
                  {console.log('圖片檔案:', service.blobs?.filter(blob => blob.content_type.includes('image')))}
                  {service.blobs?.filter(blob => blob.content_type.includes('image'))?.map((image, index) => (
                    <img key={image.id} src={image.url} alt={`圖片檔案 ${index + 1}`} className="w-full h-auto" />
                  ))}
                  {!service.blobs?.some(blob => blob.content_type.includes('image')) && <div className="text-left">未設定</div>}
                </div>
                <div className="flex flex-col space-y-2">
                  <div className="text-black font-bold font-inter text-[14px] leading-[18.2px] text-left decoration-1 underline-offset-[from-font] [text-decoration-skip-ink:none]">聲音檔案</div>
                  {console.log('音訊檔案:', service.blobs?.filter(blob => blob.content_type.includes('audio')))}
                  {service.blobs?.filter(blob => blob.content_type.includes('audio'))?.map((audio, index) => (
                    <audio key={audio.id} controls className="w-full">
                      <source src={audio.url} type={audio.content_type} />
                      您的瀏覽器不支援音訊播放
                    </audio>
                  ))}
                  {!service.blobs?.some(blob => blob.content_type.includes('audio')) && <div className="text-left">未設定</div>}
                </div>
              </div>
            </div>

          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col h-full bg-white overflow-auto">
      <Tabs defaultActiveKey="1" items={items} className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4" />
    </div>
  );
};

export default DemandDetail;
