/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { message } from "antd";
import axios from "axios";
import { useAuth } from "@/contexts/AuthProvider";
import MemoryModal from "./MemoryModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchTemplate } from "@/service/memoryService";
import { Advanced } from '@/app/chat/components/memory/components/Advanced';
import { General } from '@/app/chat/components/memory/components/General';
import { Qa } from '@/app/chat/components/memory/components/Qa';

import TemplateEditor from "./components/TemplateEditor";
import Record from "./components/Record";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Memory = ({ room }) => {
  const dispatch = useDispatch();
  const template = useSelector((state) => state.memory.template);
  const { token } = useAuth();
  const [text, setText] = useState("");
  const [url, setUrl] = useState("");
  const [blobs, setBlobs] = useState([]);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [memory, setMemory] = useState([]);
  const [depth, setDepth] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalUrl, setModalUrl] = useState("");
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [selectedButton, setSelectedButton] = useState("general");
  const [showTemplateEditor, setShowTemplateEditor] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);

// Fetch data
  useEffect(() => {
    reset();
    setShowTemplateEditor(false);
    setShowRecord(false);
    handleFetchTemplate();
    handleFetchMemory();
  }, [room, selectedButton]);

  const reset = () => {
    setText("");
    setUrl("");
    setBlobs([]);
    setDepth(1);
  };
  const handleFetchTemplate = async () => {
    setFetching(true);
    try {
        await dispatch(fetchTemplate({ room_id:room.id}));
    } finally {
        setFetching(false);
    }
};
  const handleClickUploadMore = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setBlobs([...blobs, ...Array.from(e.target.files)]);
  };

  const removeFile = (index) => {
    setBlobs(blobs.filter((_, i) => i !== index));
  };

  const props = {
    name: "file",
    multiple: true,
    onChange: (e) => {
      setBlobs([...blobs, ...e.fileList.map((file) => file.originFileObj)]);
    },
    onDrop: (e) => {
      setBlobs([...blobs, ...Array.from(e.dataTransfer.files)]);
    },
  };

  const handleFetchMemory = async () => {
    setFetching(true); // Assuming setFetching updates a state to show loading status
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/chatrooms/vector_store/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Use Promise.all to handle multiple fetches concurrently
      const fetchTextFiles = response.data.map(async (item) => {
        if (item.blob && item.blob.content_type.includes("text/plain")) {
          try {
            const textResponse = await axios.get(item.blob.url);
            const content =
              typeof textResponse.data === "string"
                ? textResponse.data
                : JSON.stringify(textResponse.data);
            const filename = content.substring(0, 50);
            return {
              ...item,
              blob: { ...item.blob, content: content, filename: filename },
            }; // storing the fetched data in 'content'
          } catch (error) {
            // message.error(JSON.stringify(error));
            // console.log(error);
            return item; // return the item unchanged if there was an error
          }
        }
        return item;
      });

      const updatedData = await Promise.all(fetchTextFiles);
      setMemory(updatedData); // Assuming setMemory updates the state with the new data
    } catch (error) {
      message.error(JSON.stringify(error));
    }
    setFetching(false); // Ensure fetching is set to false after operations complete
  };

  const handlePostText = async () => {
    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/text/" + room.id,
        {
          text: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  const handlePostUrl = async () => {
    try {
      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/url/" + room.id,
        {
          url: url,
          depth: depth,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  const handlePostFiles = async () => {
    try {
      const formData = new FormData();
      blobs.forEach((blob) => {
        formData.append("files", blob);
      });

      await axios.post(
        SERVER_URL + "/private/chatrooms/vector_store/file/" + room.id,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      message.error(JSON.stringify(error));
    }
  };

  const handleMemorize = async () => {
    setUploading(true);
    if (text.length > 0) {
      await handlePostText();
    }
    if (url.length > 0) {
      await handlePostUrl();
    }
    if (blobs.length > 0) {
      await handlePostFiles();
    }
    reset();
    handleFetchMemory();
    setUploading(false);
    message.open({
      type: "success",
      content: "記憶成功送出！若檔案類型支援，稍後重整即可在右側查看",
      duration: 5,
    });
  };

  const deleteMemory = async (recordId) => {
    setFetching(true);
    try {
      await axios.delete(
        SERVER_URL +
        "/private/chatrooms/vector_store/" +
        room.id +
        "?record_id=" +
        recordId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.error('刪除成功');
    } catch (error) {
      message.error(JSON.stringify(error));
    }
    handleFetchMemory();
    handleFetchTemplate();
    setFetching(false);
  };

  const handleOpenModal = (type, url, id) => {
    setCurrentRecordId(id);
    if (type === "url") {
      window.open(url, "_blank");
      return;
    }
    if (type.includes("text/plain")) {
      setModalUrl(url);
      setOpenModal(true);
      return;
    }
    window.open(url, "_blank");
    return;
  };

  const formatFileName = (item) => {
    if (item.blob.content_type === "webpage") {
      return item.blob.url;
    }
    if (item.blob.filename) {
      return item.blob.filename;
    }
    return item.blob.id;
  };

  const formatFileFormat = (type) => {
    if (type === "webpage") return "網頁存擋";
    if (type.includes("text/plain")) return "文字檔";
    return "檔案";
  };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    else if (size >= 1024 && size < 1048576)
      return (size / 1024).toFixed(1) + " KB";
    else return (size / 1048576).toFixed(1) + " MB";
  };

  const handleButtonClick = (buttonType) => {
    setSelectedButton(buttonType);
  };
    
  return (
    <>
      <MemoryModal
        token={token}
        recordId={currentRecordId}
        roomId={room.id}
        url={modalUrl}
        open={openModal}
        onCancel={() => setOpenModal(false)}
      />
      <div
        className="flex flex-col h-full overflow-scroll"
        style={{ width: "calc(100vw - 620px)" }}
      >
        <div>
          {selectedButton === "general" ? (
            <General
              handleButtonClick={handleButtonClick}
              selectedButton={selectedButton}
              uploading={uploading}
              text={text}
              setText={setText}
              blobs={blobs}
              formatFileSize={formatFileSize}
              removeFile={removeFile}
              handleFileChange={handleFileChange}
              fileInputRef={fileInputRef}
              handleClickUploadMore={handleClickUploadMore}
              props={props}
              url={url}
              setUrl={setUrl}
              depth={depth}
              setDepth={setDepth}
              reset={reset}
              handleMemorize={handleMemorize}
              fetching={fetching}
              memory={memory}
              handleOpenModal={handleOpenModal}
              formatFileName={formatFileName}
              formatFileFormat={formatFileFormat}
              deleteMemory={deleteMemory}
              handleFetchMemory={handleFetchMemory}
            />
          ) : selectedButton === "advanced" ? (
            <>
              {showTemplateEditor ? (
                <TemplateEditor
                  setShowTemplateEditor={setShowTemplateEditor}
                  handleButtonClick={handleButtonClick}
                  selectedButton={selectedButton}
                  templateData={selectedTemplate}
                  templateIndex={selectedTemplateIndex}
                  setSelectedTemplate={setSelectedTemplate}
                  room={room}
                />
              ) : showRecord ? (
                <Record
                handleButtonClick={handleButtonClick}
                selectedButton={selectedButton}
                fetching={fetching}
                template={template}
                setSelectedTemplate={setSelectedTemplate}
                setSelectedTemplateIndex={setSelectedTemplateIndex}
                setShowTemplateEditor={setShowTemplateEditor}
                handleFetchTemplate={handleFetchTemplate}
                setShowRecord={setShowRecord}
                memory={memory}
                handleOpenModal={handleOpenModal}
                deleteMemory={deleteMemory}
                formatFileName={formatFileName}
                formatFileFormat={formatFileFormat}
                fetchTemplate={fetchTemplate}
                setFetching={setFetching}
                room={room}
                token={token}
                dispatch={dispatch}
                templateData={selectedTemplate}
                />
              ) : (
                <Advanced
                  handleButtonClick={handleButtonClick}
                  selectedButton={selectedButton}
                  fetching={fetching}
                  template={template}
                  setSelectedTemplate={setSelectedTemplate}
                  setSelectedTemplateIndex={setSelectedTemplateIndex}
                  setShowTemplateEditor={setShowTemplateEditor}
                  handleFetchMemory={handleFetchMemory}
                  setShowRecord={setShowRecord}
                  memory={memory}
                  handleOpenModal={handleOpenModal}
                  deleteMemory={deleteMemory}
                  formatFileName={formatFileName}
                  formatFileFormat={formatFileFormat}
                  fetchTemplate={fetchTemplate}
                  setFetching={setFetching}
                  room={room}
                  token={token}
                  dispatch={dispatch}
                  handleFetchTemplate={handleFetchTemplate}
                />
              )}
            </>
          ) : (
            <Qa
              handleButtonClick={handleButtonClick}
              selectedButton={selectedButton}
              chatroomId={room.id}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Memory;
