/* eslint-disable react-hooks/exhaustive-deps */
import { ENV } from '@/constants/env';
import { Input, Select, DatePicker, InputNumber, message, Form, Row, Col, Divider, Space, TimePicker } from 'antd';
import { useState, useEffect } from 'react';
import { fetchCategoriesAPI, fetchOrganizationsAPI, createServiceAPI, createSpecAPI, createDiscountAPI, createProviderAPI, fetchIdentityListAPI, updateProductImagesAPI, createTicketAPI } from '@/app/ams/services/api';
import { useAmsContext } from '@/app/ams/context/AmsContext';
import { ticketConfig } from '@/app/ams/config/ticket/ticketConfig';
import BannerUpload from '@/app/ams/components/BannerUpload';
import TagSelector from '@/app/ams/components/TagSelector';

// useAmsContext 
const BeauticianSelect = ({ value, onChange }) => {
    const { identityList } = useAmsContext();

    // 將 identityList 轉換為包含 providers 的選項
    const options = identityList.reduce((acc, identity) => {
        if (identity.providers && identity.providers.length > 0) {
            // 為每個 provider 創建一個選項
            const providerOptions = identity.providers.map(provider => ({
                label: `${identity.name}-${provider.last_name || provider.first_name || provider.nickname || provider.email}`,
                value: JSON.stringify({
                    provider_id: provider.id,
                    identity_id: identity.id
                })
            }));
            return [...acc, ...providerOptions];
        }
        return acc;
    }, []);

    // 處理值的轉換
    const handleChange = (selectedValues) => {
        const formattedValues = selectedValues.map(value => JSON.parse(value));
        onChange(formattedValues);
    };

    // 將 value 轉換回 Select 組件需要的格式
    const formattedValue = value ? value.map(item =>
        JSON.stringify({
            provider_id: item.provider_id,
            identity_id: item.identity_id
        })
    ) : [];

    return (
        <Select
            mode="multiple"
            value={formattedValue}
            onChange={handleChange}
            options={options}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

// fetch categories
const CategorySelect = ({ value, onChange, placeholder }) => {
    const { categories, setCategories } = useAmsContext();

    useEffect(() => {
        let cats = [];
        const fetchCategories = async () => {
            const type = 'free_ticket';
            cats = await fetchCategoriesAPI(type);
            setCategories(cats);
        };
        fetchCategories();
    }, []);

    return (
        <Select
            value={value?.name}
            placeholder={placeholder}
            onChange={(_, option) => {
                onChange({
                    id: option.data.id,
                    name: option.data.name,
                    product_type: option.data.product_type
                });
            }}
            options={categories.map(category => ({
                value: category.id,
                label: category.name,
                data: category
            }))}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
        />
    );
};

// fetch organizations
const OrganizationSelect = (props) => {
    const { value, ...restProps } = props;
    const form = Form.useFormInstance();
    const {
        organizations,
        setOrganizations,
        setIdentityList
    } = useAmsContext();


    useEffect(() => {
        const initOrganizations = async () => {
            try {
                const orgs = await fetchOrganizationsAPI();
                console.log('orgs', orgs);
                setOrganizations(orgs);
            } catch (error) {
                console.error('獲取店家列表失敗:', error);
                message.error({
                    content: '獲取店家列表失敗',
                    className: 'custom-message'
                });
            }
        };
        initOrganizations();
    }, []);

    const handleChange = async (selectedValue) => {
        try {
            // 直接使用 form.setFieldValue 來更新表單值
            form.setFieldValue(['basic', 'organization'], selectedValue);

            if (selectedValue) {
                const res = await fetchIdentityListAPI(selectedValue);
                if (res) {
                    setIdentityList(res);
                }
            } else {
                setIdentityList([]);
            }
        } catch (error) {
            console.error('獲取美容師失敗:', error);
            message.error({
                content: '獲取美容師列表失敗',
                className: 'custom-message'
            });
        }
    };

    return (
        <Select
            {...restProps}
            value={value}
            onChange={handleChange}
            options={(Array.isArray(organizations) ? organizations : []).map(organization => ({
                label: organization.name,
                value: organization.id
            }))}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
        />
    );
};

// DiscountInput
const DiscountInput = ({ name, onChange }) => {
    const form = Form.useFormInstance();

    return (
        <Space.Compact>
            <InputNumber
                style={{ width: '120px' }}
                min={1}
                max={9}
                onChange={(value) => {
                    const percentOff = value ? (10 - value) * 10 : 0;
                    form.setFieldValue([...name, 'percentOff'], percentOff);
                    onChange?.(value);
                }}
            />
            <span
                style={{
                    display: 'inline-block',
                    lineHeight: '32px',
                    padding: '0 8px',
                    color: '#595959'
                }}
            >
                =
            </span>
            <Form.Item
                name={[...name, 'percentOff']}
                noStyle
            >
                <InputNumber
                    style={{ width: '120px' }}
                    disabled
                    formatter={value => `${value}`}
                />
            </Form.Item>
            <span
                style={{
                    display: 'inline-block',
                    lineHeight: '32px',
                    padding: '0 8px',
                    color: '#595959'
                }}
            >
                % off
            </span>
        </Space.Compact>
    );
};

const _77Config = {
    tabs: [
        { key: 'basic', label: '基本資訊' },
        { key: 'spec', label: '票券設定' },
        { key: 'discount', label: '折扣設定' },
        { key: 'publish', label: '票券上架' }
    ],
    ticket: {
        title: '場館管理',
        add: '新增場館',
        draft: '儲存至草稿夾',
        publish: '儲存並發布'
    },
    formItems: {
        basic: [
            {
                name: ['basic', 'organization'],
                label: '選擇店家',
                type: 'select',
                required: true,
                component: OrganizationSelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: '請選擇店家' }],
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇店家'
                }
            },
            {
                name: 'category',
                label: '選擇類別',
                type: 'select',
                required: true,
                component: CategorySelect,
                col: 12,
                labelCol: { span: 24 },
                wrapperCol: { span: 8 },
                rules: [{ required: true, message: '請選擇類別' }],
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇類別'
                }
            },
            {
                name: 'name',
                label: '名稱',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入服務名稱'
                },
                rules: [
                    { required: true, message: '請輸入服務名稱' },
                    { max: 128, message: '最多128個字元' }
                ]
            },
            {
                name: 'serviceCode',
                label: '序號',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: '系統自動填入',
                options: {
                    disabled: true,
                    placeholder: '系統自動填入',
                    style: {
                        background: '#bfbfbf'
                    }
                }
            },
            {
                name: 'tags',
                label: '標籤',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
            },
            {
                name: 'serviceImage',
                label: '主要圖片',
                type: 'custom',
                required: true,
                rules: [{ required: true, message: '請上傳主要圖片' }],
                component: BannerUpload,
                col: 24,
            },
            {
                name: 'serviceDescription',
                label: '內容說明',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                rules: [
                    { required: true, message: '請輸入內容說明' }
                ],
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入內容說明',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceNotice',
                label: '注意事項說明',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                rules: [
                    { required: true, message: '請輸入注意事項說明' }
                ],
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入注意事項說明',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'servicePeriod',
                col: 24,
                type: 'custom',
                required: true,
                component: () => (
                    <Form.Item
                        className="custom-label-flex"
                        label={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                <span>使用期限</span>
                                <Form.Item
                                    name={['servicePeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    {/* <Checkbox>無期限</Checkbox> */}
                                </Form.Item>
                            </div>
                        }
                        labelCol={{
                            style: {
                                width: '100%'
                            }
                        }}
                        wrapperCol={{
                            span: 6
                        }}
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <Form.Item
                                    name={['servicePeriod', 'dateRange']}
                                    rules={[
                                        {
                                            required: !getFieldValue(['servicePeriod', 'noLimit']),
                                            message: '請選擇使用期限'
                                        }
                                    ]}
                                    noStyle
                                >
                                    <DatePicker.RangePicker
                                        style={{ width: '100%' }}
                                        placeholder={['開始日期', '結束日期']}
                                        disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                        allowEmpty={[true, true]}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        spec: [
            {
                name: 'exchangeAmount',
                label: '兌換金額',
                type: 'number',
                required: true,
                component: InputNumber,
                style: {
                    width: '30%'
                },
                col: 12,
            },
            {
                name: 'threshold',
                label: '使用門檻',
                type: 'text',
                required: true,
                component: Input,
                style: {
                    width: '30%'
                },
                col: 12,
            }
        ],
        discount: [
            {
                name: 'discountContainer',
                label: '',
                type: 'custom',
                component: () => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['discount', 'type']}
                                    rules={[
                                        {
                                            required: true,
                                            message: '請選擇折扣'
                                        }
                                    ]}
                                    label="選擇折扣"
                                >
                                    <Select
                                        placeholder="選擇折扣"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: '無折扣', value: 'none' },
                                            { label: '會員折扣', value: 'member' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['discount', 'value']}
                                    label="折扣類型"
                                    rules={[
                                        {
                                            required: ({ getFieldValue }) => getFieldValue(['discount', 'type']) === 'member',
                                            message: '請輸入折扣值'
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (value && (value < 1 || value > 9)) {
                                                    return Promise.reject('折扣必須在1到9之間');
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <DiscountInput name={['discount']} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={['discount', 'period']}
                                    className="custom-label-flex"
                                    rules={[
                                        {
                                            required: true,
                                            message: '請選擇折扣期限'
                                        }
                                    ]}
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                            <span>折扣期限</span>
                                            <Form.Item
                                                name={['discount', 'noLimit']}
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                {/* <Checkbox>無期限</Checkbox> */}
                                            </Form.Item>
                                        </div>
                                    }
                                    labelCol={{
                                        style: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues?.discount?.noLimit !== currentValues?.discount?.noLimit;
                                        }}
                                    >
                                        {({ getFieldValue, setFieldsValue }) => (
                                            <DatePicker.RangePicker
                                                style={{ width: '100%' }}
                                                placeholder={['開始日期', '結束日期']}
                                                disabled={getFieldValue(['discount', 'noLimit'])}
                                                allowEmpty={[true, true]}
                                                value={getFieldValue(['discount', 'period'])}
                                                onChange={(dates) => {
                                                    setFieldsValue({
                                                        discount: {
                                                            ...getFieldValue('discount'),
                                                            period: dates
                                                        }
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )
            },
            {
                name: 'lateDiscountContainer',
                label: '',
                type: 'custom',
                component: () => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['lateDiscount', 'type']}
                                    rules={[
                                        {
                                            required: true,
                                            message: '請選擇晚鳥折扣'
                                        }
                                    ]}
                                    label="選擇晚鳥折扣"
                                >
                                    <Select
                                        placeholder="選擇晚鳥折扣"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: '無折扣', value: 'none' },
                                            { label: '晚鳥折扣', value: 'late' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['lateDiscount', 'value']}
                                    label="折扣類型"
                                    rules={[
                                        {
                                            required: ({ getFieldValue }) => getFieldValue(['lateDiscount', 'type']) === 'late',
                                            message: '請輸入晚鳥折扣值'
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (value && (value < 1 || value > 9)) {
                                                    return Promise.reject('折扣必須在1到9之間');
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <DiscountInput name={['lateDiscount']} />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={['lateDiscount', 'period']}
                                    className="custom-label-flex"
                                    rules={[
                                        {
                                            required: true,
                                            message: '請選擇晚鳥折扣期限'
                                        }
                                    ]}
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                            <span>晚鳥折扣期限</span>
                                            <Form.Item
                                                name={['lateDiscount', 'noLimit']}
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                {/* <Checkbox>無期限</Checkbox> */}
                                            </Form.Item>
                                        </div>
                                    }
                                    labelCol={{
                                        style: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues?.lateDiscount?.noLimit !== currentValues?.lateDiscount?.noLimit;
                                        }}
                                    >
                                        {({ getFieldValue, setFieldsValue }) => (
                                            <DatePicker.RangePicker
                                                style={{ width: '100%' }}
                                                placeholder={['開始日期', '結束日期']}
                                                disabled={getFieldValue(['lateDiscount', 'noLimit'])}
                                                value={getFieldValue(['lateDiscount', 'period'])}
                                                allowEmpty={[true, true]}
                                                onChange={(dates) => {
                                                    setFieldsValue({
                                                        lateDiscount: {
                                                            ...getFieldValue('lateDiscount'),
                                                            period: dates
                                                        }
                                                    });
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                )
            },
            {
                name: 'profitShare',
                label: '利潤分潤（單位：%）',
                type: 'number',
                required: true,
                component: InputNumber,
                style: {
                    width: '100%'
                },
                col: 12,
                options: {
                    min: 0,
                    max: 100,
                    formatter: value => `${value}%`,
                    parser: value => value.replace('%', '')
                }
            },
            {
                name: 'bonusPoints',
                label: '紅利點數（單位：點）',
                type: 'number',
                required: true,
                component: InputNumber,
                style: {
                    width: '100%'
                },
                col: 12,
                options: {
                    min: 0,
                    formatter: value => `${value} Point`,
                    parser: value => value.replace('Point', '')
                }
            },
            {
                name: 'discountDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            }
        ],
        publish: [
            {
                name: 'recommendContainer',
                label: '',
                type: 'custom',
                component: ({ children }) => (
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    name={['recommend', 'type']}
                                    label="推薦服務"
                                >
                                    <Select
                                        placeholder="選擇類別"
                                        style={{ width: '100%' }}
                                        options={[
                                            { label: '活動', value: 'event' },
                                            { label: '熱門', value: 'popular' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={['recommend', 'text']}
                                    label="顯示文字"
                                >
                                    <Input placeholder="熱門" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={['recommend', 'period']}
                                    className="custom-label-flex"
                                    label={
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%'
                                        }}>
                                            <span>期限</span>
                                            <Form.Item
                                                name={['recommend', 'noLimit']}
                                                valuePropName="checked"
                                                noStyle
                                            >
                                                {/* <Checkbox>無期限</Checkbox> */}
                                            </Form.Item>
                                        </div>
                                    }
                                    labelCol={{
                                        style: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => {
                                            return prevValues?.recommend?.noLimit !== currentValues?.recommend?.noLimit;
                                        }}
                                    >
                                        {({ getFieldValue }) => (
                                            <DatePicker.RangePicker
                                                style={{ width: '100%' }}
                                                placeholder={['開始日期', '結束日期']}
                                                allowEmpty={[true, true]}
                                                disabled={getFieldValue(['recommend', 'noLimit'])}
                                            />
                                        )}
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                ),
                col: 24
            },
            {
                name: 'beauticians',
                label: '分配可進行美業老師',
                type: 'select',
                required: true,
                component: BeauticianSelect,
                col: 12,
                rules: [
                    { required: true, message: '請選擇至少一位美容師' }
                ],
                options: {
                    mode: 'multiple',
                    placeholder: '選擇美業老師',
                }
            },
            {
                name: 'scheduleContainer',
                label: 'Schedule',
                type: 'custom',
                component: () => (
                    <Row>
                        <Col span={8}>
                            <div className="flex items-center bg-white gap-4">
                                <DatePicker
                                    placeholder="Select Date"
                                />
                                <TimePicker
                                    placeholder="Select Time"
                                    format="HH:mm"
                                />
                            </div>
                        </Col>
                    </Row>
                ),
                col: 24
            },
            {
                name: 'spacer',
                label: ' ',
                type: 'custom',
                component: () => <div className="h-[500px]" />,
            }
        ]
    }
};

// form scroll active tab
const useFormScroll = (formRef) => {
    const [activeTab, setActiveTab] = useState('basic');

    useEffect(() => {
        const handleScroll = () => {
            if (!formRef.current) return;

            const container = formRef.current.closest('.overflow-auto');
            if (!container) return;

            const containerTop = container.getBoundingClientRect().top;
            const offset = 200; // 整這個值來改變切換的觸發位置

            const tabSections = _77Config.tabs.map(tab => {
                const element = document.getElementById(tab.key);
                if (!element) return { key: tab.key, top: Infinity };
                const rect = element.getBoundingClientRect();
                // 計算相對於容器的位置
                const relativeTop = rect.top - containerTop;
                return {
                    key: tab.key,
                    top: relativeTop
                };
            }).filter(section => section.top !== Infinity);

            // 找到最近但還未超過觸發位置的section
            const currentSection = tabSections.reduce((prev, curr) => {
                if (curr.top <= offset && curr.top > prev.top) {
                    return curr;
                }
                return prev;
            }, { key: tabSections[0].key, top: -Infinity });

            if (currentSection && currentSection.key !== activeTab) {
                setActiveTab(currentSection.key);
            }
        };

        const container = formRef.current?.closest('.overflow-auto');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [formRef, activeTab]);

    return activeTab;
};

// 新增表單邏輯處理
const formLogic = {
    handleSubmit: async (values, setLoading) => {
        setLoading(true);
        try {
            const specs = [];
            const discounts = [];

            console.log('表單原始資料', values);

            // 處理折扣數據
            const discountData = [
                {
                    name: values.discount?.type === 'member' ? '會員折扣' : '不使用會員折扣',
                    description: values.discount?.type === 'member' ? '會員折扣' : '不使用會員折扣',
                    discount: values.discount?.type === 'member' ? 0 : 0,
                    discount_percentage: values.discount?.type === 'member' ? values.discount?.percentOff : 0,
                    level: values.discount?.type === 'member' ? 0 : '',
                    valid_from: values.discount?.noLimit ? null : values.discount?.period?.[0]?.format('YYYY-MM-DD') || null,
                    valid_until: values.discount?.noLimit ? null : values.discount?.period?.[1]?.format('YYYY-MM-DD') || null,
                },
                {
                    name: values.lateDiscount?.type === 'late' ? '晚鳥折扣' : '不使用晚鳥折扣',
                    description: values.lateDiscount?.type === 'late' ? '晚鳥折扣' : '不使用晚鳥折扣',
                    discount: values.lateDiscount?.type === 'late' ? 0 : 0,
                    discount_percentage: values.lateDiscount?.type === 'late' ? values.lateDiscount?.percentOff : 0,
                    level: values.lateDiscount?.type === 'late' ? 0 : 0,
                    valid_from: values.lateDiscount?.noLimit ? null : values.lateDiscount?.period?.[0]?.format('YYYY-MM-DD') || null,
                    valid_until: values.lateDiscount?.noLimit ? null : values.lateDiscount?.period?.[1]?.format('YYYY-MM-DD') || null,
                },
            ];

            // 構建 blobData
            const blobData = values.serviceImage ? values.serviceImage.blobs.map((blob, index) => ({
                preview: values.serviceImage.preview[index],
                blob: blob
            })) : [];

            // 構建 formattedData
            const formattedData = {
                name: values.name || '',
                product_type: values.category?.product_type || '',
                description: values.serviceDescription || '',
                notification: values.serviceNotice || '',
                category_id: values.category?.id || '',
                identity_ids: values.beauticians?.map(item => item.identity_id) || [],
                tags: values.serviceTags?.map(tag => ({
                    id: tag.id,
                    name: tag.name
                })) || [],
                valid_from: values.servicePeriod?.noLimit ? null : values.servicePeriod?.dateRange?.[0]?.format('YYYY-MM-DD') || null,
                valid_until: values.servicePeriod?.noLimit ? null : values.servicePeriod?.dateRange?.[1]?.format('YYYY-MM-DD') || null,
                specs: specs,
                discounts: discounts,
            };
            // 處理規格金額資料
            const spec = {
                information: {
                    threshold: values.threshold || "",
                    exchangeAmount: values.exchangeAmount || 0,
                    discount: false,
                    late_discount: false,
                },
                name: '票券設定',
                description: "票券設定描述",
                price: values.price || 0,
                cost: 0,
                discount_enabled: false,
                stock: values.stock === 'limited' ? (values.stockAmount || 0) : 0,
                unlimited_stock: values.stock === 'unlimited'
            };
            specs.push(spec);

            console.log('有specs:', specs);

            // 將規格資料加入 formattedData
            formattedData.specs = specs;

            // 處理折扣資料...
            formattedData.discounts = discountData;

            console.log('表單提交數據:', formattedData);
            // API 調用邏輯...

            const res = await createServiceAPI(formattedData, values.basic.organization);

            if (res.status === 200) {
                const { data } = res;
                const id = data.id;

                if (blobData.length > 0) {
                    await updateProductImagesAPI(id, {
                        imagesToAdd: blobData.map(item => item.blob),
                        imagesToRemove: []
                    });
                }

                // create specs
                try {
                    const res = await createSpecAPI(id, spec);
                    
                    if (res && res.specs && res.specs[0]) {
                        await createTicketAPI(id, res.specs[0].id);
                    } else {
                        throw new Error('無效的規格資料');
                    }

                } catch (error) {
                    console.error('規格創建失敗:', error);
                    message.error('部分規格創建失敗');
                }

                try {
                    for (const discount of discountData) {
                        await createDiscountAPI(id, discount);
                    }

                    console.log('beauticians:', values.beauticians);

                    // 增加防護檢查
                    if (values.beauticians && Array.isArray(values.beauticians)) {
                        for (const beautician of values.beauticians) {
                            if (beautician && beautician.identity_id) {
                                await createProviderAPI(id, beautician.identity_id, beautician.provider_id);
                            }
                        }
                    }


                    setLoading(false);
                    message.success(ticketConfig.message.success);

                    // 使用 setTimeout 延遲 1.5 秒後跳轉
                    setTimeout(() => {
                        window.location.href = '/ticket';
                    }, 1500);
                } catch (error) {
                    console.error('折扣或美容師創建失敗:', error);
                    message.error(ticketConfig.message.error);
                    window.location.href = '/ticket';
                }
            } else {
                message.error(ticketConfig.message.error);
                window.location.href = '/ticket';
            }

        } catch (error) {
            console.error('表單提交失敗:', error);
            message.error(ticketConfig.message.error);
            window.location.href = '/ticket';
        }
    },

    scrollToTab: (key, formRef) => {
        const element = document.getElementById(key);
        if (element) {
            const container = formRef.current.closest('.overflow-auto');
            if (container) {
                container.scrollTo({
                    top: element.offsetTop - 300,
                    behavior: 'smooth'
                });
            }
        }
    }
};

export { useFormScroll, formLogic };

// 根據環境變數選擇配置
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const venueFormConfig = is77 ? _77Config : null;
