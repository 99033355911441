/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Tabs, Tooltip } from "antd";
import BoundListItem from "./BoundListItem";
import { useBound } from "@/contexts/BoundContext";
import { LoadingOutlined, SwapOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const BoundList = ({
  activeId,
  setActiveId,
  tabs,
  activeTab,
  onChangeTab,
  switchTo,
  mqttConnection,
  newMessageClientList,
  refresh,
  clients,
  setClientsAirbnb,
  setClientsAgoda,
  setClients,
  roomId,
}) => {
  const { airbnbMessage, agodaMessage, listLoading, setListLoading } = useBound();

  useEffect(() => {
    if (activeTab === 0) {
      if (agodaMessage && agodaMessage.length > 0) {
        setClientsAgoda([]);
        const res = processOneAgodaMessage(agodaMessage, roomId);
        setClientsAgoda(res);
        setListLoading(false);
      }
    } else if (activeTab === 1) {
      if (clients && clients.length > 0) {
        setClients([]);
        setClients(clients);
        setListLoading(false);
      }
    }
  }, [airbnbMessage, agodaMessage, activeTab]);

  const processOneAirbnbMessage = (data, roomId) => {
    const rawDataArray = Array.isArray(data) ? data : [data];
    let processedMessages = [];

    rawDataArray.forEach((rawData) => {      
      if (rawData && rawData.result && Array.isArray(rawData.result) && rawData.result.length > 0) {
        const messages = rawData.result;
        const senderWithReservation = messages.find(
          (message) => message.sender && message.sender.includes("預訂人")
        );

        const displayName = senderWithReservation
          ? senderWithReservation.sender
          : " · 預訂人";
        const pictureUrl = senderWithReservation
          ? senderWithReservation.sender_photo_url
          : null;

        let lastMessage = null;
        for (let i = messages.length - 1; i >= 0; i--) {
          if (messages[i].content) {
            lastMessage = messages[i];
            break;
          }
        }

        if (!lastMessage) {
          lastMessage = {
            content: "No messages",
            date: "",
            time: "",
          };
        }

        const processedMessage = {
          userId: rawData.message_id || null,
          messageId: rawData.message_id || null,
          roomId: roomId || null,
          displayName: displayName || null,
          pictureUrl: pictureUrl || null,
          channel: "airbnb",
          last_two_messages: [
            {
              created_at: lastMessage.time || null,
              message: lastMessage.content || null,
            },
          ],
          unreadCount: 1,
        };

        processedMessages.push(processedMessage);
      }
    });

    return processedMessages;
  };

  const processOneAgodaMessage = (data, roomId, guestName) => {
    // check if data is an array
    const rawDataArray = Array.isArray(data) ? data : [data];
    // store processed messages
    let processedMessages = [];

    rawDataArray.forEach((rawData) => {
      if (!Array.isArray(rawData.result)) {
        return
      }
      if (rawData && rawData.result && Array.isArray(rawData.result) && rawData.result.length > 0) {
        const messages = rawData?.result;
        const senderWithReservation = messages.find(
          (message) =>
            message.sender_role && message.sender_role.includes("guest")
        );
        const displayName =  guestName;
        const pictureUrl = senderWithReservation
          ? senderWithReservation.sender_photo_url
          : null;

        // find the last message with body
        let lastMessage = null;
        for (let i = messages.length - 1; i >= 0; i--) {
          if (messages[i].body) {
            lastMessage = messages[i];
            break;
          }
        }

        if (!lastMessage) {
          lastMessage = {
            body: "No messages",
            send_date: "",
            sender_role: "",
          };
        }

        const processedMessage = {
          userId: rawData.message_id || null,
          messageId: rawData.message_id || null,
          roomId: roomId || null,
          displayName: displayName || null,
          pictureUrl: pictureUrl || null,
          channel: "agoda",
          last_two_messages: [
            {
              created_at: lastMessage.send_date || null,
              message: lastMessage.body || null,
            },
          ],
          unreadCount: 1,
        };

        processedMessages.push(processedMessage);
      } else {
        console.warn('Invalid rawData or rawData.result structure:', rawData);
      }
    });

    return processedMessages;
  };

  return (
    <div className="pr-3 flex flex-col min-w-72 max-w-72 bg-bgLight px-2 pt-6">
      <div className="flex flex-row items-center justify-between text-2xl font-bold mb-2 text-textDark ml-1">
        <div>
          第三方紀錄
          <Tooltip title="切換至語音模組">
            <SwapOutlined
              className="hover:text-primary ml-2"
              style={{ width: "12px", height: "12px" }}
              onClick={() => switchTo("asr")}
            />
          </Tooltip>
        </div>
        {/* <ReloadOutlined
          className="hover:text-primary ml-2"
          style={{ width: "12px", height: "12px" }}
          onClick={fetchClients}
        /> */}
        {mqttConnection ? (
          <Tooltip title="連線穩定">
            <div className="flex flex-row gap-1 ml-2 bg-green-600 w-2 h-2 rounded-full" />
          </Tooltip>
        ) : (
          <Tooltip title="重新連線中...點擊以重整">
            <div
              className="flex flex-row gap-1 ml-2 bg-yellow-600 w-2 h-2 rounded-full animate-ping"
              onClick={refresh}
            />
          </Tooltip>
        )}
      </div>

      <div className="flex justify-between">
        <Tabs
          defaultActiveKey={0}
          items={tabs}
          onChange={onChangeTab}
          className="px-2 w-full"
        />
      </div>
      {listLoading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : clients.length <= 0 ? (
        <div className="w-full flex flex-col gap-4 items-center justify-center pt-12">
          這裡沒有任何第三方紀錄。
        </div>
      ) :  (
        <div className="flex flex-col overflow-y-scroll pb-12 overflow-x-hidden">
          {clients
            .sort(
              (a, b) =>
                new Date(b.last_two_messages[0].created_at) -
                new Date(a.last_two_messages[0].created_at)
            )
            .map((client, index) => (
              <BoundListItem
                key={index}
                client={client}
                activeId={activeId}
                setActiveId={setActiveId}
                newMessage={newMessageClientList.includes(client.userId)}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default BoundList;
