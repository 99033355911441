import { ENV } from '@/constants/env';
import moment from 'moment';
import { Input, Button, Tag, Carousel } from 'antd';
import { detailConfig as config } from '../detail/detailConfig';

const stag5Config = {
  page:
  {
    title: "Service Management",
    addButton: "Add Service",
    information: "Basic Information",
    specification: "Service Specifications",
    discount: "Discounts and Offers",
    publish: "Service Deployment",
    serviceOrders: "Service Order Records"
  },
  onPublish: (formData, { saveServiceData }) => {
    // 保存表單數據到 context
    saveServiceData(formData);
  },
  columns: ({ setShowServiceOrders, setSelectedService }) => [
    // {
    //   title: "RECOMMEND",
    //   width: "10%",
    //   fixed: "left",
    //   dataIndex: "recommendation",
    //   align: "center",
    //   render: (value) => (
    //     <Input
    //       type="text"
    //       style={{ width: '60px' }}
    //       value={value || ''}

    //       className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&]:MozAppearance-textfield"
    //     />
    //   ),
    // },
    {
      title: "LISTED MERCHANTS",
      dataIndex: ["org", "name"],
      width: "12%",
      fixed: "left",
      sorter: (a, b) => (a.category?.name || "").localeCompare(b.category?.name || ""),
      render: (text) => text || <span className="text-gray-400">Unset</span>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      width: "8%",
      fixed: "left",
      align: "center",
      sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
      render: (status = "On Sale") => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
        >
          {status}
        </span>
      ),
    },
    {
      title: "SERVICE NUMBER",
      dataIndex: "id",
      width: "12%",
      align: "center",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => id.substring(0, 5),
    },
    {
      title: "SERVICE NAME",
      dataIndex: "name",
      width: "12%",
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      render: (text, record) => {
        const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
        return (
          <div className="flex items-center">
            <img
              src={imageUrl}
              alt="服務縮圖"
              className="w-6 h-6 object-cover rounded mr-2"
            />
            <span className="truncate">{text || '未設定'}</span>
          </div>
        );
      }
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      width: "8%",
      align: "center",
      sorter: (a, b) => (a.category?.name || "").localeCompare(b.category?.name || ""),
      render: (text) => text || <span className="text-gray-400">Unset</span>,
    },
    {
      title: "SERVICE AREA",
      dataIndex: "service_regions",
      width: "8%",
      align: "center",
      sorter: (a, b) => {
        const nameA = a.service_regions?.[0]?.name || "";
        const nameB = b.service_regions?.[0]?.name || "";
        return nameA.localeCompare(nameB);
      },
      render: (service_regions) => {
        if (!service_regions || service_regions.length === 0) {
          return <span className="text-gray-400">Unset</span>;
        }
        return service_regions[0].name;
      },
    },
    {
      title: "PRICE",
      dataIndex: "specs",
      width: "8%",
      align: "center",
      sorter: (a, b) => {
        const priceA = a.specs?.[0]?.price || 0;
        const priceB = b.specs?.[0]?.price || 0;
        return priceA - priceB;
      },
      render: (specs) => {
        if (!specs || specs.length === 0) {
          return <span className="text-gray-400">Unset</span>;
        }

        if (specs.length === 1) {
          return `$${specs[0].price}`;
        }

        const lowestPrice = Math.min(...specs.map(spec => spec.price));
        return `$${lowestPrice}`;
      },
    },
    {
      title: "ORDER NUMBER",
      dataIndex: "order",
      width: "10%",
      sorter: (a, b) => a.order - b.order,
      render: (order, record) => (
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            setShowServiceOrders(true);
            setSelectedService(record);
          }}
        >
          {order}
          <img
            src="/images/order.png"
            className="w-[70px]"
            alt="arrow"
          />
        </Button>
      ),
    },
    {
      title: "STOCK NUMBER",
      dataIndex: "specs",
      width: "10%",
      align: "center",
      sorter: (a, b) => {
        const stockA = a.specs?.[0]?.stock;
        const stockB = b.specs?.[0]?.stock;
        if (!stockA && !stockB) return 0;
        if (!stockA) return 1;
        if (!stockB) return -1;
        return parseInt(stockA) - parseInt(stockB);
      },
      render: (specs) => {
        const stock = specs?.[0]?.stock;
        if (!stock) {
          return <span className="text-gray-400">Unset</span>;
        }
        return stock === "0" ? "0" : stock;
      },
    },
    {
      title: "DISCOUNT",
      dataIndex: "discounts",
      width: "6%",
      render: (discounts) => discounts.length > 0 ? "open" : <span className="text-gray-400">OFF</span>,
    },
    {
      title: "TAG",
      dataIndex: "tags",
      width: "8%",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <span className="text-gray-400">Unset</span>;
        }

        return (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag) => (
              <Tag
                key={tag}
                className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
              >
                {tag}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "LISTING DATE",
      dataIndex: "created_at",
      width: "8%",
      align: "center",
      render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">Unset</span>,
    },
  ],
  apis:
  {
    product_type: "service_product_ip",
  },
  detail: (data, isEdit = false) => {
    if (!data) return [];

    const getDisplayPrice = (data) => {
      // 檢查 specs 價格，取最小值
      if (data.specs?.length > 0) {
        return Math.min(...data.specs.map(spec => spec.price));
      }

      // 檢查 specData 價格
      if (data.specData?.[0]?.price) {
        return data.specData[0].price;
      }

      // 檢查 specData 如果有兩個規格
      if (data.specData?.[0]?.options?.[0]?.price) {
        return data.specData[0].options[0].price;
      }

      // 檢查 fixedTable 價格
      if (data.fixedTable?.[0]?.price) {
        return data.fixedTable[0].price;
      }

      // 如果都沒有價格，返回空字串
      return '';
    };

    return [
      {
        key: "1",
        label: config.info,
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片輪播區域 */}
              <div className="relative">
                <Carousel
                  autoplay
                  dots={{ className: "custom-dots" }}
                  className="h-[200px] bg-gray-100"
                >
                  {data.blobs && data.blobs.length > 0 && (
                    data.blobs.map((blob, index) => (
                      <div key={index}>
                        <img
                          src={blob.url}
                          alt={`${data.name || 'product'}-image${index + 1}`}
                          className="w-full h-[200px] object-cover"
                        />
                      </div>
                    ))
                  )}

                  {data.serviceImage?.preview && data.serviceImage.preview.length > 0 && (
                    data.serviceImage.preview.map((preview, index) => (
                      <div key={index}>
                        <img
                          src={preview}
                          alt={`${data.name || 'product'}-image${index + 1}`}
                          className="w-full h-[200px] object-cover"
                        />
                      </div>
                    ))
                  )}
                </Carousel>
              </div>

              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-start gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.name || "Unset Product Name"}
                  </h1>
                </div>
                {/* 標籤 */}
                <div className="flex justify-start gap-2">
                  {data.tags?.length > 0 ? data.tags.map((tag, index) => (
                    <Tag key={tag.id || index} color="default" className="badge rounded-md px-2 py-1 text-[#DFB782] bg-[#F3E5D4]">{tag.name || 'Unnamed Tag'}</Tag>
                  )) : ''}
                </div>

                {/* 服務描述 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">Product Description</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    <textarea
                      className={`
                        w-full
                        min-h-[80px]
                        bg-transparent
                        resize-none
                        outline-none
                        ${!data.serviceDescription ? "text-gray-400" : ""}
                      `}
                      value={data.serviceDescription ? data.serviceDescription : data.description ? data.description : 'Unset Description'}
                      readOnly
                    />
                  </div>
                  {/* 價格顯示 */}
                  <div className="text-right my-2">
                    <div>
                      <span className="text-2xl font-bold hover:text-[#45a049] mr-1">
                        from$ {getDisplayPrice(data)} /hr
                      </span>
                    </div>
                  </div>
                </div>

                {/* 服務規格 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">Product Specifications</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    {(data.specs || (data.specData && data.specSettings) || (data.priceType === 'fixed' && data.fixedTable?.length > 0)) ? (
                      <div className="grid grid-cols-2 gap-4">
                        {/* 處理現有的 specs 數據 */}
                        {data.specs?.map((spec) => (
                          <div
                            key={spec.id}
                            className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                          >
                            <div className="flex flex-col">
                              <span className="text-lg font-medium">{spec.name}</span>
                              <div className="text-sm text-gray-500">
                                {spec.information?.tier_variations?.map((variation, index) => (
                                  <span key={index}>
                                    {variation.name}: {variation.value}
                                    {index < spec.information.tier_variations.length - 1 ? ' | ' : ''}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <span className="text-lg font-semibold text-[#DFB782]">
                              ${spec.price}
                            </span>
                          </div>
                        ))}

                        {/* 處理表單的 specData */}
                        {(() => {
                          // 獲取兩個規格的選項數量（排除空值）
                          const spec1Options = data.specSettings?.[0]?.options?.filter(opt => opt !== '') || [];
                          const spec2Options = data.specSettings?.[1]?.options?.filter(opt => opt !== '') || [];

                          // 修改：處理只有一個規格的情況
                          if (spec1Options.length > 0 && spec2Options.length === 0) {
                            return spec1Options.map((opt1, i) => {
                              const priceData = data.specData?.[i]?.options?.[0] || data.specData?.[i] || {};

                              return (
                                <div
                                  key={i}
                                  className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                                >
                                  <div className="flex flex-col">
                                    <span className="text-lg font-medium">
                                      {data.specSettings[0].specName}: {opt1}
                                    </span>
                                  </div>
                                  <div className="flex flex-col items-end">
                                    <span className="text-lg font-semibold text-[#DFB782]">
                                      ${priceData.price || ''}
                                    </span>
                                    {/* {priceData.stockAmount !== "unlimited" && (
                                      <span className="text-sm text-gray-500">
                                        Stock: {priceData.stockAmount || ''}
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              );
                            });
                          }

                          // 原有的兩個規格的處理邏輯
                          return spec1Options.flatMap((opt1, i) =>
                            spec2Options.map((opt2, j) => {
                              // 從所有 specData 中獲取價格數據
                              const allPrices = data.specData?.map(spec => spec.options).flat() || [];
                              const currentIndex = i * spec2Options.length + j;
                              const priceData = allPrices[currentIndex] || {};

                              return (
                                <div
                                  key={`${i}-${j}`}
                                  className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                                >
                                  <div className="flex flex-col">
                                    <div className="flex gap-2">
                                      <span className="text-lg font-medium">
                                        {data.specSettings[0].specName}: {opt1}
                                        {' | '}
                                        {data.specSettings[1].specName}: {opt2}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-end">
                                    <span className="text-lg font-semibold text-[#DFB782]">
                                      ${priceData.price || ''}
                                    </span>
                                    {/* {priceData.stockAmount !== "unlimited" && (
                                      <span className="text-sm text-gray-500">
                                        Stock: {priceData.stockAmount || ''}
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              );
                            })
                          );
                        })()}

                        {/* 處理 Fixed Amount */}
                        {data.priceType === 'fixed' && Array.isArray(data.fixedTable) && data.fixedTable.map((fixed, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                          >
                            <div className="flex flex-col">
                              <span className="text-md font-medium"></span>
                            </div>
                            <div className="flex flex-col items-end">
                              <span className="text-lg font-semibold text-[#DFB782]">
                                ${fixed.price}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-gray-400">Unset Price</div>
                    )}
                  </div>
                </div>

                {/* 注意事項 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">Notice</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    <textarea
                      className={`
                        w-full
                        min-h-[80px]
                        bg-transparent
                        resize-none
                        outline-none
                        ${!data.serviceNotice ? "text-gray-400" : ""}
                      `}
                      value={data.serviceNotice ? data.serviceNotice : data.notification ? data.notification : 'Unset Notice'}
                      readOnly
                    />
                  </div>
                </div>

              </div>

              {isEdit && (
                <>
                  {/* 編輯按鈕 */}
                  <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                    <Button
                      type="primary"
                      className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      {config.labels.edit}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        ),
      }
    ];
  },
  specTable: {
    price: 'Price',
    discount: 'Discount',
    discountPrice: 'Discount Price',
    lateDiscount: 'Late Discount',
    lateDiscountPrice: 'Late Discount Price',
    stock: 'Stock',
    enterPrice: 'Please Enter Price',
  },
  bannerUpload: {
    uploadBtn: '/images/upload.jpg',
  },
  message: {
    success: 'Service created successfully',
    error: 'Service creation failed'
  },
  tabs: [
    { key: '1', label: 'Order Records' },
  ],
};

const _77Config = {
  page: {
    title: "服務管理",
    serviceOrders: "服務訂單列表"
  },
  columns: ({ setShowServiceOrders, setSelectedService }) => [
    // {
    //   title: "推薦",
    //   width: "5%",
    //   fixed: "left",
    //   dataIndex: "recommendation",
    //   align: "center",
    //   render: (value) => (
    //     <Input
    //       type="text"
    //       style={{ width: '60px' }}
    //       value={value || ''}
    //       className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&]:MozAppearance-textfield"
    //     />
    //   ),
    // },
    {
      title: "上架店家",
      dataIndex: ["org", "name"],
      width: "8%",
      fixed: "left",
      sorter: (a, b) => (a.category?.name || "").localeCompare(b.category?.name || ""),
      render: (text) => text || <span className="text-gray-400">未設定店家</span>,
    },
    {
      title: "前台狀態",
      dataIndex: "status",
      width: "8%",
      fixed: "left",
      align: "center",
      sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
      render: (status = "On Sale") => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
        >
          {status}
        </span>
      ),
    },
    {
      title: "服務序號",
      dataIndex: "id",
      width: "12%",
      align: "center",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => id.substring(0, 5),
    },
    {
      title: "服務名稱",
      dataIndex: "name",
      width: "12%",
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      render: (text, record) => {
        const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
        return (
          <div className="flex items-center">
            <img
              src={imageUrl}
              alt="服務縮圖"
              className="w-6 h-6 object-cover rounded mr-2"
            />
            <span className="truncate">{text || '未設定'}</span>
          </div>
        );
      }
    },
    {
      title: "價格",
      dataIndex: "specs",
      width: "8%",
      align: "center",
      sorter: (a, b) => {
        const priceA = a.specs?.[0]?.price || 0;
        const priceB = b.specs?.[0]?.price || 0;
        return priceA - priceB;
      },
      render: (specs) => {
        if (!specs || specs.length === 0) {
          return <span className="text-gray-400">未設定價格</span>;
        }

        if (specs.length === 1) {
          return `$${specs[0].price}`;
        }

        const lowestPrice = Math.min(...specs.map(spec => spec.price));
        return `$${lowestPrice}`;
      },
    },
    {
      title: "訂單數",
      dataIndex: "order",
      width: "10%",
      sorter: (a, b) => a.order - b.order,
      render: (order, record) => (
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            setShowServiceOrders(true);
            setSelectedService(record);
          }}
        >
          {order}
          <img
            src="/images/order.png"
            className="w-[70px]"
            alt="arrow"
          />
        </Button>
      ),
    },
    {
      title: "庫存數",
      dataIndex: "specs",
      width: "10%",
      align: "center",
      sorter: (a, b) => {
        const stockA = a.specs?.[0]?.stock;
        const stockB = b.specs?.[0]?.stock;
        if (!stockA && !stockB) return 0;
        if (!stockA) return 1;
        if (!stockB) return -1;
        return parseInt(stockA) - parseInt(stockB);
      },
      render: (specs) => {
        const stock = specs?.[0]?.stock;
        if (!stock) {
          return <span className="text-gray-400">未設定庫存</span>;
        }
        return stock === "0" ? "0" : stock;
      },
    },
    {
      title: "優惠",
      dataIndex: "memberDiscount",
      width: "6%",
      render: (memberDiscount, record) => {
        const discounts = [];
        if (memberDiscount && memberDiscount !== "無折扣") {
          discounts.push(`會員${memberDiscount}`);
        }
        if (record.earlyBirdDiscount && record.earlyBirdDiscount !== "無折扣") {
          discounts.push(`晚鳥${record.earlyBirdDiscount}`);
        }
        return discounts.length > 0 ? "開啟" : <span className="text-gray-400">關閉</span>;
      },
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "8%",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <span className="text-gray-400">未設定標籤</span>;
        }
        return (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag, index) => (
              <Tag
                key={tag.id || index}
                className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
              >
                {tag.name || '未命名標籤'}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "上架日期",
      dataIndex: "created_at",
      width: "8%",
      align: "center",
      render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">未設定上架日期</span>,
    },
  ],
  formFields: [
    { name: 'name', label: '服務名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  onPublish: (formData, { saveServiceData }) => {
    // 保存表單數據到 context
    saveServiceData(formData);
  },
  apis:
  {
    product_type: "service_product_ii",
  },
  detail: (data, isEdit = false) => {
    if (!data) return [];

    const getDisplayPrice = (data) => {
      // 檢查 specs 價格，取最小值
      if (data.specs?.length > 0) {
        return Math.min(...data.specs.map(spec => spec.price));
      }

      // 檢查 specData 價格
      if (data.specData?.[0]?.price) {
        return data.specData[0].price;
      }

      // 檢查 specData 如果有兩個規格
      if (data.specData?.[0]?.options?.[0]?.price) {
        return data.specData[0].options[0].price;
      }

      // 檢查 fixedTable 價格
      if (data.fixedTable?.[0]?.price) {
        return data.fixedTable[0].price;
      }

      // 如果都沒有價格，返回空字串
      return '';
    };

    return [
      {
        key: "1",
        label: config.info,
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片輪播區域 */}
              <div className="relative">
                <Carousel
                  autoplay
                  dots={{ className: "custom-dots" }}
                  className="h-[200px] bg-gray-100"
                >
                  {data.blobs && data.blobs.length > 0 && (
                    data.blobs.map((blob, index) => (
                      <div key={index}>
                        <img
                          src={blob.url}
                          alt={`${data.name || 'product'}-image${index + 1}`}
                          className="w-full h-[200px] object-cover"
                        />
                      </div>
                    ))
                  )}

                  {data.serviceImage?.preview && data.serviceImage.preview.length > 0 && (
                    data.serviceImage.preview.map((preview, index) => (
                      <div key={index}>
                        <img
                          src={preview}
                          alt={`${data.name || 'product'}-image${index + 1}`}
                          className="w-full h-[200px] object-cover"
                        />
                      </div>
                    ))
                  )}
                </Carousel>
              </div>

              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-start gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.name || "未設定服務名稱"}
                  </h1>
                </div>
                {/* 標籤 */}
                <div className="flex justify-start gap-2">
                  {data.tags?.length > 0 ? data.tags.map((tag, index) => (
                    <Tag key={tag.id || index} color="default" className="badge rounded-md px-2 py-1 text-[#DFB782] bg-[#F3E5D4]">{tag.name || 'Unnamed Tag'}</Tag>
                  )) : ''}
                </div>

                {/* 服務描述 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">服務描述</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    <textarea
                      className={`
                        w-full
                        min-h-[80px]
                        bg-transparent
                        resize-none
                        outline-none
                        ${!data.serviceDescription ? "text-gray-400" : ""}
                      `}
                      value={data.serviceDescription ? data.serviceDescription : data.description ? data.description : '未設定服務描述'}
                      readOnly
                    />
                  </div>

                  {/* 價格顯示 */}
                  <div className="text-right my-2">
                    <div>
                      <span className="text-3xl font-bold text-[#ADDEAC] hover:text-[#45a049] mr-2">
                        $ {getDisplayPrice(data)}
                      </span><span className="text-2xl">起</span>
                    </div>
                  </div>
                </div>

                {/* 服務規格 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">服務規格</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    {(data.specs || (data.specData && data.specSettings) || (data.priceType === 'fixed' && data.fixedTable?.length > 0)) ? (
                      <div className="grid grid-cols-2 gap-4">
                        {/* 處理現有的 specs 數據 */}
                        {data.specs?.map((spec) => (
                          <div
                            key={spec.id}
                            className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                          >
                            <div className="flex flex-col">
                              <span className="text-lg font-medium">{spec.name}</span>
                              <div className="text-sm text-gray-500">
                                {spec.information?.tier_variations?.map((variation, index) => (
                                  <span key={index}>
                                    {variation.name}: {variation.value}
                                    {index < spec.information.tier_variations.length - 1 ? ' | ' : ''}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <span className="text-lg font-semibold text-[#DFB782]">
                              ${spec.price}
                            </span>
                          </div>
                        ))}

                        {/* 處理表單的 specData */}
                        {(() => {
                          // 獲取兩個規格的選項數量（排除空值）
                          const spec1Options = data.specSettings?.[0]?.options?.filter(opt => opt !== '') || [];
                          const spec2Options = data.specSettings?.[1]?.options?.filter(opt => opt !== '') || [];

                          // 修改：處理只有一個規格的情況
                          if (spec1Options.length > 0 && spec2Options.length === 0) {
                            return spec1Options.map((opt1, i) => {
                              const priceData = data.specData?.[i]?.options?.[0] || data.specData?.[i] || {};

                              return (
                                <div
                                  key={i}
                                  className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                                >
                                  <div className="flex flex-col">
                                    <span className="text-lg font-medium">
                                      {data.specSettings[0].specName}: {opt1}
                                    </span>
                                  </div>
                                  <div className="flex flex-col items-end">
                                    <span className="text-lg font-semibold text-[#DFB782]">
                                      ${priceData.price || ''}
                                    </span>
                                    {/* {priceData.stockAmount !== "unlimited" && (
                                      <span className="text-sm text-gray-500">
                                        Stock: {priceData.stockAmount || ''}
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              );
                            });
                          }

                          // 原有的兩個規格的處理邏輯
                          return spec1Options.flatMap((opt1, i) =>
                            spec2Options.map((opt2, j) => {
                              // 從所有 specData 中獲取價格數據
                              const allPrices = data.specData?.map(spec => spec.options).flat() || [];
                              const currentIndex = i * spec2Options.length + j;
                              const priceData = allPrices[currentIndex] || {};

                              return (
                                <div
                                  key={`${i}-${j}`}
                                  className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                                >
                                  <div className="flex flex-col">
                                    <div className="flex gap-2">
                                      <span className="text-lg font-medium">
                                        {data.specSettings[0].specName}: {opt1}
                                        {' | '}
                                        {data.specSettings[1].specName}: {opt2}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-end">
                                    <span className="text-lg font-semibold text-[#DFB782]">
                                      ${priceData.price || ''}
                                    </span>
                                    {/* {priceData.stockAmount !== "unlimited" && (
                                      <span className="text-sm text-gray-500">
                                        Stock: {priceData.stockAmount || ''}
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              );
                            })
                          );
                        })()}

                        {/* 處理 Fixed Amount */}
                        {data.priceType === 'fixed' && Array.isArray(data.fixedTable) && data.fixedTable.map((fixed, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                          >
                            <div className="flex flex-col">
                              <span className="text-md font-medium">固定金額</span>
                            </div>
                            <div className="flex flex-col items-end">
                              <span className="text-lg font-semibold text-[#DFB782]">
                                ${fixed.price}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-gray-400">未設定規格</div>
                    )}
                  </div>
                </div>

                {/* 注意事項 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">注意事項</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    <textarea
                      className={`
                        w-full
                        min-h-[80px]
                        bg-transparent
                        resize-none
                        outline-none
                        ${!data.serviceNotice ? "text-gray-400" : ""}
                      `}
                      value={data.serviceNotice ? data.serviceNotice : data.notification ? data.notification : '未設定注意事項'}
                      readOnly
                    />
                  </div>
                </div>

              </div>

              {isEdit && (
                <>
                  {/* 編輯按鈕 */}
                  <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                    <Button
                      type="primary"
                      className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      {config.labels.edit}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        ),
      }
    ];
  },
  specTable: {
    price: '價格',
    discount: '折扣',
    discountPrice: '折扣價',
    lateDiscount: '晚鳥優惠',
    lateDiscountPrice: '晚鳥優惠價',
    stock: '庫存',
    enterPrice: '請輸入價格',
  },
  bannerUpload: {
    uploadBtn: '/images/upload-btn.png',
  },
  message: {
    success: '商品創建成功',
    error: '商品創建失敗'
  },
  tabs: [
    { key: '1', label: '訂單紀錄' },
  ]
};

const pancoConfig = {
  page: {
    title: "服務管理",
    serviceOrders: "訂單紀錄"
  },
  columns: ({ setShowServiceOrders, setSelectedService }) => [
    // {
    //   title: "推薦",
    //   width: "5%",
    //   fixed: "left",
    //   dataIndex: "recommendation",
    //   align: "center",
    //   render: (value) => (
    //     <Input
    //       type="text"
    //       style={{ width: '60px' }}
    //       value={value || ''}
    //       className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&]:MozAppearance-textfield"
    //     />
    //   ),
    // },
    {
      title: "上架店家",
      dataIndex: ["org", "name"],
      width: "8%",
      fixed: "left",
      sorter: (a, b) => (a.category?.name || "").localeCompare(b.category?.name || ""),
      render: (text) => text || <span className="text-gray-400">未設定店家</span>,
    },
    {
      title: "前台狀態",
      dataIndex: "status",
      width: "8%",
      fixed: "left",
      align: "center",
      sorter: (a, b) => (a.status || "On Sale").localeCompare(b.status || "On Sale"),
      render: (status = "On Sale") => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${status === "On Sale" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
        >
          {status}
        </span>
      ),
    },
    {
      title: "服務序號",
      dataIndex: "id",
      width: "12%",
      align: "center",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => id.substring(0, 5),
    },
    {
      title: "服務名稱",
      dataIndex: "name",
      width: "12%",
      sorter: (a, b) => (a.name || "").localeCompare(b.name || ""),
      render: (text, record) => {
        const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
        return (
          <div className="flex items-center">
            <img
              src={imageUrl}
              alt="服務縮圖"
              className="w-6 h-6 object-cover rounded mr-2"
            />
            <span className="truncate">{text || '未設定'}</span>
          </div>
        );
      }
    },
    {
      title: "價格",
      dataIndex: "specs",
      width: "8%",
      align: "center",
      sorter: (a, b) => {
        const priceA = a.specs?.[0]?.price || 0;
        const priceB = b.specs?.[0]?.price || 0;
        return priceA - priceB;
      },
      render: (specs) => {
        if (!specs || specs.length === 0) {
          return <span className="text-gray-400">未設定價格</span>;
        }

        if (specs.length === 1) {
          return `$${specs[0].price}`;
        }

        const lowestPrice = Math.min(...specs.map(spec => spec.price));
        return `$${lowestPrice}`;
      },
    },
    {
      title: "訂單數",
      dataIndex: "order",
      width: "10%",
      sorter: (a, b) => a.order - b.order,
      render: (order, record) => (
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            setShowServiceOrders(true);
            setSelectedService(record);
          }}
        >
          {order}
          <img
            src="/images/order.png"
            className="w-[70px]"
            alt="arrow"
          />
        </Button>
      ),
    },
    {
      title: "庫存數",
      dataIndex: "specs",
      width: "10%",
      align: "center",
      sorter: (a, b) => {
        const stockA = a.specs?.[0]?.stock;
        const stockB = b.specs?.[0]?.stock;
        if (!stockA && !stockB) return 0;
        if (!stockA) return 1;
        if (!stockB) return -1;
        return parseInt(stockA) - parseInt(stockB);
      },
      render: (specs) => {
        const stock = specs?.[0]?.stock;
        if (!stock) {
          return <span className="text-gray-400">未設定庫存</span>;
        }
        return stock === "0" ? "0" : stock;
      },
    },
    {
      title: "優惠",
      dataIndex: "memberDiscount",
      width: "6%",
      render: (memberDiscount, record) => {
        const discounts = [];
        if (memberDiscount && memberDiscount !== "無折扣") {
          discounts.push(`會員${memberDiscount}`);
        }
        if (record.earlyBirdDiscount && record.earlyBirdDiscount !== "無折扣") {
          discounts.push(`晚鳥${record.earlyBirdDiscount}`);
        }
        return discounts.length > 0 ? "開啟" : <span className="text-gray-400">關閉</span>;
      },
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "8%",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <span className="text-gray-400">未設定標籤</span>;
        }
        return (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag, index) => (
              <Tag
                key={tag.id || index}
                className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
              >
                {tag.name || '未命名標籤'}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "上架日期",
      dataIndex: "created_at",
      width: "8%",
      align: "center",
      render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">未設定上架日期</span>,
    },
  ],
  formFields: [
    { name: 'name', label: '服務名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  onPublish: (formData, { saveServiceData }) => {
    // 保存表單數據到 context
    saveServiceData(formData);
  },
  apis:
  {
    product_type: "service_product_ii",
  },
  detail: (data, isEdit = false) => {
    if (!data) return [];

    const getDisplayPrice = (data) => {
      // 檢查 specs 價格，取最小值
      if (data.specs?.length > 0) {
        return Math.min(...data.specs.map(spec => spec.price));
      }

      // 檢查 specData 價格
      if (data.specData?.[0]?.price) {
        return data.specData[0].price;
      }

      // 檢查 specData 如果有兩個規格
      if (data.specData?.[0]?.options?.[0]?.price) {
        return data.specData[0].options[0].price;
      }

      // 檢查 fixedTable 價格
      if (data.fixedTable?.[0]?.price) {
        return data.fixedTable[0].price;
      }

      // 如果都沒有價格，返回空字串
      return '';
    };

    return [
      {
        key: "1",
        label: "服務資訊",
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white">
              {/* 圖片輪播區域 */}
              <div className="relative">
                <Carousel
                  autoplay
                  dots={{ className: "custom-dots" }}
                  className="h-[200px] bg-gray-100"
                >
                  {data.blobs && data.blobs.length > 0 && (
                    data.blobs.map((blob, index) => (
                      <div key={index}>
                        <img
                          src={blob.url}
                          alt={`${data.name || 'product'}-image${index + 1}`}
                          className="w-full h-[200px] object-cover"
                        />
                      </div>
                    ))
                  )}

                  {data.serviceImage?.preview && data.serviceImage.preview.length > 0 && (
                    data.serviceImage.preview.map((preview, index) => (
                      <div key={index}>
                        <img
                          src={preview}
                          alt={`${data.name || 'product'}-image${index + 1}`}
                          className="w-full h-[200px] object-cover"
                        />
                      </div>
                    ))
                  )}
                </Carousel>
              </div>

              <div className="p-6 flex flex-col gap-2">
                {/* 標題區域 */}
                <div className="flex items-center justify-start gap-2">
                  <h1 className="text-2xl font-semibold text-left font-['Inter']">
                    {data.name || "未設定服務名稱"}
                  </h1>
                </div>
                {/* 標籤 */}
                <div className="flex justify-start gap-2">
                  {data.tags?.length > 0 ? data.tags.map((tag, index) => (
                    <Tag key={tag.id || index} color="default" className="badge rounded-md px-2 py-1 text-[#DFB782] bg-[#F3E5D4]">{tag.name || 'Unnamed Tag'}</Tag>
                  )) : ''}
                </div>

                {/* 服務描述 */}
                <div className="flex flex-col justify-center gap-2">
                  <div className="mb-2 text-xl font-semibold font-['Inter'] leading-[26px]">服務描述</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    <textarea
                      className={`
                        w-full
                        min-h-[80px]
                        bg-transparent
                        resize-none
                        outline-none
                        ${!data.serviceDescription ? "text-gray-400" : ""}
                      `}
                      value={data.serviceDescription ? data.serviceDescription : data.description ? data.description : '未設定服務描述'}
                      readOnly
                    />
                  </div>

                  {/* 價格顯示 */}
                  <div className="text-right my-2">
                    <div>
                      <span className="text-3xl font-bold text-[#ADDEAC] hover:text-[#45a049] mr-2">
                        $ {getDisplayPrice(data)}
                      </span><span className="text-2xl">起</span>
                    </div>
                  </div>
                </div>

                {/* 服務規格 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">服務規格</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    {(data.specs || (data.specData && data.specSettings) || (data.priceType === 'fixed' && data.fixedTable?.length > 0)) ? (
                      <div className="grid grid-cols-2 gap-4">
                        {/* 處理現有的 specs 數據 */}
                        {data.specs?.map((spec) => (
                          <div
                            key={spec.id}
                            className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                          >
                            <div className="flex flex-col">
                              <span className="text-lg font-medium">{spec.name}</span>
                              <div className="text-sm text-gray-500">
                                {spec.information?.tier_variations?.map((variation, index) => (
                                  <span key={index}>
                                    {variation.name}: {variation.value}
                                    {index < spec.information.tier_variations.length - 1 ? ' | ' : ''}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <span className="text-lg font-semibold text-[#DFB782]">
                              ${spec.price}
                            </span>
                          </div>
                        ))}

                        {/* 處理表單的 specData */}
                        {(() => {
                          // 獲取兩個規格的選項數量（排除空值）
                          const spec1Options = data.specSettings?.[0]?.options?.filter(opt => opt !== '') || [];
                          const spec2Options = data.specSettings?.[1]?.options?.filter(opt => opt !== '') || [];

                          // 修改：處理只有一個規格的情況
                          if (spec1Options.length > 0 && spec2Options.length === 0) {
                            return spec1Options.map((opt1, i) => {
                              const priceData = data.specData?.[i]?.options?.[0] || data.specData?.[i] || {};

                              return (
                                <div
                                  key={i}
                                  className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                                >
                                  <div className="flex flex-col">
                                    <span className="text-lg font-medium">
                                      {data.specSettings[0].specName}: {opt1}
                                    </span>
                                  </div>
                                  <div className="flex flex-col items-end">
                                    <span className="text-lg font-semibold text-[#DFB782]">
                                      ${priceData.price || ''}
                                    </span>
                                    {/* {priceData.stockAmount !== "unlimited" && (
                                      <span className="text-sm text-gray-500">
                                        Stock: {priceData.stockAmount || ''}
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              );
                            });
                          }

                          // 原有的兩個規格的處理邏輯
                          return spec1Options.flatMap((opt1, i) =>
                            spec2Options.map((opt2, j) => {
                              // 從所有 specData 中獲取價格數據
                              const allPrices = data.specData?.map(spec => spec.options).flat() || [];
                              const currentIndex = i * spec2Options.length + j;
                              const priceData = allPrices[currentIndex] || {};

                              return (
                                <div
                                  key={`${i}-${j}`}
                                  className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                                >
                                  <div className="flex flex-col">
                                    <div className="flex gap-2">
                                      <span className="text-lg font-medium">
                                        {data.specSettings[0].specName}: {opt1}
                                        {' | '}
                                        {data.specSettings[1].specName}: {opt2}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="flex flex-col items-end">
                                    <span className="text-lg font-semibold text-[#DFB782]">
                                      ${priceData.price || ''}
                                    </span>
                                    {/* {priceData.stockAmount !== "unlimited" && (
                                      <span className="text-sm text-gray-500">
                                        Stock: {priceData.stockAmount || ''}
                                      </span>
                                    )} */}
                                  </div>
                                </div>
                              );
                            })
                          );
                        })()}

                        {/* 處理 Fixed Amount */}
                        {data.priceType === 'fixed' && Array.isArray(data.fixedTable) && data.fixedTable.map((fixed, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between bg-white p-4 rounded-lg border border-gray-200 hover:border-[#DFB782] transition-colors"
                          >
                            <div className="flex flex-col">
                              <span className="text-md font-medium">固定金額</span>
                            </div>
                            <div className="flex flex-col items-end">
                              <span className="text-lg font-semibold text-[#DFB782]">
                                ${fixed.price}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="text-gray-400">未設定規格</div>
                    )}
                  </div>
                </div>

                {/* 注意事項 */}
                <div className="mt-4">
                  <div className="text-xl font-semibold mb-2">注意事項</div>
                  <div className="bg-gray-100 p-4 rounded-lg min-h-[80px]">
                    <textarea
                      className={`
                        w-full
                        min-h-[80px]
                        bg-transparent
                        resize-none
                        outline-none
                        ${!data.serviceNotice ? "text-gray-400" : ""}
                      `}
                      value={data.serviceNotice ? data.serviceNotice : data.notification ? data.notification : '未設定注意事項'}
                      readOnly
                    />
                  </div>
                </div>

              </div>

              {isEdit && (
                <>
                  {/* 編輯按鈕 */}
                  <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                    <Button
                      type="primary"
                      className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      {config.labels.edit}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        ),
      }
    ];
  },
  specTable: {
    price: '價格',
    discount: '折扣',
    discountPrice: '折扣價',
    lateDiscount: '晚鳥優惠',
    lateDiscountPrice: '晚鳥優惠價',
    stock: '庫存',
    enterPrice: '請輸入價格',
  },
  bannerUpload: {
    uploadBtn: '/images/upload-btn.png',
  },
  message: {
    success: '商品創建成功',
    error: '商品創建失敗'
  },
  tabs: [
    { key: '1', label: '訂單紀錄' },
  ]
};


// 根據環境變數選擇配置
const isPanco = ENV.E_COMMERCE.PANCO;
const isStag5 = ENV.E_COMMERCE.STAG5;
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const serviceConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : is77 ? _77Config : null;