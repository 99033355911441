import React from "react";
import LineSetting from "../chatSettings/LineSetting";
import AirbnbSetting from "../chatSettings/AirbnbSetting";
import AgodaSetting from "../chatSettings/AgodaSetting";

const BoundSetting = ({ room, token, clientId, tabs, client }) => {
  return (
    <>
      {tabs === 0 ? (
        <div className="bg-bgLight h-full min-w-72 max-w-72">

        <AgodaSetting
          client={client}
      />
      </div>
      ) : tabs === 1 ? (
          <div className="bg-bgLight h-full min-w-72 max-w-72 overflow-y-auto">
            <LineSetting room={room} token={token} clientId={clientId}/>
          </div>
      ): 
      ''
    }
    </>
  );
};

export default BoundSetting;