import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { serviceConfig } from '../config/service/serviceConfig';

const BannerUpload = ({ value, onChange }) => {
    const [bannerImages, setBannerImages] = useState([]);
    const [blobData, setBlobData] = useState([]);

    useEffect(() => {
        if (Array.isArray(value)) {
            setBannerImages(value);
        }
    }, [value]);

    const handleUpdateImage = (img, type, index) => {
        console.log('Update image:', img, type, index);
    };

    const handleRemoveImage = (index) => {
        const updated = [...bannerImages];
        const updatedBlobs = [...blobData];
        updated.splice(index, 1);
        updatedBlobs.splice(index, 1);
        setBannerImages(updated);
        setBlobData(updatedBlobs);
        onChange?.({ preview: updated, blobs: updatedBlobs });
    };

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            // 保存預覽圖片
            const newImages = [...bannerImages, reader.result];
            setBannerImages(newImages);
            
            // 保存 blob 數據
            const newBlobs = [...blobData, file];
            setBlobData(newBlobs);
            
            // 傳遞預覽圖片和 blob 數據給父組件
            onChange?.({ preview: newImages, blobs: newBlobs });
        };
        reader.readAsDataURL(file);
        return false;
    };

    return (
        <>
            <div className="rounded p-2">
                <div className="flex gap-4 justify-start">
                    {bannerImages.map((img, index) => (
                        <div key={index} className="relative w-[360px] h-[202.5px]">
                            <img
                                src={img}
                                alt={`Banner ${index + 1}`}
                                className="w-full h-full object-cover rounded-lg cursor-pointer"
                                onClick={() => handleUpdateImage(img, "banner", index)}
                            />
                            <button
                                type="button"
                                className="absolute top-2 right-2 bg-gray-500 text-white rounded-full p-1 w-6 h-6 flex items-center justify-center"
                                onClick={() => handleRemoveImage(index)}
                            >
                                &times;
                            </button>
                        </div>
                    ))}

                    {bannerImages.length < 5 && (
                        <Upload
                            listType="picture-card"
                            className="[&_.ant-upload]:!w-[360px] [&_.ant-upload]:!h-[202.5px]"
                            showUploadList={false}
                            multiple={false}
                            accept="image/*"
                            beforeUpload={handleUpload}
                        >
                            <div className="w-[360px] h-[202.5px]">
                                <img
                                    src={serviceConfig.bannerUpload.uploadBtn}
                                    alt="upload button"
                                    width={360}
                                    height={202.5}
                                    priority="true"
                                    sizes="100vw"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            </div>
                        </Upload>
                    )}
                </div>
            </div>
        </>
    );
};

export default BannerUpload;