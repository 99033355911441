/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// antd
import { Table, Button, message, Input, Select } from "antd";
import { PlusOutlined, DownloadOutlined, SearchOutlined } from "@ant-design/icons";
// components
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import ToolBar from "@/app/ams/components/ToolBar";
import Bread from "@/app/ams/components/Bread";
// context
import { useAmsContext } from '@/app/ams/context/AmsContext';
import { userConfig } from "@/app/ams/config/user/userConfig";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
// apis
import { 
  fetchUserServiceOrdersAPI, 
  fetchUserProductOrdersAPI, 
  fetchUserTicketOrdersAPI, 
  fetchUserCompaignsAPI 
} from '@/app/ams/services/api';
// utils
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


export default function User() {
  const { selectedUser, setSelectedUser, users, fetchUsers, setUsers, debugMode, loading } = useAmsContext();
  const [showButton, setShowButton] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [searchText, setSearchText] = useState('');

  const [currentView, setCurrentView] = useState('default');

  useEffect(() => {
    fetchUsers();
  }, [debugMode]);

  // select first user
  useEffect(() => {
    if (users?.length > 0 && !selectedUser) {
      const firstUser = users[0];
      handleRowClick(firstUser);
    }
  }, [users]);

  // select user
  const handleRowClick = (record) => {
    if (selectedUser?.key !== record.key) {
      setSelectedUser(record);
    }
  }

  // table columns
  const tableColumns = userConfig.columns.map(column => ({
    ...column,
    onCell: (record) => ({
      onClick: () => {
        if (column.onCellClick) {
          column.onCellClick(record, {
            users,
            setUsers,
            setSelectedUser
          });
        }
      }
    })
  }));

  // export logic
  const handleExport = async () => {
    try {
      // 創建一個新的 ZIP 文件
      const zip = new JSZip();

      // 添加需求資料為 JSON 文件
      const usersJson = JSON.stringify(users, null, 2);
      zip.file('users.json', usersJson);

      // 為每個需求創建一個資料夾
      for (const user of users) {
        const userFolder = zip.folder(`user_${user.id}`);

        // 添加圖片
        if (user.blobs) {
          for (let i = 0; i < user.blobs.length; i++) {
            const blob = user.blobs[i];
            if (blob.content_type.includes('image')) {
              try {
                const response = await fetch(blob.url);
                const imageBlob = await response.blob();
                userFolder.file(
                  `image_${i + 1}.${blob.content_type.split('/')[1]}`,
                  imageBlob
                );
              } catch (error) {
                console.error(`下載圖片失敗: ${blob.url}`, error);
              }
            }
            // 如果是音訊檔
            else if (blob.content_type.includes('audio')) {
              try {
                const response = await fetch(blob.url);
                const audioBlob = await response.blob();
                userFolder.file(
                  `audio_${i + 1}.${blob.content_type.split('/')[1]}`,
                  audioBlob
                );
              } catch (error) {
                console.error(`下載音訊失敗: ${blob.url}`, error);
              }
            }
          }
        }
      }

      // 生成 ZIP 文件並下載
      const content = await zip.generateAsync({ type: 'blob' });
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      saveAs(content, `會員資料_${timestamp}.zip`);
    } catch (error) {
      console.error('匯出失敗:', error);
      message.error('匯出會員資料失敗，請稍後再試');
    }
  };

  // render tabs
  const renderTabs = () => {
    return (
      <div className="flex gap-4 mb-2">
        {userConfig.tabs.map((tab) => (
          <Button
            key={tab.key}
            className={`${
              currentView === tab.key
                ? "bg-[#90C290] hover:bg-[#7AB27A]"
                : "bg-white hover:bg-gray-100 border border-gray-200"
            } px-3 py-2 rounded-full`}
            onClick={() => setCurrentView(tab.key)}
          >
            {tab.label}
          </Button>
        ))}
      </div>
    );
  };

  // show user service orders
  const ShowUserServiceOrders = () => {
    const [serviceOrders, setServiceOrders] = useState([]);
    useEffect(() => {
      const res = async () => {
        const res = await fetchUserServiceOrdersAPI(selectedUser?.id);
        setServiceOrders(res);
      }
      res();
    }, [selectedUser]);

    return (
      <div className="flex w-full overflow-auto bg-gray-50">
        <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
          <div className="flex justify-between items-center">
            <div className="text-lg font-bold">{selectedUser?.nickname || selectedUser?.name || selectedUser?.first_name || selectedUser?.last_name || selectedUser?.email || selectedUser?.phone}</div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black invisible"
            >
              {userConfig?.page?.addButton || ""}
            </Button>
          </div>
          <Bread title={userConfig?.page?.title || ""} path="user" subTitle={userConfig?.page?.serviceOrders || ""} />
          <div className="flex gap-4">
            {renderTabs()}
          </div>
          {/* <Statistics data={statisticsConfig?.statistics?.user} type="user" /> */}
          <Table
            columns={tableColumns}
            dataSource={serviceOrders}
            scroll={{ x: 2300 }}
            loading={loading}
            className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              className: record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : "",
            })}
            rowClassName={(record) => `cursor-pointer ${record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : ""}`}
          />
        </div>
        <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
          <Detail
            data={selectedUser}
            type="user"
            items={Array.isArray(userConfig?.detail?.(selectedUser)) ?
              userConfig?.detail?.(selectedUser) :
              []}
            showButton={false}
          />
        </div>
      </div>
    );
  };

  // show user product orders
  const ShowUserProductOrders = () => {
    const [productOrders, setProductOrders] = useState([]);
    useEffect(() => {
      const res = async () => {
        const res = await fetchUserProductOrdersAPI(selectedUser?.id);
        setProductOrders(res);
      }
      res();
    }, [selectedUser]);
    return (
      <div className="flex w-full overflow-auto bg-gray-50">
        <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
          <div className="flex justify-between items-center">
            <div className="text-lg font-bold">{selectedUser?.nickname || selectedUser?.name || selectedUser?.first_name || selectedUser?.last_name || selectedUser?.email || selectedUser?.phone}</div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black invisible"
            >
              {userConfig?.page?.addButton || ""}
            </Button>
          </div>
          <Bread title={userConfig?.page?.title || ""} path="user" subTitle={userConfig?.page?.productOrders || ""} />
          <div className="flex gap-4">
            {renderTabs()}
          </div>
          {/* <Statistics data={statisticsConfig?.statistics?.user} type="user" /> */}
          <Table
            columns={tableColumns}
            dataSource={productOrders}
            scroll={{ x: 2300 }}
            loading={loading}
            className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              className: record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : "",
            })}
            rowClassName={(record) => `cursor-pointer ${record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : ""}`}
          />
        </div>
        <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
          <Detail
            data={selectedUser}
            type="user"
            items={Array.isArray(userConfig?.detail?.(selectedUser)) ?
              userConfig?.detail?.(selectedUser) :
              []}
            showButton={false}
          />
        </div>
      </div>
    );
  };

  // show user ticket records
  const ShowUserTicketRecords = () => {
    const [ticketOrders, setTicketOrders] = useState([]);
    useEffect(() => {
      const res = async () => {
        const res = await fetchUserTicketOrdersAPI(selectedUser?.id);
        setTicketOrders(res);
      }
      res();
    }, [selectedUser]);
    return (
      <div className="flex w-full overflow-auto bg-gray-50">
        <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
          <div className="flex justify-between items-center">
            <div className="text-lg font-bold">
              {selectedUser?.nickname || selectedUser?.name || selectedUser?.first_name || selectedUser?.last_name || selectedUser?.email || selectedUser?.phone}
            </div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black invisible"
            >
              {userConfig?.page?.[0]?.addButton || ""}
            </Button>
          </div>
          <Bread title={userConfig?.page?.title || ""} path="user" subTitle={userConfig?.page?.ticketRecords || ""} />
          <div className="flex gap-4">
            {renderTabs()}
          </div>
          {/* <Statistics data={statisticsConfig?.statistics?.user} type="user" /> */}
          <Table
            columns={tableColumns}
            dataSource={ticketOrders}
            scroll={{ x: 2300 }}
            loading={loading}
            className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              className: record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : "",
            })}
            rowClassName={(record) => `cursor-pointer ${record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : ""}`}
          />
        </div>
        <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
          <Detail
            data={selectedUser}
            type="user"
            items={Array.isArray(userConfig?.detail?.(selectedUser)) ?
              userConfig?.detail?.(selectedUser) :
              []}
            showButton={false}
          />
        </div>
      </div>
    );
  };

  // show user event records
  const ShowUserEventRecords = () => {
    const [eventRecords, setEventRecords] = useState([]);
    useEffect(() => {
      const res = async () => {
        const res = await fetchUserCompaignsAPI(selectedUser?.id);
        setEventRecords(res);
      }
      res();
    }, [selectedUser]);
    return (
      <div className="flex w-full overflow-auto bg-gray-50">
        <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
          <div className="flex justify-between items-center">
            <div className="text-lg font-bold">
              {selectedUser?.nickname || selectedUser?.name || selectedUser?.first_name || selectedUser?.last_name || selectedUser?.email || selectedUser?.phone}
            </div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black invisible"
            >
              {userConfig?.page?.[0]?.addButton || ""}
            </Button>
          </div>
          <Bread title={userConfig?.page?.title || ""} path="user" subTitle={userConfig?.page?.compaign || ""} />
          <div className="flex gap-4">
            {renderTabs()}
          </div>
          {/* <Statistics data={statisticsConfig?.statistics?.user} type="user" /> */}
          <Table
            columns={tableColumns}
            dataSource={''}
            scroll={{ x: 2300 }}
            loading={loading}
            className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              className: record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : "",
            })}
            rowClassName={(record) => `cursor-pointer ${record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : ""}`}
          />
        </div>
        <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
          <Detail
            data={selectedUser}
            type="user"
            items={Array.isArray(userConfig?.detail?.(selectedUser)) ?
              userConfig?.detail?.(selectedUser) :
              []}
            showButton={false}
          />
        </div>
      </div>
    );
  };

  // show default
  const ShowDefault = () => {
    return (
      <div className="flex w-full overflow-auto bg-gray-50">
        <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
          <div className="flex justify-between items-center">
            <div className="text-lg font-bold">{userConfig?.page?.title || ""}</div>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black invisible"
            >
              {userConfig?.page?.[0]?.addButton || ""}
            </Button>
          </div>
          <Bread title={userConfig?.page?.title || ""} path="user" page={userConfig?.page?.add || ""} />
          <Statistics data={statisticsConfig?.statistics?.user} type="user" />
          <div className="flex justify-between items-center my-2">
            <div className="flex space-x-4">
              <Input
                className="w-80"
                placeholder="Search"
                prefix={<SearchOutlined />}
                // onChange={handleSearch}
                // value={searchText}
                allowClear
              />
              <Select
                className="w-40"
                placeholder="開放狀態"
                options={statusOptions}
                onChange={handleStatusChange}
                allowClear
                value={selectedStatus || undefined}
              />
            </div>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleExport}
              className="bg-[#67BE5F] hover:bg-[#ADDEAC] border-[#67BE5F] hover:border-[#ADDEAC]"
            >
              {userConfig?.page?.export || ""}
            </Button>
          </div>
          <Table
            columns={tableColumns}
            dataSource={getFilteredUsers()}
            scroll={{ x: 2300 }}
            loading={loading}
            className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              className: record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : "",
            })}
            rowClassName={(record) => `cursor-pointer ${record.key === (selectedUser?.key ?? '') ? "bg-gray-100" : ""}`}
          />
        </div>
        <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
          <Detail
            data={selectedUser}
            type="user"
            items={Array.isArray(userConfig?.detail?.(selectedUser, showButton, setCurrentView)) ?
              userConfig?.detail?.(selectedUser, showButton, setCurrentView) :
              []}
            showButton={false}
          />
        </div>
      </div>
    );
  };

  // render content
  const renderContent = () => {
    const views = {
      default: <ShowDefault />,
      service: <ShowUserServiceOrders />,
      product: <ShowUserProductOrders />,
      ticket: <ShowUserTicketRecords />,
      event: <ShowUserEventRecords />
    };
    return views[currentView] || views.default;
  };

  // status options
  const statusOptions = [
    { value: true, label: '開放狀態' },
    { value: false, label: '關閉狀態' }
  ];


  // handle status change
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  // 在 render 之前過濾數據
  const getFilteredUsers = () => {
    return users.filter(user => {
      const matchesSearch = !searchText || 
        (user.name && user.name.toLowerCase().includes(searchText.toLowerCase())) ||
        (user.email && user.email.toLowerCase().includes(searchText.toLowerCase())) ||
        (user.phone && user.phone.toLowerCase().includes(searchText.toLowerCase()));
        
      const matchesStatus = selectedStatus === '' || user.enabled === selectedStatus;
      
      return matchesSearch && matchesStatus;
    });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">
        <div className="flex-1 flex overflow-auto">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};
