/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Segmented, Tooltip, Select, Button, Skeleton, Tag, message, Input } from 'antd'
import { PhoneOutlined, CloudSyncOutlined, DeleteOutlined, EditOutlined, CheckOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  fetchCustomTags,
  fetchHotels,
  deleteSample,
  rerunASR,
  rerunAnalysis,
  rerunHotel,
  rerunLabels,
  fetchSample,
  addCustomTagToSample,
  deleteCustomTagFromSample,
  updateHotelToSample,
  updateTranscript
} from './hooks/voice.api';
import VoiceInputArea from './VoiceInputArea';

const OFFSET_HOUR = 8;

const SampleSkeleton = () => (
  <div className="w-full h-full bg-bgLight border-l border-r overflow-y-scroll px-8 pt-4 pb-8">
    <Skeleton active />
    <div className="my-8 mx-4">
      <Skeleton.Button active block style={{ width: "100%" }} />
    </div>
    <div className="flex flex-col gap-12">
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <div className="flex flex-row justify-center items-center gap-2 pt-4 ">
        <Skeleton.Button active style={{ width: 90, height: 32 }} />
        <Skeleton.Button active style={{ width: 90, height: 32 }} />
        <Skeleton.Button active style={{ width: 90, height: 32 }} />
        <Skeleton.Button active style={{ width: 90, height: 32 }} />
      </div>
    </div>
  </div>
);


const VoicePanel = ({ room, token, itemId, tagGroupId, parentRefresh }) => {
  const [tagOptions, setTagOptions] = useState([]);
  const [hotelOptions, setHotelOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [tab, setTab] = useState('AI 大綱');
  const [selectedTags, setSelectedTags] = useState([]);
  const [originalTags, setOriginalTags] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [editTagMode, setEditTagMode] = useState(false);
  const [changingHotel, setChangingHotel] = useState(false);
  const [editSegmentIndex, setEditSegmentIndex] = useState(null);
  const [editSegmentMode, setEditSegmentMode] = useState(false);
  const [newSegment, setNewSegment] = useState('');
  const [fetchingTranscript, setFetchingTranscript] = useState(false);

  useEffect(() => {
    // reset state
    setLoading(true);
    setItem(null);
    setSelectedTags([]);
    setOriginalTags([]);
    setSelectedHotel(null);
    setEditTagMode(false);
    setTagOptions([]);
    setHotelOptions([]);
    setChangingHotel(false);
    setEditSegmentIndex(null);
    setEditSegmentMode(false);
    setNewSegment('');
    setFetchingTranscript(false);
    setTab('AI 大綱');
    // fetch data
    itemId && tagGroupId && refresh();
  }, [room, itemId, tagGroupId])

  const refresh = async () => {
    setLoading(true);
    fetchSample(room.id, token, itemId).then((data) => {
      setItem(data);
      data && setSelectedTags(data.labels.map((label) => label.split("::")[0].split("^")[0]));
      data && setOriginalTags(data.labels.map((label) => label.split("::")[0].split("^")[0]));
      data && setSelectedHotel(data.hotel_id);
      fetchCustomTags(room.id, token, tagGroupId).then((data) => {
        data && setTagOptions(data.tags.map((tag) => ({ label: tag.name, value: tag.id })));
        fetchHotels(room.id, token).then((data) => {
          data && setHotelOptions(data.map((hotel) => ({ label: hotel.body.name, value: hotel.id })));
          // console.log(item)
          setLoading(false);
        });
      });
    });
  }

  const formatAndTimeZone = (timestamp) => {
    let timeObj = new Date(timestamp);

    timeObj.setHours(timeObj.getHours() + OFFSET_HOUR); // TODO: why no *2?

    const month = ('0' + (timeObj.getMonth() + 1)).slice(-2);
    const day = ('0' + timeObj.getDate()).slice(-2);
    const hour = ('0' + timeObj.getHours()).slice(-2);
    const minute = ('0' + timeObj.getMinutes()).slice(-2);

    return `${month}/${day} ${hour}:${minute}`;
  };

  const handleRerunLabels = async () => {
    setLoading(true);
    await rerunLabels(room.id, token, item.id);
    setLoading(false);
    parentRefresh();
  }

  const handleRerunHotel = async () => {
    setLoading(true);
    await rerunHotel(room.id, token, item.id);
    setLoading(false);
    parentRefresh();
  }

  const handleRerunASR = async () => {
    setLoading(true);
    await rerunASR(room.id, token, item.id);
    setLoading(false);
    parentRefresh();
  }

  const handleRerunAnalysis = async () => {
    setLoading(true);
    await rerunAnalysis(room.id, token, item.id);
    setLoading(false);
    parentRefresh();
  }

  const handleDelete = async () => {
    await deleteSample(room.id, token, item.id);
    parentRefresh(true);
  }

  const handleUpdateTag = async () => {
    setLoading(true);

    const addTags = selectedTags.filter((tag) => !originalTags.includes(tag));
    const deleteTags = originalTags.filter((tag) => !selectedTags.includes(tag));

    // Loop to add tags
    for (const tag of addTags) {
      await addCustomTagToSample(room.id, token, item.id, tag);
    }

    // Loop to delete tags
    for (const tag of deleteTags) {
      await deleteCustomTagFromSample(room.id, token, item.id, tag);
    }

    setLoading(false);
    setEditTagMode(false);
    refresh();
  }

  const handleChangeHotel = async (value) => {
    setSelectedHotel(value);
    setChangingHotel(true);
    await updateHotelToSample(room.id, token, itemId, value);
    setChangingHotel(false);
    message.success('目標飯店已更新');
  };

  const handleEditSegment = async (index) => {
    setEditSegmentIndex(null);
    setEditSegmentMode(false);
    // setFetchingTranscript(true);
    updateTranscript(room.id, token, itemId, index, newSegment).then((data) => {
      setItem({
        ...item, transcription: [
          ...item.transcription.slice(0, index),
          {
            ...item.transcription[index],
            text: newSegment
          },
          ...item.transcription.slice(index + 1)
        ]
      });
    }).catch((error) => {
      message.error('編輯失敗');
    });
    // await fetchTranscript(room.id, token, itemId).then((data) => {
    //   setItem({ ...item, transcription: data.segments });
    //   setFetchingTranscript(false);
    // });
    setNewSegment('');
  }

  const formatSecondToTime = (second) => {
    const hour = Math.floor(second / 3600);
    const minute = Math.floor((second % 3600) / 60);
    const sec = Math.floor(second % 60);
    if (hour > 0) {
      return `${hour}:${('0' + minute).slice(-2)}:${('0' + sec).slice(-2)}`;
    } else {
      return `${minute}:${('0' + sec).slice(-2)}`;
    }
  }

  if (!item || loading) return <SampleSkeleton />

  if (item.state === "Done" || item.state === "Unknown") {
    return (
      <div className="w-full h-full bg-bgLight border-l border-r overflow-y-scroll px-8 pt-4 pb-8">
        <div className="w-full flex flex-row items-center">
          <h1 className="font-bold line-clamp-1">
            {item.customer_question}
          </h1>
          <ReloadOutlined
            className="hover:text-primary mr-2"
            style={{ marginLeft: "4px", width: "12px", height: "12px" }}
            onClick={refresh}
          />
        </div>
        <div className="flex flex-row justify-between items-center">
          <div>
            <a className="text-xs text-textLight mt-1 underline cursor-pointer line-clamp-1" href={item.sample_url} target='_blank' rel="noreferrer">
              {item.sample_filename}
            </a>
            <div className="flex flex-row items-center justify-start mt-2 mb-4 gap-4">
              <div className="text-xs text-textDark line-clamp-1">
                <PhoneOutlined className="mr-1" />
                {item.sample_duration.toFixed(0)} 秒
              </div>
              <div className="text-xs text-textDark">
                上傳於 {formatAndTimeZone(item.created_at)}
              </div>
            </div>
          </div>
          <DeleteOutlined className="hover:text-red-500 cursor-pointer text-textDark" onClick={handleDelete} />
        </div>
        <audio controls src={item.sample_url} className='w-full pb-4 border-b' />
        <Segmented
          block
          className="my-4 mx-4 bg-bgDark"
          options={['AI 大綱', '語音原文']}
          defaultValue="AI 大綱"
          value={tab}
          onChange={(value) => { setTab(value) }}
        />
        {
          tab === 'AI 大綱' &&
          <>
            <div className="font-bold pb-1 mt-2">
              目標飯店
            </div>
            <Select
              placeholder="選擇飯店"
              className="w-full"
              defaultValue={item.hotel_id}
              value={selectedHotel}
              onChange={handleChangeHotel}
              options={hotelOptions}
              disabled={changingHotel}
            />
            <div className="flex flex-row items-center justify-start gap-2 font-bold pb-1 mt-4">
              標籤
              {
                editTagMode ?
                  <CheckOutlined onClick={handleUpdateTag} className="hover:text-primary" />
                  :
                  <EditOutlined onClick={() => setEditTagMode(true)} className="hover:text-primary" />
              }
            </div>
            {
              editTagMode ?
                <Select
                  mode="multiple"
                  placeholder="選擇標籤"
                  className="w-full"
                  defaultValue={selectedTags}
                  options={tagOptions}
                  onChange={(value) => setSelectedTags(value)}
                />
                :
                <div className="flex flex-row flex-wrap gap-1 py-1">
                  {
                    item.labels.map((label) => (
                      <Tag className="m-0" key={label}>{label.split("::")[0].split("^")[1]}</Tag>
                    ))
                  }
                </div>
            }
            <div className="flex flex-col gap-4 mt-4">
              <VoiceInputArea roomId={room.id} token={token} sampleId={itemId} title="顧客問題" content={item.customer_question} loading={loading} keyword="customer_question" refresh={refresh} />
              <VoiceInputArea roomId={room.id} token={token} sampleId={itemId} title="客服回覆" content={item.service_response} loading={loading} keyword="service_response" refresh={refresh} />
              <VoiceInputArea roomId={room.id} token={token} sampleId={itemId} title="AI 回覆" content={item.optimized_response} loading={loading} keyword="optimized_response" refresh={refresh} />
            </div>
            <div className="w-full flex flex-row items-center justify-center">
              <div className="flex flex-row justify-center items-center gap-2 py-4 mt-6 mb-4 w-fit px-8 rounded-full shadow-lg shadow-primaryMedium">
                <Tooltip title="讓 AI 重新分析語音，會直接覆寫目前的內容。">
                  <div className="font-bold mr-2 text-sm">
                    AI 重新分析
                  </div>
                </Tooltip>
                <Button onClick={handleRerunAnalysis}>
                  大綱
                </Button>
                <Button onClick={handleRerunASR}>
                  語音原文
                </Button>
                <Button onClick={handleRerunLabels}>
                  標籤
                </Button>
                <Button onClick={handleRerunHotel}>
                  目標飯店
                </Button>
              </div>
            </div>
          </>
        }
        {
          tab === '語音原文' &&
          <div className="flex flex-col pt-1">
            <div className="flex flex-row text-xs w-full bg-bgDark h-8 items-center text-center rounded-t">
              <div className="w-16">編號</div>
              <div className="w-16">起始秒</div>
              <div className="w-16">結束秒</div>
              <div className="w-full">語音文本</div>
            </div>
            <div className="flex flex-col w-full overflow-y-scroll border rounded-b h-full">
              {
                fetchingTranscript ?
                  <Skeleton active className="mt-4" />
                  :
                  item.transcription.length > 0 && item.transcription.map((segment, index) => (
                    <>
                      {
                        editSegmentMode && editSegmentIndex === index ?
                          <div className="flex flex-row py-2 items-center border-b group bg-bgMedium">
                            <div className="w-16 text-center text-xs text-textDark flex-wrap">{index + 1}</div>
                            <div className="w-16 text-center text-xs text-textDark flex-wrap">{formatSecondToTime(segment.start)}</div>
                            <div className="w-16 text-center text-xs text-textDark flex-wrap">{formatSecondToTime(segment.end)}</div>
                            <Input className="w-full max-w-full pl-3 ml-1 pr-2 border-l" defaultValue={segment.text} onChange={(e) => setNewSegment(e.target.value)} />
                            <CheckOutlined onClick={() => handleEditSegment(index)} className="hover:text-primary w-4 mx-2" />
                          </div>
                          :
                          <div className="flex flex-row py-3 items-center border-b group hover:bg-bgMedium bg-white">
                            <div className="w-16 text-center text-xs text-textDark flex-wrap">{index + 1}</div>
                            <div className="w-16 text-center text-xs text-textDark flex-wrap">{formatSecondToTime(segment.start)}</div>
                            <div className="w-16 text-center text-xs text-textDark flex-wrap">{formatSecondToTime(segment.end)}</div>
                            <div className="w-full max-w-full break-all text-wrap flex-wrap pl-3 ml-1 pr-2 border-l text-sm">{segment.text ? segment.text : '-'}</div>
                            <EditOutlined onClick={() => { setNewSegment(segment.text); setEditSegmentIndex(index); setEditSegmentMode(true); }} className="hover:text-primary w-4 mx-2 opacity-0 group-hover:opacity-100" />
                          </div>
                      }
                    </>
                  ))
              }
            </div>
          </div>
        }
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-bgLight border-l border-r px-8 pt-4 pb-12">
      <CloudSyncOutlined className="text-3xl mb-4" />
      <div className=''>
        語音分析中，請稍候。
      </div>
      <Button danger className='mt-4' onClick={handleDelete}>
        刪除語音
      </Button>
    </div>
  )
}

export default VoicePanel