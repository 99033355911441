import { ENV } from '@/constants/env';
import { Tag, Input, Button } from 'antd';
import moment from 'moment';
import { detailConfig as config } from '../detail/detailConfig';
const ellipsisStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%'
};

const _77Config = {
  page: [
    {
      title: "Service Management",
      addButton: "Add Service",
      information: "Basic Information",
      specification: "Service Specifications",
      discount: "Discounts and Offers",
      publish: "Service Deployment",
    }
  ],
  columns: ({ setCurrentView, setSelectedVenue, currentView }) => [
    {
      title: "場館序號",
      dataIndex: "id",
      width: "5%",
      align: "center",
      fixed: "left",
      sorter: (a, b) => a.id.localeCompare(b.id),
      render: (id) => id.substring(0, 5),
    },
    {
      title: "場館名稱",
      dataIndex: "src_name",
      width: "12%",
      fixed: "left",
      sorter: (a, b) => (a.src_name || "").localeCompare(b.src_name || ""),
      render: (text, record) => {
        const imageUrl = record.blobs?.[0]?.url || record.imageUrl || '/images/default-service.jpg';
        return (
          <div className="flex items-center"
            style={ellipsisStyle}
          >
            <img
              src={imageUrl}
              alt="服務縮圖"
              className="w-6 h-6 object-cover rounded mr-2"
            />
            <span
              className="truncate text-blue-500 hover:text-blue-700 hover:underline"
              onClick={() => {
                setCurrentView('ticket');
              }}
              style={{ cursor: 'pointer' }}
            >
              {text || '未設定服務名稱'}
            </span>
          </div>
        );
      }
    },
    {
      title: "空間數量",
      dataIndex: "halls",
      width: "8%",
      align: "center",
      sorter: (a, b) => (a.halls?.length || 0) - (b.halls?.length || 0),
      render: (halls, record) => (
        <>
          <span className="mr-2">
            {halls?.length || 0}
          </span>
          <Button
            type="primary"
            className="w-[45px] h-[27px] px-[13px] py-[8px] rounded-[4px] border border-[#ADDEAC] bg-gray-50 text-[#67BE5F] hover:border-[#67BE5F] hover:bg-[#ADDEAC] hover:text-white"
            onClick={(e) => {
              e.stopPropagation();
              setCurrentView('halls');
              console.log('currentView', currentView);
              setSelectedVenue(record);
            }}
          >
            發送
          </Button>
        </>
      ),
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "12%",
      render: (tags) => {
        if (!tags || tags.length === 0) {
          return <span className="text-gray-400">未設定標籤</span>;
        }
        return (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag, index) => (
              <Tag
                key={tag.id || index}
                className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
              >
                {tag.name || '未命名標籤'}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "加入日期",
      dataIndex: "created_at",
      width: "5%",
      align: "center",
      render: (date) => date ? moment(date).format("YYYY-MM-DD") : <span className="text-gray-400">未設定上架日期</span>,
    },
    {
      title: "場館電話",
      dataIndex: "phone",
      width: "8%",
      align: "center",
      render: (phone) => phone ? phone : <span className="text-gray-400">未設定電話</span>,
    },
    {
      title: "場館Email",
      dataIndex: "email",
      width: "8%",
      align: "center",
      render: (email) => email ? email : <span className="text-gray-400">未設定Email</span>,
    },
    {
      title: "所在縣市",
      dataIndex: "city",
      width: "8%",
      align: "center",
      render: (city) => city ? city : <span className="text-gray-400">未設定縣市</span>,
    },
    {
      title: "所在地區",
      dataIndex: "area",
      width: "8%",
      align: "center",
      render: (area) => area ? area : <span className="text-gray-400">未設定地區</span>,
    }
  ],
  formFields: [
    { name: 'name', label: '票券名稱', type: 'text', required: true },
    { name: 'price', label: '價格', type: 'number', required: true },
    { name: 'description', label: '描述', type: 'textarea', required: false },
  ],
  detail: (data, type) => {
    if (!data) return [];
    
    return [
      {
        key: "1",
        label: config.info,
        children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white">
            {/* 圖片區域 */}
            <div className="relative">
              <img 
                src={data.imageUrl} 
                alt={data.name} 
                className="w-full h-[174px] object-contain"
              />
              {type === 'service' && data.recommendation && (
                <div className="absolute top-4 left-4 bg-[#FF7171] text-white px-3 py-1 rounded-md">
                  {config.popular}
                </div>
              )}
            </div>

            <div className="p-6 flex flex-col gap-2">
              {/* 標題區域 */}
              <h1 className="text-2xl font-bold text-center">
                {data.name}
                {/* <span className="text-gray-400 ml-2 text-[18px]">
                  {data.gender ? (
                    data.gender === 'male' ? '先生' : data.gender === 'female' ? '女士' : '先生/女士'
                  ) : '先生/女士'}
                </span> */}
              </h1>

              {/* 功能標籤 - 只在服務類型時顯示 */}
              {type === 'service' && (
                <div className="flex gap-2">
                  <div className="bg-[#F3E5D4] text-[#DFB782] px-2 py-1 rounded-md">
                    {config.instantUse}
                  </div>
                  <div className="bg-[#F3E5D4] text-[#DFB782] px-2 py-1 rounded-md">
                    {config.freeCancellation}
                  </div>
                </div>
              )}

              {/* 描述資訊 */}
              <div>
                <h3 className="text-lg font-bold">{type === 'user' ? config.userInfo : config.serviceDesc}</h3>
                <p className="text-gray-700 whitespace-pre-line">{data.description}</p>
              </div>

              {/* 注意事項/其他資訊 */}
              <div>
                <h3 className="text-lg font-bold">{type === 'user' ? config.otherInfo : config.notice}</h3>
                <p className="text-gray-700 whitespace-pre-line">{data.notification}</p>
              </div>

              {/* 訂單資訊/規格選擇 */}
              {data.specs && (
                <div>
                  <h3 className="text-gray-400 mb-2">{type === 'user' ? config.orderInfo : config.specSelection}</h3>
                  <div className="flex gap-4">
                    {data.specs.map((spec, index) => (
                      <button key={index} className="bg-gray-100 px-6 py-3 rounded-lg">
                        {spec.name}: {spec.price}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* 編輯按鈕 */}
            <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
              <Button
                type="primary"
                className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
              >
                {config.edit}
              </Button>
            </div>
          </div>
        </div>
        ),
      }
    ];
  },
  onPublish: (formData, { saveServiceData }) => {
    saveServiceData(formData);
  },
  apis: 
    {
      product_type: "free_ticket",
    },
  tabs: [
    { key: 'halls', label: '空間列表' },
  ],
  message: {
    success: '場館創建成功',
    error: '場館創建失敗',
  }
};

// 根據環境變數選擇配置
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const venueConfig = is77 ? _77Config : null;
