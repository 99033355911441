/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col, Space, Card, Select, message, Spin, Tooltip } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { addRecord, updateRecord } from '@/service/memoryService';
import { LoadingOutlined } from "@ant-design/icons";


import DoubleRightOutlinedImg from "../assets/DoubleRightOutlined-icon.png";
import ActiveRightArrowImg from "../assets/ActiveRightArrow-icon.png";

// LevelSection component
const LevelSection = ({
  level,
  items,
  onChange,
  onNextLevel,
  selectedItem,
  show = true,
}) => {
  return (
    <Card
      bordered={false}
      style={{
        marginBottom: 20,
        height: "100%",
        backgroundColor: "white",
        border: "1px solid #E0E0E0",
        borderRadius: "0",
        margin: "0",
      }}
    >
      <div className="text-[14px] mb-5">Level {level}</div>
      {show && Array.isArray(items) && items.map((item, index) => {
        const hasChildren = Array.isArray(item.children) && item.children.length > 0;
        const isSelected = selectedItem === index && hasChildren;
        return (
          <Row
            key={`level-${level}-item-${index}`}
            gutter={16}
            align="middle"
            style={{ marginBottom: 10 }}
          >
            <Col span={16}>
              <div className="font-bold">{item.name}</div>
              <Input
                placeholder={`請輸入資料`}
                value={item.value || ""}
                onChange={(e) => onChange(level, index, e.target.value, "value")}
                style={{ marginTop: "5px" }}
              />
            </Col>
            <Col span={8}>
              <Space className="flex justify-end">
                {/* del btn */}
                {/* <Button
                  onClick={() => onDelete(level, index)}
                  className="bg-transparent border-none p-0 hover:opacity-75"
                >
                  <img src={CloseCircleOutlinedImg} alt="Delete" className="w-4 h-4" />
                </Button> */}
                {level < 3 && hasChildren && (
                  <div className="h-full flex">
                    <Button
                    onClick={() => onNextLevel(level, index)}
                    className={`bg-transparent border-none p-0 hover:bg-green-600 items-end h-full mt-5`}
                  >
                    {isSelected ? (
                      <img src={ActiveRightArrowImg} alt="Next Level Active" className="w-4 h-4" />
                    ) : (
                      <img src={DoubleRightOutlinedImg} alt="Next Level" className="w-4 h-4" />
                    )}
                  </Button>
                  </div>
                )}
              </Space>
            </Col>
          </Row>
        );
      })}
    </Card>
  );
};

// Record component
const Record = ({
  setShowRecord,
  handleButtonClick,
  selectedButton,
  recordData = {},
  recordIndex,
  room,
  handleFetchTemplate
}) => {
  const dispatch = useDispatch();
  const [recordName, setRecordName] = useState(""); // edit recordData's name
  const [recordValue, setRecordValue] = useState(""); // edit recordData's value
  const [structure, setStructure] = useState([]); // structure state
  const [loading, setLoading] = useState(false); // loading state
  const [selectedPath, setSelectedPath] = useState([]); // selected path state
  const [selectedItems, setSelectedItems] = useState({ // selected items state
    1: null,
    2: null,
    3: null,
  });
  // Get templates from the memory store
  const template = useSelector(state => state.memory.template);
  
  // show levels state
  const [showLevels, setShowLevels] = useState({
    1: true,
    2: false,
    3: false,
  });


  useEffect(() => {
    console.log('showLevels:', showLevels);
  }, [showLevels]);
    
    

  // Ensure children for each level
  const ensureChildren = (data, level = 1) => {
    if (!Array.isArray(data)) return [];
    return data.map(item => {
      const newItem = { ...item };
      if (level < 3 && !Array.isArray(newItem.children)) {
        newItem.children = [];
      }
      if (newItem.children && Array.isArray(newItem.children)) {
        newItem.children = ensureChildren(newItem.children, level + 1);
      }
      return newItem;
    });
  };

  // Find first item with children
  const findFirstItemWithChildren = (items, level) => {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (Array.isArray(item.children) && item.children.length > 0) {
        return { item, index: i };
      }
    }
    return null;
  };

  // Check if any item in a level has children
  const anyItemHasChildren = (items) => {
    return items.some(item => Array.isArray(item.children) && item.children.length > 0);
  };

  // Initialize recordData to page
  useEffect(() => {
    if (recordData?.structure?.structure) {
      setRecordName(recordData.name || "");
      setRecordValue(recordData.value || "");
      const structuredData = ensureChildren(JSON.parse(JSON.stringify(recordData.structure.structure)));
      setStructure(structuredData);
      setSelectedPath([]);
      setSelectedItems({ 1: null, 2: null, 3: null });
      setShowLevels({ 1: true, 2: false, 3: false });

      // Pre-render levels: Automatically select and render the first item with children
      preRenderNextLevel(structuredData);
    }
  }, [recordData]);

  // Pre-render next levels based on structure
  const preRenderNextLevel = (structure) => {
    const level1Result = findFirstItemWithChildren(structure, 1);
    if (level1Result) {
      setShowLevels((prev) => ({ ...prev, 2: true }));
      setSelectedItems((prev) => ({ ...prev, 1: level1Result.index }));
      setSelectedPath([level1Result.index]);

      const level2Result = findFirstItemWithChildren(level1Result.item.children, 2);
      if (level2Result) {
        setShowLevels((prev) => ({ ...prev, 3: true }));
        setSelectedItems((prev) => ({ ...prev, 2: level2Result.index }));
        setSelectedPath([level1Result.index, level2Result.index]);
      }
    }
  };

  // Handle setting selected item
  const handleSetSelectedItem = (level, index) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelected = { ...prevSelectedItems, [level]: index };
      if (level === 1) {
        newSelected[2] = null;
        newSelected[3] = null;
      } else if (level === 2) {
        newSelected[3] = null;
      }
      return newSelected;
    });

    setSelectedPath((prevPath) => {
      const newPath = [...prevPath];
      newPath[level - 1] = index;
      return newPath.slice(0, level);
    });
  };

  // Get item by path
  const getItemByPath = (path, data) => {
    let current = data;
    for (let i = 0; i < path.length; i++) {
      const index = path[i];
      if (!Array.isArray(current)) {
        return [];
      }
      current = current[index]?.children || [];
    }
    return Array.isArray(current) ? current : [];
  };

  // Handle level change
  const handleLevelChange = (level, index, value, key = "name") => {
    const newData = [...structure];
    const path = selectedPath.slice(0, level - 1).concat(index);
    const item = getItemByPath(path.slice(0, -1), newData)[
      path[path.length - 1]
    ];
    if (item) {
      item[key] = value;
      setStructure(newData);
    }
  };

  // Handle add item
  const handleAddItem = (level) => {
    const newData = [...structure];
    const path = selectedPath.slice(0, level - 1);
    const parent = getItemByPath(path, newData);
    if (Array.isArray(parent)) {
      if (level < 3) {
        parent.push({
          name: "",
          children: [],
        });
      } else {
        parent.push({
          name: "",
        });
      }
      setStructure(newData);

      // Re-evaluate if next levels should be shown
      reevaluateShowLevels(level, newData, path);
    }
  };

  // Re-evaluate showLevels based on current structure
  const reevaluateShowLevels = (level, currentStructure, path) => {
    const parentItems = getItemByPath(path, currentStructure);
    if (anyItemHasChildren(parentItems)) {
      setShowLevels((prev) => ({ ...prev, [level + 1]: true }));
    } else {
      // Hide subsequent levels if no items have children
      setShowLevels((prev) => {
        const updated = { ...prev };
        updated[level + 1] = false;
        updated[level + 2] = false;
        return updated;
      });
      // Reset selected items for hidden levels
      setSelectedItems((prev) => {
        const updated = { ...prev };
        updated[level] = null;
        updated[level + 1] = null;
        return updated;
      });
      // Reset selected path for hidden levels
      setSelectedPath((prevPath) => prevPath.slice(0, level));
    }
  };
  const MAX_LEVEL = 3;
  const resetShowLevels = (currentLevel) => {
    setShowLevels((prevShowLevels) => {
      const updated = { ...prevShowLevels };
      for (let lvl = currentLevel + 1; lvl <= MAX_LEVEL; lvl++) {
        updated[lvl] = false;
      }
      return updated;
    });
  };

  // Handle next level selection (when user clicks the icon)
  const handleNextLevel = (level, index) => {
    // 1. Reset showLevels beyond the current level
    resetShowLevels(level);
  
    // 2. Set the selected item
    handleSetSelectedItem(level, index);    
  
    // 3. Find the next level's first item with children
    const selectedItem = getItemByPath(selectedPath.slice(0, level - 1), structure)[index];
    console.log('selectedItem:', selectedItem);
    
    if (selectedItem && Array.isArray(selectedItem.children) && selectedItem.children.length > 0) {
      // 4. Show the next level
      setShowLevels((prevShowLevels) => ({
        ...prevShowLevels,
        [level + 1]: true,
      }));
  
      // 5. Select the first item with children in the next level
      const nextLevel = level + 1;
      const nextItems = selectedItem.children;
      const nextResult = findFirstItemWithChildren(nextItems);
      console.log('nextResult:', nextResult);
      
      if (nextResult) {
        setSelectedItems((prev) => ({ ...prev, [nextLevel]: nextResult.index }));
        setSelectedPath((prev) => {
          const newPath = [...prev.slice(0, nextLevel - 1), nextResult.index];
          console.log('newPath after nextResult:', newPath);
          return newPath;
        });
  
        // 6. Check if the next level has a third level
        if (nextLevel < MAX_LEVEL) {
          const hasThirdLevel = nextItems.some(item => Array.isArray(item.children) && item.children.length > 0);
          
          if (hasThirdLevel) {
            // 7. Show the third level
            setShowLevels((prev) => ({ ...prev, [nextLevel + 1]: true }));
            
            // 8. Select the first item with children in the third level
            const firstWithThirdLevel = nextItems.find(item => Array.isArray(item.children) && item.children.length > 0);
            
            if (firstWithThirdLevel) {
              const thirdLevelIndex = 0; // 默認選擇第三層的第一個項目
              setSelectedItems((prev) => ({ ...prev, [nextLevel + 1]: thirdLevelIndex }));
              setSelectedPath((prev) => [...prev.slice(0, nextLevel), thirdLevelIndex]);
            }
          } else {
            // 9. Hide the third level if no third-level data is available
            setShowLevels((prev) => ({ ...prev, [nextLevel + 1]: false }));
            console.log('No third-level data available, hiding third level.');
          }
        }
      }
    } else {
      // Hide the next level if no children are available
      setShowLevels((prevShowLevels) => ({
        ...prevShowLevels,
        [level + 1]: false,
      }));
    }
  };
  
  
  

  // Map structure
  const mapStructure = (items) => {
    return items.map(item => {
      const newItem = { name: item.name, value: item.value || "" };
      if (item.children && item.children.length > 0) {
        newItem.children = mapStructure(item.children);
      }
      return newItem;
    });
  };

  // Handle save record
  const handleSaveRecord = async () => {
    const selectedTemplate = template.find(t => t.name === recordName);
  
    if (!selectedTemplate) {
      message.warning('請選擇模板名稱');
      return;
    }
  
    const temp = {
      name: recordName,
      value: recordValue || "",
      structure: mapStructure(structure),
      template_id: selectedTemplate.id,
    };
  
    try {
      setLoading(true);
      if (recordIndex !== null && recordIndex !== undefined) {
        await dispatch(updateRecord({ room_id: room.id, record: temp, record_id: recordData.id }));
      } else {
        await dispatch(addRecord({ room_id: room.id, record: temp, template_id: selectedTemplate.id }));
      }
      setLoading(false);
      setShowRecord(false);
      setSelectedItems(null);
      message.success('儲存成功');
      await handleFetchTemplate();
    } catch (error) {
      setLoading(false);
      message.error("儲存失敗");
    }
  };

  return (
    <div className="h-[100vh] bg-[rgba(256,256,256,1)]">
      {/* Navigation Buttons */}
      <div className="flex justify-between bg-gray-100 w-[276px] rounded m-4">
        <Tooltip title="點擊查看一般記憶">
          <button
            onClick={() => handleButtonClick("general")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "general" ? "bg-[#ADDEAC]" : "bg-gray-100"
              }`}
          >
            一般記憶
          </button>
        </Tooltip>
        <Tooltip title="點擊查看進階記憶">
          <button
            onClick={() => handleButtonClick("advanced")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "advanced" ? "bg-[#ADDEAC]" : "bg-gray-100"
              }`}
          >
            進階記憶
          </button>
        </Tooltip>
        <Tooltip title="點擊查看問答記憶">
          <button
            onClick={() => handleButtonClick("qa")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "qa" ? "bg-[#ADDEAC]" : "bg-gray-100"
              }`}
          >
            問答記憶
          </button>
        </Tooltip>
      </div>

      {/* Template Name and Item Input */}
      <div className="flex justify-between mt-4 ml-4 mr-4">
        <div className="flex gap-9">
          <div>
            <span className="text-[14px]">模板名稱</span>
            <Row
              justify="center"
              className="w-[196px] mt-1"
              style={{ marginBottom: 20 }}
            >
             <Select
              placeholder="選擇模板名稱"
              value={recordName || undefined} 
              onChange={(value) => {
                const selectedTemplate = template.find(t => t.name === value);
                if (selectedTemplate) {
                  
                  console.log('Template ID:', selectedTemplate.id);
                  
                  setRecordName(selectedTemplate.name);
                  setRecordValue(selectedTemplate.value || "");
                  const structuredData = ensureChildren(selectedTemplate.structure.structure);
                  setStructure(structuredData);
                  setSelectedPath([]);
                  setSelectedItems({ 1: null, 2: null, 3: null });
                  setShowLevels({ 1: true, 2: false, 3: false });
                  
                  // Pre-render levels: Automatically select and render the first item with children
                  preRenderNextLevel(structuredData);
                } else {
                  console.log('未找到選中的模板');
                }
              }}
              style={{ width: '100%' }}
            >
              {template.map(template => (
                <Select.Option key={template.id} value={template.name}>
                  {template.name}
                </Select.Option>
              ))}
            </Select>

            </Row>
          </div>
          <div>
            <span className="text-[14px]">記憶名稱</span>
            <Row
              justify="center"
              className="w-[196px] mt-1"
              style={{ marginBottom: 20 }}
            >
              <Input
                placeholder="輸入名稱"
                value={recordValue}
                onChange={(e) => setRecordValue(e.target.value)}
                disabled={!recordName}
              />
            </Row>
          </div>
        </div>
        <Row justify="center" className="gap-5">
          <Tooltip title="取消目前操作">
            <Button onClick={() => setShowRecord(false)}>取消</Button>
          </Tooltip>
          <Tooltip title="保存目前記錄">
            <Button type="primary" onClick={handleSaveRecord}>
              儲存
            </Button>
          </Tooltip>
        </Row>
      </div>

      {/* Template Structure Editing Area */}
      {loading ? <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div> : (
        <Row gutter={0} style={{ height: "100%" }}>
        {/* Level 1 */}
        <Col span={8} style={{ height: "100%" }}>
          <LevelSection
            level={1}
            items={structure || []} 
            onAdd={() => handleAddItem(1)}
            onChange={(level, index, value, key) =>
              handleLevelChange(level, index, value, key)
            }
            // onDelete={(level, index) => handleDeleteItem(level, index)}
            onNextLevel={handleNextLevel}
            selectedItem={selectedItems[1]}
            setSelectedItem={(index) => handleSetSelectedItem(1, index)}
            show={showLevels[1]}
          />
        </Col>

        {/* Level 2 */}
        <Col span={8} style={{ height: "100%" }}>
          <LevelSection
            level={2}
            items={getItemByPath(selectedPath.slice(0, 1), structure) || []}
            onAdd={() => handleAddItem(2)}
            onChange={(level, index, value, key) =>
              handleLevelChange(level, index, value, key)
            }
            // onDelete={(level, index) => handleDeleteItem(level, index)}
            onNextLevel={handleNextLevel}
            selectedItem={selectedItems[2]}
            setSelectedItem={(index) => handleSetSelectedItem(2, index)}
            show={showLevels[2]}
          />
        </Col>
     
        {/* Level 3 */}
        <Col span={8} style={{ height: "100%" }}>
          <LevelSection
            level={3}
            items={getItemByPath(selectedPath.slice(0, 2), structure) || []}
            onAdd={() => handleAddItem(3)}
            onChange={(level, index, value, key) =>
              handleLevelChange(level, index, value, key)
            }
            // onDelete={(level, index) => handleDeleteItem(level, index)}
            onNextLevel={handleNextLevel}
            selectedItem={selectedItems[3]}
            setSelectedItem={(index) => handleSetSelectedItem(3, index)}
            show={showLevels[3]}
          />
        </Col>
      </Row>
      )}
    </div>
  );
};

export default Record;
