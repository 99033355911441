/* eslint-disable react-hooks/exhaustive-deps */
import { ENV } from '@/constants/env';
import { Input, Select, DatePicker, Upload, Tag, message, Form, Divider, Checkbox, Switch, Button } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { fetchCategoriesAPI, fetchOrganizationsAPI, createOrganizationAPI, verifyOrganizationAPI } from '@/app/ams/services/api';
import { useAmsContext } from '../../context/AmsContext';
import { fetchIdentityListAPI } from '../../services/api';
import BannerUpload from '@/app/ams/components/BannerUpload';
import TagSelector from '@/app/ams/components/TagSelector';
import BusinessHours from '@/app/ams/components/BusinessHours';

// 綁定官方平台
const PlatformSelect = () => {
    const [platforms, setPlatforms] = useState([]);
    return (
        <>
            <h2 className="text-[20px] font-bold mb-4">綁定官方平台</h2>
                            <div className="bg-[#F7F7F7] p-4 rounded-lg">
                                {platforms.map((platform, index) => (
                                    <div className="flex items-center gap-4 mb-4" key={platform.id}>
                                        <div className="flex-grow grid grid-cols-2 gap-4">
                                            <div>
                                                <div className="text-sm mb-1">平台</div>
                                                <Select
                                                    placeholder="請選擇平台"
                                                    className="w-full"
                                                    value={platform.type}
                                                    onChange={(value) => {
                                                        const updatedPlatforms = [...platforms];
                                                        updatedPlatforms[index].type = value;
                                                        setPlatforms(updatedPlatforms);
                                                    }}
                                                    options={[
                                                        { value: 'Facebook', label: 'Facebook' },
                                                        { value: 'Instagram', label: 'Instagram' },
                                                        { value: 'Youtube', label: 'Youtube' },
                                                        { value: 'Tiktok', label: 'Tiktok' }
                                                    ]}
                                                />
                                            </div>
                                            <div>
                                                <div className="text-sm mb-1">連結址</div>
                                                <Input
                                                    placeholder="http://example.com"
                                                    value={platform.url}
                                                    onChange={(e) => {
                                                        const updatedPlatforms = [...platforms];
                                                        updatedPlatforms[index].url = e.target.value;
                                                        setPlatforms(updatedPlatforms);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <Button
                                            type="text"
                                            danger
                                            icon={<CloseOutlined />}
                                            onClick={() => {
                                                const updatedPlatforms = platforms.filter((_, i) => i !== index);
                                                setPlatforms(updatedPlatforms);
                                            }}
                                        />
                                    </div>
                                ))}
                                <Button
                                    type="dashed"
                                    className="mt-4 text-[#67BE5F] border-[#67BE5F]"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        setPlatforms([...platforms, { id: Date.now().toString(), type: "", url: "" }]);
                                    }}
                                >
                                    新增官方平台
                                </Button>
                            </div>
        </>
    );
};

const pancoConfig = {
    tabs: [
        { key: 'basic', label: '基本資訊' },
    ],
    organization: {
        title: '店家管理',
        add: '新增店家',
        draft: '儲存至草稿夾',
        publish: '儲存並發布'
    },
    formItems: {
        basic: [
            {
                name: 'name',
                label: '店家名稱',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家名稱'
                },
                rules: [
                    { required: true, message: '請輸入店家名稱' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'serviceCode',
                label: '店家序號',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: '系統自動填入',
                options: {
                    disabled: true,
                    placeholder: '系統自動填入',
                    style: {
                        background: '#d9d9d9'
                    }
                }
            },
            {
                name: 'serviceImage',
                label: '店家主要圖片',
                type: 'custom',
                required: true,
                rules: [{ required: true, message: '請上傳圖片' }],
                component: BannerUpload,
                col: 24,
            },
            {
                name: 'storePhone',
                label: '店家電話',
                type: 'text',
                required: true,

                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家電話'
                },
                rules: [
                    { required: true, message: '請輸入店家電話' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'email',
                label: '店家Email',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家Email'
                },
                rules: [
                    { required: true, message: '請輸入店家Email' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'admin',
                label: '負責人',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入負責人姓名'
                },
                rules: [
                    { required: true, message: '請輸入負責人姓名' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'phone',
                label: '電話',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家Email'
                },
                rules: [
                    { required: true, message: '請輸入店家Email' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'search',
                label: '分派店家帳號權限',
                type: 'custom',
                component: () => (
                    <div className="relative w-full">
                        <Input
                            placeholder="透過手機號碼、姓名搜尋用戶"
                            className="w-full h-[40px] pl-4 pr-12 rounded-lg border border-gray-300"
                        />
                        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21 21L16.65 16.65" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                ),
                col: 8,
                wrapperCol: { span: 24 }
            },
            {
                name: 'establishmentDate',
                label: '創立日期',
                type: 'date',
                required: true,
                component: DatePicker,
                col: 24,
                wrapperCol: { span: 24 },
            },
            {
                name: 'address',
                label: '店家地址',
                type: 'custom',
                required: true,
                col: 24,
                component: () => (
                    <div className="flex gap-4">
                        <Form.Item
                            name="city"
                            rules={[{ required: true, message: '請輸入城市' }]}
                            style={{ marginBottom: 0, flex: 1 }}
                        >
                            <Input placeholder="請輸入城市" addonAfter="市" />
                        </Form.Item>
                        <Form.Item
                            name="district"
                            rules={[{ required: true, message: '請輸入區域' }]}
                            style={{ marginBottom: 0, flex: 1 }}
                        >
                            <Input placeholder="請輸入區域" addonAfter="區" />
                        </Form.Item>
                        <Form.Item
                            name="detail"
                            rules={[{ required: true, message: '請輸入詳細地址' }]}
                            style={{ marginBottom: 0, flex: 2 }}
                        >
                            <Input placeholder="請輸入詳細地址" />
                        </Form.Item>
                    </div>
                ),
                wrapperCol: { span: 24 }
            },
            {
                name: 'serviceDescription',
                label: '服務描述',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入服務介紹',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceTags',
                label: '標籤',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
                labelCol: { span: 24 }
            },
            
            {
                name: 'reservation',
                label: '預約',
                type: 'select',
                col: 6,
                required: true,
                component: Select,
                labelCol: { span: 24 },
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇預約方式',
                    options: [
                        { label: '不需預約', value: 'no_reservation' },
                        { label: '須預約日期', value: 'date_only' },
                        { label: '須預約日期及時段', value: 'date_time' }
                    ]
                },
                initialValue: 'no_reservation'
            },
            {
                name: 'reservationPeriod',
                col: 6,
                type: 'custom',
                component: () => (
                    <Form.Item
                        name="reservationPeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>可預約區間</span>
                                <Form.Item
                                    name={['reservationPeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>不限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.reservationPeriod?.noLimit !== currentValues?.reservationPeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['reservationPeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'servicePeriod',
                col: 24,
                type: 'custom',
                required: true,
                component: () => (
                    <Form.Item
                        name="servicePeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>使用期間</span>
                                <Form.Item
                                    name={['servicePeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>無期限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                        wrapperCol={{
                            span: 6
                        }}
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'businessHours',
                label: '',
                type: 'custom',
                required: true,
                col: 24,
                component: ({ form }) => <BusinessHours form={form} />,
                wrapperCol: { span: 24 }
            },
            {
                name: 'platform',
                label: '',
                type: 'custom',
                component: PlatformSelect,
                col: 24,
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            },
            
        ]
    }
};

const stag5Config = {
    tabs: [
        { key: 'basic', label: 'Basic Information' },
    ],
    organization: {
        title: 'Store Management',
        add: 'Add Store',
        draft: 'Save to Draft',
        publish: 'Save and Publish'
    },
    formItems: {
        basic: [
            {
                name: 'name',
                label: '店家名稱',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家名稱'
                },
                rules: [
                    { required: true, message: '請輸入店家名稱' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'serviceCode',
                label: '店家序號',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: '系統自動填入',
                options: {
                    disabled: true,
                    placeholder: '系統自動填入',
                    style: {
                        background: '#d9d9d9'
                    }
                }
            },
            {
                name: 'serviceImage',
                label: '店家主要圖片',
                type: 'custom',
                required: true,
                rules: [{ required: true, message: '請上傳圖片' }],
                component: BannerUpload,
                col: 24,
            },
            {
                name: 'storePhone',
                label: '店家電話',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家電話'
                },
                rules: [
                    { required: true, message: '請輸入店家電話' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'email',
                label: '店家Email',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家Email'
                },
                rules: [
                    { required: true, message: '請輸入店家Email' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'admin',
                label: '負責人',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入負責人姓名'
                },
                rules: [
                    { required: true, message: '請輸入負責人姓名' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'phone',
                label: '電話',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家Email'
                },
                rules: [
                    { required: true, message: '請輸入店家Email' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'search',
                label: '分派店家帳號權限',
                type: 'custom',
                component: () => (
                    <div className="relative w-full">
                        <Input
                            placeholder="透過手機號碼、姓名搜尋用戶"
                            className="w-full h-[40px] pl-4 pr-12 rounded-lg border border-gray-300"
                        />
                        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21 21L16.65 16.65" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                ),
                col: 8,
                wrapperCol: { span: 24 }
            },
            {
                name: 'establishmentDate',
                label: '創立日期',
                type: 'date',
                required: true,
                component: DatePicker,
                col: 24,
                wrapperCol: { span: 24 },
            },
            {
                name: 'address',
                label: '店家地址',
                type: 'custom',
                required: true,
                col: 24,
                component: () => (
                    <div className="flex gap-4">
                        <Form.Item
                            name="city"
                            rules={[{ required: true, message: '請輸入城市' }]}
                            style={{ marginBottom: 0, flex: 1 }}
                        >
                            <Input placeholder="請輸入城市" addonAfter="市" />
                        </Form.Item>
                        <Form.Item
                            name="district"
                            rules={[{ required: true, message: '請輸入區域' }]}
                            style={{ marginBottom: 0, flex: 1 }}
                        >
                            <Input placeholder="請輸入區域" addonAfter="區" />
                        </Form.Item>
                        <Form.Item
                            name="detail"
                            rules={[{ required: true, message: '請輸入詳細地址' }]}
                            style={{ marginBottom: 0, flex: 2 }}
                        >
                            <Input placeholder="請輸入詳細地址" />
                        </Form.Item>
                    </div>
                ),
                wrapperCol: { span: 24 }
            },
            {
                name: 'serviceDescription',
                label: '服務描述',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入服務介紹',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceTags',
                label: '標籤',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
                labelCol: { span: 24 }
            },
            
            {
                name: 'reservation',
                label: '預約',
                type: 'select',
                col: 6,
                required: true,
                component: Select,
                labelCol: { span: 24 },
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇預約方式',
                    options: [
                        { label: '不需預約', value: 'no_reservation' },
                        { label: '須預約日期', value: 'date_only' },
                        { label: '須預約日期及時段', value: 'date_time' }
                    ]
                },
                initialValue: 'no_reservation'
            },
            {
                name: 'reservationPeriod',
                col: 6,
                type: 'custom',
                component: () => (
                    <Form.Item
                        name="reservationPeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>可預約區間</span>
                                <Form.Item
                                    name={['reservationPeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>不限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.reservationPeriod?.noLimit !== currentValues?.reservationPeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['reservationPeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'servicePeriod',
                col: 24,
                type: 'custom',
                required: true,
                component: () => (
                    <Form.Item
                        name="servicePeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>使用期間</span>
                                <Form.Item
                                    name={['servicePeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>無期限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                        wrapperCol={{
                            span: 6
                        }}
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'businessHours',
                label: '',
                type: 'custom',
                required: true,
                col: 24,
                component: ({ form }) => <BusinessHours form={form} />,
                wrapperCol: { span: 24 }
            },
            {
                name: 'platform',
                label: '',
                type: 'custom',
                component: PlatformSelect,
                col: 24,
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            },
            
        ]
    }
};

const _77Config = {
    tabs: [
        { key: 'basic', label: '基本資訊' },
    ],
    organization: {
        title: '店家管理',
        add: '新增店家',
        draft: '儲存至草稿夾',
        publish: '儲存並發布'
    },
    formItems: {
        basic: [
            {
                name: 'name',
                label: '店家名稱',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家名稱'
                },
                rules: [
                    { required: true, message: '請輸入店家名稱' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'serviceCode',
                label: '店家序號',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                placeholder: '系統自動填入',
                options: {
                    disabled: true,
                    placeholder: '系統自動填入',
                    style: {
                        background: '#d9d9d9'
                    }
                }
            },
            {
                name: 'serviceImage',
                label: '店家主要圖片',
                type: 'custom',
                required: true,
                rules: [{ required: true, message: '請上傳圖片' }],
                component: BannerUpload,
                col: 24,
            },
            {
                name: 'storePhone',
                label: '店家電話',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家電話'
                },
                rules: [
                    { required: true, message: '請輸入店家電話' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'email',
                label: '店家Email',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家Email'
                },
                rules: [
                    { required: true, message: '請輸入店家Email' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'admin',
                label: '負責人',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入負責人姓名'
                },
                rules: [
                    { required: true, message: '請輸入負責人姓名' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'phone',
                label: '電話',
                type: 'text',
                required: true,
                component: Input,
                col: 12,
                options: {
                    placeholder: '請輸入店家Email'
                },
                rules: [
                    { required: true, message: '請輸入店家Email' },
                    { max: 128, message: '最多128字元' }
                ]
            },
            {
                name: 'search',
                label: '分派店家帳號權限',
                type: 'custom',
                component: () => (
                    <div className="relative w-full">
                        <Input
                            placeholder="透過手機號碼、姓名搜尋用戶"
                            className="w-full h-[40px] pl-4 pr-12 rounded-lg border border-gray-300"
                        />
                        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21 21L16.65 16.65" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                ),
                col: 8,
                wrapperCol: { span: 24 }
            },
            {
                name: 'establishmentDate',
                label: '創立日期',
                type: 'date',
                required: true,
                component: DatePicker,
                col: 24,
                wrapperCol: { span: 24 },
            },
            {
                name: 'address',
                label: '店家地址',
                type: 'custom',
                required: true,
                col: 24,
                component: () => (
                    <div className="flex gap-4">
                        <Form.Item
                            name="city"
                            rules={[{ required: true, message: '請輸入城市' }]}
                            style={{ marginBottom: 0, flex: 1 }}
                        >
                            <Input placeholder="請輸入城市" addonAfter="市" />
                        </Form.Item>
                        <Form.Item
                            name="district"
                            rules={[{ required: true, message: '請輸入區域' }]}
                            style={{ marginBottom: 0, flex: 1 }}
                        >
                            <Input placeholder="請輸入區域" addonAfter="區" />
                        </Form.Item>
                        <Form.Item
                            name="detail"
                            rules={[{ required: true, message: '請輸入詳細地址' }]}
                            style={{ marginBottom: 0, flex: 2 }}
                        >
                            <Input placeholder="請輸入詳細地址" />
                        </Form.Item>
                    </div>
                ),
                wrapperCol: { span: 24 }
            },
            {
                name: 'serviceDescription',
                label: '服務描述',
                type: 'textarea',
                required: true,
                component: Input.TextArea,
                col: 24,
                options: {
                    autoSize: { minRows: 4, maxRows: 4 },
                    showCount: true,
                    maxLength: 500,
                    placeholder: '請輸入服務介紹',
                    style: {
                        resize: 'none',
                        height: '120px'
                    }
                }
            },
            {
                name: 'serviceTags',
                label: '標籤',
                type: 'custom',
                required: true,
                component: TagSelector,
                col: 24,
                labelCol: { span: 24 }
            },
            
            {
                name: 'reservation',
                label: '預約',
                type: 'select',
                col: 6,
                required: true,
                component: Select,
                labelCol: { span: 24 },
                style: {
                    display: 'block',
                    width: '100%'
                },
                options: {
                    placeholder: '請選擇預約方式',
                    options: [
                        { label: '不需預約', value: 'no_reservation' },
                        { label: '須預約日期', value: 'date_only' },
                        { label: '須預約日期及時段', value: 'date_time' }
                    ]
                },
                initialValue: 'no_reservation'
            },
            {
                name: 'reservationPeriod',
                col: 6,
                type: 'custom',
                component: () => (
                    <Form.Item
                        name="reservationPeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>可預約區間</span>
                                <Form.Item
                                    name={['reservationPeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>不限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.reservationPeriod?.noLimit !== currentValues?.reservationPeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['reservationPeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'servicePeriod',
                col: 24,
                type: 'custom',
                required: true,
                component: () => (
                    <Form.Item
                        name="servicePeriod"
                        className="custom-label-flex"
                        label={
                            <div style={{ 
                                display: 'flex',
                                justifyContent: 'space-between', 
                                width: '100%'
                            }}>
                                <span>使用期間</span>
                                <Form.Item
                                    name={['servicePeriod', 'noLimit']}
                                    valuePropName="checked"
                                    noStyle
                                >
                                    <Checkbox>無期限</Checkbox>
                                </Form.Item>
                            </div>
                        }
                        labelCol={{ 
                            style: { 
                                width: '100%'
                            }
                        }}
                        wrapperCol={{
                            span: 6
                        }}
                        style={{
                            marginTop: '16px'
                        }}
                    >
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                return prevValues?.servicePeriod?.noLimit !== currentValues?.servicePeriod?.noLimit;
                            }}
                        >
                            {({ getFieldValue }) => (
                                <DatePicker.RangePicker 
                                    style={{ width: '100%' }}
                                    disabled={getFieldValue(['servicePeriod', 'noLimit'])}
                                />
                            )}
                        </Form.Item>
                    </Form.Item>
                )
            },
            {
                name: 'businessHours',
                label: '',
                type: 'custom',
                required: true,
                col: 24,
                component: ({ form }) => <BusinessHours form={form} />,
                wrapperCol: { span: 24 }
            },
            {
                name: 'platform',
                label: '',
                type: 'custom',
                component: PlatformSelect,
                col: 24,
            },
            {
                name: 'basicDivider',
                type: 'custom',
                component: () => <Divider className="my-1 border-t-2 border-solid border-gray-300" />,
                col: 24
            },
            
        ]
    }
};

// form scroll active tab
const useFormScroll = (formRef) => {
    const [activeTab, setActiveTab] = useState('basic');

    useEffect(() => {
        const handleScroll = () => {
            if (!formRef.current) return;

            const container = formRef.current.closest('.overflow-auto');
            if (!container) return;

            const containerTop = container.getBoundingClientRect().top;
            const offset = 200; // 整這個值來改變切換的觸發位置

            const tabSections = pancoConfig.tabs.map(tab => {
                const element = document.getElementById(tab.key);
                if (!element) return { key: tab.key, top: Infinity };
                const rect = element.getBoundingClientRect();
                // 計算相對於容器的位置
                const relativeTop = rect.top - containerTop;
                return {
                    key: tab.key,
                    top: relativeTop
                };
            }).filter(section => section.top !== Infinity);

            // 找到最接近但還未超過觸發位置的section
            const currentSection = tabSections.reduce((prev, curr) => {
                if (curr.top <= offset && curr.top > prev.top) {
                    return curr;
                }
                return prev;
            }, { key: tabSections[0].key, top: -Infinity });

            if (currentSection && currentSection.key !== activeTab) {
                setActiveTab(currentSection.key);
            }
        };

        const container = formRef.current?.closest('.overflow-auto');
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [formRef, activeTab]);

    return activeTab;
};

// 新增表單邏輯處理
const formLogic = {
  handleSubmit: async (values) => {
    try {
        // const discounts = [];
        // const specs = [];


        console.log('表單原始資料', values);
        
        const formattedData = {
            name: values.name || '',
            phone: values.phone || '',
            email: values.email || '',
            // address: values.serviceNotice || '',
            // region_id: values.category?.id || '',
            // service_region_ids: values.beauticians?.map(item => item.identity_id) || [],
        };

        // 處理規格金額資料

        // if (values.priceType === 'spec' && values.specData) {
        //     // 取得規格1和規格2的所有選項（過濾空值）
        //     const spec1Options = values.specSettings[0]?.options.filter(opt => opt.trim() !== '') || [];
        //     const spec2Options = values.specSettings[1]?.options.filter(opt => opt.trim() !== '') || [];
            
        //     values.specData.forEach((row, rowIndex) => {
        //         const spec1Name = values.specSettings[0]?.specName || '';
        //         const spec2Name = values.specSettings[1]?.specName || '';
                
        //         if (row.options) {
        //             // 多規格情況
        //             row.options.forEach((option, optionIndex) => {
        //                 const information = {
        //                     tier_variations: [
        //                         {
        //                             name: spec1Name,
        //                             value: spec1Options[rowIndex]
        //                         }
        //                     ]
        //                 };

        //                 // 如果有規格2，則添加到 tier_variations
        //                 if (spec2Name && spec2Options[optionIndex]) {
        //                     information.tier_variations.push({
        //                         name: spec2Name,
        //                         value: spec2Options[optionIndex]
        //                     });
        //                 }

        //                 const formattedSpec = {
        //                     information,
        //                     name: `${spec1Options[rowIndex]} ${spec2Options[optionIndex] || ''}`.trim(),
        //                     description: "",
        //                     price: option.price || 0,
        //                     cost: 0,
        //                     discount_enabled: option.discount || false,
        //                     stock: option.stock === 'limited' ? (option.stockAmount || 0) : 0,
        //                     unlimited_stock: option.stock === 'unlimited'
        //                 };
        //                 specs.push(formattedSpec);
        //             });
        //         } else {
        //             // 單一規格情況
        //             const information = {
        //                 tier_variations: [
        //                     {
        //                         name: spec1Name,
        //                         value: spec1Options[rowIndex]
        //                     }
        //                 ]
        //             };

        //             const formattedSpec = {
        //                 information,
        //                 name: spec1Options[rowIndex] || '',
        //                 description: "",
        //                 price: row.price || 0,
        //                 cost: 0,
        //                 discount_enabled: row.discount || false,
        //                 stock: row.stock === 'limited' ? (row.stockAmount || 0) : 0,
        //                 unlimited_stock: row.stock === 'unlimited'
        //             };
        //             specs.push(formattedSpec);
        //         }
        //     });
        // }

        // 處理一般折扣
        // if (values.discount?.type === 'member') {
        //     discounts.push({
        //         name: '會員折扣',
        //         description: `會員${values.discount.value}折優惠`,
        //         discount: values.discount.value || 0,
        //         discount_percentage: values.discount.percentOff || 0,
        //         level: 1, // 會員等級
        //         valid_from: values.discount?.period?.[0]?.format('YYYY-MM-DD') || null,
        //         valid_until: values.discount?.period?.[1]?.format('YYYY-MM-DD') || null
        //     });
        // }

        // 處理晚鳥優惠
        // if (values.lateDiscount?.type === 'late') {
        //     discounts.push({
        //         name: '晚鳥優惠',
        //         description: `晚鳥${values.lateDiscount.value}折優惠`,
        //         discount: values.lateDiscount.value || 0,
        //         discount_percentage: values.lateDiscount.percentOff || 0,
        //         level: 0, // 不需要會員等級
        //         valid_from: values.lateDiscount?.period?.[0]?.format('YYYY-MM-DD') || null,
        //         valid_until: values.lateDiscount?.period?.[1]?.format('YYYY-MM-DD') || null
        //     });
        // }

        // 將規格資料加入 formattedData
        // formattedData.specs = specs;
        
        // 將折扣資料加入 formattedData
        // formattedData.discount = discounts;
        // formattedData.profit_share = values.profitShare || 0;
        // formattedData.bonus_points = values.bonusPoints || 0;
        
        console.log('表單提交數據:', formattedData);
        const res = await createOrganizationAPI(formattedData);
        
        if (res.status === 200) {
            const { data } = res;
            const id = data.id;
            const verifyRes = await verifyOrganizationAPI(id);
            if (verifyRes.status === 200) {
                message.success('organization created successfully');
                window.location.href = '/organization';
            } else {
                message.error('organization creation failed');
            }

            // 修改這裡：循環處理所有的 specs
            // try {
            //     for (const spec of specs) {
            //         await createSpecAPI(id, spec);
            //     }
            //     message.success('服務和規格創建成功');
            // } catch (error) {
            //     console.error('規格創建失敗:', error);
            //     message.error('部分規格創建失敗');
            // }

            // try {
            //     for (const discount of discounts) {
            //         console.log('discount:', discount);
            //         await createDiscountAPI(id, discount);
            //     }
            //     message.success('服務和折扣創建成功');
            // } catch (error) {
            //     console.error('折扣創建失敗:', error);
            //     message.error('折扣創建失敗');
            // }

        } else {
            message.error('organization creation failed');
        }

    } catch (error) {
        console.error('表單提交失敗:', error);
        message.error('organization creation failed');
    }
  },

  scrollToTab: (key, formRef) => {
    const element = document.getElementById(key);
    if (element) {
      const container = formRef.current.closest('.overflow-auto');
      if (container) {
        container.scrollTo({
          top: element.offsetTop - 300,
          behavior: 'smooth'
        });
      }
    }
  }
};

export { useFormScroll, formLogic };

// 根據環境變數選擇配置
const isPanco = ENV.E_COMMERCE.PANCO;
const isStag5 = ENV.E_COMMERCE.STAG5;
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const organizationFormConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : is77 ? _77Config : null;
