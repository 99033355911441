/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Collapse, theme, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import axios from 'axios';

import { useUser } from '@/contexts/UserProvider';
import { useAuth } from '@/contexts/AuthProvider';

import { ReactComponent as Edit } from '../../settings/assets/edit_icon.svg';
import { ReactComponent as Delete } from '../../settings/assets/delete_icon.svg';

import ConfirmWindow from './ConfirmWindow';

const { Panel } = Collapse;

const SysMgnt = ({ changeTab, departments, departmentUsers, createdRooms }) => {
  const { user } = useUser();
  const { token: themeToken } = theme.useToken();
  const { token } = useAuth();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [context, setContext] = useState('');
  const [title, setTitle] = useState('');
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    console.log('selectedUserId', selectedUserId);
  }, [selectedUserId]);
    

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const panelStyle = {
    marginBottom: 0,
    background: themeToken.colorFillAlter,
    border: 'none',
    padding: 0,
  };

  const handlePopupConfirm = (title, context, id) => {
    setConfirmPopup(true);
    setContext(context);
    setTitle(title);
    setSelectedRoom(id);
    setSelectedUserId(id);
  };

  const handleDelete = async () => {
    // if (title === '移除房間') {
    try {
      await axios.delete(`${SERVER_URL}/private/chatrooms/${selectedRoom}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload();
    } catch (error) {
      message.error(error.response.data.detail)
    }
  };

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: '#ADDEAC', fontSize: '20px' }} />}
      style={{
        background: themeToken.colorBgContainer,
        maxHight: '85dvh',
        overflowY: 'auto',
        zIndex: 0,
        padding: 0,
        backgroundColor: '#F7F7F7',
      }}
    >
      {confirmPopup && <ConfirmWindow
        title={title}
        context={context}
        handlePopupClose={() => setConfirmPopup(false)}
        confirmChangeOwner={() => { }}
        confirmAction={handleDelete} />}
      <Panel
        header={(
          <div className='flex w-[97%] justify-between p-0'>
            {user && user.company_name}
            {user && user.role_id === 4 ?
              <div className='flex space-x-2'>
                <Edit className='cursor-pointer'
                  onClick={() => changeTab(2)} />
              </div> : null}
          </div>)}
        key="1"
        style={panelStyle}>
        <Collapse
          bordered={false}
          defaultActiveKey={['2']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: '#ADDEAC', fontSize: '20px', marginLeft: '30px' }} />}
          style={{
            background: themeToken.colorBgContainer,
            width: '100%',
            backgroundColor: '#F7F7F7',
            padding: 0

          }}
        >
          {departments && departments.map((department) => (
            <Panel
              header={(
                <div className='flex w-[98.5%] justify-between'>
                  {department.name}
                  {user && user.role_id === 4 ?
                    <div className='flex space-x-2' >
                      {/* <p>{department.id}</p> */}
                      <Edit className='cursor-pointer' onClick={() => { changeTab(3, department.id) }} />
                      {/* <Delete className='cursor-pointer' onClick={() => { handlePopupConfirm('移除部門', '請確認是否移除部門：' + department.name) }} /> */}
                    </div> : null}
                </div>)}
              key={department.id}
              style={panelStyle}>
              <Collapse
                bordered={false}
                defaultActiveKey={[]}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} style={{ color: '#ADDEAC', fontSize: '20px', marginLeft: '60px' }} />}
                style={{
                  // background: themeToken.colorBgContainer,
                  backgroundColor: '#F7F7F7',
                  width: '100%',
                  padding: 0
                }}
              >
                {departmentUsers[department.id]?.map((depUser) => (
                  <Panel
                    header={(
                      <div className='flex w-[100%]  justify-between items-center'>
                        {depUser.nickname}
                        <div className='flex space-x-2 '>
                          <Edit className='cursor-pointer' onClick={() => { changeTab(0, department.id, depUser.id) }} />
                          {/* <Delete className='cursor-pointer' onClick={() => { handlePopupConfirm('移除帳號', '請確認是否移除帳號：' + depUser.nickname, depUser.id) }} /> */}
                        </div>
                      </div>
                    )}
                    key={depUser.id}
                    style={panelStyle}
                  >
                    {createdRooms[depUser.id]?.length > 0 ? (
                      createdRooms[depUser.id].map((room) => (
                        <Panel
                          header={(
                            <div className='flex justify-between items-center bg-[#ECF7EC] border-b-[1px] border-gray py-1 ml-[60px]'>
                              {room.name}
                              <div className='flex space-x-5'>
                                <Edit className='cursor-pointer' onClick={() => { changeTab(1, department.id, depUser.id, room.id) }} />
                                <Delete className='cursor-pointer' onClick={() => { handlePopupConfirm('移除房間', '請確認是否移除房間：' + room.name, room.id) }} />
                              </div>
                            </div>
                          )}
                          key={room.id}
                          className='panelStyle'
                        />
                      ))
                    ) :
                      null}
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          ))}
        </Collapse>
      </Panel>
    </Collapse>
  );
}

export default SysMgnt;