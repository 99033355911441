/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, message, Switch, Radio, Input, Modal, Tooltip } from "antd";
import axios from "axios";

import TutorialModal from "./TutorialModal";

import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const WEBHOOK_PATH = "/public/line/callback/";

const propertyMap = (item) => {
  if (item === "useWebhook") return "use_webhook";
  if (item === "temperature") return "temperature";
  if (item === "webSearch") return "web_search";
  if (item === "responseMode") return "response_mode";
  if (item === "queryOnly") return "query_only";
  if (item === "agentType") return "agent_type";
  return item;
};

const Footer = ({ disabled, ctaWord = "完成", onClick, tutorial }) => {
  return (
    <div className="flex flex-row justify-end items-center gap-2 mt-4">
      <Button onClick={() => tutorial(true)}>說明</Button>
      <Button type="primary" onClick={onClick} disabled={disabled}>
        {ctaWord}
      </Button>
    </div>
  );
};

const BaseModal = ({
  id,
  token,
  open,
  close,
  webhook,
  tutorial,
  refresh,
  oldChannelId,
  oldChannelSecret,
  oldChannelAccessToken,
}) => {
  const [channelId, setChannelId] = useState(oldChannelId);
  const [channelSecret, setChannelSecret] = useState(oldChannelSecret);
  const [channelAccessToken, setChannelAccessToken] = useState(
    oldChannelAccessToken
  );

  const handleUpdateBase = async () => {
    try {
      await axios.patch(
        `${SERVER_URL}/private/property/chatroom/line_channel/settings/${id}/base`,
        {
          channel_id: channelId,
          channel_secret: channelSecret,
          channel_access_token: channelAccessToken,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Line@ 串接變更成功");
      close();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  const handleComplete = () => {
    handleUpdateBase();
    refresh();
    close();
    webhook(true);
  };

  return (
    <Modal
      open={open}
      onCancel={close}
      width={485}
      footer={
        <Footer
          disabled={false}
          ctaWord="變更"
          onClick={handleComplete}
          tutorial={tutorial}
        />
      }
      title="Line@ 變更串接"
    >
      <div className="text-xs text-textLight mb-4">
        請確認三項資料皆正確，以免變更後串接失敗。變更串接後可能導致聊天資料於本系統中遺失。
      </div>
      <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Channel ID</div>
          <Input
            value={channelId}
            onChange={(e) => setChannelId(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Channel Secret</div>
          <Input
            value={channelSecret}
            onChange={(e) => setChannelSecret(e.target.value)}
          />
        </div>
        <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
          <div className="font-bold">Access Token</div>
          <Input
            value={channelAccessToken}
            onChange={(e) => setChannelAccessToken(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

const WebhookModal = ({ id, open, close, handleCopy, copied, tutorial }) => {
  const [copiedOnce, setCopiedOnce] = useState(false);

  useEffect(() => {
    if (copied) {
      setCopiedOnce(true);
    }
  }, [copied]);

  return (
    <Modal
      open={open}
      maskClosable={false}
      closeIcon={null}
      width={400}
      footer={
        <Footer disabled={!copiedOnce} onClick={close} tutorial={tutorial} />
      }
      title="Webhook 網址"
    >
      <div className="flex flex-col items-center min-h-20">
        <div className="items-start">
          <div className="text-textDark mb-2">
            前往 Line Developer 後台，在您 Provider 的中 Messaging API
            的頁面內，於 Webhook settings 的 Webhook URL 區塊貼上以下網址。
          </div>
          <Tooltip title={copied ? "已複製" : "點擊以複製"}>
            <div
              className="flex flex-col p-2 rounded bg-bgLight border w-full"
              onClick={handleCopy}
            >
              {`${SERVER_URL}${WEBHOOK_PATH}${id}`}
            </div>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

const LineSetting = ({ room, token, showWebhook = true, clientId = null }) => {
  const [loading, setLoading] = useState(false);
  const [bound, setBound] = useState(false);
  const [openWebhookModal, setOpenWebhookModal] = useState(false);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [channelSecret, setChannelSecret] = useState("");
  const [channelAccessToken, setChannelAccessToken] = useState("");
  const [temperature, setTemperature] = useState(null);
  const [webSearch, setWebSearch] = useState(null);
  const [agentType, setAgentType] = useState(null);
  const [responseMode, setResponseMode] = useState(null);
  const [queryOnly, setQueryOnly] = useState(null);
  const [useWebhook, setUseWebhook] = useState(null);
  const [openUnbind, setOpenUnbind] = useState(false);
  const [openBaseModal, setOpenBaseModal] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
    console.log('agentType', agentType);
    
  }, [copied]);

  useEffect(() => {
    fetchLineProperty();
    fetchUseWebhook();
  }, [room, clientId]);

  const fetchUseWebhook = async () => {
    if (clientId) {
      setLoading(true);
      try {
        const response = await axios.get(
          `${SERVER_URL}/private/property/chatroom/line_channel/settings/${room.id}/use_webhook/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setUseWebhook(response.data);
      } catch (error) {
        // if 404 not show
        if (error.response?.status !== 404) {
          message.error(JSON.stringify(error.response?.data?.detail));
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchLineProperty = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setChannelId(response.data.body.channel_id);
      setChannelSecret(response.data.body.channel_secret);
      setChannelAccessToken(response.data.body.channel_access_token);

      // console.log(response.data.body)

      setTemperature(response.data.body.temperature);
      setWebSearch(response.data.body.web_search);
      setAgentType(response.data.body.agent_type);
      setResponseMode(response.data.body.response_mode);
      setQueryOnly(response.data.body.query_only);

      setBound(true);
    } catch (error) {
      // message.error(JSON.stringify(error.response?.data?.detail));
      setBound(false);
    } finally {
      setLoading(false);
    }
  };

  const handleBind = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}`,
        {
          channel_id: channelId,
          channel_secret: channelSecret,
          channel_access_token: channelAccessToken,
          temperature: 0.7,
          web_search: false,
          agent_type: "image_reader",
          response_mode: "text_only",
          query_only: false,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Line@ 已串接成功");
      fetchLineProperty();
      setOpenWebhookModal(true);
    } catch (error) {
      // message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProperty = async (item, value) => {
    setLoading(true);
    const mappedItem = propertyMap(item);

    const path =
      mappedItem === "use_webhook"
        ? `${SERVER_URL}/private/property/chatroom/line_channel/settings/${room.id}/${mappedItem}/${clientId}`
        : `${SERVER_URL}/private/property/chatroom/line_channel/settings/${room.id}/${mappedItem}`;

    try {
      await axios.patch(
        path,
        {},
        {
          params: {
            [mappedItem]: value,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("已更新 AI 狀態設定");
      fetchLineProperty();
      fetchUseWebhook();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${SERVER_URL}${WEBHOOK_PATH}${room.id}`);
    setCopied(true);
  };

  const handleUnbind = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `${SERVER_URL}/private/property/chatroom/line_channel/${room.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Line@ 已解除串接");
      setBound(false);
      setOpenUnbind(false);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <>
      <BaseModal
        id={room.id}
        token={token}
        open={openBaseModal}
        close={() => setOpenBaseModal(false)}
        webhook={setOpenWebhookModal}
        tutorial={setOpenTutorialModal}
        refresh={fetchLineProperty}
        oldChannelId={channelId}
        oldChannelSecret={channelSecret}
        oldChannelAccessToken={channelAccessToken}
      />
      <WebhookModal
        open={openWebhookModal}
        close={() => setOpenWebhookModal(false)}
        id={room.id}
        handleCopy={handleCopy}
        copied={copied}
        tutorial={setOpenTutorialModal}
      />
      <TutorialModal
        open={openTutorialModal}
        close={() => setOpenTutorialModal(false)}
      />
      <div className="flex flex-col justify-start items-start w-full px-4 py-6 gap-4 overflow-y-auto">
        {bound ? (
          <>
            {clientId && (
              <>
                <div className="text-lg text-textDark font-bold w-full">
                  AI 個別狀態設定
                </div>
                <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                    <div className="font-bold">AI 狀態</div>
                    <div className="flex flex-row justify-between w-full items-center">
                      <div>針對此客戶啟用 AI</div>
                      <Tooltip title="將自動傳送狀態變更訊息給客戶">
                        <Switch
                          value={useWebhook}
                          onChange={(e) =>
                            handleUpdateProperty("useWebhook", e)
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex justify-between items-center text-lg text-textDark font-bold w-full">
              <span>AI 全域狀態設定</span>
              <ReloadOutlined
                className="flex items-center hover:text-primary ml-2"
                style={{ width: "12px", height: "12px" }}
                onClick={fetchLineProperty}
              />
            </div>
            <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                <div className="font-bold">Web 模式</div>
                <div className="flex flex-row justify-between w-full items-center">
                  <div>AI 上網</div>
                  <Switch
                    value={webSearch}
                    onChange={(e) => handleUpdateProperty("webSearch", e)}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                <div className="font-bold">連續對話模式</div>
                <div className="flex flex-row justify-between w-full items-center">
                  <div>AI 會延續之前的對話</div>
                  <Switch
                    value={!queryOnly}
                    onChange={(e) => handleUpdateProperty("queryOnly", !e)}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                <div className="font-bold">AI 回覆模式</div>
                <div className="flex flex-row justify-between w-full items-center">
                  <Radio.Group value={temperature} buttonStyle="solid">
                    <Radio.Button
                      value={0.3}
                      onClick={() => handleUpdateProperty("temperature", 0.3)}
                    >
                      精確
                    </Radio.Button>
                    <Radio.Button
                      value={0.5}
                      onClick={() => handleUpdateProperty("temperature", 0.5)}
                    >
                      平衡
                    </Radio.Button>
                    <Radio.Button
                      value={0.7}
                      onClick={() => handleUpdateProperty("temperature", 0.7)}
                    >
                      創造
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                <div className="font-bold">AI 回覆方式</div>
                <div className="flex flex-row justify-between w-full items-center">
                  <Radio.Group value={responseMode} buttonStyle="solid">
                    <Radio.Button
                      value={"voice_only"}
                      onClick={() =>
                        handleUpdateProperty("responseMode", "voice_only")
                      }
                    >
                      語音
                    </Radio.Button>
                    <Radio.Button
                      value={"text_only"}
                      onClick={() =>
                        handleUpdateProperty("responseMode", "text_only")
                      }
                    >
                      文字
                    </Radio.Button>
                    <Radio.Button
                      value={"text_and_voice"}
                      onClick={() =>
                        handleUpdateProperty("responseMode", "text_and_voice")
                      }
                    >
                      語音+文字
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>
              {/* <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                  <div className="font-bold">AgentType</div>
                  <div className="flex flex-row justify-between w-full items-center">
                    <Radio.Group value={agentType} buttonStyle="solid">
                      <Radio.Button value={'image_reader'} onClick={() => handleUpdateProperty("agentType", "image_reader")}>image_reader</Radio.Button>
                      <Radio.Button value={'image_reader_only_text'} onClick={() => handleUpdateProperty("agentType", "image_reader_only_text")}>image_reader_only_text</Radio.Button>
                    </Radio.Group>
                  </div>
                </div> */}
            </div>
            {showWebhook && (
              <>
                <div className="text-lg text-textDark font-bold w-full">
                  串接管理
                </div>
                <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Channel ID</div>
                    <div className="flex flex-1 p-2 rounded bg-bgLight border w-[418px] overflow-scroll max-w-full">
                      {channelId}
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Channel Secret</div>
                    <div className="flex flex-1 p-2 rounded bg-bgLight border w-[418px] overflow-scroll max-w-full">
                      {channelSecret}
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight overflow-scroll">
                    <div className="font-bold">Access Token</div>
                    <div className="flex flex-1 p-2 rounded bg-bgLight border w-[418px] overflow-scroll max-w-full">
                      {channelAccessToken}
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                    <div className="font-bold">Webhook 網址</div>
                    <Tooltip title={copied ? "已複製" : "點擊以複製"}>
                      <div
                        className="flex flex-col p-2 rounded bg-bgLight border w-[418px] cursor-pointer max-w-full break-all"
                        onClick={handleCopy}
                      >
                        {`${SERVER_URL}${WEBHOOK_PATH}${room.id}`}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex flex-row justify-space w-full gap-1">
                  <div className="flex gap-1">
                    <Button onClick={() => setOpenTutorialModal(true)}>
                      說明
                    </Button>
                    <Button onClick={() => setOpenBaseModal(true)}>
                      變更串接
                    </Button>
                  </div>
                  {openUnbind ? (
                    <div className="flex gap-1">
                      <Button danger onClick={() => handleUnbind()}>
                        解除串接
                      </Button>
                      <Button onClick={() => setOpenUnbind(false)}>取消</Button>
                    </div>
                  ) : (
                    <Button danger onClick={() => setOpenUnbind(true)}>
                      解除串接
                    </Button>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="text-lg text-textDark font-bold w-full">
              Line@ 串接
            </div>
            <div className="text-xs text-textLight">
              透過串接 Line@ 官方帳號，您的顧客可以透過 Line 跟您的 Teamsync AI
              機器人聊天，以接觸更多潛在顧客及粉絲。
            </div>
            <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                <div className="font-bold">Channel ID</div>
                <Input
                  value={channelId}
                  onChange={(e) => setChannelId(e.target.value)}
                />
              </div>
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                <div className="font-bold">Channel Secret</div>
                <Input
                  value={channelSecret}
                  onChange={(e) => setChannelSecret(e.target.value)}
                />
              </div>
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border-t border-t-borderLight">
                <div className="font-bold">Access Token</div>
                <Input
                  value={channelAccessToken}
                  onChange={(e) => setChannelAccessToken(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-row w-full justify-end gap-2">
              <Button onClick={() => setOpenTutorialModal(true)}>說明</Button>
              <Button
                type="primary"
                disabled={!channelId || !channelSecret || !channelAccessToken}
                onClick={() => handleBind(true)}
              >
                串接
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LineSetting;
