import axios from 'axios';
import { getAuthConfig, getOfficialAuthConfig } from '@/app/ams/services/api';
import { getDebugMode } from '@/app/ams/utils/debugConfig';

const Official = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true';
const callApi = axios.create({
  timeout: 200000,
  retry: 3,
  retryDelay: 1000,
});

callApi.interceptors.request.use(async (config) => {
  try {
    let authConfig;
    if(Official) {
     authConfig = await getOfficialAuthConfig();
    } else {
     authConfig = await getAuthConfig(true);
    }
    config.baseURL = authConfig.serverUrl;
    config.headers.Authorization = `Bearer ${authConfig.accessToken}`;
    config.maxRedirects = 5;
    config.followRedirects = true;
    config.validateStatus = function (status) {
      return status >= 200 && status < 500;
    };
    return config;
  } catch (error) {
    return Promise.reject(error);
  }
}, (error) => {
  return Promise.reject(error);
});

callApi.interceptors.response.use(null, async (error) => {
  const config = error.config;
  if (!config || !config.retry || config.retryCount >= config.retry) {
    return Promise.reject(error);
  }
  config.retryCount = config.retryCount || 0;
  config.retryCount += 1;
  const delayRetry = new Promise(resolve => {
    setTimeout(resolve, config.retryDelay || 1000);
  });
  return delayRetry.then(() => callApi(config));
});

export default callApi;
