import React from 'react'
import UserAvatar from '@/app/settings/UserAvatar';
import { Switch } from 'antd';
import { useAmsContext } from '../context/AmsContext';

const ToolBar = ({ pure = false }) => {
  const { debugMode, setDebugMode } = useAmsContext();

  // useEffect(() => {
  //   console.log('debugMode', debugMode);
  // }, [debugMode]);
  
  if (pure) {
    return (
      <div className="flex flex-row w-full justify-end items-center min-h-14 border-b-borderLight px-6 border-b">
        <UserAvatar />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-row w-full justify-between items-center min-h-14 border-b-borderLight px-6 border-b">
        <div className="flex flex-row gap-4 items-center justify-start">
          <div className="text-textDark font-bold text-lg mr-6 max-w-96 truncate">
          </div>
        </div>
        <div className="flex flex-row gap-4 items-center justify-end">
          <div className="flex flex-row gap-2 items-center">
            <span className="text-textDark text-sm">Debug</span>
            <Switch checked={debugMode} onChange={setDebugMode} size="small" />
          </div>
          <UserAvatar />
        </div>
      </div>
    </>
  )
}

export default ToolBar