import { Form, Input, Switch } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

export default function BusinessHours() {
    const { getFieldValue, setFieldsValue } = Form.useFormInstance();
    
    const weekDays = [
        { label: '週一', value: 'monday' },
        { label: '週二', value: 'tuesday' },
        { label: '週三', value: 'wednesday' },
        { label: '週四', value: 'thursday' },
        { label: '週五', value: 'friday' },
        { label: '週六', value: 'saturday' },
        { label: '週日', value: 'sunday' }
    ];

    const handleSwitchChange = (day, checked) => {
        const currentTimeSlots = getFieldValue(['businessHours', day, 'timeSlots']) || [{ start: '', end: '' }];
        setFieldsValue({
            businessHours: {
                ...getFieldValue('businessHours'),
                [day]: {
                    enabled: checked,
                    timeSlots: currentTimeSlots 
                }
            }
        });
    };

    return (
        <div className="space-y-4 bg-gray-50 p-4">
            <div className="text-sm font-bold">營業時間設定</div>
            {weekDays.map((day) => (
                <div key={`business-hours-${day.value}`}>
                    <Form.List 
                        name={['businessHours', day.value, 'timeSlots']}
                        initialValue={[{ start: '', end: '' }]}
                    >
                        {(fields, { add, remove }) => (
                            <div className="flex flex-col gap-2">
                                {fields.map((field, index) => {
                                    const { key, ...restField } = field;
                                    return (
                                        <div key={`${day.value}-timeslot-${key}-${index}`} 
                                             className="flex items-center gap-2"
                                             style={{ height: '32px' }}>
                                            <div className="w-[136px] flex items-center">
                                                {index === 0 ? (
                                                    <>
                                                        <Switch 
                                                            checked={getFieldValue(['businessHours', day.value, 'enabled'])}
                                                            onChange={(checked) => handleSwitchChange(day.value, checked)}
                                                        />
                                                        <span className="w-12 ml-2">{day.label}</span>
                                                    </>
                                                ) : null}
                                            </div>
                                            <Form.Item
                                                {...restField}
                                                name={[field.name, 'start']}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input 
                                                    placeholder="10:00" 
                                                    style={{ width: '80px' }}
                                                />
                                            </Form.Item>
                                            <span>至</span>
                                            <Form.Item
                                                {...restField}
                                                name={[field.name, 'end']}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input 
                                                    placeholder="22:00" 
                                                    style={{ width: '80px' }}
                                                />
                                            </Form.Item>
                                            {fields.length > 1 && (
                                                <MinusCircleOutlined 
                                                    onClick={() => remove(field.name)}
                                                    style={{ color: '#ff4d4f', cursor: 'pointer' }}
                                                />
                                            )}
                                            {index === fields.length - 1 && (
                                                <PlusCircleOutlined
                                                    onClick={() => add({ start: '', end: '' })}
                                                    style={{ color: '#52c41a', cursor: 'pointer' }}
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </Form.List>
                </div>
            ))}
        </div>
    );
};