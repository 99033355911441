import { ENV } from '@/constants/env';

const stag5Config = {
    statistics: {
        service: [
            {
                title: "Total Services",
                dataIndex: "total_services",
                width: "200px",
                type: "main"
            },
            {
                title: "Active Services",
                dataIndex: "active_services",
                width: "200px",
                type: "main"
            },
            {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        product: [
            {
                title: "Total Products",
                dataIndex: "total_products",
                width: "200px",
                type: "main"
            },
            {
                title: "Active Products",
                dataIndex: "active_products",
                width: "200px",
                type: "main"
            },
            {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        user: [
            {
                title: "Total Membership",
                dataIndex: "total_users",
                width: "200px",
                type: "main"
            },
            {
                title: "Consumed Members",
                dataIndex: "active_users",
                width: "200px",
                type: "main"
            },
            {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        organization: [
            {
                title: "Total Stores",
                dataIndex: "total_organizations",
                width: "200px",
                type: "main"
            },
            {
                title: "Active Stores",
                dataIndex: "active_organizations",
                width: "200px",
                type: "main"
            },
            {
                title: "Low Stock Warnings",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "On Promotion",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "Drafts",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
    }
}

const pancoConfig = {
    statistics: {
        service: [
            {
                title: "總服務數",
                dataIndex: "total_services",
                width: "200px",
                type: "main"
            },
            {
                title: "上架服務數",
                dataIndex: "active_services",
                width: "200px",
                type: "main"
            },
            {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        product: [
            {
                title: "總商品數",
                dataIndex: "total_products",
                width: "200px",
                type: "main"
            },
            {
                title: "上架商品數",
                dataIndex: "active_products",
                width: "200px",
                type: "main"
            },
            {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        user: [
            {
                title: "總會員人數",
                dataIndex: "total_users",
                width: "200px",
                type: "main"
            },
            {
                title: "已消費會員人數",
                dataIndex: "active_users",
                width: "200px",
                type: "main"
            },
            {
                title: "近7天加入的會員",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "已消費會員人數",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            }
        ],
        organization: [
            {
                title: "總店家數",
                dataIndex: "total_organizations",
                width: "200px",
                type: "main"
            },
            {
                title: "上架店家數",
                dataIndex: "active_organizations",
                width: "200px",
                type: "main"
            },
            ,
            {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ]
    }
}

const _77Config = {
    statistics: {
        ticket: [
            {
                title: "總票券數",
                dataIndex: "total_tickets",
                width: "200px",
                type: "main"
            },
            {
                title: "可用票券數",
                dataIndex: "active_services",
                width: "200px",
                type: "main"
            },

            {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        product: [
            {
                title: "總商品數",
                dataIndex: "total_products",
                width: "200px",
                type: "main"
            },
            {
                title: "上架商品數",
                dataIndex: "active_products",
                width: "200px",
                type: "main"
            },
            {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        user: [
            {
                title: "總會員人數",
                dataIndex: "total_users",
                width: "200px",
                type: "main"
            },
            {
                title: "已消費會員人數",
                dataIndex: "active_users",
                width: "200px",
                type: "main"
            },
            {
                title: "近7天加入的會員",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "已消費會員人數",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            }
        ],
        organization: [
            {
                title: "總店家數",
                dataIndex: "total_organizations",
                width: "200px",
                type: "main"
            },
            {
                title: "上架店家數",
                dataIndex: "active_organizations",
                width: "200px",
                type: "main"
            },
            {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
        venue: [
            {
                title: "總場館數",
                dataIndex: "total_tickets",
                width: "200px",
                type: "main"
            }
        ],
        campaign: [
            {
                title: "總場活動數",
                dataIndex: "total_tickets",
                width: "200px",
                type: "main"
            },
            {
                title: "庫存不足警告",
                dataIndex: "low_stock_warnings",
                width: "200px",
                type: "sub"
            },
            {
                title: "促銷中",
                dataIndex: "on_promotion",
                width: "200px",
                type: "sub"
            },
            {
                title: "草稿",
                dataIndex: "drafts",
                width: "200px",
                type: "sub"
            }
        ],
    }
}

const isPanco = ENV.E_COMMERCE.PANCO;
const isStag5 = ENV.E_COMMERCE.STAG5;
const is77 = ENV.E_COMMERCE.SEVENTY_SEVEN;

export const statisticsConfig = isPanco ? pancoConfig : isStag5 ? stag5Config : is77 ? _77Config : null;
