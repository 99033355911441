/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ticketConfig } from "@/app/ams/config/ticket/ticketConfig";
import Statistics from "@/app/ams/components/Statistics";
import Detail from "@/app/ams/components/Detail";
import { useAmsContext } from '@/app/ams/context/AmsContext';
import Form from "@/app/ams/components/Form";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { useFormScroll, formLogic, ticketFormConfig } from "@/app/ams/config/ticket/ticketFormConfig";
import ToolBar from "@/app/ams/components/ToolBar";
import { statisticsConfig } from "@/app/ams/config/statistics/statisticsConfig";
import Bread from "@/app/ams/components/Bread";
import { fetchTicketsOrdersAPI } from '@/app/ams/services/api';

export default function Ticket() {
  const { selectedTicket, setSelectedTicket, tickets, loading, fetchTickets, debugMode } = useAmsContext();
  const columns = ticketConfig.columns;
  const productType = ticketConfig.apis.product_type;
  const [formData, setFormData] = useState({});
  const [currentView, setCurrentView] = useState('default');
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    console.log('currentView', currentView);

  }, [currentView]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchTickets(productType);
    console.log('debugMode', debugMode);
    
  }, []);

  const handleFormChange = (changedValues) => {
    setFormData(changedValues);
  };

  useEffect(() => {
    if (tickets?.length > 0 && !selectedTicket) {
      const firstTicket = tickets[0];
      const ticketWithImage = {
        ...firstTicket,
        imageUrl: firstTicket?.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: firstTicket.specs || [],
        description: firstTicket.description || '',
        notification: firstTicket.notification || '',
        status: firstTicket.status || 'On Sale',
        provider: firstTicket.provider || '',
        category: firstTicket.category || {},
        tags: firstTicket.tags || [],
        recommendation: firstTicket.recommendation || null,
        publishDate: firstTicket.publishDate || null
      };
      setSelectedTicket(ticketWithImage);
    }
  }, [tickets, selectedTicket]);

  const handleRowClick = (record) => {
    if (selectedTicket?.key !== record.key) {
      const ticketWithImage = {
        ...record,
        imageUrl: record?.blobs?.[0]?.url || '/images/default-service.jpg',
        specs: record.specs || [],
        description: record.description || '',
        notification: record.notification || '',
        status: record.status || 'On Sale',
        provider: record.provider || '',
        category: record.category || {},
        tags: record.tags || [],
        recommendation: record.recommendation || null,
        publishDate: record.publishDate || null
      };
      setSelectedTicket(ticketWithImage);
    }
  };

  const renderTabs = () => {
    return (
      <div className="flex gap-4 my-2">
        {ticketConfig.tabs.map((tab) => (
          <Button
            key={tab.key}
            className={`${currentView === tab.key
              ? "bg-[#90C290] hover:bg-[#7AB27A]"
              : "bg-white hover:bg-gray-100 border border-gray-200"
              } px-3 py-2 rounded-full`}
            onClick={() => setCurrentView(tab.key)}
          >
            {tab.label}
          </Button>
        ))}
      </div>
    );
  };

  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">{ticketFormConfig.ticket.title}</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          {ticketFormConfig.ticket.title}
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">{ticketFormConfig.ticket.add}</span>
      </div>
    </div>
  );

  const ShowTicketOrders = () => {
    const [ticketOrders, setTicketOrders] = useState([]);
    useEffect(() => {
      const fetchOrders = async () => {
        try {
          const response = await fetchTicketsOrdersAPI(selectedTicket?.id, selectedTicket?.specs[0]?.id);
          const orders = Array.isArray(response) ? response : [];
          const ordersWithKeys = orders.map((order, index) => ({
            ...order,
            key: order.id || `order-${index}`
          }));
          setTicketOrders(ordersWithKeys);
        } catch (error) {
          console.error('獲取票券訂單失敗:', error);
          setTicketOrders([]);
        }
      };

      if (selectedTicket?.id && selectedTicket?.specs?.[0]?.id) {
        fetchOrders();
      }
    }, [selectedTicket]);

    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold">{selectedTicket?.name || selectedTicket?.title}</div>
            </div>
            <Bread title={ticketFormConfig?.page?.title || ""} path="ticket" subTitle={ticketFormConfig?.page?.ticketOrders || ""} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            <Statistics data={statisticsConfig?.statistics?.ticket} type="ticket" />
            <Table
              columns={columns({
                setSelectedTicket,
                tickets,
              })}
              dataSource={ticketOrders}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedTicket?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedTicket?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedTicket}
              type="ticket"
              items={ticketConfig?.detail?.(selectedTicket, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowTicketSend = () => {
    const [ticketOrders, setTicketOrders] = useState([]);
    useEffect(() => {
      const fetchOrders = async () => {
        try {
          const response = await fetchTicketsOrdersAPI(selectedTicket?.id, selectedTicket?.specs[0]?.id);
          const orders = Array.isArray(response) ? response : [];
          const ordersWithKeys = orders.map((order, index) => ({
            ...order,
            key: order.id || `order-${index}`
          }));
          setTicketOrders(ordersWithKeys);
        } catch (error) {
          console.error('獲取票券訂單失敗:', error);
          setTicketOrders([]);
        }
      };

      if (selectedTicket?.id && selectedTicket?.specs?.[0]?.id) {
        fetchOrders();
      }
    }, [selectedTicket]);

    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold">{selectedTicket?.name || selectedTicket?.title}</div>
            </div>
            <Bread title={ticketFormConfig?.page?.title || ""} path="ticket" subTitle={ticketFormConfig?.page?.ticketOrders || ""} />
            <div className="flex gap-4">
              {renderTabs()}
            </div>
            <Statistics data={statisticsConfig?.statistics?.ticket} type="ticket" />
            <Table
              columns={columns({
                setSelectedTicket,
                tickets,
              })}
              dataSource={ticketOrders}
              loading={loading}
              pagination={{
                pageSize: 10,
                className: "sticky bottom-0 pb-2",
              }}
              onRow={(record) => ({
                onClick: () => handleRowClick(record),
                className: record.key === (selectedTicket?.key ?? '') ? "bg-gray-100" : "",
              })}
              rowClassName={(record) => `cursor-pointer ${record.key === (selectedTicket?.key ?? '') ? "bg-gray-100" : ""}`}
              scroll={{ x: 2000 }}
              size="small"
              className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
            />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail
              data={selectedTicket}
              type="ticket"
              items={ticketConfig?.detail?.(selectedTicket, true)}
            />
          </div>
        </div>
      </>
    );
  };

  const ShowDefault = () => {
    return (
      <>
        <div className="flex w-full overflow-auto bg-gray-50">
          <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
              <div className="flex justify-between items-center">
                <div className="text-lg font-bold">{ticketFormConfig.ticket.title}</div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setShowForm(true)
                  }}
                  className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                >
                  {ticketFormConfig.ticket.add}
                </Button>
              </div>
              <Bread title={ticketFormConfig.ticket.title} path="ticket" page={ticketFormConfig.ticket.add} />
              <Statistics data={statisticsConfig?.statistics?.ticket} type="ticket" />
              <Table
                columns={columns({
                  currentView,
                  setCurrentView,
                  setSelectedTicket,
                  tickets,
                })}
                dataSource={tickets}
                loading={loading}
                pagination={{
                  pageSize: 10,
                  className: "sticky bottom-0 pb-2",
                }}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                  className: record.key === (selectedTicket?.key ?? '') ? "bg-gray-100" : "",
                })}
                rowClassName={(record) => `cursor-pointer ${record.key === (selectedTicket?.key ?? '') ? "bg-gray-100" : ""}`}
                scroll={{ x: 2000 }}
                size="small"
                className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0]"
              />
            </div>
            <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
              <Detail
                data={selectedTicket}
                type="ticket"
                items={ticketConfig?.detail?.(selectedTicket, true)}
              />
          </div>
        </div>
      </>
    );
  };

  // render content
  const renderContent = () => {
    const views = {
      default: <ShowDefault />,
      ticket: <ShowTicketOrders />,
      ticket_send: <ShowTicketSend />,
    };
    return views[currentView] || views.default;
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
      <ToolBar />
      <div className="flex flex-col w-full h-[calc(100vh-64px)]">
        <div className="flex-1 flex overflow-auto">
        {showForm ? 
        <>
        <div className="flex w-full space-x-4 overflow-auto">
          <div className="flex-1 overflow-hidden">
            <BreadcrumbNav />
            <Form onChange={handleFormChange} formConfig={ticketFormConfig} formLogic={formLogic} useFormScroll={useFormScroll} title={ticketFormConfig.ticket.title} draftText={ticketFormConfig.ticket.draft} publishText={ticketFormConfig.ticket.publish} />
          </div>
          <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
            <Detail data={formData} items={ticketConfig.detail(formData, false)} />
            </div>
          </div>
        </>
        :
        (renderContent())}
        </div>
      </div>
    </div>
  );
};
