import { request, callApi } from '@/utils';
import { productConfig } from '@/app/ams/config/product/productConfig';
import { getDebugMode, setDebugMode } from '@/app/ams/utils/debugConfig';  
import { message } from 'antd';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// ===== 認證相關 API =====
const AUTH_STORAGE_KEY = 'auth_config';
const AUTH_EXPIRY_TIME = 5 * 60 * 1000; // 5分鐘，單位為毫秒

const getStoredAuthConfig = () => {
  const storedData = localStorage.getItem(AUTH_STORAGE_KEY);
  if (!storedData) return null;
  
  try {
    const { accessToken, serverUrl, timestamp } = JSON.parse(storedData);
    const now = Date.now();
    
    // 檢查是否超過5分鐘
    if (now - timestamp > AUTH_EXPIRY_TIME) {
      localStorage.removeItem(AUTH_STORAGE_KEY);
      return null;
    }
    
    return { accessToken, serverUrl };
  } catch (error) {
    console.error('解析儲存的認證配置失敗:', error);
    localStorage.removeItem(AUTH_STORAGE_KEY);
    return null;
  }
};

const storeAuthConfig = (accessToken, serverUrl) => {
  const authData = {
    accessToken,
    serverUrl,
    timestamp: Date.now()
  };
  localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(authData));
};

export const getAuthConfig = async (checkTime = true) => {
  try {
    if (checkTime) {
      // 先檢查 localStorage
    const storedConfig = getStoredAuthConfig();
    if (storedConfig) {
        return storedConfig;
      }
    }

    // 如果沒有有效的儲存配置，則重新獲取
    const debug = getDebugMode();
    const [accessToken, serverUrl] = await Promise.all([
      getAccessToken(debug),
      getServerUrl(debug)
    ]);

    // 儲存新的配置到 localStorage
    storeAuthConfig(accessToken, serverUrl);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};

export const getOfficialAuthConfig = async () => {
  try {
    const [accessToken, serverUrl] = await Promise.all([
      getOfficialAccessToken(),
      getOfficialServerUrl()
    ]);

    return {
      accessToken,
      serverUrl
    };
  } catch (error) {
    console.error('獲取認證配置失敗:', error);
    throw new Error('Failed to fetch authentication configuration');
  }
};

export const getServerUrl = async (debug) => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/ecommerce/profile?debug=${debug}`);
    return response.body.server_url;
    
  } catch (error) {
    console.log('error', error);
    
    if (error && error.status === 404 && debug === true) {
      console.warn('404 錯誤，將 debugMode 切換為 false 並重新嘗試...');
      message.warning('開發模式尚未開啟，請聯絡客服');
      setDebugMode(false);
      return await getServerUrl(false); // 重新調用函數，使用 debug = false
    }
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};

export const getAccessToken = async (debug) => {
  try {
    const response = await request.get(
      `${SERVER_URL}/private/property/ecommerce/jwt?debug=${debug}`
    );

    return response.access_token;
  } catch (error) {
    if (error && error.status === 404 && debug === true) {
      console.warn('404 錯誤，將 debugMode 模式關閉 並重新嘗試...');
      setDebugMode(false);
      return await getAccessToken(false); // 重新調用函數，使用 debug = false
    }
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};

export const getOfficialAccessToken = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/jwt`);
    console.log('response', response.access_token);
    return response.access_token;
  } catch (error) {
    console.error('獲取存取令牌失敗:', error);
    throw new Error('Unable to fetch access token');
  }
};

export const getOfficialServerUrl = async () => {
  try {
    const response = await request.get(`${SERVER_URL}/private/property/official_site/profile`);
    console.log('response', response.body.server_url);
    
    return response.body.server_url;
  } catch (error) {
    console.error('獲取伺服器 URL 失敗:', error);
    throw new Error('Unable to fetch server URL');
  }
};

// ===== 使用者相關 API =====
export const fetchUsersAPI = async () => {
  try {
    const response = await callApi.get('/system/user/all');
    return response.data.users;
  } catch (error) {
    console.error('獲取用戶數據失敗:', error);
    throw new Error('Unable to fetch users');
  }
};

export const fetchUserServiceOrdersAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_user/${userId}?product_type=service_product_ii`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶服務訂單失敗:', error);
    throw new Error('Unable to fetch service orders');
  }
};

export const fetchUserProductOrdersAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_user/${userId}?product_type=normal_product_dp`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶商品訂單失敗:', error);
    throw new Error('Unable to fetch product orders');
  }
};

export const fetchUserTicketOrdersAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_user/${userId}?product_type=free_ticket`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶票券訂單失敗:', error);
    throw new Error('Unable to fetch ticket orders');
  }
};

export const fetchUserCompaignsAPI = async (userId) => {
  try {
    const response = await callApi.get(`/system/campaign/${userId}`);
    return response.data;
  } catch (error) {
    console.error('獲取用戶活動列表失敗:', error);
    throw new Error('Unable to fetch campaign list');
  }
};

export const fetchIdentityAPI = async (identityId) => {
  try {
    const response = await callApi.get(`/public/identity/${identityId}`);
    return response;
  } catch (error) {
    console.error('獲取身份證列表失敗:', error);
    throw error;
  }
};

export const fetchIdentityListAPI = async (orgId) => {
  if (orgId) {
    try {
      const response = await callApi.get(`/public/identity/all/${orgId}`);
      
      if (response.data && Array.isArray(response.data)) {
        return response.data;
      }
      return [];
    } catch (error) {
      console.error('獲取美容師列表失敗:', error);
      throw error;
    }
  }
  return [];
};

// ===== 組織相關 API =====
export const fetchOrganizationsAPI = async () => {
  try {
    const response = await callApi.get('/public/organization/');
    return response.data;
  } catch (error) {
    console.error('獲取店家列表失敗:', error);
    throw error;
  }
};

export const createOrganizationAPI = async (organizationData) => {
  try {
    const response = await callApi.post('/private/organization/', organizationData);
    return response;
  } catch (error) {
    console.error('創建店家失敗:', error);
    throw error;
  }
};

export const verifyOrganizationAPI = async (organizationId) => {
  try {
    const response = await callApi.post(`/system/organization/verify/${organizationId}`);
    return response;
  } catch (error) {
    console.error('驗證店家失敗:', error);
    throw error;
  }
}

export const fetchOrganizationServiceOrdersAPI = async (productType, orgId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_org/${orgId}?product_type=${productType}`);
    return response.data;
  } catch (error) {
    console.error('獲取服務訂單失敗:', error);
    throw new Error('Unable to fetch service orders');
  }
};

export const fetchOrganizationProductOrdersAPI = async (productType, orgId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_org/${orgId}?product_type=${productType}`);
    return response.data;
  } catch (error) {
    console.error('獲取商品訂單失敗:', error);
    throw new Error('Unable to fetch product orders');
  }
};

// ===== 服務相關 API =====
export const fetchServicesAPI = async (productType, orgId) => {
  try {
    const url = orgId 
      ? `/public/product/?product_type=${productType}&org_id=${orgId}`
      : `/public/product/?product_type=${productType}`;
      
    const response = await callApi.get(url);
    return response.data;
  } catch (error) {
    console.error('獲取店家列表失敗:', error);
    throw error;
  }
};

export const fetchServiceOrdersAPI = async (productId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_product/${productId}`);
    return response.data;
  } catch (error) {
    console.error('獲取服務訂單失敗:', error);
    throw new Error('Unable to fetch service orders');
  }
};

export const updateServiceAPI = async (serviceId, updateData) => {
  try {
    const { name, description, notification } = updateData;
    const updates = [];
    
    // Update name
    if (name) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=name&value=${encodeURIComponent(name)}`
        )
      );
    }

    // Update description
    if (description) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=description&value=${encodeURIComponent(description)}`
        )
      );
    }

    // Update notification
    if (notification) {
      updates.push(
        callApi.patch(
          `/private/sp/${serviceId}?fields=notification&value=${encodeURIComponent(notification)}`
        )
      );
    }

    // Execute all updates concurrently
    await Promise.all(updates);
    return true;
    
  } catch (error) {
    console.error('更新服務數據失敗:', error);
    throw new Error('Unable to update service');
  }
};

export const createServiceAPI = async (formattedData, organizationId) => {
  try {
    const response = await callApi.post(`/private/product/${organizationId}`, formattedData);
    return response;
  } catch (error) {
    console.error('儲存服務數據失敗:', error);
    throw new Error('Unable to save service');
  }
};

export const createProviderAPI = async (serviceId, identityId, providerId) => {
  try {
    const body = {
      identity_id: identityId,
      user_id: providerId
    }
    const response = await callApi.post(`/private/product/${serviceId}/provider`,body);
    return response;
  } catch (error) {
    console.error('新增美容師失敗:', error);
    throw error;
  }
};

// ===== 產品相關 API =====
export const fetchProductsAPI = async () => {
  try {
    // 1. Check category data
    const categoriesResponse = await productConfig.apis.fetchCategories();
    const categories = categoriesResponse.data;
    
    // 2. Check product data for each category
    const productsPromises = categories.map(category => 
      callApi.get(`/public/product/byCategory/${category.id}`)
    );
    
    const productsResponses = await Promise.all(productsPromises);
    
    // 3. Check final integrated data
    const allProducts = productsResponses.reduce((acc, response) => {
      const products = response.data.map(product => ({
        ...product,
        key: product.id,
        category: categories.find(cat => cat.id === product.category_id)
      }));
      return [...acc, ...products];
    }, []);
    
    return allProducts;
    
  } catch (error) {
    console.error('獲取產品數據失敗:', error);
    throw new Error('Unable to fetch products');
  }
};

export const fetchProductOrdersAPI = async (productId) => {
  try {
    const response = await callApi.get(`/system/orders/list_by_product/${productId}`);
    return response.data;
  } catch (error) {
    console.error('獲取產品訂單失敗:', error);
    throw new Error('Unable to fetch product orders');
  }
};

export const fetchOfficialProductsAPI = async () => {
  try {
    const response = await callApi.get('/group/6fbba4f2-d965-452a-8ec4-75e780317b25');
    console.log('response:', response);
    return response;
  } catch (error) {
    console.error('獲取需求單數據失敗:', error);
    throw new Error('Unable to fetch demand');
  }
};

export const updateProductImagesAPI = async (productId, { imagesToAdd, imagesToRemove = [] }) => {
  try {
    const formData = new FormData();
    
    // 添加新圖片
    if (imagesToAdd && imagesToAdd.length > 0) {
      imagesToAdd.forEach(file => {
        formData.append('images_to_add', file);
      });
    }

    // 構建 URL，包含要刪除的圖片 ID
    let url = `/private/product/${productId}/images`;
    if (imagesToRemove.length > 0) {
      const removeQuery = imagesToRemove.map(id => `images_to_remove=${id}`).join('&');
      url += `?${removeQuery}`;
    }

    const response = await callApi.patch(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response;
  } catch (error) {
    console.error('更新產品圖片失敗:', error);
    throw error;
  }
};

// ===== 票券相關 API =====
export const fetchTicketsOrdersAPI = async (productId, specId) => {
  try {
    if (!productId || !specId) {
      return [];
    }
    
    const url = `/system/ticket/config/${productId}?product_spec_id=${specId}`;
    const response = await callApi.get(url);
    
    // 確保返回的是數組
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('獲取票券數據失敗:', error);
    return []; // 發生錯誤時返回空數組
  }
};

// ===== 場地相關 API =====
export const fetchVenueAPI = async () => {
  try {
    // 1. 獲取組織列表
    const response = await callApi.get('/public/organization/');
    
    // 2. 確保有返回數據且為數組
    if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
      return [];
    }

    // 3. 根據 created_at 排序並獲取最早創建的組織
    const earliestOrg = response.data.reduce((earliest, current) => {
      if (!earliest || new Date(current.created_at) < new Date(earliest.created_at)) {
        return current;
      }
      return earliest;
    }, null);

    // 4. 獲取組織 ID
    const orgId = earliestOrg ? earliestOrg.id : null;
    if (!orgId) return [];

    // 5. 獲取場地數據
    const venueResponse = await callApi.get(`/public/erp/category/${orgId}/category/detail`);

    return venueResponse.data;

  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    throw new Error('Unable to fetch venue');
  }
};

export const fetchHallsAPI = async () => {
  try {
    // 1. 獲取組織列表
    const response = await callApi.get('/public/organization/');
    
    // 2. 確保有返回數據且為數組
    if (!response.data || !Array.isArray(response.data) || response.data.length === 0) {
      return [];
    }

    // 3. 根據 created_at 排序並獲取最早創建的組織
    const earliestOrg = response.data.reduce((earliest, current) => {
      if (!earliest || new Date(current.created_at) < new Date(earliest.created_at)) {
        return current;
      }
      return earliest;
    }, null);

    // 4. 獲取組織 ID
    const orgId = earliestOrg ? earliestOrg.id : null;
    if (!orgId) return [];

    // 5. 獲取場地數據
    const venueResponse = await callApi.get(`/public/erp/category/${orgId}/category/detail`);

    return venueResponse.data;

  } catch (error) {
    console.error('獲取場地數據失敗:', error);
    throw new Error('Unable to fetch venue');
  }
};

// ===== 活動相關 API =====
export const fetchCampaignAPI = async () => {
  try {
    const response = await callApi.get('/public/campaign/fromRange?valid_from=2000-12-31 00:00&valid_to=3000-12-31 00:00');
    return response.data;
  } catch (error) {
    console.error('獲取活動數據失敗:', error);
    throw new Error('Unable to fetch campaign');
  }
};


// ===== 規格與折扣相關 API =====
export const createSpecAPI = async (serviceId, specData) => {
  try {
    const response = await callApi.post(`/private/product/${serviceId}/spec`, specData);
    return response.data;
  } catch (error) {
    console.error('新增規格失敗:', error);
    throw error;
  }
};

export const createTicketAPI = async (productId, productSpecId) => {
  try {
    const response = await callApi.post(`/private/ticket/create/${productId}?product_spec_id=${productSpecId}`);
    return response.data;
  } catch (error) {
    console.error('新增規格失敗:', error);
    throw error;
  }
};

export const createDiscountAPI = async (serviceId, discountData) => {
  try {
    console.log('創建折扣前的數據檢查:', {
      serviceId,
      discountData,
      hasValidFrom: !!discountData.valid_from,
      hasValidUntil: !!discountData.valid_until,
      validFromType: typeof discountData.valid_from,
      validUntilType: typeof discountData.valid_until
    });

    // Ensure date format is correct
    const formattedData = {
      ...discountData,
      valid_from: discountData.valid_from || null,
      valid_until: discountData.valid_until || null
    };

    const response = await callApi.post(`/private/product/${serviceId}/discount`, formattedData);
    return response;
  } catch (error) {
    console.error('折扣創建錯誤:', {
      error,
      requestData: discountData,
      response: error.response?.data
    });
    throw error;
  }
};

// ===== 分類相關 API =====
export const fetchCategoriesAPI = async (type) => {
  try {
    const response = await callApi.get(`/public/category/?product_type=${type}`);
    
    if (response.data) {
      const categoryList = response.data.map(category => ({
        id: category.id,
        name: category.name,
        product_type: category.product_type,
        category_id: category.id
      }));
      
      return categoryList;
    }
    return [];
  } catch (error) {
    console.error('獲取類別表失敗:', error);
    throw error;
  }
};
