import { Link } from "react-router-dom";
import { useEffect } from "react";
import { HomeOutlined } from "@ant-design/icons";


export default function Bread({ title, path, subTitle }) {
  return (
    <div className="flex flex-col px-4 my-2">
      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="cursor-pointer"
        >
          <a href={`/${path}`} className="hover:text-black">{title}</a>
        </span>
        {subTitle && (
          <>
            <span className="mx-2">/</span>
            <span>{subTitle}</span>
          </>
        )}
      </div>
    </div>
  )
}